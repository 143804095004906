import _ from 'lodash';
import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import {
  BOOKING_STATUS_FILTERS,
  BOOKING_STATUS_FILTERS_NAMES,
} from 'config/constants';

import BookingConfirmationStatus from '../../BookingConfirmationStatus/BookingComfirmationStatus';
import BookingFormCancelStatusBtn from './BookingFormCancelStatusBtn/BookingFormCancelStatusBtn';

const BookingFormStatusField = ({ status, form, isDisabled, values, isCreateBooking, classPrefix = 'booking-form' }) => {
  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
  } = usePopperTooltip(
    {
      offset: [0, 5],
      trigger: 'click',
      placement: 'bottom-start',
      visible: isStatusPopupOpen,
      onVisibleChange: setIsStatusPopupOpen,
    }
  );

  const FILTERS_FOR_SELECT = _.pickBy(BOOKING_STATUS_FILTERS, (_, key) => {
    if (key === BOOKING_STATUS_FILTERS_NAMES.all) {
      return false;
    }

    if (key === BOOKING_STATUS_FILTERS_NAMES.cancelled) {
      return false;
    }

    return true;
  })

  const handleClick = (option) => {
    if (isDisabled) return;

    if (option.label !== BOOKING_STATUS_FILTERS_NAMES.cancelled) {
      form.change('is_success', 1);
    }

    form.change('bookingStatus', option);
    setIsStatusPopupOpen(false);
  }

  return (
    <>
      <button
        type='button'
        ref={setTriggerRef}
        className={`${classPrefix}__status-btn`}
        style={{ borderColor: status.color }}
        disabled={isDisabled}
      >
        {status.label}
        <span
          className={`${classPrefix}__dot`}
          style={{ backgroundColor: status.color }}
        />
      </button>

      {visible &&
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: `${classPrefix}__status-popup` })}
        >
          <div className={`${classPrefix}__status-popup-header`}>Select booking status</div>
          <div className={`${classPrefix}__status-popup-list`}>
            {Object.values(FILTERS_FOR_SELECT).map((option) => (
              <BookingConfirmationStatus
                status={option.label}
                key={option.label}
                onClick={() => handleClick(option)}
              />
            ))}

            {!isCreateBooking && (
              <BookingFormCancelStatusBtn
                form={form}
                values={values}
                status={BOOKING_STATUS_FILTERS_NAMES.cancelled}
              />
            )}
          </div>
        </div>
      }
    </>
  );
};

export default BookingFormStatusField;
