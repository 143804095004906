import React from 'react';

import MissedCallItem from 'components/MIssedCallItem/MissedCallItem';
import AutoSizer from "react-virtualized-auto-sizer";
import { Scrollbars } from "react-custom-scrollbars-2";


const DialpadMissedCallsList = ({ callList, ...rest }) => {
  return (
    <AutoSizer>
    {({ width, height }) => (
      <Scrollbars
        hideTracksWhenNotNeeded
        style={{ width, height: height - 28 }}
        renderThumbVertical={props => (
          <div {...props} className="thumb-vertical" />
        )}
        renderTrackVertical={props => (
          <div {...props} className="track-vertical" />
        )}
      >
        <div className='dialpad-list__list-container'>
          {callList?.length ?
            <ul className="dialpad-list__list">
              {callList.map(id => (
                <MissedCallItem
                  id={id}
                  key={id}
                  {...rest}
                />
              ))}
            </ul>
            :
            <div className="dialpad-list__no-items">no items</div> 
          }
        </div>
      </Scrollbars>
    )}
  </AutoSizer>
  );
};

export default React.memo(DialpadMissedCallsList);