import { v4 } from 'uuid';
import showContactName from './showContactName';
import getHighlightedText from './getHighlightedText';
import { ADR_BOOK_SEARCH_FILTERS } from 'config/constants';

const setDeviderBetweenJSX = (array, devider) => {
  return array.reduce((acc, jsx) => !acc ? [jsx] : [...acc, devider, jsx], null);
}

export default (contact, search, searchFilters) => {
  const getHighlightsForField = (title, field, selector) => {
    let highlights = [];

    const pushHighlight = (item) => {
      if (!item) {
        return;
      }

      const commonText = selector && typeof item === "object" ? selector(item) : item;
      let highlightedText;

      if (commonText) {
        highlightedText = getHighlightedText(commonText, search, 'adr-book-item__highlight');
        highlightedText && highlights.push(highlightedText);
      }
    }

    if (field instanceof Array) {
      field.forEach(pushHighlight);
    } else {
      pushHighlight(field);
    }

    return highlights.length ? (
      <span key={v4()} className='adr-book-item__highlight-field'>
        <span className='adr-book-item__highlight-field-title'>{title}: </span>{setDeviderBetweenJSX(highlights, ', ')}
      </span>
    ) : null;
  }

  const isVisualBySearchFilters = (searchFilters, filter) => {
    return searchFilters.includes(filter) || searchFilters.length === 0;
  }

  const getHighlights = () => {
    let highlights = [];

    const notes = isVisualBySearchFilters(searchFilters, ADR_BOOK_SEARCH_FILTERS.NOTES) && (contact.minimizedNotes || contact.note);
    const urls = isVisualBySearchFilters(searchFilters, ADR_BOOK_SEARCH_FILTERS.LINK_URL) && (contact.minimizedUrls || contact.urls);
    const tels = isVisualBySearchFilters(searchFilters, ADR_BOOK_SEARCH_FILTERS.TEL_NUMBER) && (contact.minimizedTels || contact.tels);
    const emails = isVisualBySearchFilters(searchFilters, ADR_BOOK_SEARCH_FILTERS.EMAIL) && (contact.minimizedEmails || contact.emails);
    const addresses = isVisualBySearchFilters(searchFilters, ADR_BOOK_SEARCH_FILTERS.ADDRESS) && (contact.minimizedAddresses || contact.addresses);
    const telegramNicknames = isVisualBySearchFilters(searchFilters, ADR_BOOK_SEARCH_FILTERS.TEL_NICKNAME) && (contact.minimizeTelegramNicknames || contact.telegram_nicknames);

    [
      (isVisualBySearchFilters(searchFilters, ADR_BOOK_SEARCH_FILTERS.DIVA_ID) && 
      getHighlightsForField('ID', contact.diva_id)),
      (isVisualBySearchFilters(searchFilters, ADR_BOOK_SEARCH_FILTERS.ID) && 
      getHighlightsForField('Custom ID', contact.custom_id)),
      getHighlightsForField('Phone', tels, ({ tel }) => tel),
      getHighlightsForField('Email', emails, ({ email }) => email),
      getHighlightsForField('Telegram', telegramNicknames, ({ nickname }) => nickname),
      getHighlightsForField('Address', addresses, ({ address }) => address),
      getHighlightsForField('Link', urls, ({ url }) => url),
      getHighlightsForField('Note', notes),
    ].forEach(
      (highlight) => highlight && highlights.push(highlight)
    )

    return setDeviderBetweenJSX(highlights, '/');
  };

  const getHighlightedSearchText = () => {
    const searchText = search;
    const idxOfSearchedPart = contact.fn.toUpperCase().indexOf(searchText.toUpperCase());

    if(idxOfSearchedPart >= 0) {
      const strBefore = contact.fn.slice(0, idxOfSearchedPart)
      let strBeforeToArraay = [<span key={v4()}>{strBefore}</span>];

      if(strBefore.startsWith('EX') && !!contact.is_ex) {
        strBeforeToArraay = [<mark key={v4()} className="adr-book-item__ex">EX</mark>, strBefore.slice(2)];
      }

      const sanitizedSearchText = searchText.replace(/[-\/()]/g, '\\$&');
      const escapedSubstring = sanitizedSearchText.replace(/\+/g, "\\+");
      const regExForSearchedText = new RegExp(escapedSubstring, "gi");
      const searchedTxtFromFn = contact.fn.match(regExForSearchedText) ? contact.fn.match(regExForSearchedText)[0] : '';

      const searchedStrToJsx = <mark key={v4()} className="adr-book-item__highlight">{searchedTxtFromFn}</mark>;
      const strAfter = <span key={v4()}>{contact.fn.slice(idxOfSearchedPart + searchText.length)}</span>;
      const searchResult = [...strBeforeToArraay, searchedStrToJsx, strAfter];
      return isVisualBySearchFilters(searchFilters, ADR_BOOK_SEARCH_FILTERS.FN) ? searchResult : contact.fn;
    } else return contact.fn;
  };

  const contactTels = contact.minimizedTels || contact.tels || [];
  const highLightedFnText = contact.fn && search ? getHighlightedSearchText() : showContactName(contact.fn, 'adr-book-item__ex');
  const highLightedContent = search ? getHighlights() || contactTels[0]?.tel : contactTels[0]?.tel;

  return [highLightedFnText, highLightedContent];
};