import { BOOKING_STATES } from '../config/constants'

const { BOOKING, ...restBookingStates } = BOOKING_STATES;

const causes = {
  [BOOKING_STATES.REQUESTS]: (booking) => Array.isArray(booking.requests) ? booking.requests?.length > 0 : booking.requests,
  [BOOKING_STATES.NOTES]: (booking) => Boolean(booking.notes),
  [BOOKING_STATES.BOOKING]: (booking) => !booking.is_prebooking,
  [BOOKING_STATES.PREBOOKING]: (booking) => booking.is_prebooking,
  [BOOKING_STATES.AGENT]: (booking) => booking.agent_ids && Object.values(booking.agent_ids).some(Boolean),
  [BOOKING_STATES.ZENKIT]: (booking) => Boolean(booking.zenkit_url),
}

export default (booking) => (selectedStates) => {
  if (selectedStates) {
    return selectedStates.find((state) => {
      if (causes[state](booking)) {
        return state;
      }
    })
  } else {
    return Object.keys(restBookingStates).filter((state) => causes[state](booking))
  }
}
