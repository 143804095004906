import React from 'react';
import { LS } from 'utils';

import './ConfirmOnLogoutModal.scss';
import API from 'api/api';
import AsyncButton from 'components/AsyncButton/AsyncButton';

const ConfirmOnLogoutModal = (props) => {
  const logoutUser = () => {
    LS.setItem("adrBookContactType", 0, localStorage.currentUserId);

    return API.logoutUser()
      .catch(console.dir)
      .finally(() => {

        delete localStorage.isLoggedIn;
        delete localStorage.currentUserId;

        sessionStorage.setItem('lastInteractionPage', window.location.pathname);

        navigate(ROUTES.login);
      })
  }

  return (
    <div className="confirm-logout-modal">
      <h2 className="confirm-logout-modal__title">Confirm your action</h2>
      <h3 className="confirm-logout-modal__subtitle">Are you sure you want to sign out now?</h3>

      <div className='confirm-logout-modal__btns'>
        <button
            className="confirm-logout-modal__btn confirm-logout-modal__btn--cancel"
            onClick={() => props.closeModal()}
        >
            Cancel
        </button>
        <AsyncButton
            onClick={logoutUser}
            className="confirm-logout-modal__btn confirm-logout-modal__btn--logout"
        >
            Logout
        </AsyncButton>
      </div>
    </div>
  );
}

export default ConfirmOnLogoutModal;