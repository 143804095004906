import React, { useEffect, useRef, useState } from 'react';
import './Title.scss';
import { classModifier } from 'utils';
import classNames from 'classnames';

const Title = React.forwardRef((props, ref) => {
  const {
    className,
    isShowCount = true,
    icon: Icon,
    iconSize,
    iconPosition = 'right',
    count,
    onClick,
    children
  } = props;

  // const [isOverflowed, setIsOverflowed] = useState(false);
  const inlineRef = useRef();

  const classes = classNames(
    className,
    classModifier(
      'title',
      (onClick || ref) && 'clickable'
    )
  )

  // useEffect(() => {
  //   if (!inlineRef.current) return;

  //   const element = inlineRef.current;

  //   const checkOverflow = () => {
  //     if (element) {
  //       const isOverflowing =
  //         element.scrollWidth > element.offsetWidth ||
  //         element.scrollHeight > element.offsetHeight;
  //       setIsOverflowed(isOverflowing);
  //     }
  //   };

  //   const resizeObserver = new ResizeObserver(checkOverflow);

  //   resizeObserver.observe(element);

  //   checkOverflow();

  //   return () => resizeObserver.unobserve(element);
  // }, [])

  return (
    <h4
      className={classes}
      onClick={onClick}
      ref={ref}
    >
      {iconPosition === 'left' && Icon &&
        <div className="title__icon-wrapper">
          <Icon
            className="title__icon"
            width={iconSize}
            height={iconSize}
          />
        </div>
      }

      <span
        ref={inlineRef}
        className="title__text"
      >
        {children}
      </span>

      {iconPosition === 'right' && Icon &&
        <div className="title__icon-wrapper">
          <Icon
            className="title__icon"
            width={iconSize}
            height={iconSize}
          />
        </div>
      }

      {!!count && isShowCount &&
        <span className="title__count">
          {" - " + props.count}
        </span>
      }
    </h4>
  )
});

export default React.memo(Title);
