import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import API from 'api/api';
import ICONS from 'assets/icons';
import { useDidMount, useToggle } from 'hooks';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';
import { getActiveDivaGirls } from 'redux/ducks/divaGirls';
import { selectContactById } from 'redux/selectors/selectors';
import { updateActiveAdrBookContactId } from 'redux/ducks/addressBook';
import { classModifier, getContactAvatar, showContactName, textLineSplitter } from 'utils';
import { CONTACT_INFO_DATE_CONFIG } from 'config/dates-сonfig';
import { ROUTES } from '../../../../../../config/constants';
import { openModal } from 'redux/ducks/activeWindows';
import { updateActiveContact } from 'redux/ducks/clientChats';

import "./ContactInfo.scss";
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import CopyToClipboardButton from 'components/Buttons/CopyToClipboardButton';
import LockForm from 'components/LockForm/LockForm';
import Spinner from 'components/UI/Spinner/Spinner';
import DateTime from 'components/DateTime';
import classNames from 'classnames';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import ContactTags from 'components/ContactTags/ContactTags';

const ContactInfo = props => {
  const {
    activeRecipient,
    notForClients,
    openModal,
    isAdrBookPageOpen,
  } = props;
  const [bookingInfoLoading, toggleBookingInfoLoading] = useToggle(true);
  const [extendedBookingInfo, setExtendedBookingInfo] = useState([]);

  const dispatch = useDispatch();

  useDidMount(() => {
    API.getExtendedBookingInfoByCallerId(activeRecipient.id)
      .then(({ data }) => {
        setExtendedBookingInfo(data);
      })
      .catch(console.error)
      .finally(() => toggleBookingInfoLoading(false));
  });

  const openContactAgentChat = (contact) => {
    props.updateActiveContact(contact);
    props.saveMapState?.()
    props.closeModal();
  };

  const handleopenContactChat = () => {
    props.updateActiveContact(activeRecipient);
    props.saveMapState?.()
    props.closeModal();
  }

  const renderGirlUrls = urls => urls.map(url => (
    <div className="contact-info__group--item contact-info__group--links" key={url.url}>
      <div className="contact-info__value contact-info__value--trim">
        <ICONS.link />

        <span className="contact-info__link-label">{url.title} </span>
        <span className="contact-info__link">
          <a
            href={url.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {url.url}
          </a>
        </span>
      </div>

      <div className="links__btn-copy">
        <CopyToClipboardButton
          className='item__copy'
          value={url.url}
          disabled={!url.url}
          title='Copy link'
        />
      </div>
    </div>
  ));

  const openImage = () => {
    const src = getContactAvatar(activeRecipient, 'contact', true);

    openModal(MODAL_TYPES.image, { src, isRound: true });
  };

  const handleEditContact = () => {
    API.switchAdrBookCaller(props.contact);

    dispatch(updateActiveAdrBookContactId(props.contact));

    if (location.pathname !== ROUTES.adrBook && !isAdrBookPageOpen) {
      window.open(ROUTES.adrBook, '_blank');
    }
  }

  const renderTelItemLabel = (fieldValue) => (
    <div className='contact-info__via-label'>
      {activeRecipient.tels.find(telItem => telItem.uuid === fieldValue)?.labelName}
    </div>
  );

  const renderSelectedViaItems = () => {
    
    const viaFields = {
      via_phone_uuid: {
        name: 'Phone',
        icon: 'phoneSquare'
      },
      via_sms_phone_uuid: {
        name: 'SMS',
        icon: 'phoneSquare'
      },
      via_email_uuid: {
        name: 'Email',
        icon: 'mail'
      },
      via_telegram: {
        name: 'Telegram',
        icon: 'paperPlane'
      }
    };

    return Object.entries(activeRecipient.via).map(viaItem => {
      const [fieldName, fieldValue] = viaItem;
      const { name, icon } = viaFields[fieldName];
      const isTelegramVia = name === 'Telegram';
      const isPhoneVia = name === 'Phone';
      const Icon = ICONS[icon];
      
      if(fieldValue) {
        return isTelegramVia
          ? <Icon key={fieldName} className={classModifier('contact-info__via-btn-icon', 'telegram')} />
          : <div key={fieldName} className="contact-info__via">
              <Icon className='contact-info__via-btn-icon'/>
              {name}
              {isPhoneVia && renderTelItemLabel(fieldValue)}
            </div> 
      } else return null;
    });
  };

  return (
    <section className="contact-info">
      <header className="contact-info__header">
        <div className="contact-info__header-links">
          <div className="contact-info__links-row">
            <span
              className="contact-info__contact-info--edit-link"
              onClick={handleEditContact}
            >
              <ICONS.adrBook width="13px" height="13px" />

              Open in address book
            </span>

            {activeRecipient?.agentId &&
              <span
                className="contact-info__contact-info--agent"
              >
                <ICONS.a  width="12px" height="12px" />
      
               {activeRecipient.agentName || props.agent.fn}
              </span> 
            }
          </div>

          <CopyToClipboardButton
            className='item__copy'
            value={activeRecipient.fn}
            disabled={!activeRecipient.fn}
            title='Copy name'
          />
        </div>

        <div className="contact-info__header-info">
          <div 
            className="contact-info__img-wrap"
            onClick={openImage}
          >
            <LazyLoadImage
              src={getContactAvatar(activeRecipient)}
              alt="ava"
              spinnerSize="60px"
              className="img--round"
            />

            {Boolean(activeRecipient.available_card) && (
              <ICONS.card className='contact-info__card' />
            )}

          </div>
          <div className="contact-info__contact-info">
            <div className="contact-info__contact-info--header">
              {'Created: '}

              <DateTime
                date={activeRecipient.date_created}
                config={CONTACT_INFO_DATE_CONFIG}
              />
              
              {' ; '}

              {'Edited: '}

              <DateTime
                date={activeRecipient.date_updated}
                config={CONTACT_INFO_DATE_CONFIG}
              />
            </div>
            <div className="contact-info__contact-info--footer">
              {showContactName(activeRecipient.fn, 'chat-header__ex')}
            </div>

            {activeRecipient.type === 2 && activeRecipient.age && activeRecipient.birthday &&
              <span className="contact-info__contact-info--age">
                {activeRecipient.birthday && `Date of birth: ${activeRecipient.birthday} ; `} 
                Age: {activeRecipient.age} y.o.
              </span>
            }
          </div>

        </div>

        <ContactTags
          className='contact-info__header--contact-tags'
          callers={activeRecipient}
        />
      </header>

      <div className="contact-info__main">
        <div className="contact-info__group">

          {activeRecipient?.via && Object.values(activeRecipient.via).some(Boolean) && 
            <div className='contact-info__group--via'>
              <h4 className={classModifier("contact-info__group-title", "via")}>
                Contact only via: 
              </h4>
              {renderSelectedViaItems()}
            </div>
          }

          {activeRecipient?.tels?.length
            ? activeRecipient.tels.map(tel => (
                <div className="contact-info__group--item contact-info__group--phone" key={tel.tel} >
                  <div className="contact-info__value contact-info__value--trim">
                    <div className="contact-info__value contact-info__value--trim">
                      <ICONS.phoneSquare width="14px" height="14px"/>
                      <span className='contact-info__value-label'>
                        {tel.labelName
                          ? ` (${tel.labelName})`
                          : ' (no label)'
                        }
                      </span>
                      <span>
                        {tel.tel}
                      </span>
                    </div>

                    <CopyToClipboardButton
                      className='item__copy'
                      value={tel.tel}
                      disabled={!tel.tel}
                      title='Copy phone'
                    />
                  </div>

                  <button onClick={handleopenContactChat} className='contact-info__value-btn'>
                    <ICONS.comments width="20px" height="20px"/>
                  </button>
                </div>
            ))
            : <div className="contact-info__group--item"> No phone </div>
          }

          {activeRecipient.emails?.length
            ? activeRecipient.emails.map(email => (
              <div className="contact-info__group--item contact-info__group--email" key={email.email}>
                <div className="contact-info__value contact-info__value--trim">
                  <ICONS.mail width="14px" height="14px"/>
                  <span>{email.email}</span>
                </div>

                <CopyToClipboardButton
                  className="item__copy"
                  value={email.email}
                  disabled={!email.email}
                  title='Copy phone'
                />
              </div>
            ))
            : <div className="contact-info__group--item"> No email </div>
          }

          {activeRecipient.telegram_nicknames?.length
            ? activeRecipient.telegram_nicknames.map(nickname => (
              <div className="contact-info__group--item contact-info__group--telegram" key={nickname.nickname}>
                <div className="contact-info__value contact-info__value--trim">
                  <ICONS.telegram width="14px" height="14px"/>
                  <span>{nickname.nickname}</span>
                </div>

                <CopyToClipboardButton
                  className="item__copy"
                  value={nickname.nickname}
                  disabled={!nickname.nickname}
                  title='Copy nickname'
                />
              </div>
            ))
            : <div className="contact-info__group--item"> No telegram nickname </div>
          }
        </div>

        <div className="contact-info__group">
          {!!activeRecipient.urls?.length
            ? renderGirlUrls(activeRecipient.urls)
            : <div className="contact-info__group--item"> No links </div>
          }
        </div>

        <div className="contact-info__group">
          {!!activeRecipient?.addresses?.length
            ? activeRecipient.addresses.map(({ address, id }) => (
              <div
                key={id}
                className="contact-info__group--item contact-info__group--address"
              >
                <div className="contact-info__value">
                  <ICONS.mapFill width="14px" height="11px"/>
                  <div className="contact-info__value contact-info__value--prewrap">
                    {textLineSplitter((address || 'No address'))}
                  </div>
                </div>

                <CopyToClipboardButton
                  className='item__copy'
                  value={address}
                  disabled={!address}
                  title='Copy address'
                />
              </div>
            ))
            : <div className="contact-info__group--item"> No address </div>
          }
        </div>

        <div className="contact-info__group">
          {activeRecipient.note
            ? <>
                <h4 className="contact-info__group-title contact-info__group-title--note">Notes:</h4>
                <div className="contact-info__group--item contact-info__group--note"> 
                  <div className="contact-info__value contact-info__value--prewrap">
                    {activeRecipient.note}
                  </div>

                  <CopyToClipboardButton
                    className='item__copy'
                    value={activeRecipient.note}
                    disabled={!activeRecipient.note}
                    title='Copy note'
                  />
                </div>
              </>
            : <div className="contact-info__group--item"> No comments </div>
          }
        </div>


        {activeRecipient.type === 1 &&
          <div className="contact-info__group">
            <h4 className="contact-info__group-title">Not for:</h4>

            <ul className="contact-info__group--list">
              {notForClients.map(contact => {
                const notForName = contact.callerIgnore?.fn || contact.ignoreName;
                const girlStatusModifier = contact.availability_status
                  ? contact.availability_status.split(" ").join("-").toLowerCase()
                  : null

                return (
                  <li key={contact.id}>
                    <Tooltip text={notForName} visibleWhenIsOverflowed>
                      <p className={classModifier('contact-info__girl', girlStatusModifier)}>
                        {notForName}
                      </p>
                    </Tooltip>

                    <CopyToClipboardButton
                      className={classNames('item__copy', 'contact-info__group--icon-btn')}
                      value={notForName}
                      disabled={!notForName}
                      title='Copy name'
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        }

        {!props.saveMapState && <LockForm classPrefix='contact-info-lock-form' editedContact={activeRecipient}/>}

        {activeRecipient.map &&
          <div className="contact-info__group">
            <div className="contact-info__group--item contact-info__group--map">
              <div className="contact-info__value contact-info__value--trim">
                <a
                  href={activeRecipient.map}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {activeRecipient.map}
                </a>
              </div>

              <div className="map__btn-copy">
                <CopyToClipboardButton
                  className='item__copy'
                  value={activeRecipient.map}
                  disabled={!activeRecipient.map}
                  title='Copy map'
                />
              </div>
            </div>
          </div>
        }
      

        {activeRecipient.type === 2 && !props.saveMapState &&
          <div className="contact-info__group">
            {props.agent && props.agent.fn
              ? <div className="contact-info__group--item">
                <button
                  className="contact-info__value contact-info__value--btn"
                  onClick={(props.agent && props.agent.fn)
                    ? () => openContactAgentChat(props.agent)
                    : null
                  }
                >
                  {props.agent?.fn}
                </button>
              </div>
              : <div className="contact-info__group--item"> Empty field... </div>
            }
          </div>
        }

        {!props.saveMapState && (
          <div className={classModifier("contact-info__group", "uid")}>
            <h4 className="contact-info__group-title">Fastmail UID:</h4>

            {activeRecipient.uid
              ? <div className="contact-info__group--item contact-info__group--uid">
                  <div className="contact-info__value">
                    {activeRecipient.uid}
                  </div>

                  <CopyToClipboardButton
                    className='item__copy'
                    value={activeRecipient.uid}
                    disabled={!activeRecipient.uid}
                    title='Copy note'
                  />
                </div>
              : <div className="contact-info__group--item"> No UID </div>
            }
          </div>
        )}

        <div className='contact-info__group contact-info__group--bookings'>
          {bookingInfoLoading
            ? <Spinner spinnerSize={30} />
            : Boolean(extendedBookingInfo?.length) && (
              extendedBookingInfo.map((girlInfo) => (
                <div className='contact-info__value' key={girlInfo.girlName}>
                  <div className={classModifier('contact-info__img-wrap', 'booking')}>
                    <LazyLoadImage
                      src={girlInfo.avatar}
                      alt="ava"
                    />
                  </div>

                  <div className='contact-info__booking-content'>
                    <p className='contact-info__name'>
                      {girlInfo.girlName}
                    </p>

                    <p className='contact-info__booking-info'>
                        <span className='contact-info__booking-count'>
                          {girlInfo.bookingsCount}
                        </span>

                      <ICONS.eye/>

                      <span className='contact-info__booking-data'>
                        last seen
                        {' '}
                        <DateTime
                          date={girlInfo.lastBookingDate}
                          config={CONTACT_INFO_DATE_CONFIG}
                        />
                      </span>
                    </p>
                  </div>
                </div>
              ))
            )
          }
        </div>
      </div>
    </section>
  )
};

const mSTP = (state, ownProps) => ({
  divaProfile: state.divaGirls.entities[selectContactById(state, ownProps.activeRecipient.id)?.diva_default_id],
  isAdrBookPageOpen: state.user.isAdrBookPageOpen,
  agent: selectContactById(state, ownProps.activeRecipient.agentId),
});

const mDTP = {
  getActiveDivaGirls,
  openModal,
  updateActiveContact,
};

export default connect(mSTP, mDTP)(ContactInfo);
