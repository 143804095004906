import { useToggle } from 'hooks';
import { BUTTON_THEMES, SIZES } from 'config/constants';

import ICONS from 'assets/icons';
import ProfileRates from 'components/ProfileRates/ProfileRates';
import Button from 'components/UI/Button/Button';


const ShowGirlRatesButton = (props) => {
  const [isRatesOpen, toggleRatesOpen] = useToggle(false);

  return (
    <Button
      theme={props.theme || BUTTON_THEMES.NONE_DARK}
      icon={ICONS.poundSign}
      iconSize={props.iconSize || SIZES.L}
      onMouseEnter={() => toggleRatesOpen(true)}
      onMouseLeave={() => toggleRatesOpen(false)}
      disabled={!props.profile}
    >
      {isRatesOpen && (
        <div className="chat-header__rates">
          <ProfileRates
            isEditor
            profileId={props.profile?.id}
            isMember={props.profile?.is_for_member || false}
            defaultPrices={props.profile?.prices || []}
            memberPrices={props.profile?.prices_member || []}
            secretPrices={props.profile?.prices_secret || []}
          />
        </div>
      )}
    </Button>
  )
}

export default ShowGirlRatesButton;
