import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useDidMount, useToggle, useDidUpdate } from 'hooks';
import { classModifier, getDefaultField } from 'utils';
import { callTo } from 'redux/ducks/calls';
import { onHoldActiveConference } from 'redux/ducks/webrtc';
import { toggleCreateContactForm } from 'redux/ducks/addressBook';
import { deleteAllMissedForMe } from 'redux/ducks/missedCalls'
import { selectActiveWebrtcConference, selectUserTimezone } from 'redux/selectors/selectors';
import { CONTACTS_LIMIT } from 'redux/ducks/contacts';

import './Dialpad.scss';
import API from 'api/api';

import { BUTTON_THEMES, CONTACT_TYPES, SIZES } from 'config/constants';
import DialpadSearchItem from './DialpadSearchItem';
import DialpadRecentCallsItem from './DialpadRecentCallsItem';
import DialpadActiveChatsItem from './DialpadActiveChatsItem';
import List from 'components/List/List';
import ICONS from 'assets/icons';
import SearchInput from 'components/SearchInput/SearchInput';

import dialBtn0 from "../../../../assets/sounds/dialBtn-0.mp3"
import dialBtn1 from "../../../../assets/sounds/dialBtn-1.mp3"
import dialBtn2 from "../../../../assets/sounds/dialBtn-2.mp3"
import dialBtn3 from "../../../../assets/sounds/dialBtn-3.mp3"
import dialBtn4 from "../../../../assets/sounds/dialBtn-4.mp3"
import dialBtn5 from "../../../../assets/sounds/dialBtn-5.mp3"
import dialBtn6 from "../../../../assets/sounds/dialBtn-6.mp3"
import dialBtn7 from "../../../../assets/sounds/dialBtn-7.mp3"
import dialBtn8 from "../../../../assets/sounds/dialBtn-8.mp3"
import dialBtn9 from "../../../../assets/sounds/dialBtn-9.mp3"
import dialBtnCall from "../../../../assets/sounds/dialBtn-call.mp3"
import dialBtnCallEnd from "../../../../assets/sounds/callEnd.mp3"
import dialBtnDel from "../../../../assets/sounds/dialBtn-del.mp3"
import dialBtnDelAll from "../../../../assets/sounds/dialBtn-delAll.mp3"
import dialBtnSharp from "../../../../assets/sounds/dialBtn-sharp.mp3"
import dialBtnStar from "../../../../assets/sounds/dialBtn-star.mp3"
import Tooltip from '../../../../components/UI/Tooltip/Tooltip';
import Button from 'components/UI/Button/Button';
import DialpadMissedCallsList from './DialpadMissedCallsList';

const SOUNDS = {
  0: dialBtn0,
  1: dialBtn1,
  2: dialBtn2,
  3: dialBtn3,
  4: dialBtn4,
  5: dialBtn5,
  6: dialBtn6,
  7: dialBtn7,
  8: dialBtn8,
  9: dialBtn9,
  'call': dialBtnCall,
  'callEnd': dialBtnCallEnd,
  'del': dialBtnDel,
  'delAll': dialBtnDelAll,
  'sharp': dialBtnSharp,
  'star': dialBtnStar,
}

const MAX_LENGTH = 20;

const Dialpad = ({ 
  setTooltipRef, 
  onCloseDialPad,
  getTooltipProps, 
  isDialpadOpen, 
  username, 
  ...props
}) => {
  
  const [query, setQuery] = useState('');
  const [searchItems, setSearchItems] = useState(null);
  const [contact, setContact] = useState(null);
  const [listMode, setListMode] = useState('chats');
  const [isLoading, setIsLoading] = useState(true);

  const [listOfActiveChats, setListOfActiveChats] = useState([]);
  const [listOfRecentCalls, setListOfRecentCalls] = useState([]);

  const navigate = useNavigate();

  const [isRecentCallsListOpen, toggleRecentCallsList] = useToggle(false);
  const [isMissedCallsListOpen, toggleMissedCallsList] = useToggle(true);

  const selectRef = useRef();

  const contactTypes = {
    [CONTACT_TYPES.CLIENT]: 'client',
    [CONTACT_TYPES.GIRL]: 'girl',
    [CONTACT_TYPES.AGENT]: 'agent',
  };

  const listItemProps = {
    setContact,
    setQuery,
    contactTypes,
    userTimezone: props.userTimezone,
    userHour12: props.userHour12,
    username,
  };

  useDidMount(() => { 
    Promise.all([getDefaultCallersList(), getRecentCallsList()])
      .then(()=> setIsLoading(false))

    setTimeout(() => {
      selectRef.current && selectRef.current.focus();
    }, 0);
  });

// the useDidUpdate is used to refresh the list for a new search query through mode
  
  useDidUpdate(() => {
      if (searchItems) {
        if (query.length === 1) {
          return;
        }

        setListMode(query);
        return;
      }

      setListMode(isRecentCallsListOpen ? 'recent' : 'chats');
    }, [searchItems, isRecentCallsListOpen]);

  const searchContactsList = (query) => {
    setIsLoading(true);

    API.searchContactsDialpad(query, 0, CONTACTS_LIMIT)
      .then(res => {
        setSearchItems(res.data);
        setIsLoading(false);
      })
      .catch(console.error);
  };
  
  const getDefaultCallersList = (offset) =>
    API.getDefaultCallersList(offset)
      .then(({ data }) => {
        setListOfActiveChats(prev => ([...prev, ...data]));
      })
      .catch(console.error);

  const getRecentCallsList = (offset) =>
    API.getRecentCallsList(offset)
      .then(({ data }) => {
        setListOfRecentCalls(prev => ([...prev, ...data]));
      })
      .catch(console.error);

  const callToContact = () => {
    if (!props.currentUserSip) return;

    if (!query.trim().length) return;

    if (!Number.isInteger(+query)) {
      return alert('Please write a number or select a contact')
    }

    if (props.activeConference) {
      props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
    }

    playDialSound('call');

    if (contact) {
      const tel = getDefaultField(contact.tels, 'tel');

      props.callTo(tel);
    }
    else {
      const newContact = {
        fn: query,
        tels: [{ tel: query, default: 1 }]
      };

      props.callTo(query);
    }

  };

  const typeNumber = (e) => {
    if (query.length + 1 > MAX_LENGTH) return;

    let value = e.target.value;

    playDialSound(value);

    if (value === 'sharp') {
      value = '#';
    }
    else if (value === 'star') {
      value = '*';
    }
    else if (value === 'plus') {
      value = '+';
    }

    setQuery(query + value);

    selectRef.current.focus();
  };

  const removeLastSymbol = (e) => {
    if (query.length) {
      playDialSound(e.target.value);

      setQuery(prevNumber => prevNumber.slice(0, -1));
    }
    selectRef.current.focus();
  };

  const removeAllSymbols = () => {
    if (query) {
      setQuery('');

      playDialSound('delAll');
      selectRef.current.focus();
    }
  };

  const playDialSound = (value) => {
    value = value === '+' || value === 'plus'
      ? 'sharp'
      : value;

    let messageSound = new Audio(SOUNDS[value]);
    messageSound.play();
  };

  const handleAddContactInAdrBook = () => {
    navigate('/address-book', {
      state: {
        telFromDialPad: query
      }
    });
    props.toggleCreateContactForm(true);

    onCloseDialPad();
  };

  const clearAllMissedForMe = () => {
		API.clearAllMissedForMe()
			.then(() => {
				props.deleteAllMissedForMe();
			})
			.catch(console.error);
	};

  return (
    <div
      className="dialpad"
      ref={setTooltipRef}
      {...getTooltipProps()}
    >
      <div className="dialpad__header">
        <div className="dialpad__header-left">
          <SearchInput 
            inputRef={selectRef}
            query={query}
            placeholder="Enter number or name"
            inputWrapClassName="dialpad__input-wrap"
            inputClassName="dialpad__input"
            setQuery={setQuery}
            startSearch={searchContactsList}
            stopSearch={() => setSearchItems(null)}
          />
          
          <Button
            theme={BUTTON_THEMES.PRIMARY}
            disabled={!searchItems}
            icon={ICONS.plusCircle}
            iconSize={SIZES.M}
            onClick={handleAddContactInAdrBook}
          />
        </div>

        <div className="dialpad__tabs-wrapper">
          <div className="dialpad__tabs">
            <Button
              theme={BUTTON_THEMES.TAG_LIGHT}
              active={isMissedCallsListOpen}
              disabled={searchItems}
              rounded={SIZES.S}
              text="Missed"
              onClick={() => {
                setQuery('');
                toggleRecentCallsList(false);
                toggleMissedCallsList(true);
              }}
            />

            <Button
              theme={BUTTON_THEMES.TAG_LIGHT}
              active={!isRecentCallsListOpen && !isMissedCallsListOpen}
              disabled={searchItems}
              rounded={SIZES.S}
              text="Com"
              onClick={() => {
                setQuery('');
                toggleRecentCallsList(false);
                toggleMissedCallsList(false);
              }}
            />

            <Button
              theme={BUTTON_THEMES.TAG_LIGHT}
              active={isRecentCallsListOpen}
              disabled={searchItems}
              rounded={SIZES.S}
              text="Recent"
              onClick={() => {
                setQuery('');
                toggleRecentCallsList(true);
                toggleMissedCallsList(false);
              }}
            />
          </div>
        </div>
      </div>

      <div className="dialpad__content">
        <div className="dialpad__btns">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
            <button
              key={num}
              className="dialpad__btn"
              onClick={typeNumber}
              value={num}
            >
              {num}
            </button>
          ))}

          <button
            className="dialpad__btn"
            onClick={typeNumber}
            value="star"
          >
            *
          </button>
          <button
            className="dialpad__btn"
            onClick={typeNumber}
            value="0"
          >
            0
          </button>
          <button
            className="dialpad__btn"
            onClick={typeNumber}
            value="sharp"
          >
            #
          </button>
          <button
            className="dialpad__btn"
            onClick={removeLastSymbol}
            value="del"
          > 
            <ICONS.chevronRotated className="dialpad__icon-arrow"/>
          </button>
          <button
            className="dialpad__btn"
            onClick={typeNumber}
            value="plus"
          >
            +
          </button>

          <Tooltip
            text={'You do not have the sip number. Please select it in the settings.'}
            extraCondition={!props.currentUserSip}
          >
            {(setTriggerRef) => (
              <button
                className={classModifier('dialpad__btn', [
                  'phone',
                  !props.currentUserSip && 'disabled',
                ])}
                onClick={callToContact}
                value="call"
                ref={setTriggerRef}
              >
                <ICONS.phoneNew className="dialpad__icon-phone"/>
              </button>
            )}
          </Tooltip>
        </div>

        <div className={classModifier('dialpad__list-wrapper', [searchItems && 'search'])}>
          {isMissedCallsListOpen ?
            <>
              <div className='dialpad__list-missed-header'>
                <button
							    onClick={clearAllMissedForMe}
							    className="dialpad__clear-all-btn"
                >
							    Clear All
					    	</button>
              </div>

              <DialpadMissedCallsList
                callList={props.missedCallsIds}
                missed 
              />
            </>
          :
            <List
              list={searchItems || (
                isRecentCallsListOpen ? listOfRecentCalls : listOfActiveChats
              )}
              limit={CONTACTS_LIMIT}
              mode={listMode}
              scrollInitialPosition='top'
              classPrefix='dialpad-list'
              loadMore={searchItems
                ? searchContactsList
                : (isRecentCallsListOpen ? getRecentCallsList : getDefaultCallersList)}
              useIndexAsItemKey // when rewriting the list, the items from the previous one remain. 
              // to fix this bug, we use the index, not the id, for the key
              listLoadPending={isLoading}
            >
              {searchItems
                ? <DialpadSearchItem {...listItemProps} />
                : (isRecentCallsListOpen
                  ? <DialpadRecentCallsItem {...listItemProps} />
                  : <DialpadActiveChatsItem {...listItemProps} />
                )}
            </List>
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12,
  username: state.user.username,
  currentUserSip: state.user.sip,
  missedCallsIds: state.missedCalls.ids,
});

const mapDispatchToProps = {
  onHoldActiveConference,
  toggleCreateContactForm,
  callTo,
  deleteAllMissedForMe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialpad);
