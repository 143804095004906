import React, { useEffect, useState } from 'react';
import { Popper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

import './Menu.scss';
import Portal from 'components/Portal';
import Menu from './Menu';


const getRefferenceElement = (event) => ({
  getBoundingClientRect: () => ({
    top: event.clientY,
    left: event.clientX,
    right: event.clientX,
    bottom: event.clientY,
    width: 0,
    height: 0,
  }),
})


const FloatingMenu = (props) => {
  const {
    options,
    children,
    trigger = "click",
    disabled,
  } = props;

  const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  
  const openMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setReferenceElement(getRefferenceElement(event)); // set point for anchoring the menu
    setIsFloatingMenuVisible(true);
    props.setIsFloatingMenuVisible && props.setIsFloatingMenuVisible(true);
  }

  const closeMenu = () => {
    setIsFloatingMenuVisible(false);
    props.setIsFloatingMenuVisible && props.setIsFloatingMenuVisible(false)
  };

  const handleContextMenu = (event) => {
    if (trigger !== 'rightClick') return;
    
    openMenu(event);
  };

  const handleClick = (event) => {
    if (trigger !== 'click') return;
    
    openMenu(event);
  };

  const anchorProps = trigger === 'rightClick' ? { onContextMenu: handleContextMenu } : { onClick: handleClick };

  useEffect(() => {
    document.addEventListener('mousedown', closeMenu);
    window.addEventListener('scroll', closeMenu, { capture: true });

    return () => {
      document.removeEventListener('mousedown', closeMenu);
      window.removeEventListener('scroll', closeMenu, { capture: true });
    }
  }, [])

  if (disabled) return children;

  return (
    <>
      {children instanceof Function
        ? children(setTriggerRef)
        : (React.cloneElement(React.Children.only(children), anchorProps))
      }
        <CSSTransition
          in={isFloatingMenuVisible}
          timeout={100}
          unmountOnExit
        >
          <Portal>
            <Popper
              referenceElement={referenceElement}
              placement='right-start'
            >
              {(props) => (
                <Menu
                  className="floating-menu-new"
                  options={options}
                  closeDropdownMenu={closeMenu}
                  {...props}
                />
              )}
            </Popper>
          </Portal>
        </CSSTransition>
    </>
  )
}

export default FloatingMenu;
