import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import {
  getContactAvatar,
  classModifier,
  parseMsgText,
  isEmptyObj,
  showContactName,
} from 'utils';
import {
  selectContactById,
  selectIsContactActive,
  selectRelatedOperator,
  selectUserTimezone
} from 'redux/selectors/selectors';
import ICONS from 'assets/icons';
import { useToggle } from 'hooks';
import { BUTTON_THEMES, CONTACT_TYPES, INTERACTION_TYPES } from 'config/constants';
import { startSearchContactsMsgsInChat, updateActiveContact } from 'redux/ducks/clientChats';
import { openModal, closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { CARD_TABS } from 'config/constants';
import { CONTACT_ITEM_CONFIG } from 'config/dates-сonfig';
import { v4 as uuid } from 'uuid';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
// import { EditContactFormNew } from 'containers/Dialogs/Dialogs'; //TODO v2
import DateTime from 'components/DateTime';
import Spinner from 'components/UI/Spinner/Spinner';
import SystemMsg from 'containers/Chat/components/ChatTimeline/interactions/SystemMsg/SystemMsg';
import Portal from 'components/Portal';
import { CONTACTS_LIMIT, getContactsById, updateContact } from '../../../../redux/ducks/contacts';
import BookingIcon from 'components/Buttons/ClientBookingButton';
import API from 'api/api';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';
import getIconTypeByInteractionId from 'utils/getIconTypeByInteractionId';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import ContactTags from 'components/ContactTags/ContactTags';
import { SPECIAL_TAG_KEYS } from 'components/ContactTags/config/config';
import FloatingMenu from 'components/UI/Menu/FloatingMenu';
import ContactItemBlock from 'components/UI/ContactItemBlock/ContactItemBlock';
import getContactStatus from 'utils/getContactStatus';
import getContactLastInteraction from 'utils/getContactLastInteraction';
import getRelatedOperatorToLastInteraction from 'utils/getRelatedOperatorToLastInteraction';
import Button from 'components/UI/Button/Button';
import Title from 'components/UI/Title/Title';

const ContactItem = (props) => {
  const {
    contactName: ContactName,
    contact = {},
    style,
    isActive,
    isBusy,
    dividedContactId,
    girlBookingDetails,
    isFromListOfGirlWithStatus,
    additionalInformation,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
  const [contextItems, setContextItems] = useState([]);

  const girlBookingsInfo = girlBookingDetails.find(el => el.girlId === props.id);

  const {
    INCOMING_MSG,
    INCOMING_CALL,
    INCOMING_MSG_TELEGRAM,
    INCOMING_MSG_WHATSAPP,
    INCOMING_MSG_IPHONE_IMESSAGE,
    INCOMING_MSG_IPHONE_SMS,
    INCOMING_EMAIL,
    INCOMING_PRIVATE_MSG_TELEGRAM,
  } = INTERACTION_TYPES;

  const lastInteractionType = getIconTypeByInteractionId(contact.interactionObject?.type);
  const LastInteractionIcon = ICONS[lastInteractionType];

  const [isBusyTooltipShowed, setBusyTooltipShowing] = useToggle(false);

  const isCommunicationViaBot = [
      INTERACTION_TYPES.INCOMING_NIGHT_BOT_MESSAGE, 
      INTERACTION_TYPES.OUTGOING_NIGHT_BOT_MESSAGE
    ].includes(contact.interactionObject?.type);

  const isTemporary = contact.callerTags?.some(tag => tag.title === 'Temporary') || !!contact.isTemporary;
  const isGirlContactItem = contact.type === +CONTACT_TYPES.GIRL;
  const isRelatedUser = !!contact.relatedUserId && contact.relatedUserId !== props.userId;

  useEffect(() => {
    if (!isContextMenuOpened) {
      const newItems = props.generateContextItems(contact);
      setContextItems(newItems);
    }
  }, [contact, isContextMenuOpened]);

  const handleContactClick = (e) => {
    e.preventDefault();

    // props.getContactsById(contact.id, contact.type)
    //   .then((contact) => {
        !isActive && props.updateActiveContact(contact);
      // });

    const chatSearch = props.type === CONTACT_TYPES.CLIENT
      ? props.clientsChatSearch
      : props.girlsChatSearch;

    // if we search by msgs (global) and search contact is active (opened chat with it),
    // we need to start search inside the contactsChat
    if (isActive && props.searchSource === 'msgs' && !!props.search && chatSearch !== props.search) {
      props.startSearchContactsMsgsInChat(props.type, props.search);
    }
  };

  // const onContextMenu = (e) => {
  //   e.preventDefault();

  //   return props.isContextMenu
  //     ? props.openContextMenu([e.clientX, e.clientY], contact)
  //     : null;
  // };

  // Show count when description is closed

  const renderRelatedOperator = () => {
    if (!contact.relatedUserId && !contact.lastOperator) {
      return null;
    }

    let name;

    if (isCommunicationViaBot) {
      name = 'bot'
    } else if (contact.relatedUserId === props.userId) {
      name = 'You';
    } else if (!contact.relatedUserId && contact.lastOperator?.id === props.userId) {
      name = 'released by you';
    } else if (props.relatedOperator) {
      name = props.relatedOperator.username;
    } else {
      name = `released by ${contact.lastOperator?.username}`;
    }

    return name;
  };

  const handleEdit = (event) => {
    event.stopPropagation();

    props.openModal(MODAL_TYPES.contactCard, {
      contact,
      defaultTab: CARD_TABS.EDIT,
      autoFocusInput: 'fn',
    })
  };

  const fetchSearchClientsContacts = ({ query, offset, cancelToken }) => {
    return API.searchContacts(1, query, offset, CONTACTS_LIMIT, cancelToken)
      .then(({ data }) => ({
        newItems: data,
        newHasMore: data.length === CONTACTS_LIMIT,
      }))
      .catch(console.error);
  };

  const onAddClient = () => {
    props.openModal(MODAL_TYPES.searchList, {
      fetchData: fetchSearchClientsContacts,
      itemComponent: SearchListContactItem,
      onChoose: async (selectedContact) => {
        setIsLoading(true)
        try {
          const preparedContact = {
            ...selectedContact,
            tels: [
              ...selectedContact.tels,
              {
                default: 0,
                labelName: 'default',
                uuid: uuid(),
                tel: contact.fn,
              },
            ]
          }

          await API.mergeContactCards(preparedContact, contact.id)

          props.openModal(MODAL_TYPES.contactCard, {
            contact: preparedContact,
            defaultTab: CARD_TABS.EDIT,
            autoFocusInput: 'fn',
          });
          props.closeModal(MODAL_TYPES.searchList);
        } catch (error) {
          console.error(error)
        }
        setIsLoading(false)
      },
    })
  }

  const addNewContactItems = [
    {
      name: 'Add new contact',
      action: handleEdit,
    },
    {
      name: 'Add to existing contact',
      action: onAddClient,
    },
  ]

  if (isEmptyObj(contact)) {
    return (
      <div className="contact-item contact-item--pending">
        <Spinner spinnerSize="32px" />
      </div>
    )
  }

  const lastInteraction = getContactLastInteraction(contact.interactionObject, props.userId);


  {/* {contact.type === CONTACT_TYPES.GIRL && !!girlBookingsInfo &&
    <div className="contact-item__bookings-block">
      
      <ICONS.eye className="contact-item__booking-icon-eye" />
      <span className="contact-item__bookings-count">{girlBookingsInfo?.bookingsCount}</span>
    </div>
  } */}

  const classes = classModifier('sidebar-contacts-list__contact-item', [
    contact.marked && 'marked',
    contact.pinned && 'pinned',
    contact.id === dividedContactId && 'divided',
  ])

  return (
    <FloatingMenu
      options={contextItems}
      setIsFloatingMenuVisible={setIsContextMenuOpened}
      trigger='rightClick'
    >
      {/* <div
        id={props.id}
        className={classModifier('contact-item', [
          contact.marked && !props.isSimpleView && 'marked',
          contact.availability && contact.availability === 'off today'
            ? 'off-today'
            : contact.availability,
          contact.agentId && 'with-agent', // [ ] its specific for girl contact
          isActive && 'active',
          !!contact.unreadCount && 'unread-msgs',
          contact.type === 2 && 'girl',
          dividedContactId === contact.id && 'divided',
        ])}
        onClick={handleContactClick}
      >
        <div className="contact-item__dividing-line" />

        <div
          className={`contact-item__img-wrap ${contact.blocked ? "blocked" : ""} ${contact.is_active_chat ? "contact-item__img-wrap--active" : ""
            }`}
        >
          <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className="contact-item__img" />
          {busyStatus}

          {contact?.has_tel-egram_groups &&
            <ICONS.users className="contact-item__telegram-group-icon" />
          }

          {LastInteractionIcon && (
            <div className='contact-item__icon-wrapper'>
              <LastInteractionIcon className={classModifier("contact-item__icon", lastInteractionType)} />
            </div>
          )}

          {isCommunicationViaBot &&
            <div className={classModifier('contact-item__icon-wrapper', 'top')}>
              <ICONS.robot className={classModifier('contact-item__icon', 'bot')} />
            </div>
          }
        </div>

        <div className="contact-item__content-wrap">
          <div className="contact-item__info">
            {Boolean(contact.closestBooking) && (
              <BookingIcon
                className="contact-item__booking-icon"
                booking={contact.closestBooking}
                userTimezone={props.userTimezone}
              />
            )}

            {Boolean(contact.todayBookingsCount) && (
              <mark className="contact-item__unfinished-bookings-count">{contact.todayBookingsCount}</mark>
            )}

            <Tooltip text={contact.fn}>
              <h4 className="contact-item__name">
                <ContactTags
                  callers={contact}
                  tagClassName="contact-item__tag"
                  selectedSpecificTags={SPECIAL_TAG_KEYS.DEBTPRICE}
                />

                {showContactName(contactName, 'contact-item__ex', contact.fn)}
              </h4>
            </Tooltip>

            {contact.last_interaction && 
              <DateTime
                className="contact-item__time"
                date={isFromListOfGirlWithStatus ? contact.statusUpdateDate : contact.last_interaction}
                config={CONTACT_ITEM_CONFIG}
                relative={!!contact.statusUpdateDate}
              />
            }
          </div>

          <div className="contact-item__bottom">
            {contact.draft_message &&
              <ICONS.pencil className="contact-item__draft-icon" title="draft" />
            }

            <div
              className={`contact-item__last-msg ${contact.interactionObject
                ? ([INCOMING_MSG, INCOMING_CALL, INCOMING_MSG_TELEGRAM, INCOMING_MSG_WHATSAPP, 
                    INCOMING_EMAIL, INCOMING_MSG_IPHONE_IMESSAGE, INCOMING_MSG_IPHONE_SMS, INCOMING_PRIVATE_MSG_TELEGRAM]
                    .includes(contact.interactionObject.type)
                  && 'contact-item__last-msg--operator')
                : ''
                }`
              }
            >
              {(contact.interactionObject || contact.interactionObject_searched) &&
                renderLastInteraction()
              }
            </div>

            {renderRelatedOperator()}

          </div>

        </div>

        {isLoading && (
          <Portal>
            <div className="contact-item--compare-clients-tels-spinner">
              <Spinner spinnerSize="32px" />
            </div>
          </Portal>
        )
        }
      </div > */}
      

        <ContactItemBlock
          className={classes}
          active={isActive}
          onClick={handleContactClick}
        >
          <ContactItemBlock.Avatar
            className="sidebar-contacts-list__contact-item-avatar"
            src={getContactAvatar(contact)}
            status={getContactStatus(contact)}
          />
          
          <ContactItemBlock.Body>
            <ContactName />

            <ContactItemBlock.Body.ExtraContent
              className="sidebar-contacts-list__last-interaction"
            >
              {contact.draft_message && <ICONS.pencil title="draft" />}
              {parseMsgText(lastInteraction, true)}
            </ContactItemBlock.Body.ExtraContent>

            <ContactItemBlock.Body.ExtraContent
              className="sidebar-contacts-list__contact-time"
            >
              <DateTime
                date={isFromListOfGirlWithStatus ? contact.statusUpdateDate : contact.last_interaction}
                config={CONTACT_ITEM_CONFIG}
                relative={!!contact.statusUpdateDate}
              />
              
              {Boolean(contact.closestBooking) && (
                <BookingIcon
                  className="sidebar-contacts-list__booking-icon"
                  booking={contact.closestBooking}
                  userTimezone={props.userTimezone}
                />
              )}
            </ContactItemBlock.Body.ExtraContent>

            <ContactItemBlock.Body.ExtraContent
              className="sidebar-contacts-list__operator-info"
            >
              {renderRelatedOperator()}
            </ContactItemBlock.Body.ExtraContent>

          </ContactItemBlock.Body>

          <ContactItemBlock.UnreadMessageCounter
            className="sidebar-contacts-list__unread-msgs-counter"
            count={contact.unreadCount}
            color={contact.withImportantUnread ? 'red' : isRelatedUser && 'blue'}
          />
        </ContactItemBlock>
    </FloatingMenu>
  );
};

const mapStateToProps = (state, ownProps) => ({
  contact: selectContactById(state, ownProps.id),
  isActive: selectIsContactActive(state, ownProps.type, ownProps.id),
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  // agent: selectAgentContactById(state, ownProps.id),
  relatedOperator: selectRelatedOperator(state, ownProps.id),
  userHour12: state.user.hour12,
  girlBookingDetails: state.clientChats.bookingDetails,
});

const mapDispatchToProps = {
  getContactsById,
  updateActiveContact,
  startSearchContactsMsgsInChat,
  openModal,
  closeModal,
  updateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactItem);
