import React from 'react';

import ICONS from 'assets/icons';
import { getContactAvatar } from 'utils';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const NotForFieldItem = ({ removeItem, ignoreName, item }) => {

  console.log('item', item)

  return (
    <div className="adr-book-contact-form__not-for-item">
      <LazyLoadImage
        src={getContactAvatar({ photo: item.avatar }, item.type)}
        alt="not for avatar"
      />
      <span>{ignoreName}</span>
      <button
        type="button"
        onClick={removeItem}
        className="adr-book-contact-form__not-for-item-btn"
      >
        <ICONS.close className="adr-book-contact-form__not-for-item-icon"/>
      </button>
    </div>
  )
}

export default NotForFieldItem;