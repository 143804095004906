import React, { useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import ICONS from 'assets/icons';
import LS from 'utils/localStorageAPI';
import { classModifier } from 'utils';
import { ADR_BOOK_CONTACT_TYPE_FILTERS, INPUT_THEMES } from 'config/constants';
import { removeContact } from 'redux/ducks/contacts';
import {
  changeContactType,
  toggleCreateContactForm,
  updateSearch
} from 'redux/ducks/addressBook';
import { useDidUpdate } from 'hooks';
import { updateTagsFilter } from 'redux/ducks/addressBook';
import { selectContactById } from 'redux/selectors/selectors';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import './AdrBookHeader.scss';
import SearchInput from 'components/SearchInput/SearchInput';
import Spinner from 'components/UI/Spinner/Spinner';
import Button from 'components/Button/Button';
import Navbar from 'containers/Header/components/Navbar/Navbar';
import AdrBookSearchFilters from 'containers/AdrBookSearchFilters/AdrBookSearchFilters';

const adrBookFilterOptions = [
  { label: 'All Contacts', value: ADR_BOOK_CONTACT_TYPE_FILTERS.ALL },
  { label: 'Clients Contacts', value: ADR_BOOK_CONTACT_TYPE_FILTERS.CLIENT },
  { label: 'Escort Contacts', value: ADR_BOOK_CONTACT_TYPE_FILTERS.GIRL },
  { label: 'Agent Contacts', value: ADR_BOOK_CONTACT_TYPE_FILTERS.AGENT },
  { label: 'Services Contacts', value: ADR_BOOK_CONTACT_TYPE_FILTERS.SERVICES },
  { label: 'Reception Contacts', value: ADR_BOOK_CONTACT_TYPE_FILTERS.RECEPTION },
  { label: 'Network Provider', value: ADR_BOOK_CONTACT_TYPE_FILTERS.NETWORK_PROVIDER },
];

const AdrBookHeader = (props) => {
  const {
    isCreatedNewContact,
    historyStateListLength,
    lastStateListItemId,
  } = props.undoDataProps;

  const [query, setQuery] = useState(props.search || '');

  const isDisabledForMerge = props.selectedContactsList.length !== 2;

  useDidUpdate(() => {
    LS.setItem('adrBookSearch', props.search);
  }, [props.search]);

  useDidUpdate(() => {
    LS.setItem('adrBookSearchFilters', props.searchFilters);
  }, [props.searchFilters]);

  const onContactTypeChange = (option) => {
    props.updateTagsFilter();
    props.changeContactType(option.value, props.userId);
  };

  const showCreateContactForm = () => {
    if (!props.active && props.isNewContactCreation) {
      props.saveContact();
    }

    if (!props.isNewContactCreation) {
      props.toggleCreateContactForm(true);
    }
  };

  const handleMergeSelected = () => {
    const [donor, acceptor] = props.selectedContactsList;

    props.openModal(
      MODAL_TYPES.mergeContacts,
      {
        donorContact: donor.selectedContact,
        acceptorContact: acceptor.selectedContact,
      }
    )
  }

  const deleteContact = () => {
    if (props.isSelectMode && window.confirm('Are you sure you want to delete selected contact?')) {
      props.setSelectedContactsList(() => []);
      return;
    }

    if (window.confirm('Are you sure you want to delete this contact?')) {
      return props.removeContact(props.active);
    }
  };

  const renderUndoBlock = () => {
    const style = { width: props.isCreatedNewContact ? 200 : 150 }

    if (isCreatedNewContact && props.active) {
      return (
        <div
          className="adr-book-header__undo-block"
          style={style}
        >
          <span className="adr-book-header__undo-block-saved-msg">Contact saved!</span>
        </div>
      )
    }

    if (props.undoDataProps.pending) {
      return (
        <div
          className="adr-book-header__undo-block"
          style={style}
        >
          <div className="adr-book-header__undo-spinner-wrapper">
            <Spinner spinnerSize={30} />
          </div>
        </div>
      );
    }

    if (
      !props.isNewContactCreation &&
      historyStateListLength > 1 &&
      props.active === lastStateListItemId
    ) {
      return (
        <div className="adr-book-header__undo-block">
          <div className="adr-book-header__undo-text">
            <span>
              <span><ICONS.checkRounded className='adr-book-header__undo-btn-icon' /></span>
              Saved !
            </span>
            <button
              type="button"
              onClick={props.setPrevState}
              className="adr-book-header__undo-block-btn"
            >
              <span>Undo the {props.editedContact?.change} change</span>
              <ICONS.undoNew />
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <header className="adr-book-header">
      <div className="container">
        <Navbar isBurgerMenu />

        {Object.values(ADR_BOOK_CONTACT_TYPE_FILTERS).includes(props.contactType)
          ? (
            <div className="adr-book-header__contacts-select-wrapper">
              <Select
                options={adrBookFilterOptions}
                isSearchable={false}
                className="react-select"
                classNamePrefix="react-select"
                value={adrBookFilterOptions.find(
                  ({ value }) => value === props.contactType
                )}
                onChange={onContactTypeChange}
                getOptionValue={option => option.value}
              />
            </div>
          ) : (
            <div className="adr-book-header__select-spinner">
              <Spinner spinnerSize={30} className="adr-book-header__select-spinner" />
            </div>
          )
        }

        <SearchInput
          theme={INPUT_THEMES['dim-width-border']}
          query={query}
          placeholder="Search contact"
          inputWrapClassName="adr-book-header__search-wrap"
          inputClassName="adr-book-header__search-input"
          setQuery={setQuery}
          startSearch={props.updateSearch}
          stopSearch={props.updateSearch}
          showSearchBtn
          showClearBtn
          debounceTime={1000}
          isDisabled={props.isNewContactCreation}
        >
          <AdrBookSearchFilters />
        </SearchInput>

        <div className="adr-book-header__actions">
          {props.isSelectMode 
            ? <Button
                className="adr-book-header__adr-book-btn"
                onClick={handleMergeSelected}
                disabled={isDisabledForMerge}
                icon={<ICONS.plus width="14px" height="14px" />}
              >
                Merge  Selected
              </Button>
            : <Button
                className="adr-book-header__adr-book-btn"
                onClick={showCreateContactForm}
                disabled={props.isNewContactCreation}
                icon={<ICONS.plusCircle width="18px" height="18px" />}
              />
          }

          {(!props.isNewContactCreation && !!props.active) &&
            <Button
              className={classModifier("adr-book-header__adr-book-btn", 'delete')}
              onClick={deleteContact}
              icon={<ICONS.trashTransparent />}
            >
              Delete Contact
            </Button>
          }
        </div>

        {props.isNewContactCreation && !props.active &&
          <div className="adr-book-header__adr-book-btn-block">
            <Button
              className="adr-book-header__adr-book-btn"
              onClick={() => props.toggleCreateContactForm(false)}
              icon={<ICONS.close />}
            >
              Cancel
            </Button>
            <Button
              className="adr-book-header__adr-book-btn"
              onClick={() => props.saveContact()}
              disabled={!props.edited}
              icon={<ICONS.check />}
            >
              Create
            </Button>
          </div>
        }

        {renderUndoBlock()}

        {/* <div className="adr-book-header__mailbox-users-wrapper">
          <span>Users using this mailbox</span>
          <div className="adr-book-header__mailbox-users">
            <LazyLoadImage src={getContactAvatar("")} alt="ava" className="adr-book-header__mailbox-user__img" />
            <LazyLoadImage src={getContactAvatar("")} alt="ava" className="adr-book-header__mailbox-user__img" />
          </div>
        </div> */}
      </div>
    </header>
  )
}

const mapStateToProps = state => ({
  userId: state.user.id,
  contactType: state.addressBook.contactType,
  search: state.addressBook.search,
  searchFilters: state.addressBook.searchFilters,
  isNewContactCreation: state.addressBook.isNewContactCreation,
  active: state.addressBook.active,
  editedContact: selectContactById(state, state.addressBook.active),
});

const mapDispatchToProps = {
  changeContactType,
  toggleCreateContactForm,
  updateSearch,
  removeContact,
  updateTagsFilter,
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookHeader);
