
import { useEffect, useState } from 'react';
import { useSetSocketPageStatus } from 'hooks';
import { Allotment } from 'allotment';

import { classModifier } from 'utils';
import { getContactsById } from 'redux/ducks/contacts';
import useSplitPaneSizes from 'hooks/useSplitPaneSizes';
import { getContactMessageContext, updateActiveContact } from 'redux/ducks/clientChats';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';
import { POSITIONS, SOCKET_PAGES_TITLES } from 'config/constants';

import './MainPage.scss';
import ClientChat from 'containers/Chat/ClientChat';
import GirlChat from 'containers/Chat/GirlChat';
import OperatorsList from 'components/OperatorsList/OperatorsList';
import PublicChat from 'containers/Chat/PublicChat/PublicChat';
import SlidingPanel from 'components/UI/SlidingPanel/SlidingPanel';
import SidebarClientsChatList from 'containers/SidebarChatLists/SidebarClientsChatList';
import SidebarGirlsChatList from 'containers/SidebarChatLists/SidebarGirlsChatList';


const MainSection = () => {
  const [notForClients, setNotForClients] = useState([]);

  return (
    <div className="main-page__chats">
      <ClientChat
        title="Conversations with Clients"
        setNotForClients={setNotForClients}
      />

      <PublicChat />

      <GirlChat
        title="Conversations with Girls"
        notForClients={notForClients}
      />
    </div>
  )
}

const MainPage = () => {
  
  useEffect(() => {
    if (window?.location.hostname === 'localhost') return;

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const userTimezone = useSelector((state) => state.user.userTimezone);

  const main = useSplitPaneSizes('main');

  useSetSocketPageStatus(SOCKET_PAGES_TITLES.main);

  const mainPageOpenIds = useSelector((state) => state.user.pages?.[SOCKET_PAGES_TITLES.main]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (mainPageOpenIds?.length > 1) {
      dispatch(openModal(MODAL_TYPES.openCommModule, {
        title: "Comm module is already open in other tab",
        isNeedButton: false,
      }));
    } else if (mainPageOpenIds?.length < 2) {
      dispatch(closeModal(MODAL_TYPES.openCommModule, false));
    }
  }, [mainPageOpenIds?.length])

  useEffect(() => {
    const channel = new BroadcastChannel('tab_channel');

    channel.onmessage = (event) => {
      if (event.data.name === 'openChat') {
        window.focus();
        
        event.data?.contact && dispatch(updateActiveContact(event.data.contact));
      }

      if (event.data.name === 'openChatMessage') {
        window.focus();

        const { caller, messageId } = event.data;
        dispatch(getContactMessageContext(messageId, caller.type, caller, null, userTimezone));
      }
    };

    const contactForChatUpdate = new BroadcastChannel('adr_book_contact');

    contactForChatUpdate.onmessage = (event) => {
      const editedContact = event.data.editedContact;

      if(editedContact.id) {
        dispatch(getContactsById(editedContact.id))
          .then((contact) => {
            if (contact) {
              window.focus();
              dispatch(updateActiveContact(contact));
            }
          });
      }
    };

    return () => {
      channel.close();
      contactForChatUpdate.close();
    };
  }, []);

  return (
    <main className="main-page">
      <div className="main-page__main">
        <div className="container">
          <Allotment
            defaultSizes={main.defaultSizes}
            onChange={main.onDragEnd}
          >
            <Allotment.Pane minSize={270} maxSize={400}>
              <div className={classModifier("main-page__sidebar", "left")}>
                <SidebarClientsChatList />
              </div>
            </Allotment.Pane>

            <Allotment.Pane minSize={940}>
              <MainSection />
            </Allotment.Pane>

            <Allotment.Pane minSize={270} maxSize={400}>
              <div className={classModifier("main-page__sidebar", "right")}>
                <SlidingPanel
                  position={POSITIONS.BOTTOM}
                  content={<OperatorsList />}
                  text="Operators"
                >
                  <SidebarGirlsChatList />
                </SlidingPanel>
              </div>
            </Allotment.Pane>
          </Allotment>
        </div>
      </div>
    </main>
  );
};

export default MainPage;
