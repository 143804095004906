const getDivaInfoItem = (item, dimension) => {
  if (!item) {
    return '—';
  }

  if (dimension) {
    if (dimension === '£') {
      return dimension + " " + item;
    }

    return item + " " + dimension;
  }

  return item;
}

export const getDivaInfo = (girl) => {
  return `
    ${getDivaInfoItem(girl.prices[0].incall)} /
    ${getDivaInfoItem(girl.prices[2].incall)} /
    ${getDivaInfoItem(girl.prices[2].outcall)} /
    ${getDivaInfoItem(girl.age, 'y.o.')} /
    ${getDivaInfoItem(girl.height, 'cm')} /
    ${getDivaInfoItem(girl.location_main)} /
  `
    // ${getDivaInfoItem(girl.breast_size, 'DD')} /
    // ${getDivaInfoItem(girl.dress, 'UK')} /
}

