import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import Select from "react-select";

import API from "api/api";
import ICONS from "assets/icons";
import {
  setActiveMailChat,
  clearActiveMailChat,
  setUnreadEmailsCount,
  MAIL_MSGS_SORT_OPTIONS,
} from "redux/ducks/mail";
import {
  useDidMount,
  useToggle,
  useDidUpdate,
  useWillUnmount,
  usePrevious,
} from "hooks";
import { LS, capitalizeFirstLetter } from "utils";
import { TITLE } from "config/constants";

import "./MailChat.scss";
import MailChatItem from "./MailChatItem/MailChatItem";
import Spinner from "components/UI/Spinner/Spinner";

const MailChat = ({ params }) => {
  const [isAscMsgsList, setIsAscMsgsList] = useState(
    LS.getItem("msgsListIsAscSort")
  );
  const msgsFromState = useSelector(
    (state) => state.mail.activeMailChatMessages
  )?.filter((msg) => !msg.isDraft);

  const sortedMessages = useMemo(() => {
    return isAscMsgsList ? msgsFromState : [...msgsFromState].reverse();
  }, [isAscMsgsList, msgsFromState]);

  const [messages, setMessages] = useState(sortedMessages);

  const dispatch = useDispatch();

  const sortType = isAscMsgsList ? "ASC" : "DESC";

  useDidUpdate(() => {
    LS.setItem("msgsListIsAscSort", isAscMsgsList);
    setMessages((prev) =>
      prev.length === sortedMessages.length ? prev : sortedMessages
    );
  }, [isAscMsgsList, sortedMessages]);

  const navigate = useNavigate();
  const canGoBack = window.history.length > 1;
  const { folder } = useParams();

  const prevListLength = usePrevious(messages.length);

  const [isEmailNotFounded, setIsEmailNotFounded] = useState(false);

  const [pending, togglePending] = useToggle(true);

  const { id: mailChatId } = params;

  useDidMount(() => {
    API.getMailChatMessages(Number(mailChatId))
      .then(({ data: { messages, unreadCount, unreadSpamCount } }) => {
        const subject = !!messages[0].subject
          ? messages[0].subject
          : "no subject";
        document.title = `${capitalizeFirstLetter(subject)} | ${TITLE}`;

        const prepMessages = messages.map((msg) =>
          msg.info ? { ...msg, info: JSON.parse(msg.info) } : msg
        );

        dispatch(setActiveMailChat(Number(mailChatId), prepMessages));
        dispatch(setUnreadEmailsCount({ unreadCount, unreadSpamCount }));
      })
      .catch((err) => {
        console.error(err);

        if (err.response.data.code === 404) {
          setIsEmailNotFounded(true);
        }
      })
      .finally(() => togglePending(false));
  });

  useWillUnmount(() => {
    dispatch(clearActiveMailChat());
  });

  useDidUpdate(() => {
    // If the last message was deleted - return
    if (prevListLength && !messages.length) {
      handleReturnBtnClick();
    }
  }, [messages]);

  const handleChangeSortType = (option) => {
    setIsAscMsgsList(option.value === "ASC");
  };

  const handleReturnBtnClick = () =>
    canGoBack ? navigate(-1) : navigate(`/mail/${folder}`);

  if (isEmailNotFounded) {
    return (
      <div className="mail-chat mail-chat--not-found">
        <div className="mail-chat__header">
          <button
            className="mail-chat__return-btn"
            type="button"
            onClick={handleReturnBtnClick}
          >
            <ICONS.arrow className="mail-chat__return-btn-icon" />
            Back
          </button>
        </div>

        <div className="mail-chat__main">
          <h2 className="mail-chat__not-founded">Email not found :(</h2>
        </div>
      </div>
    );
  }

  if (pending) {
    return (
      <div className="mail-chat mail-chat--centered">
        <Spinner spinnerSize="80px" />
      </div>
    );
  }

  return (
    <div className="mail-chat">
      <div className="mail-chat__header">
        <button
          className="mail-chat__return-btn"
          type="button"
          onClick={handleReturnBtnClick}
        >
          <ICONS.arrow className="mail-chat__return-btn-icon" />
          Back
        </button>

        <div className="mail-chat__sort">
          <Select
            className="react-select"
            classNamePrefix="react-select"
            options={MAIL_MSGS_SORT_OPTIONS}
            defaultValue={MAIL_MSGS_SORT_OPTIONS.find(
              (item) => item.value === sortType
            )}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={handleChangeSortType}
            isSearchable={false}
            components={{
              IndicatorSeparator: null,
            }}
          />
        </div>
      </div>

      <div className="mail-chat__main">
        <Scrollbars autoHide autoHideTimeout={800}>
          {messages.map((messageItem, index, list) => {
            const newestMsg = list.reduce((pre, cur) =>
              Date.parse(pre.created_at) < Date.parse(cur.created_at)
                ? cur
                : pre
            );

            return (
              <div className="mail-chat__item" key={messageItem.id}>
                <MailChatItem
                  className="mail-chat__message"
                  index={index}
                  currentMail={messageItem}
                  isLastItem={list.length - 1 === index}
                  isNewestMsg={newestMsg.id === messageItem.id}
                />
              </div>
            );
          })}
        </Scrollbars>
      </div>
    </div>
  );
};

export default MailChat;
