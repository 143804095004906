import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePopperTooltip } from 'react-popper-tooltip';

import {
  LIST_TYPES,
  MAIL_LIST_LIMIT,
  moveMailsToFolder,
  deleteMailsToTrash,
  deleteMailsPermanently,
  deleteMailsFromSearchList,
  setMailListPending,
  archiveMails,
  updateSearch,
} from 'redux/ducks/mail';
import { classModifier } from 'utils';
import { selectActiveFolderTab } from 'redux/selectors/selectors';

import SearchInput from 'components/SearchInput/SearchInput';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import Button from 'components/Button/Button';
import ICONS from 'assets/icons';
import { INPUT_THEMES } from 'config/constants';

const MIN_SEARCH_LENGTH = 3;

const MailHeaderListActions = (props) => {
  const {
    offset,
    search,
    updateSearch,
    activeFolder,
    archiveMails,
    mailListPending,
    activeFolderTab,
    activeSortFilter,
    moveMailsToFolder,
    setMailListPending,
    deleteMailsToTrash,
    deleteMailsPermanently,
    selectedConversationsIds,
    deleteMailsFromSearchList,
  } = props;

  const [query, setQuery] = useState(search || '');
  const [isMoveToMenuActive, setIsMoveToMenuActive] = useState(false);
  const [pending, setPending] = useState({ deletePending: false, archivePending: false, movePending: false });

  const navigate = useNavigate();

  const somePending = [mailListPending, ...Object.values(pending)].includes(true);

  const isInbox = activeFolder === LIST_TYPES.inbox;
  const isSent = activeFolder === LIST_TYPES.sent;
  const isSpam = activeFolder === LIST_TYPES.spam;
  const isTrash = activeFolder === LIST_TYPES.trash;
  const isDrafts = activeFolder === LIST_TYPES.drafts;
  const isSearch = activeFolder === LIST_TYPES.search;
  const isPermanentlyDelete = isSpam || isTrash || isDrafts;
  const isDisplayArchiveBtn = isInbox || isSent;
  const isDisplayMoveToBtn = !isDrafts && !isSearch;

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
  } = usePopperTooltip(
    {
      offset: [0, 0],
      trigger: 'click',
      visible: isMoveToMenuActive,
      onVisibleChange: setIsMoveToMenuActive,
    }
  );

  const getConfigForOperationsWithEmails = (additionalParams) => {
    const config = {};

    if (isSearch) {
      config.search = search;
    } else {
      config.folder = activeFolder;
      config.mode = activeFolderTab;
      config.sortBy = activeSortFilter;
    }

    config.offset = offset;
    config.limit = MAIL_LIST_LIMIT;
    config.conversationsIds = selectedConversationsIds;

    return { ...config, ...additionalParams };
  };

  useEffect(() => {
    setQuery(search);
  }, [search]);

  const handleDeleteMails = async () => {
    setMailListPending(true);
    setPending(prev => ({ ...prev, deletePending: true }));

    if (isSearch) {
      await deleteMailsFromSearchList(getConfigForOperationsWithEmails());
    } else if (isPermanentlyDelete) {
      await deleteMailsPermanently(getConfigForOperationsWithEmails());
    } else {
      await deleteMailsToTrash(getConfigForOperationsWithEmails());
    }

    setMailListPending(false);
    setPending(prev => ({ ...prev, deletePending: false }));
  };

  const handleArchiveMails = async () => {
    setMailListPending(true);
    setPending(prev => ({ ...prev, archivePending: true }));

    await archiveMails(getConfigForOperationsWithEmails());

    setMailListPending(false);
    setPending(prev => ({ ...prev, archivePending: false }));
  };

  const handleMoveMails = async (moveTo) => {
    setMailListPending(true);
    setPending(prev => ({ ...prev, movePending: true }));

    await moveMailsToFolder(getConfigForOperationsWithEmails({ to: moveTo }));

    setMailListPending(false);
    setPending(prev => ({ ...prev, movePending: false }));
  };

  const handleUpdateSearch = (query) => {
    updateSearch(query);

    if (query.length >= MIN_SEARCH_LENGTH) {
      navigate('/mail/search');
    }
  };

  const generateMoveToMenuItems = () => ([
    {
      content: 'Inbox',
      action: () => handleMoveMails('inbox'),
    },
    {
      content: 'Archive',
      action: () => handleMoveMails('archive'),
    },
    {
      content: 'Sent',
      action: () => handleMoveMails('sent'),
    },
    {
      content: 'Junk',
      action: () => handleMoveMails('spam'),
    },
    {
      content: 'Trash',
      action: () => handleMoveMails('trash'),
    },
  ].filter(item => item.content.toLowerCase() !== activeFolder));

  return (
    <>
      <SearchInput
        id="search-mails"
        placeholder="Search mails"
        inputWrapClassName="mail-header__search-input-wrap"
        inputClassName="mail-header__search-input"
        theme={INPUT_THEMES['dim-width-border']}
        query={query}
        showClearBtn
        showSearchBtn
        minSearchLength={MIN_SEARCH_LENGTH - 1}
        setQuery={setQuery}
        stopSearch={handleUpdateSearch}
        startSearch={handleUpdateSearch}
      />

      <div className='mail-header__actions'>
        <Button
          title='Archive'
          className={classModifier('mail-header__action-btn', [
            'main',
            'archive',
            !isDisplayArchiveBtn && 'hidden',
          ])}
          pending={pending.archivePending}
          disabled={somePending || !selectedConversationsIds.length}
          onClick={handleArchiveMails}
          icon={<ICONS.archive />}
        >
          Archive
        </Button>

        <Button
          title='Delete'
          className={classModifier('mail-header__action-btn', 'delete', 'main')}
          pending={pending.deletePending}
          disabled={somePending || !selectedConversationsIds.length}
          onClick={handleDeleteMails}
          icon={<ICONS.trash />}
        >
          Delete
        </Button>

        <div
          className={classModifier('mail-header__move-to-wrap', [
            isMoveToMenuActive && 'move-menu-active'
          ])}
          ref={setTriggerRef}
        >
          <Button
            className={classModifier('mail-header__action-btn', [
              !isDisplayMoveToBtn && 'hidden',
              'move-to'
            ])}
            title='Move to'
            pending={pending.movePending}
            disabled={somePending || !selectedConversationsIds.length}
            icon={<ICONS.folderOpen />}
          >
            Move to
          </Button>

          {visible &&
            <div
              ref={setTooltipRef}
              {...getTooltipProps({ className: 'mail-header__move-to-popup-wrap' })}
            >
              <DropdownMenu
                items={generateMoveToMenuItems()}
                closeDropdownMenu={() => setIsMoveToMenuActive(false)}
              />
            </div>
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  offset: state.mail.offset,
  search: state.mail.mailList.search,
  mailListPending: state.mail.mailList.mailListPending,
  activeFolder: state.mail.mailList.activeFolder,
  activeFolderTab: selectActiveFolderTab(state)?.mode,
  activeSortFilter: state.mail.mailList.activeSortFilter,
  selectedConversationsIds: state.mail.selectedConversationsIds,
});

const mapDispatchToProps = {
  archiveMails,
  updateSearch,
  moveMailsToFolder,
  deleteMailsToTrash,
  setMailListPending,
  deleteMailsPermanently,
  deleteMailsFromSearchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailHeaderListActions);
