import React, { useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Select from 'react-select';
import { usePopperTooltip } from 'react-popper-tooltip';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import './CheckBoxFormItem.scss';
import CheckBoxItemOption from './CheckBoxItemOption';


const CheckBoxFormItem = ({ 
  viaTitle, 
  setValues, 
  isDisabled,
  saveContact,
  viaFieldName, 
  selectOptions, 
  valueToChange, 
  isMistressOnly,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const form = useForm();
  const { values } = useFormState();

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
  } = usePopperTooltip(
    {
      offset: [0, 5],
      trigger: 'click',
      placement: 'bottom-start',
      visible: isTooltipOpen,
      onVisibleChange: setIsTooltipOpen,
    }
  );

  const isMistress = viaFieldName === 'is_mistress';

  const renderViaItemStateIcon = (item) => item 
    ? <ICONS.check className="contact-form-via-item__icon-check"/>
    : <span className={classModifier("contact-form-via-item__btn-checkbox", isDisabled && "disabled")}></span>

  const toggleMenuIsOpen = (e, onChange, item) => {
    if (e.target.closest("span[data-btn='title']")) {
      setIsOpen(!isOpen);
      return;
    } 

    if (!item) {
      setIsOpen(!isOpen);
    } else {
      if(isOpen) {
        setIsOpen(false);
      } else {
        onChange(null);
      }
    }
  };

  const setSelectOptions = (e, input) => {
    selectOptions 
      ? toggleMenuIsOpen(e, input.onChange, input.value) 
      : handleChange(input);

      setIsTooltipOpen(false);
  }

  const handleClick = (e, input) => {
    if(isMistress) {
      setIsTooltipOpen(true);
      return;
    }
    setSelectOptions(e, input);
  };

  const handleChange = (input) => {
    if (viaFieldName === 'is_ex') {
      // remove the prefix if the status ex is removed
      if (!!input.value) {
        setValues('fn', valueToChange.slice(3));
      } else {
        // add a prefix if the status ex is enabled
        const updatedFn = 'EX ' + (valueToChange || '');

        setValues('fn', updatedFn);
      }
    }

    input.onChange(!input.value);

    if(input.name === 'is_mistress_only') {
      saveContact('is_mistress_only', !input.value);

      !!values.is_mistress && form.change('is_mistress', 0);
      return;
    }

    if(input.name === 'is_mistress') {
      saveContact('is_mistress', !input.value);

      !!values.is_mistress_only && form.change('is_mistress_only', 0);
      return;
    }

    if (
      viaFieldName === 'is_ex' || 
      viaFieldName === 'available_card' || 
      viaFieldName === 'is_top'
    ) {
      saveContact(viaFieldName, !input.value);
    }
  };

  return (
    <Field
      name={viaFieldName}
      render={({ input }) => (
        <div className={classModifier("contact-form-via-item", isMistress && 'is-mistress')}>
          <button 
            type="button"
            disabled={isDisabled}
            ref={node => isMistress ? setTriggerRef(node) : null}
            onBlur={() => isOpen && setIsOpen(false)}
            className="contact-form-via-item__btn"
            onClick={e => handleClick(e, input)}
          >
            {isOpen 
              ? <ICONS.chevron className="contact-form-via-item__icon-chevron"/>
              : renderViaItemStateIcon(isMistressOnly || input.value)
            }

            <span 
              data-btn='title' 
              className={classModifier("contact-form-via-item__btn-title", input.value && "checked")}
            >
              {viaTitle}
            </span>
          </button>

          {visible && 
            <div
              ref={setTooltipRef}
              {...getTooltipProps({ className: 'contact-form-via-item__popup' })}
            >
              <div 
                onClick={e => setSelectOptions(e, input)}
                className={'contact-form-via-item__popup-list-item'}
              >
                {renderViaItemStateIcon(input.value)}
                Mistress
              </div>

              <Field
                name="is_mistress_only"
                render={({ input: checkboxInput }) => (
                  <div 
                    onClick={e => setSelectOptions(e, checkboxInput)} 
                    className={'contact-form-via-item__popup-list-item'}>
                      {renderViaItemStateIcon(checkboxInput.value)}
                      Only mistress
                  </div>
                )}
              />
            </div>
          }

          {selectOptions && 
            <Select
              className="react-select"
              classNamePrefix="react-select"
              menuIsOpen={isOpen}
              isSearchable={false}
              onChange={option => {
                input.onChange(option.value);
                isOpen && setIsOpen(false);
              }}
              options={selectOptions}
              getOptionLabel={option => option}
              getOptionValue={option => option.value}
              value={selectOptions?.find(item => item.value === input.value) || null}
              components={{
                Control: () => <div></div>,
                Option: CheckBoxItemOption
              }}
            />
          }
        </div>
      )}
    />

  )
}

export default React.memo(CheckBoxFormItem);
