import React, { useEffect, useState } from 'react'
import './TasksAndRemindersItem.scss'
import ICONS from 'assets/icons';
import { classModifier, getContactAvatar, getDateByTimezoneOffset, parseMsgText } from 'utils';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'hooks';
import {
  deleteTasksOrReminders,
  markAsDoneTasksOrReminders,
  markAsDoneWebmasterTask,
  markAsInProgressTasksOrReminders,
  toggleIsOpenTasksAndRemindersModal,
  updateTasksOrReminders
} from 'redux/ducks/notifications';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Portal from 'components/Portal';
import DateTime from 'components/DateTime';
import {
  FEEDBACK_DATE_CONFIG,
  TASKS_OR_REMINDERS_DATE_CONFIG,
  CONTACT_LOG_DATE_CONFIG
} from 'config/dates-сonfig';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import popperViewportCenter from 'config/popperViewportCenter';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import { selectContactById, selectModalCount, selectOperatorOrUserById, selectUserTimezone } from 'redux/selectors/selectors';
import { REMOVE_CLIENT_MSG_REMIND, deleteRemindedMsgId, getContactMessageContext } from 'redux/ducks/clientChats';
import { REMOVE_GIRL_REMIND_MSG, changeChatSource } from 'redux/ducks/girlChats';
import { BUTTON_THEMES, CHAT_SOURCES, CHAT_TYPES, SIZES } from 'config/constants';
import TaskLog from './TaskLog';
import PinnedMsgsAttachments from 'containers/Chat/components/ChatTimeline/PinnedMsgs/PinnedMsgsAttachments';
import MsgText from 'containers/Chat/components/ChatTimeline/interactions/MsgText';
import handleLinkClick from 'utils/handleLinkClick';
import Checkbox from 'components/Checkbox/Checkbox';
import Button from 'components/UI/Button/Button';

const TasksAndRemindersItem = ({ item }) => {
  const dispatch = useDispatch();

  const modalCount = useSelector((state) => selectModalCount(state));
  const operator = useSelector((state) => selectOperatorOrUserById(state, item.user_id));
  const operatorCreated = useSelector((state) =>
    selectOperatorOrUserById(state, item.created_by_user_id));
  const operatorAssigned = useSelector((state) =>
    selectOperatorOrUserById(state, item.assigned_to_user_id));
  const operatorCompleted = useSelector((state) =>
    selectOperatorOrUserById(state, item.completed_by_user_id));
  const userTimezone = useSelector((state) => selectUserTimezone(state));
  const isTasksAndRemindersModalOpen = useSelector(state => state.notifications.isOpenTasksAndRemindersModal)
  const callerFromRedux = useSelector((state) => selectContactById(state, item.caller?.id || item.interaction?.caller?.id));
  const callerIdsOfActiveGirlChats = useSelector((state) => state.girlChats.tabs);

  const [isTaskDone, toggleIsTaskDone] = useToggle(Boolean(item.completed_by_user_id || item.is_completed));
  const [date, setDate] = useState(item.remind_at);
  const [isTaskInfoModalOpen, setIsTaskInfoModalOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const caller = item.caller || item.interaction?.caller;

  // useEffect(() => {
  //   toggleIsTaskDone(Boolean(item.completed_by_user_id || item.is_completed))
  // }, [item])

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    offset: [-4, 0],
    placement: 'right-start',
    trigger: 'hover',
    visible: isTaskInfoModalOpen,
    onVisibleChange: () => {
      isTaskInfoModalOpen && setIsTaskInfoModalOpen(true)
      setIsCalendarOpen(false)
    },
  });

  const calendarTooltip = usePopperTooltip({
    trigger: 'click',
    visible: isCalendarOpen,
    onVisibleChange: setIsCalendarOpen,
  }, {
    modifiers: [popperViewportCenter],
  });

  const isReminders = item.is_sent === 0 || item.is_sent === 1;
  const isWebmasterTask = item.is_web_master;

  const handleNewDate = (timestamp) => {
    setDate(timestamp);
    dispatch(updateTasksOrReminders({
      isReminders: isReminders,

      remind_at: timestamp,
      id: item.id
    })).then(() => {
      setIsCalendarOpen(false)
      setIsTaskInfoModalOpen(false)
    });
  };

  const handleCompleteTask = () => {
    return dispatch(markAsDoneTasksOrReminders(item.id, !isTaskDone, isWebmasterTask))
      .then(toggleIsTaskDone);
  }

  const handleDeleteTask = (e) => {
    e.stopPropagation();

    if (window.confirm("Do you want to delete?")) {
      dispatch(
        deleteTasksOrReminders({
          item,
          isReminders,
          isWebmasterTask,
        })
      ).then(() => {
        if (item.caller) {
          dispatch(
            deleteRemindedMsgId(
              item.caller.type,
              item.id
            )
          );
          dispatch({
            type:
              item.caller.type === 1
                ? REMOVE_CLIENT_MSG_REMIND
                : REMOVE_GIRL_REMIND_MSG,
            payload: item.id,
          });
        }
      });
    }
  }

  const goToMsgContext = () => {
    dispatch(changeChatSource(CHAT_SOURCES.MSGS, item.caller.type));
    dispatch(getContactMessageContext(item.interaction.id, CHAT_TYPES.CLIENT, item.caller, '', userTimezone));
    dispatch(toggleIsOpenTasksAndRemindersModal({ status: !isTasksAndRemindersModalOpen }))
  };

  let message;

  if (item.interaction?.message || item.interaction?.body) {
    message = (
      <div
        className='interaction__text-wrap'
        onClick={(event) => handleLinkClick(event, dispatch, item, callerIdsOfActiveGirlChats)}
      >
        <MsgText interaction={{ ...item.interaction, hasUrls: true }} />
      </div>
    );
  } else if (!(item.interaction?.message || item.interaction?.body) && (item.interaction?.attachment || item.interaction?.attachments)) {
    const realAttachments = item.interaction?.attachments || item.interaction?.attachment;
    const { images, videos, voiceMsg } = typeof realAttachments === 'string' ? JSON.parse(realAttachments) : realAttachments;

    message = images || videos || voiceMsg
      ? <PinnedMsgsAttachments attachments={realAttachments} isMenuOpen={false} />
      : '"[ATTACHMENTS]"';
  }

  return ( // [ ]: implement other tooltip header buttons
    <li
      className="tasks-and-reminders-item__wrapper"
      ref={setTriggerRef}
      onMouseEnter={() => setIsTaskInfoModalOpen(true)}
      onMouseLeave={() => !modalCount && setIsTaskInfoModalOpen(false)}
    >
      <div
        className={classModifier("tasks-and-reminders-item", [
          isTaskInfoModalOpen && "open",
          isTaskDone && "done",
        ])}
      >
        <div className="tasks-and-reminders-item__top-line">
          <div className="tasks-and-reminders-item__text-box">
            {!isReminders && (
              <Checkbox
                inputProps={{ checked: isTaskDone, onChange: handleCompleteTask }}
              />
            )}
            <div
              className="tasks-and-reminders-item__title"
              onClick={(event) => handleLinkClick(event, dispatch, item, callerIdsOfActiveGirlChats)}
            >
              {isReminders && item.interaction
                ? (
                  <MsgText
                    classPrefix='tasks-and-reminders-item'
                    interaction={{ ...item.interaction, hasUrls: true }}
                  />
                )
                : item.title || item.description}
            </div>
          </div>
          <div className="tasks-and-reminders-item__date">
            {item.remind_at && (
              <ICONS.bellNew className="tasks-and-reminders-item__icon-bell" />
            )}
            {isWebmasterTask && <ICONS.w className="tasks-and-reminders-item__icon-webmaster" />}
            <DateTime date={item.date_created} config={FEEDBACK_DATE_CONFIG} />
          </div>
        </div>

        <div className="tasks-and-reminders-item__bottom-line">
          <div className="tasks-and-reminders-item__text">
            {(item.caller || item.associated_with) ? (
              <div className="tasks-and-reminders-item__contact">
                <LazyLoadImage src={getContactAvatar(item.caller || item.associated_with)} alt="ava" />
                <div className="tasks-and-reminders-item__contact-name">
                  {item.caller?.fn || item.associated_with?.fn}
                </div>
              </div>
            ) : (
              <MsgText
                classPrefix='tasks-and-reminders-item'
                interaction={{ ...item, hasUrls: true }}
              />
            )}
          </div>
          <div className="tasks-and-reminders-item__operator">
            {isReminders
              ? operator.username
              : item.assigned_to_user_id
                ? operatorAssigned.username
                : "All operators"}
          </div>
        </div>

        {isTaskInfoModalOpen && (
          <Portal>
            <div
              className="tasks-and-reminders-item__tooltip"
              onClick={() => {
                !isReminders &&
                  dispatch(
                    openModal(MODAL_TYPES.addNewTasksOrRemaindersModal, {
                      item,
                      setIsTaskInfoModalOpen,
                      callerFromRedux,
                      isWebmaster: isWebmasterTask,
                    })
                  );
              }}
              ref={setTooltipRef}
              {...getTooltipProps({ style: { zIndex: 112 } })}
            >
              <header className="tasks-and-reminders-item__tooltip-header">
                {/* <div
                  className={classModifier(
                    "tasks-and-reminders-item__reminders-text",
                    [item.remind_at && "date"]
                  )}
                  onClick={() => isReminders && setIsCalendarOpen(true)}
                >
                  {item.remind_at ? (
                    <ICONS.bellFill className="tasks-and-reminders-item__icon-bell" />
                  ) : (
                    <ICONS.bellSlash className="tasks-and-reminders-item__icon-bell" />
                  )}
                  {item.remind_at ? (
                    <DateTime
                      date={item?.remind_at}
                      config={TASKS_OR_REMINDERS_DATE_CONFIG}
                    // ignoreTimezone
                    />
                  ) : (
                    "Reminder not set"
                  )}
                </div>
                <div className="tasks-and-reminders-item__date-btns">
                  {isWebmasterTask && <ICONS.w id="webmaster" />}
                  {isReminders ? (
                    <ICONS.clock id="clock" />
                  ) : (
                    <ICONS.pencil id="edit" />
                  )}
                  <ICONS.trashTransparent
                    id="delete"
                    onClick={handleDeleteTask}
                  />
                </div> */}
                <div className="flex-row">
                  <Button
                    theme={item.remind_at ? BUTTON_THEMES.FILTER : BUTTON_THEMES.PRIMARY}
                    iconSize={SIZES.M}
                    isBroad
                  >
                    <Button.Icon icon={ICONS.bellNew} />
                    <Button.Text>
                      {item.remind_at ? (
                        <DateTime
                          date={item?.remind_at}
                          config={TASKS_OR_REMINDERS_DATE_CONFIG}
                        />
                      ) : (
                        "reminder not set"
                      )}
                    </Button.Text>
                  </Button>
                </div>

                <div className="flex-row">
                  <Button
                    theme={BUTTON_THEMES.PRIMARY}
                    icon={ICONS.trashTransparent}
                    iconSize={SIZES.M}
                    onClick={handleDeleteTask}
                  />
                </div>
              </header>
              
              <article className="tasks-and-reminders-item__tooltip-content">
                {item.title && (
                  <div className="tasks-and-reminders-item__tooltip-title-box">
                    <div className="tasks-and-reminders-item__tooltip-title">
                      {item.title}
                    </div>
                  </div>
                )}
                {item.description && (
                  <div className="tasks-and-reminders-item__tooltip-title-box">
                    <div className="tasks-and-reminders-item__tooltip-description">
                      <MsgText
                        classPrefix='tasks-and-reminders-item-tooltip'
                        interaction={{ ...item, hasUrls: true }}
                      />
                    </div>
                  </div>
                )}
                {caller && item.interaction && (
                  <div
                    className="tasks-and-reminders-item__client-wrapper"
                    onClick={() => isReminders && goToMsgContext()}
                  >
                    <div
                      className={classModifier('tasks-and-reminders-item__client', [
                        // isReminders && 'reminder',
                        'reminder'
                      ])}
                    >
                      <LazyLoadImage
                        src={getContactAvatar(caller)}
                        alt="ava"
                      />
                      <div className="tasks-and-reminders-item__client-text-content">
                        <span className="tasks-and-reminders-item__client-name">
                          {caller?.fn}
                        </span>
                        <span className="tasks-and-reminders-item__client-contact">
                          {isReminders && item.interaction ? (
                            <DateTime
                              date={item.interaction.date_created}
                              config={CONTACT_LOG_DATE_CONFIG}
                            />
                          ) : (
                            caller?.tels?.[0]?.tel ||
                            caller?.emails?.[0]?.email ||
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                    {item.interaction && (
                      <div
                        className="tasks-and-reminders-item__tooltip-description--reminder"
                        onClick={event => event.stopPropagation()}
                      >
                        {message}
                      </div>
                    )}
                  </div>
                )}

                <TaskLog item={item} className="tasks-and-reminders-item__created-info" />

                {isCalendarOpen && (
                  <div
                    ref={calendarTooltip.setTooltipRef}
                    {...calendarTooltip.getTooltipProps({
                      className: 'calendar-form-field__tooltip',
                    })}
                  >
                    <DateTimePicker
                      onSelectDate={handleNewDate}
                      onCancel={() => setIsCalendarOpen(false)}
                      initDate={date}
                      minDate={+getDateByTimezoneOffset(userTimezone) + 900000}
                    />
                  </div>
                )}
              </article>
            </div>
          </Portal>
        )}
      </div>
    </li>
  );
}

export default TasksAndRemindersItem;
