import { lazy } from 'react';

import { MODAL_TYPES } from 'redux/ducks/activeWindows';

import OutgoingCallModal from './OutgoingCallModal';
import IncomingCallModal from './IncomingCallModal';
import ModalError from './ModalError/ModalError';
import ModalImage from 'components/ModalImage/ModalImage';
import ModalVideo from 'components/ModalVideo/ModalVideo';
import AwaySystemModal from './AwaySystemModal/AwaySystemModal';
import MailContactSearch from './MailContactSearch/MailContactSearch';
import NotForContactModal from './NotForContactModal/NotForContactModal';
import FeedbackForm from 'components/FeedbackForm/FeedbackForm';
import ProfileCarousel from '../../ProfileCard/components/ProfileCarousel/ProfileCarousel';
import SearchList from 'components/SearchList/SearchList';
import FilterEditor from './FilterEditor/FilterEditor';
import BookingForm from 'components/BookingForm/BookingForm';
import BookingEditForm from 'components/BookingEditForm/BookingEditForm';
import GirlsMapModal from './GirlsMapModal/GirlsMapModal';
import ProfilesBooked from './ProfilesBooked/ProfilesBooked';
import TransferredOrAssistanceSessionModal from './TransferredOrAssistanceSessionModal/TransferredOrAssistanceSessionModal';
import ProfileReviews from 'components/ProfileReviews/ProfileReviews';
import BookingsListModal from 'components/BookingsListModal/BookingsListModal';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import MultiMessageModal from './MultiMessageModal/MultiMessageModal';
import PhotoGallery from './PhotoGallery/PhotoGallery';
import AddNewTasksOrRemaindersModal from './AddNewTasksOrRemaindersModal/AddNewTasksOrRemaindersModal';
import BufferChats from 'components/BufferChats/BufferChats';
import BlockForm from './BlockForm/BlockForm';
import ConflictingBookingsModal from './ConflictingBookingsModal/ConflictingBookingsModal';
import ImgCropper from 'components/ImgCropper/ImgCropper';
import ComputerModal from './ComputerModal/ComputerModal';
import ContactCard from './ContactCard/ContactCard';
import OpenCommModule from './OpenCommModule/OpenCommModule';
import BufferedGirlsBookingsModal from './BufferedGirlsBookingsModal/BufferedGirlsBookingsModal';
import ConfirmOnLogoutModal from './ConfirmOnLogoutModal/ConfirmOnLogoutModal';

// const LoadContactCard = lazy(() => import(/* webpackChunkName: "ContactCard"*/ './ContactCard/ContactCard'));
const LoadUserSettings = lazy(() => import(/* webpackChunkName: "UserSettings"*/ './UserSettings/UserSettings'));
const LoadProfilePricesEditing = lazy(() => import(/* webpackChunkName: "ProfilePricesEditing"*/ './ProfilePricesEditingModal/ProfilePricesEditingModal'));
const LoadCallModal = lazy(() => import(/* webpackChunkName: "CallModal"*/ './CallModal/CallModal'));
const LoadBookingExistWarning = lazy(() => import(/* webpackChunkName: "LoadBookingExistWarningChunk"*/ 'components/BookingExistWarning/BookingExistWarning'));
const LoadProfilesBuffer = lazy(() => import(/* webpackChunkName: "LoadProfilesBufferChunk"*/ './ProfilesBuffer/ProfilesBuffer'));
export const LoadContactsMergeList = lazy(() => import(/* webpackChunkName: "LoadContactsMergeListChunk"*/ './ContactsMergeList/ContactsMergeList'));
export const LoadContactsMergeEditor = lazy(() => import(/* webpackChunkName: "LoadMergeContactsEditorChunk"*/ './ContactsMergeEditor/ContactsMergeEditor'));
export const LoadSendAttachmentMessageModal = lazy(() => import(/* webpackChunkName: "LoadSendAttachmentMessageModalChunk"*/ './SendAttachmentMessageModal/SendAttachmentMessageModal'));
export const LoadSendGirlsToClientModal = lazy(() => import(/* webpackChunkName: "LoadSendGirlsToClientModalChunk"*/ './SendGirlsToClientModal/SendGirlsToClientModal'));
export const LoadSendNavigationPathModal = lazy(() => import(/* webpackChunkName: "LoadSendNavigationPathModalChunk"*/ './SendNavigationPathModal/SendNavigationPathModal'));

export default {
  [MODAL_TYPES.userSettings]: LoadUserSettings,
  [MODAL_TYPES.mailContactSearch]: MailContactSearch,
  [MODAL_TYPES.outgoingCall]: OutgoingCallModal,
  [MODAL_TYPES.incomingCall]: IncomingCallModal,
  [MODAL_TYPES.contactCard]: ContactCard,
  [MODAL_TYPES.image]: ModalImage,
  [MODAL_TYPES.video]: ModalVideo,
  [MODAL_TYPES.error]: ModalError,
  [MODAL_TYPES.awaySystem]: AwaySystemModal,
  [MODAL_TYPES.profilePricesEditing]: LoadProfilePricesEditing,
  [MODAL_TYPES.notForContact]: NotForContactModal,
  [MODAL_TYPES.call]: LoadCallModal,
  [MODAL_TYPES.feedback]: FeedbackForm,
  [MODAL_TYPES.profileCarousel]: ProfileCarousel,
  [MODAL_TYPES.photoGallery]: PhotoGallery,
  [MODAL_TYPES.searchList]: SearchList,
  [MODAL_TYPES.salesFiltersEditor]: FilterEditor,
  [MODAL_TYPES.bookingForm]: BookingForm,
  [MODAL_TYPES.bookingEditForm]: BookingEditForm,
  [MODAL_TYPES.bookingExistWarning]: LoadBookingExistWarning,
  [MODAL_TYPES.girlsMap]: GirlsMapModal,
  [MODAL_TYPES.timePicker]: DateTimePicker,
  [MODAL_TYPES.profilesBooked]: ProfilesBooked,
  [MODAL_TYPES.profilesBuffer]: LoadProfilesBuffer,
  [MODAL_TYPES.transferredOrAssistanceSession]: TransferredOrAssistanceSessionModal,
  [MODAL_TYPES.profileReviews]: ProfileReviews,
  [MODAL_TYPES.bookingsList]: BookingsListModal,
  [MODAL_TYPES.contactsMergeList]: LoadContactsMergeList,
  [MODAL_TYPES.mergeContacts]: LoadContactsMergeEditor,
  [MODAL_TYPES.multiMessageModal]: MultiMessageModal,
  [MODAL_TYPES.addNewTasksOrRemaindersModal]: AddNewTasksOrRemaindersModal,
  [MODAL_TYPES.bufferChatsModal]: BufferChats,
  [MODAL_TYPES.blockForm]: BlockForm,
  [MODAL_TYPES.conflictingBookingsModal]: ConflictingBookingsModal,
  [MODAL_TYPES.openAvatarEditor]: ImgCropper,
  [MODAL_TYPES.computerModal]: ComputerModal,
  [MODAL_TYPES.sendAttachmentMessageModal]: LoadSendAttachmentMessageModal,
  [MODAL_TYPES.sendGirlsToClientModal]: LoadSendGirlsToClientModal,
  [MODAL_TYPES.sendNavigationPathModal]: LoadSendNavigationPathModal,
  [MODAL_TYPES.openCommModule]: OpenCommModule,
  [MODAL_TYPES.bufferedGirlsBookingsModal]: BufferedGirlsBookingsModal,
  [MODAL_TYPES.confirmOnLogoutModal]: ConfirmOnLogoutModal,
}
