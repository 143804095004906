import React, { useEffect } from 'react';
import { FormSpy } from 'react-final-form';

const isFormEdited = (values, initialValues, isNotFull) => {
  const isNotEqualObjects = (initialValues, values, fieldName) => {
    if ((values || []).length !== (initialValues || []).length) {
      return true;
    }

    switch (fieldName) {
      case 'services': {
        return values?.some((obj, idx) => (
          obj.name !== initialValues[idx].name ||
          obj.price !== initialValues[idx].price ||
          obj.checked !== initialValues[idx].checked
        ));
      }

      case 'bookingDuration': {
        return (
          values.label !== initialValues.label ||
          values.value !== initialValues.value
        );
      }

      case 'address': {
        return (
          values?.type !== initialValues?.type ||
          values?.latitude !== initialValues?.latitude ||
          values?.longitude !== initialValues?.longitude ||
          values?.locationName !== initialValues?.locationName
        );
      }

      case 'requirements': {
        return values?.some((obj, idx) => (
          !!obj.isChecked !== !!initialValues[idx].isChecked
        ));
      }

      case 'notes': {
        return Boolean(values?.notes) && Boolean(initialValues?.notes)
          && values?.notes !== initialValues?.notes;
      }

      default: return false;
    }
  }

  const isOperatorIdNotEqual = values?.operatorId !== initialValues?.operatorId;
  const isBookingStatusNotEqual = values?.bookingStatus !== initialValues?.bookingStatus;
  const isRequirementsNotEqual = isNotEqualObjects(initialValues?.requirements, values?.requirements, 'requirements');
  const isDuoRequirementsNotEqual = values.escort && isNotEqualObjects(initialValues?.duoRequirements, values?.duoRequirements, 'requirements');
  const isAddressesNotEqual = isNotEqualObjects(initialValues?.address || {}, values?.address || {}, 'address');
  const isNotesNotEqual = isNotEqualObjects(initialValues, values, 'notes');
  const isSuccessNotEqual = isNotFull && values?.is_success !== initialValues?.is_success;
  const isDescriptionNotEqual = values?.description !== initialValues?.description;
  const isReasonNotEqual = values?.reason !== initialValues?.reason;

  return isOperatorIdNotEqual
    || isBookingStatusNotEqual
    || isRequirementsNotEqual
    || isDuoRequirementsNotEqual
    || isAddressesNotEqual
    || isNotesNotEqual
    || isSuccessNotEqual
    || isDescriptionNotEqual
    || isReasonNotEqual;
};

const BookingEditAutoSave = props => {
  const {
    values,
    saveBooking,
    prevFormState
  } = props;

  useEffect(() => {
    if (isFormEdited(values, prevFormState)) {
      saveBooking({values});
    }
  }, [values]);
  
  return null;
};

export default props => (
  <FormSpy
    {...props}
    subscription={{
      active: true,
      values: true
    }}
    component={BookingEditAutoSave}
  />
)
