import { useDispatch } from 'react-redux';

import ICONS from 'assets/icons';
import { BUTTON_THEMES, SIZES } from 'config/constants';
import { toggleContactMark } from 'redux/ducks/contacts';

import Button from 'components/UI/Button/Button';


const MarkChatButton = (props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleContactMark(props.activeRecipient));
  }

  return (
    <Button
      className={props.className}
      title={props.activeRecipient.marked ? 'Unmark' : 'Mark'}
      icon={ICONS.mark}
      active={props.activeRecipient.marked}
      iconSize={props.size || SIZES.L}
      theme={props.theme || BUTTON_THEMES.NONE_DARK}
      disabled={props.disabled}
      onClick={handleClick}
    />
  )
}

export default MarkChatButton;
