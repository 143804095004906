import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import { createSession, updateActiveSession } from 'redux/ducks/sessions';
import { selectActiveGirlByDiva, selectActiveSessionByContactId, selectDefaultSession } from 'redux/selectors/selectors';
import Button from 'components/UI/Button/Button';
import { BUTTON_THEMES, SIZES } from 'config/constants';
import ICONS from 'assets/icons';


const CreateSessionButton = (props) => { // [ ]: should red background color be implemented when active session is active
  const contactSessionId = useSelector(
    state => selectActiveSessionByContactId(state, props.activeRecipient.id)
  );
  const activeSession = useSelector(state => state.sessions.activeSession);
  const activeGirl = useSelector(selectActiveGirlByDiva);
  const defaultSession = useSelector(selectDefaultSession);
  const isSalesPageOpen = useSelector(state => state.user.isSalesPageOpen);

  const dispatch = useDispatch();

  const toggleSession = () => {
    if (contactSessionId) {
      API.switchSalesSession(contactSessionId);

      dispatch(updateActiveSession(contactSessionId));

      // return setIsNotificationActive(true);
    } else {
      if (activeSession === 0) {
        dispatch(createSession(
          props.activeRecipient.id,
          [],
          activeGirl.id || defaultSession.comparedIds,
          defaultSession.activeFilters,
          defaultSession.additionalFilters,
          defaultSession.recentlyViewedIds,
        )).then((sessionId) => {
          dispatch(updateActiveSession(sessionId));
        })
      } else {
        createSession(props.activeRecipient.id).then((sessionId) => {
          dispatch(updateActiveSession(sessionId));
        })
      }
    }

    if (!isSalesPageOpen) {
      window.open('/sales', '_blank');
    }
  }

  return (
    <Button
      className={props.className}
      title={contactSessionId ? 'switch session' : 'start session'}
      theme={props.theme || BUTTON_THEMES.NONE_DARK}
      icon={ICONS.puzzleNew}
      iconSize={props.iconSize || SIZES.L}
      onClick={toggleSession}
      disabled={props.disabled}
    />
  )
}

export default CreateSessionButton;
