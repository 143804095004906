import classNames from 'classnames';

import { classModifier } from 'utils';

import "./Counter.scss";
import { useEffect, useRef } from 'react';


const Counter = (props) => {
  const {
    className,
    count,
    color,
    title,
    underlied,
    axis = 'vertical',
    icon: Icon,
  } = props;

  const ref = useRef();

  if (typeof count !== 'number') {
    return console.error('Count should be a number');
  }

  // useEffect(() => {
  //   if (!ref.current) return;

  //   const counterStyles = window.getComputedStyle(ref.current);
    
  //   if (Number(counterStyles.zIndex) === -1) {
  //     ref.current.classList.add('counter--underlied');
  //   }
  // }, [ref])

  if (count === 0) {
    return null;
  }

  const classes = classModifier(
    'counter',
    [
      // color && 'color-' + color,
      count > 99 && 'overflowed',
      underlied && 'underlied',
      axis && 'axis-' + axis,
    ]
  );

  const styles = color ? {
    backgroundColor: `rgba(var(${color}), 0.8)`,
    color: 'rgb(var(--primary-color))'
  } : {}

  return (
    <mark
      className={classNames(classes, className)}
      title={title}
      style={styles}
      ref={ref}
    >
      <div className="counter__content">
        {Icon && <Icon />}
        <span className="counter__number">
          {count > 99 ? '99+' : count}
        </span>
      </div>
    </mark>
  )
}

export default Counter;
