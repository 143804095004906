import { memo } from 'react';
import classNames from 'classnames';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { useDebounce, useDidUpdate } from 'hooks';

import './SearchInput.scss'
import { INPUT_THEMES } from 'config/constants';


const SearchInput = (props) => {
  const {
    query,
    onChange,
    setQuery,
    stopSearch,
    startSearch,
    inputRef,
    isDisabled,
    showSearchBtn,
    showClearBtn,
    inputClassName,
    inputWrapClassName,
    minSearchLength = 1,
    placeholder = 'Search',
    icon: Icon,
    theme = INPUT_THEMES.primary,
    children,
    ...inputProps
  } = props;

  useDidUpdate(() => {
    if (query.length === 0) {
      stopSearch?.('');
    }
  }, [query]);

  const handleSearch = () => {
    if (query.length > minSearchLength) {
      startSearch?.(query);
    }
  };

  const onInputChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    setQuery(e.target.value)
  };

  useDebounce(handleSearch, 700, query);

  const renderRightIcon = () => {
    if (query.length > 0) {
      return (
        <button type='button' className="search-input__btn" onClick={() => setQuery('')}>
          <ICONS.close className={classModifier('search-input__icon', 'clear')} />
        </button>
      )
    }

    return (
      <div className="search-input__icon-wrapper">
        {Icon && <Icon className={classModifier("search-input__icon", "children")} />}
      </div>
    )
  }

  return (
    <div className={classNames(inputWrapClassName, classModifier('search-input', theme))}>
      {showSearchBtn && (
        <div className="search-input__icon-wrapper">
          <ICONS.searchNew className={classModifier('search-input__icon', 'search')} />
        </div>
      )}

      <input
        autoComplete="off"
        className={classNames(inputClassName, 'search-input__input')}
        placeholder={placeholder}
        disabled={isDisabled}
        tabIndex="0"
        type="text"
        value={query}
        ref={inputRef}
        onChange={onInputChange}
        autoFocus={props.autoFocus}
        {...inputProps}
      />
      
      <div className="search-input__extra-buttons-wrapper">
        {children}
      </div>

      {renderRightIcon()}
    </div>
  );
};

export default memo(SearchInput);
