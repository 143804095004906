import classNames from 'classnames';

import { BOOKING_STATUS_FILTERS_NAMES } from 'config/constants';

import './BookingConfirmationStatus.scss';
import Tooltip from 'components/UI/Tooltip/Tooltip';

const BookingConfirmationStatus = ({ className, booking }) => {
  return (
    <Tooltip
      text={`${booking.reason || '- '}: ${booking.description || '-'}`}
      disable={booking.confirmation_status !== BOOKING_STATUS_FILTERS_NAMES.cancelled}
    >
      <mark className={classNames(className, 'booking-confirmation-status')}>
        {booking.confirmation_status}
      </mark>
    </Tooltip>
  )
}

export default BookingConfirmationStatus;
