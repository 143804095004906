import React, { useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { selectOperatorsList } from 'redux/selectors/selectors';
import {
  ALL_FILTER,
  ONLINE_FILTER,
  filterOperators
} from 'redux/ducks/operators';

import './OperatorsList.scss';
import List from 'components/List/List';
import SidebarOperatorItem from './components/OperatorItem';
import SidebarContactsSelectFilter from 'containers/SidebarChatLists/components/SidebarContactsFilters/SidebarContactsSelectFilter';
import SidebarHeader from 'containers/SidebarChatLists/components/SidebarHeader/SidebarHeader';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import { classModifier } from 'utils';
import SidebarContactsList from 'containers/SidebarChatLists/components/SidebarContactsList/SidebarContactsList';
import { CHAT_CONTACTS_LIMIT } from 'redux/ducks/contacts';

const OperatorsList = ({
  operatorIds = [],
  activeFilter,
  filterOperators,
  lastItemRef,
  selectorRef,
  handleHeaderClick,
  toggleOpenned,
}) => {
  const operatorsCount = operatorIds.length;
  const [operatorFilterIsOpen] = useState(false);

  const filters = useMemo(() => [
    { name: ONLINE_FILTER, action: () => filterOperators(ONLINE_FILTER) },
    { name: ALL_FILTER, action: () => filterOperators(ALL_FILTER) },
  ], []);

  return (
    <div className={classModifier("sidebar-sections", "right")}>
      <SidebarContactsSelectFilter
        className="sidebar-sections__filters"
        filters={filters}
        defaultFilter={activeFilter}
        selectorRef={selectorRef}
        toggleOpenned={toggleOpenned}
        operatorFilterIsOpen={operatorFilterIsOpen}
      />

      <div className="sidebar-sections__list">
        {/* <List
          list={operators}
          classPrefix="operators-list"
          scrollbarProps={{
            renderTrackVertical: CustomScrollbarTrack,
            renderThumbVertical: CustomScrollbarThumb
          }}
        >
          {({ index, ...restProps }) => (
            <SidebarOperatorItem
              activeFilter={activeFilter}
              operatorFilterIsOpen={operatorFilterIsOpen}
              {...restProps}
              {...(index === operatorsCount - 1 && { ref: lastItemRef })}
            />
          )}
        </List> */}
        <SidebarContactsList
          list={operatorIds}
          limit={CHAT_CONTACTS_LIMIT}
          listItem={SidebarOperatorItem}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  operatorIds: selectOperatorsList(state),
  activeFilter: state.operators.activeFilter,
});

const mapDispatchToProps = {
  filterOperators,
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsList);
