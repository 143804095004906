import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";

import API from "api/api";
import { useDidMount } from "hooks";
import { openModal } from "redux/ducks/activeWindows";
import { addContactsToEntities } from "redux/ducks/contacts";
import {
  removeComparedId,
  updateComparedIdFromRecentlyViewed,
  updateBufferedId,
  removeBufferedId,
  addGirlsIdsThatLinksIsSendInChat,
} from "redux/ducks/sessions";
import {
  selectIsProfileInBuffer,
  selectProfilesSameServices,
  selectCanIEditSession,
  selectProfilesSameParams,
  selectProfilePrevBookedCount,
  selectPropertyOfActiveSession,
  selectAgentByGirlId
} from 'redux/selectors/selectors'
import { getActiveDivaGirls } from "redux/ducks/divaGirls";

import "./ProfileCard.scss";
import ProfileCardActions from "./components/ProfileCardActions";
import ProfileCardHeader from "./components/ProfileCardHeader";
import ProfileCardTitle from "./components/ProfileCardTitle";
import ProfileCardPhotos from "./components/ProfileCardPhotos/ProfileCardPhotos";
import ProfileCardInfo from "./components/ProfileCardInfo";
import ProfileCardServices from "./components/ProfileCardServices";
import ProfileCardContacts from "./components/ProfileCardContacts/ProfileCardContacts";
import Labels from "components/Labels/Labels";
import CustomScrollbarThumb from "components/UI/CustomScrollbarThumb/CustomScrollbarThumb";
import CustomScrollbarTrack from "components/UI/CustomScrollbarTrack/CustomScrollbarTrack";

const ProfileCard = ({ id, profile, agentName, ...props }) => {
  const [contactId, setContactId] = useState(null);

  useDidMount(() => {
    if (profile.contact_uid) {
      API.getGirlsByContactUid([profile.contact_uid])
        .then(({ data }) => {
          if (data && data[0]) {
            props.addContactsToEntities(data);
            setContactId(data[0].id);
          }
        })
        .catch(console.error);
    }
  });

  const onClose = () => props.isBufferZone
    ? props.removeBufferedId(id, props.activeSession, props.canIEditSession)
    : props.removeComparedId(id, props.comparedIds, props.activeSession, props.canIEditSession, props.activeFilters);

  const portraitMedia = useMemo(() => (
    profile.images_info
      .filter((image) => (
        image.is_published && image.ratio === 'portrait'
      ))
      .sort((prevImage, nextImage) => Number(nextImage.is_video) - Number(prevImage.is_video))
  ), [profile.images_info]);

  return (
    <div className="profile-card">
      <button
        className="profile-card__close"
        onClick={onClose}
      />

      <ProfileCardHeader profile={profile} contactId={contactId} />

      <ProfileCardActions
        hostProfileId={props.hostProfileId}
        updateComparedIdFromRecentlyViewed={props.updateComparedIdFromRecentlyViewed}
        id={id}
        id_new={profile.id_new}
        profileContactUid={profile.contact_uid}
        updateBufferedId={props.updateBufferedId}
        isInBuffer={props.isInBuffer}
        activeSession={props.activeSession}
        canIEditSession={props.canIEditSession}
        isBufferZone={props.isBufferZone}
        removeBufferedId={props.removeBufferedId}
        activeCallerId={props.activeCallerId}
        profileLink={profile.link}
        sessionIds={props.sessionIds}
        addGirlsIdsThatLinksIsSendInChat={props.addGirlsIdsThatLinksIsSendInChat}
        girlsIdsThatLinksIsSend={props.girlsIdsThatLinksIsSend}
        recentlyViewedIds={props.recentlyViewedIds}
      />

      <Scrollbars
        autoHide
        renderThumbVertical={CustomScrollbarThumb}
        renderTrackVertical={CustomScrollbarTrack}
      >
        <ProfileCardTitle
          id={id}
          name={profile.name}
          notesCount={profile.notesCount}
          prices={profile.prices}
          rating={profile.rating}
          reviewsCount={profile.reviewsCount}
          openModal={props.openModal}
          contactId={contactId}
          prevBookedCount={props.prevBookedCount}
        />

        <ProfileCardPhotos
          id={id}
          agentName={agentName}
          openModal={props.openModal}
          media={portraitMedia.length
            ? portraitMedia
            : [profile.avatar]
          }
          activeSession={props.activeSession}
          isBufferZone={props.isBufferZone}
          isAvailable={profile.is_available_now}
          isArchived={profile.is_archived}
          flagProps={{
            nationality: profile.nationality,
            nationality_alt: profile.nationality_alt
          }}
        />

        <div className="profile-card__labels">
          <Labels
            profile={profile}
            classPrefix={'profile-card'}
            selector={['main']}
          />
        </div>

        <ProfileCardContacts
          contactId={contactId}
        />

        <ProfileCardInfo
          profile={profile}
          age={profile.age}
          birthday={profile.birthday}
          hair={profile.hair}
          height={profile.height}
          breast_size={profile.breast_size}
          dress={profile.dress}
          nationality={profile.nationality}
          languages={profile.language}
          location={profile.location}
          address={{
            place: profile.place,
            latitude: profile.latitude,
            longitude: profile.longitude,
          }}
          sameParams={props.sameParams}
        />

        <ProfileCardServices
          services={profile.services}
          sameServices={props.services}
        />
      </Scrollbars>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  profile: state.divaGirls.entities[ownProps.id],
  isInBuffer: selectIsProfileInBuffer(state, ownProps.id),
  activeSession: state.sessions.activeSession,
  canIEditSession: selectCanIEditSession(state),
  services: ownProps.isBufferZone
    ? state.divaGirls.bufferZoneSameServices
    : selectProfilesSameServices(state),
  sameParams: ownProps.isBufferZone
    ? state.divaGirls.bufferZoneSameParams
    : selectProfilesSameParams(state),
  prevBookedCount: selectProfilePrevBookedCount(state, ownProps.id),
  activeFilters: (!!state.sessions.entities[state.sessions.activeSession] && selectPropertyOfActiveSession(state, 'activeFilters')),
  activeCallerId: state.sessions.entities[state.sessions.activeSession].callerId,
  sessionIds: state.sessions.ids,
  girlsIdsThatLinksIsSend: state.sessions.entities[state.sessions.activeSession].messageSendGirlsIds,
  agentName: selectAgentByGirlId(state, ownProps.id)?.fn,
  recentlyViewedIds: state.sessions.entities[state.sessions.activeSession].recentlyViewedIds,
});

const mapDispatchToProps = {
  openModal,
  removeComparedId,
  updateComparedIdFromRecentlyViewed,
  updateBufferedId,
  getActiveDivaGirls,
  removeBufferedId,
  addContactsToEntities,
  addGirlsIdsThatLinksIsSendInChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
