import ICONS from 'assets/icons';

import { BUTTON_THEMES, SIZES } from 'config/constants';

import Button from 'components/UI/Button/Button';


const BellButton = (props) => (
  <Button
    className={props.className}
    icon={ICONS.bellNew}
    disabled
    {...props}
  />
);

export default BellButton;
