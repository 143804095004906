import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import API from 'api/api';
import { BUTTON_THEMES, ROUTES, SIZES } from 'config/constants';
import { LS, classModifier, getContactAvatar } from 'utils';
import { ADMIN_PAGE_ROOT } from 'services/axiosInstance';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import './UserMenu.scss';
import ICONS from 'assets/icons';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import DropdownMenu from 'components/UI/Menu/DropdownMenu';
import TimezoneClock from './components/TimezoneClock';
import StatusMarker from 'components/UI/StatusMarker/StatusMarker';
import getContactStatus from 'utils/getContactStatus';
import Button from 'components/UI/Button/Button';
import classNames from 'classnames';


const UserMenu = (props) => {
  const user = useSelector(state => state.user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const renderAwayButton = () => {
    switch (user.status) {
      case "online":
        return 'away';
      case "away":
        return 'online';
      default:
        return 'Busy';
    }
  };

  const changeProfileStatus = () => {
    if (user.status === 'busy') {
      return;
    }

    if (user.status === 'online') {
      return API.changeBusyStatus('away')
        .catch(console.error)
    }

    return API.changeBusyStatus('online')
      .catch(console.error)
  };

  const generateSubMenuItems = () => ([
    {
      name: renderAwayButton(),
      notCloseOnClick: true,
      action: changeProfileStatus,
      icon: <StatusMarker status={renderAwayButton()} />,
      checkDisabled: [
        () => {
          return user.status === 'away' && 'You can not change status to busy'
        },
        () => { 
          return user.status === 'offline' && 'You can not change status to offline'
        },
      ]
    },
    {
      name: 'Settings',
      icon: <ICONS.gear />,
      action: () => dispatch(openModal(MODAL_TYPES.userSettings)),
    },
    {
      name: 'Admin',
      icon: <ICONS.cube />,
      action: () => window.open(ADMIN_PAGE_ROOT, '_blank'),
    },
    {
      name: 'Logout',
      icon: <ICONS.arrowNew />,
      // action: logoutUser,
      content: 'Logout',
      action: () => props.openModal(MODAL_TYPES.confirmOnLogoutModal),
    },
  ]);

  return (
    <DropdownMenu options={generateSubMenuItems()}>
      {(ref, isMenuActive) => (
        <Button
          className={classNames(props.className, "user-menu")}
          active={isMenuActive}
          iconSize={SIZES.XS}
          ref={ref}
          theme={BUTTON_THEMES.NONE_DARK}
        >
          <Button.ContentWrapper>
            <div className="user-menu__img-wrap">
              <LazyLoadImage className="user-menu__img" src={getContactAvatar(user)}/>
              <StatusMarker className="user-menu__status" status={getContactStatus(user)} />
            </div>
          </Button.ContentWrapper>

          <Button.Text className="user-menu__content">
            <span className="user-menu__name">{user.username}</span>
            <TimezoneClock />
            <span className='user-menu__city'>{user.timezone.name.split('/').at(-1)}</span>
          </Button.Text>

          <Button.Arrow />
        </Button>
      )}
    </DropdownMenu>
  );
};

export default UserMenu;
