import { classModifier } from 'utils';

import './StatusMarker.scss';
import classNames from 'classnames';


const StatusMarker = (props) => {
  const {
    status,
    size = 'medium',
    className,
  } = props;

  const classes = classNames(
    className,
    classModifier(
      'status-marker',
      [
        size && `size-${size}`,
        status,
      ]
    )
  )

  return <div className={classes} />;
}

export default StatusMarker;
