import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { classModifier } from 'utils';
import { useToggle } from 'hooks';

import "./Menu.scss";
import Menu from 'components/UI/Menu/Menu';

const DropdownMenu = ({ options, children }) => {
  const [isMenuActive, toogleIsMenuActive] = useToggle(false);

  const {
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip(
    {
      trigger: 'click',
      visible: isMenuActive,
      onVisibleChange: toogleIsMenuActive
    }, 
  );

  const classes = classModifier(
    'dropdown-menu-new',
    isMenuActive && 'active',
  );

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      {children instanceof Function
        ? children(setTriggerRef, isMenuActive)
        : React.cloneElement(React.Children.only(children), { ref: setTriggerRef })}

      <Menu
        ref={setTooltipRef}
        className={classes}
        options={options}
        closeDropdownMenu={toogleIsMenuActive}
      />
    </div>
  )
}

export default DropdownMenu;
