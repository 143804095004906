import React, { useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';

import "./ContactItemBlock.scss";
import { classModifier, isEmptyObj } from 'utils';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import StatusMarker from '../StatusMarker/StatusMarker';
import Counter from '../Counter/Counter';


const ContactItemBlock = React.forwardRef((props, ref) => {
  const inlineRef = useRef();

  const classes = classNames(
    props.className,
    classModifier(
      'contact-item-block',
      [
        props.marked && 'marked',
        props.pinned && 'pinned'
      ]
    ),
    props.active && 'active'
  )

  return (
    <div
      {...props}
      ref={ref || inlineRef}
      className={classes}
    >
      <div className="contact-item-block__content">
        {props.children}
      </div>
    </div>
  )
})

ContactItemBlock.Avatar = React.forwardRef((props, ref) => {
  return (
    <div
      className={classNames(props.className, "contact-item-block__avatar")}
      ref={ref}
    >
      {props.status && (
        <StatusMarker
          className="contact-item-block__contact-status"
          status={props.status}
        />
      )}

      <LazyLoadImage
        className="contact-item-block__avatar-img"
        src={props.src}
        alt="avatar"
      />

      {/* <ChannelInteractionIcon /> */} 
    </div>
  )
})

ContactItemBlock.Body = React.forwardRef((props, ref) => {
  const childrenKeyValues = [];

  React.Children.forEach(props.children, (child) => {
    childrenKeyValues.push({
      name: child.type.displayName,
      isChildrenEmpty: child.type.displayName === 'ExtraContent' && !child.props.children,
      value: child
    });
  });

  if (childrenKeyValues.length > 4) {
    throw new Error('ContactItemBlock.Body can have only 4 children');
  }

  if (childrenKeyValues.every((child) => !child.isChildrenEmpty) && childrenKeyValues.length === 4) {
    return (
      <div className={classModifier('contact-item-block__body', 'column')}>
        <div className="contact-item-block__row">
          {childrenKeyValues[0].value}
          {childrenKeyValues[2].value}
        </div>

        <div className="contact-item-block__row">
          {childrenKeyValues[1].value}
          {childrenKeyValues[3].value}
        </div>
      </div>
    )
  }

  return (
    <div
      ref={ref}
      className={classModifier('contact-item-block__body', 'row')}
    >
      {childrenKeyValues[1]
        ? (
        <div className="contact-item-block__column">
          {childrenKeyValues[0].value}
          {childrenKeyValues[1].value}
        </div>
        )
        : childrenKeyValues[0].value
      }

      {childrenKeyValues[2] && (
        <div className="contact-item-block__column">
          {childrenKeyValues[2]?.value}
          {childrenKeyValues[3]?.value}
        </div>
      )}
    </div>
  )
})

ContactItemBlock.Body.Name = React.forwardRef((props, ref) => {
  const {
    children,
    component,
    ...restProps
  } = props;

  const classes = classNames(
    'contact-item-block__name',
    'contact-item-block__extra-content',
  );

  if (props.component) {
    const Component = props.component;

    return (
      <Component
        ref={ref}
        className={classes}
        {...restProps}
      >
        {props.children}
      </Component>
    )
  }

  return (
    <h4
      ref={ref}
      className={classes}
    >
      {props.children}
    </h4>
  )
})

ContactItemBlock.Body.Name.displayName = 'ContactName';

ContactItemBlock.Body.ExtraContent = React.forwardRef((props, ref) => {
  if (!props.children) {
    return null;
  }

  return (
    <p
      className={classNames("contact-item-block__extra-content", props.className)}
      ref={ref}
    >
      {props.children}
    </p>
  )
})

ContactItemBlock.Body.ExtraContent.displayName = 'ExtraContent';

ContactItemBlock.UnreadMessageCounter = (props) => {
  return (
    <Counter
      className={classNames("contact-item-block__unread-message-counter", props.className)}
      {...props}
    />
  )
}

export default ContactItemBlock;
