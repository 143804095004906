import React, { memo } from 'react';

import { INPUT_THEMES } from 'config/constants';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';

import './BookingsByDateModal.scss';
import {
  BookingFilters,
  BookingFiltersList,
  BookingSearchInput,
  BookingSelectFilters,
  BookingStatusFilters,
  DateTimeChanger,
  TodayButton,
  useFilteredBookings
} from 'components/FilteredBookings';
import CalendarBookingsList from 'containers/CalendarMain/components/CalendarBookingsList/CalendarBookingsList';

const limit = 20;

const BookingsByDateModalHeader = ({ getters, isCurrentDate }) => {
  const {
    getDateTimeChangerProps,
    getTodayButtonProps,
    getSearchBookingsProps,
    getFiltersContainerProps,
    getStatusFilterProps,
    getFilterProps,
    getSelectProps,
  } = getters;

  return (
    <div className="bookings-by-date__header-wrapper">
      <BookingFiltersList
        className="bookings-by-date__header"
        counterPosition="top"
        {...getFiltersContainerProps()}
      >
        <div className="bookings-by-date__header-row">
          <BookingSearchInput
            inputWrapClassName='bookings-by-date__search-input'
            placeHolder='Search booking'
            showSearchBtn
            showClearBtn
            theme={INPUT_THEMES.primary}
            {...getSearchBookingsProps()}
          />
          
          <DateTimeChanger
            className="bookings-by-date__date-changer"
            isCurrentDate={isCurrentDate}
            {...getDateTimeChangerProps()}
            {...getTodayButtonProps()}
          />
        </div>

        {/* <BookingSelectFilters.agent
            className="bookings-by-date__agent-filter"
            {...getSelectProps()}
          /> */}

        <div className="bookings-by-date__header-row">
          <BookingStatusFilters {...getStatusFilterProps()}>
            <BookingStatusFilters.todo />
            <BookingStatusFilters.confirmed />
            <BookingStatusFilters.done />
            <BookingStatusFilters.cancelled />
          </BookingStatusFilters>

          <BookingFilters {...getFilterProps()}>
            <BookingFilters.outcall />
            <BookingFilters.duo />
            <BookingFilters.prebooking />
            <BookingFilters.agent />
          </BookingFilters>
        </div>
      </BookingFiltersList>
    </div>
  )
}

const BookingsByDateModal = () => {
  const { getters, date, isCurrentDate } = useFilteredBookings({ limit, isAutoScrollToActualTime: true })

  return (
    <div className="bookings-by-date">
      <BookingsByDateModalHeader
        getters={getters}
        isCurrentDate={isCurrentDate}
      />

      <CalendarBookingsList
        getters={getters}
        date={date}
        classNameModifier="modal-timeline"
        isCurrentDate={isCurrentDate}
        bookingType={BOOKING_ITEM_VIEWS.HORIZONTAL}
        showTimeFilters={true}
      />
    </div>
  );
};

export default memo(BookingsByDateModal);
