import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { BUTTON_THEMES, SIZES } from 'config/constants';

import ICONS from 'assets/icons';
import Button from 'components/UI/Button/Button';
import Dialpad from 'containers/Header/components/Dialpad/Dialpad';
import Portal from 'components/Portal';


const DialpadButton = (props) => {
  const [isDialpadOpen, setIsDialpadOpen] = useState(false);

  const dialpadPopperTooltip = usePopperTooltip({
    trigger: 'click',
    placement: 'bottom-start',
    visible: isDialpadOpen,
    onVisibleChange: setIsDialpadOpen,
  });

  return (
    <>
      <Button
        className={props.className}
        ref={dialpadPopperTooltip.setTriggerRef}
        icon={ICONS.phoneMenu}
        active={isDialpadOpen}
        title="Open dialpad"
        {...props}
      />

      {isDialpadOpen &&
        <Portal>
          <Dialpad
            setTooltipRef={dialpadPopperTooltip.setTooltipRef}
            getTooltipProps={dialpadPopperTooltip.getTooltipProps}
            isDialpadOpen={isDialpadOpen}
            onCloseDialPad={setIsDialpadOpen}
          />
        </Portal>
      }
    </>
  )
}

export default DialpadButton;
