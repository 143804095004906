import { useEffect, useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';
import { v4 as uuid } from 'uuid';

import { classModifier } from 'utils';

import './SelectFormField.scss';

const SelectFormField = ({
  input,
  meta,
  fieldType,                  // 'type', 'duration'
  isDisabled,
  options,
  stateValue,
  isSearchable = false,
  setBookingEditProps,
  onBookingDurationChange,
  duration,
  setProfile,
  dValue
}) => {
  const inputId = useMemo(() => uuid(), []);

  const { value, onBlur } = input || {};
  const { touched, error } = meta || {};

  const isBookingDurationField = fieldType === 'bookingDuration' && onBookingDurationChange;
  const currValue = value || stateValue || dValue || [];

  const labelText = () => {
    switch (fieldType) {
      case 'type':
        return 'Type';
      case 'duration':
      case 'bookingDuration':
        return 'Booking Duration';
      case 'rate':
        return 'Select rate';
      case 'discount':
        return 'Discount';
      case 'taxi':
        return 'Taxi Fee';
      default:
        return '';
    }
  };

  const selectOptions = () => {
    switch (fieldType) {
      case 'type':
        return TYPE_FIELD_OPTIONS;
      case 'duration':
        return DURATION_FIELD_OPTIONS;
      case 'taxi':
        return TAXI_FIELD_OPTIONS;
      case 'discount':
        return DISCOUNT_FIELD_OPTIONS;
      case 'rate':
      case 'address': 
      case 'bookingDuration': 
        return options;
    }
  };

  const selectStyles = useMemo(() => {
    switch (fieldType) {
      case 'type':
        return {
          option: getTypeFieldOptionStyle,
          singleValue: getTypeFieldSingleValueStyle,
        };
    }
  }, [fieldType]);

  useEffect(() => {
    if (duration && onBookingDurationChange) {
      onBookingDurationChange(duration);
    }
  }, [duration, onBookingDurationChange, fieldType])

  const onChange = data => {
    setProfile && setProfile(data.profile);

    const isValueForEdit = data.__isNew__ && 
      (fieldType === 'taxi' || fieldType === 'discount');

    const selectData = isValueForEdit
      ? { ...data, label: `£ ${data.label}`, value: +data.value }
      : data;

    if(input?.onChange) {
      input.onChange(selectData);
      isBookingDurationField && onBookingDurationChange(selectData);
    } else {
      setBookingEditProps(selectData);
    }
  };

  return (
    <div
      className={classModifier('select-form-field', [
        fieldType === 'type' && 'type',
      ])}
    >
      <label
        className='select-form-field__label'
        htmlFor={inputId}
      >
        {labelText()}
      </label>

      <CreatableSelect
        value={currValue.value === null ? null : currValue}
        onChange={onChange}
        onBlur={onBlur}
        inputId={inputId}
        placeholder='-'
        isDisabled={isDisabled}
        options={selectOptions()}
        styles={selectStyles}
        defaultValue={dValue}
        components={{
          IndicatorSeparator: null,
        }}
        isSearchable={isSearchable}
        className='field-select'
        classNamePrefix='field-select'
        noOptionsMessage={() => null}
      />

      {touched && error &&
        <p className='feedback-form__field-error'>{error}</p>
      }
    </div>
  );
};

export const TYPE_FIELD_OPTIONS = [
  { value: 2, label: 'Positive', color: '#01DF85' },
  { value: 1, label: 'Negative', color: '#F8737F' },
  { value: 0, label: 'Neutral', color: '#808080' },
];

export const DURATION_FIELD_OPTIONS = [
  { value: 30, label: '30 minutes' },
  { value: 45, label: '45 minutes' },
  { value: 60, label: '1 hour' },
  { value: 90, label: '90 minutes' },
  { value: 120, label: '2 hours' },
  { value: 180, label: '3 hours' },
  { value: 480, label: '8 hours' },
  { value: 720, label: '12 hours' },
];

export const TAXI_FIELD_OPTIONS = [
  { value: null, label: `-` },
  { value: 10, label: '£ 10' },
  { value: 20, label: '£ 20' },
  { value: 30, label: '£ 30' },
  { value: 40, label: '£ 40' },
  { value: 50, label: '£ 50' },
  { value: 60, label: '£ 60' },
  { value: 70, label: '£ 70' },
  { value: 80, label: '£ 80' },
  { value: 90, label: '£ 90' },
  { value: 100, label: '£ 100' },
];

export const DISCOUNT_FIELD_OPTIONS = [
  { value: null, label: `-` },
  { value: 10, label: '£ 10' },
  { value: 20, label: '£ 20' },
  { value: 30, label: '£ 30' },
  { value: 40, label: '£ 40' },
  { value: 50, label: '£ 50' },
  { value: 60, label: '£ 60' },
  { value: 70, label: '£ 70' },
  { value: 80, label: '£ 80' },
  { value: 90, label: '£ 90' },
  { value: 100, label: '£ 100' },
];

const getTypeFieldOptionStyle = (provided, { data }) => ({
  ...provided,
  ':before': { backgroundColor: data.color },
});

const getTypeFieldSingleValueStyle = (provided, { data }) => ({
  ...provided,
  ':after': { backgroundColor: data.color },
});

export default SelectFormField;
