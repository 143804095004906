import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';

import API from 'api/api';
import ICONS from 'assets/icons';
import { classModifier, validateFormValues } from 'utils';
import { useDidMount, useWillUnmount } from 'hooks';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import {
  DRAFT_STATUSES,
  COMPOSE_PENDING_TYPES,
  setDelayedAction,
  setComposePending,
  setComposeDraftId,
  setDraftStatus,
} from 'redux/ducks/mail';

import './MailComposer.scss';
import FormTextField from 'components/FormTextField/FormTextField';
import MailAttachment from '../MailAttachment/MailAttachment'
import MailFormAutosave from '../MailFormAutosave/MailFormAutosave';
import MailComposerEmailField from './components/MailComposerEmailFiled';
import { BUTTON_THEMES, SEND_DELAY_MSG } from 'config/constants';
import Spinner from 'components/UI/Spinner/Spinner';
import Button from 'components/Button/Button';

const MailComposer = (props) => {
  const {
    pending,
    draftId,
    draftStatus,
    setDraftStatus,
    setComposePending,
    setComposeDraftId,
    setDelayedAction,
    userId,
    defaultModeOfOperation,
    availableMails,
  } = props;

  const dispatch = useDispatch();

  const [emails, setEmails] = useState([]);
  const [emailsCC, setEmailsCC] = useState([]);
  const [emailsBCC, setEmailsBCC] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [serverErrors, setServerErrors] = useState({});
  const [emailInputValueError, setEmailInputValueError] = useState(null);
  const [isAddCc, setIsAddCc] = useState(false);
  const [isAddBcc, setIsAddBcc] = useState(false);

  const setFormValueRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  const somePending = Object.values(pending).includes(true);
  const globalPending = pending.sendMsg || pending.delDraft;
  const locationDraft = location.state?.draft;

  useEffect(() => {
    locationDraft?.email && setEmails([locationDraft?.email]);
  }, [locationDraft?.email]);

  useDidMount(() => {
    if (locationDraft) {
      const { id, attachments } = locationDraft;

      setDraftStatus(DRAFT_STATUSES.saved);
      setComposeDraftId(id);
      setAttachments(attachments.map(att => ({ ...att, loading: false })));

      // To prevent loading data from location.state in case of page reload
      navigate(location.pathname,  { state: { ...location.state, draft: null }});
    }
  });

  useWillUnmount(() => {
    setDraftStatus(null);
    setComposeDraftId(null);
  });

  const sendEmailMessage = ({ values, delay, isWindowUnload }) => {
    const { subject = '', message = '', mailFrom } = values;

    // Generate draft id on front-end
    const newDraftId = draftId || uuid();
    if (!isWindowUnload) {
      setComposeDraftId(newDraftId);
    }

    return API.sendEmailMessage({
      config: {
        from: mailFrom,
        recipient: emails,
        subject,
        body: message,
        isDraft: true,
        draftId: newDraftId,
        delay,
        userId,
        cc: emailsCC,
        bcc: emailsBCC,
      },
      isBeacon: isWindowUnload,
    });
  }

  const onSubmit = (values) => {
    setComposePending({ [COMPOSE_PENDING_TYPES.sendMsg]: true });

    sendEmailMessage({
      values,
      delay: SEND_DELAY_MSG
    })
      .then(({ data }) => {
        const { id, to, subject, body, attachments } = data;

        setDelayedAction({
          type: 'compose',
          delay: SEND_DELAY_MSG,
          delayedActionText: 'Message Sent!',
          cancelRequestParams: { id },
          cancelActionParams: { id, to, subject, body, attachments },
          actionId: `compose-${id}`,
        });
        navigate('/mail/inbox');
        setComposePending({ [COMPOSE_PENDING_TYPES.sendMsg]: false });
        setComposeDraftId(null);
      })
      .catch(err => {
        setComposePending({ [COMPOSE_PENDING_TYPES.sendMsg]: false });
        setServerErrors(JSON.parse(err.response.data.message));
      });
  }

  const handleSaveDraft = ({ values, isWindowUnload }) => {
    // Return Boolean in case of window unload
    if (isWindowUnload) {
      return sendEmailMessage({ values, isWindowUnload: true });
    }

    // By default return Promise
    return sendEmailMessage({ values });
  }

  const handleAddAttachments = (e) => {
    const uploadFiles = [...e.target.files];

    if (!uploadFiles.length) return;

    e.target.value = null;

    setAttachments(state => [
      ...state,
      ...uploadFiles.map(({ name, size }) => ({
        fileName: name,
        fileSize: size,
        loading: true,
      })),
    ]);
    setComposePending({ [COMPOSE_PENDING_TYPES.attach]: true });

    // Generate draft id on front-end
    const newDraftId = draftId || uuid();
    setComposeDraftId(newDraftId);

    API.uploadMailAttachments({ uploadFiles, draftId: newDraftId })
      .then(({ data: { attachments } }) => {
        setAttachments(attachments.map(att => ({ ...att, loading: false })));
      })
      .catch(err => {
        setAttachments(prev => prev.filter(({ loading }) => !loading));
        showErrorIfUploadLimit(dispatch, err);
      })
      .finally(() => {
        setComposePending({ [COMPOSE_PENDING_TYPES.attach]: false });
      });
  }

  const handleDeleteAttachment = delId => {
    setComposePending({ [COMPOSE_PENDING_TYPES.attach]: true });

    API.deleteMailAttachment({ delId })
      .then(({ data: { attachments } }) => {
        setAttachments(attachments.map(att => ({ ...att, loading: false })));
      })
      .finally(() => {
        setComposePending({ [COMPOSE_PENDING_TYPES.attach]: false });
      });
  }

  const autosaveAdditionalFields = useMemo(() => ({ emails, emailsBCC, emailsCC }), [emails, emailsBCC, emailsCC]);

  const mailsOption = useMemo(() => {
    return availableMails?.map((mail) => ({
      value: mail.id,
      label: mail.operatorName,
      is_default: mail.is_default_type,
    }));
  }, [availableMails, defaultModeOfOperation]);

  return (
    <div className={classModifier('mail-composer', [
      somePending && 'some-pending',
      globalPending && 'global-pending'
    ])}>
      <Form
        initialValues={{
          subject: locationDraft?.subject || '',
          message: locationDraft?.message || '',
          mailFrom: defaultModeOfOperation
            ? defaultModeOfOperation?.operatorName
            : availableMails[0]?.operatorName
            || '',
        }}
        initialValuesEqual={() => true}
        onSubmit={onSubmit}
        validate={(values) => validate({ ...values, emails, emailInputValueError })}
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },

          changeMailFrom: (...rest) => {
            const [args, state, utils] = rest;

            utils.changeValue(state, "mailFrom", () => args[0].label);
          },
        }}
        render={({ handleSubmit, form }) => {
          if (!setFormValueRef.current) setFormValueRef.current = form.mutators.setValue;

          return (
            <form
              onSubmit={handleSubmit}
              className="mail-composer__form"
              id="mailComposerForm"
            >
              <div className="mail-composer__form-group mail-composer__form-group--email">
                <div className="mail-composer__form-group--mail-from-box">
                  <label className="mail-composer__label">From</label>
                  <div className="mail-composer__form-group--mail-from">
                    {mailsOption?.length ? (
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Select mail"
                        options={mailsOption}
                        defaultValue={{
                          value:
                            defaultModeOfOperation?.id || availableMails[0].id,
                          label:
                            defaultModeOfOperation?.operatorName ||
                            availableMails[0].operatorName,
                          is_default:
                            defaultModeOfOperation?.is_default ||
                            availableMails[0].is_default,
                        }}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.label}
                        onChange={form.mutators.changeMailFrom}
                        isSearchable={false}
                        components={{
                          IndicatorSeparator: null,
                        }}
                        name="mailFrom"
                        required
                      />
                    ) : (
                      <Spinner spinnerSize={30} />
                    )}
                  </div>
                </div>
                <div className="mail-composer__form-group--emails">
                  <Field name="emails">
                    {({ meta }) => (
                      <MailComposerEmailField
                        name="emails"
                        meta={meta}
                        isMulti
                        emails={emails}
                        setEmails={setEmails}
                        serverErrors={serverErrors}
                        emailInputValueError={emailInputValueError}
                        setEmailInputValueError={setEmailInputValueError}
                      />
                    )}
                  </Field>
                  <div
                    className={classModifier(
                      "mail-composer__form-group-emails-extra",
                      [(isAddBcc || isAddCc) && "field"]
                    )}
                  >
                    {isAddCc && (
                      <Field name="email_cc">
                        {({ meta }) => (
                          <MailComposerEmailField
                            name="email_cc"
                            label="Copy To"
                            meta={meta}
                            emails={emailsCC}
                            setEmails={setEmailsCC}
                            serverErrors={serverErrors}
                            emailInputValueError={emailInputValueError}
                            setEmailInputValueError={setEmailInputValueError}
                            removeBtnFunction={() => {
                              setIsAddCc(false)
                              setEmailsCC([])
                            }}
                          />
                        )}
                      </Field>
                    )}
                    {isAddBcc && (
                      <Field name="email_bcc">
                        {({ meta }) => (
                          <MailComposerEmailField
                            name="email_bcc"
                            label="Blind Copy To"
                            meta={meta}
                            emails={emailsBCC}
                            setEmails={setEmailsBCC}
                            serverErrors={serverErrors}
                            emailInputValueError={emailInputValueError}
                            setEmailInputValueError={setEmailInputValueError}
                            removeBtnFunction={() => {
                              setIsAddBcc(false)
                              setEmailsBCC([])
                            }}
                          />
                        )}
                      </Field>
                    )}

                    {!isAddCc && (
                      <Button
                        className="mail-composer__form-group--add-button"
                        theme={BUTTON_THEMES.none}
                        onClick={() => setIsAddCc(true)}
                      >
                        <ICONS.plus className="mail-composer__form-group--add-button-icon" /> &nbsp;
                        Add Cc
                      </Button>
                    )}

                    {!isAddBcc && (
                      <Button
                        className="mail-composer__form-group--add-button"
                        theme={BUTTON_THEMES.none}
                        onClick={() => setIsAddBcc(true)}
                      >
                      <ICONS.plus className="mail-composer__form-group--add-button-icon" />&nbsp;
                        Add Bcc
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="mail-composer__form-group mail-composer__form-group--subject">
                <Field
                  name="subject"
                  label="Subject"
                  autoComplete="off"
                  errorClass="input-error-text"
                  errorInputClass="input-error"
                  labelClassName="mail-composer__label"
                  inputClassName="mail-composer__input mail-composer__input--subject"
                  component={FormTextField}
                  serverErrors={serverErrors}
                  inputProps={{
                    placeholder: "Email title",
                    autoComplete: "off",
                  }}
                />
              </div>

              <div className="mail-composer-attachments">
                <label className="mail-composer-attachments__add-btn">
                  Attach
                  <ICONS.clip className="mail-composer-attachments__add-btn-icon" />
                  <input
                    className="visually-hidden"
                    type="file"
                    multiple
                    onChange={handleAddAttachments}
                    disabled={somePending}
                  />
                </label>

                <ul className="mail-composer-attachments__list">
                  {attachments.map(
                    ({ id, fileName, loading, extension, url }, idx) => (
                      <MailAttachment
                        url={url}
                        key={idx}
                        fileName={fileName}
                        fileExtension={extension}
                        loading={loading}
                        onDelete={() => handleDeleteAttachment(id)}
                        disableDelete={somePending}
                      />
                    )
                  )}
                </ul>
              </div>

              <div className="mail-composer__form-group mail-composer__form-group--message">
                <Field
                  name="message"
                  label="Message"
                  inputType="textarea"
                  errorClass="input-error-text"
                  errorInputClass="input-error"
                  labelClassName="mail-composer__label"
                  inputClassName="mail-composer__textarea"
                  inputWrapClassName="mail-composer__input-wrap"
                  component={FormTextField}
                  serverErrors={serverErrors}
                />
              </div>

              <MailFormAutosave
                isHide
                status={draftStatus}
                onSave={handleSaveDraft}
                setStatus={setDraftStatus}
                additionalFields={autosaveAdditionalFields}
                disabled={pending.sendMsg || pending.delDraft}
              />
            </form>
          );
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  pending: state.mail.compose.pending,
  draftId: state.mail.compose.draftId,
  draftStatus: state.mail.compose.draftStatus,
  userId: state.user.id,
  defaultModeOfOperation: state.mail.defaultModeOfOperation,
  availableMails: state.mail.availableMails,
});

const mapDispatchToProps = {
  setDraftStatus,
  setComposePending,
  setComposeDraftId,
  setDelayedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailComposer);

const validate = ({ emails, subject, message, emailInputValueError }) => {
  const errors = {};

  if (!emails.length) {
    errors.emails = 'Enter the email';
  } else {
    emails.map((email) => {
      if (validateFormValues.isEmpty(email)) {
        errors.emails = 'Enter the email';
      }
      else if (!validateFormValues.isEmail(email)) {
        errors.emails = 'Incorrect email';
      }
    });
  }

  if (emailInputValueError) {
    errors.emails = emailInputValueError;
  }

  if (validateFormValues.isEmpty(subject)) {
    errors.subject = 'Enter the subject';
  }

  if (validateFormValues.isEmpty(message)) {
    errors.message = 'Enter the message';
  }

  return errors;
};

export const showErrorIfUploadLimit = (dispatch, err) => {
  const status = err.response?.status;
  const message = err.response?.data?.message;

  if (status === 400 && message?.includes("upload limit")) {
    dispatch(
      openModal(MODAL_TYPES.error, {
        title: "Mail error",
        text: message,
        size: "small",
      })
    );
  }
}
