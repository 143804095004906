import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Button/Button";
import { MODAL_TYPES, closeModal, openModal } from "redux/ducks/activeWindows";
import { CS } from "utils";
import API from "api/api";
import "./ComputerModal.scss";

const ComputerModal = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleUpdateComputer = () => {
    const domain = window.location.hostname;
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 1);

    API.checkAvailabilityComputer(user.last_computer)
      .then(({ data }) => {
        if (data) {
          CS.setCookie(
            'computer',
            user.last_computer || '',
            { domain: domain, expires: expirationDate }
          );
          dispatch(closeModal(MODAL_TYPES.computerModal));
        } else {
          dispatch(openModal(MODAL_TYPES.userSettings, { defaultTabIndex: 1 }));
          dispatch(closeModal(MODAL_TYPES.computerModal));
        }
      })
      .catch(console.error)
  };

  const handleOpenUserSettings = () => {
    dispatch(openModal(MODAL_TYPES.userSettings, { defaultTabIndex: 1 }));
    dispatch(closeModal(MODAL_TYPES.computerModal));
  };

  return (
    <div className="computer-modal">
      <h2 className="computer-modal__title">Are you at the {user.last_computer} computer?</h2>

      <div className="computer-modal__btns">
        <Button
          className="computer-modal__btn"
          onClick={handleUpdateComputer}
        >
          Yes
        </Button>

        <Button
          className="computer-modal__btn"
          onClick={handleOpenUserSettings}
        >
          No
        </Button>
      </div>
    </div>
  );
};

export default ComputerModal;
