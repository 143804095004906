import React from 'react';
import classNames from 'classnames';

import './DropdownMenu.scss';

const DropdownMenu = React.forwardRef((props, ref) => { // [ ]: Create new shared component for this because it's used in multiple places
  const {
    items = [],
    className,
  } = props;

  const handleItemClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    item.action && item.action();

    if (item.notCloseOnClick) {
      return;
    }

    props.closeDropdownMenu && props.closeDropdownMenu();
  }

  return (
    <ul
      ref={ref}
      className={classNames(className, 'dropdown-menu')}
    >
      {items.map((menuItem, idx) => (
        <li
          key={idx}
          className='dropdown-menu__item'
          onMouseOver={menuItem.onMouseOver || null}
          onClick={(e) => handleItemClick(e, menuItem)}
        >
          <div className='dropdown-menu__icon-container'>
            {menuItem.icon}
          </div>

          {menuItem.content}
        </li>
      ))}
    </ul>
  );
})

export default DropdownMenu;
