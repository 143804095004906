import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useCallback, useEffect, useState } from 'react';

import { CALENDAR_CHOOSE_DATE_CONFIG } from 'config/dates-сonfig';
import { useToggle } from 'hooks';
import { SIZES } from 'config/constants';

import './DateTimeChanger.scss';
import DateTime from 'components/DateTime';
import ICONS from 'assets/icons';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import Button from 'components/UI/Button/Button';

export const DateTimeChanger = ({ className, withIcon, width = 218, ...props }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useToggle(false);
  const [customRange, setCustomRange] = useState(null);

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    visible: isCalendarOpen,
    onVisibleChange: setIsCalendarOpen,
  });

  const handleSelectDate = useCallback((date) => {
    props.setDate(date);
    setIsCalendarOpen();
  }, [])

  return (
    <>
      <div className={classNames("date-time-changer", className)}>
        <Button
          ref={setTriggerRef}
          className="date-time-changer__container"
          active={isCalendarOpen}
          disabled={props.disabled}
        >
          <Button.Icon icon={ICONS.calendar} />

          <Button.Text>
            <DateTime
              date={props.date}
              separator={props.range === 2 ? '/' : '-'}
              config={CALENDAR_CHOOSE_DATE_CONFIG}
              nonReduceEqualDate
              ignoreTimezone
            />
          </Button.Text>
        </Button>

        <Button
          icon={ICONS.location}
          iconSize={SIZES.S}
          disabled={props.isCurrentDate}
          onClick={props.onTodayButtonClick}
        />

        <Button
          className="date-time-changer__prev-btn"
          icon={ICONS.chevron}
          iconSize={SIZES.XS}
          onClick={props.changeDateToPrev}
        />

        <Button
          className="date-time-changer__next-btn"
          icon={ICONS.chevron}
          iconSize={SIZES.XS}
          onClick={props.changeDateToNext}
        />
      </div>

      {isCalendarOpen && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'date-time-changer__tooltip' })}
        >
          <DateTimePicker
            initDate={props.date}
            isTimeActive={false}
            range={props.range}
            displayRangeMenu={!props.range}
            customRange={customRange}
            setCustomRange={setCustomRange}
            onSelectDate={handleSelectDate}
            onCancel={setIsCalendarOpen}
            ignoreTimezone
          />
        </div>
      )}
    </>
  )
}
