import React, { createContext, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import { BOOKING_STATUS_FILTERS } from 'components/FilteredBookings/config/constants';
import { classModifier } from 'utils';

import './BookingStatusFilters.scss';
import Button from 'components/UI/Button/Button';
import { BUTTON_THEMES } from 'config/constants';

const BookingStatusFiltersContext = createContext(null);

export const BookingStatusFilters = ({ getterProps, className, children }) => (
  <BookingStatusFiltersContext.Provider value={{ getterProps }}>
    <div className={classNames("booking-status-filters", className)}>
      { children }
    </div>
  </BookingStatusFiltersContext.Provider>
)

Object.entries(BOOKING_STATUS_FILTERS).forEach(([filterName, { label, color }]) => {
  const Component = ({ getterProps }) => {
    const contextProps = useContext(BookingStatusFiltersContext);
    const { active, statusCount, ...props }
    	= (contextProps?.getterProps || getterProps)({ name: filterName });

    const [actualStatusCount, setActualStatusCount] = useState(statusCount || 0);

    useEffect(() => {
			if (props.disabled || statusCount === undefined) return;

      setActualStatusCount(statusCount);
    }, [props.disabled, statusCount])

    return (
      <Button
        type="button"
        theme={BUTTON_THEMES.FILTER}
        active={active}
        onClick={props.onClick}
      >
        <Button.ContentWrapper>
          ({actualStatusCount > 99 ? '99+' : actualStatusCount})
        </Button.ContentWrapper>

        <Button.Text>
          {label}
        </Button.Text>
        
        <Button.Status color={color} />
      </Button>
    )
  }

  BookingStatusFilters[filterName] = Component;
})
