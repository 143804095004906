import classNames from 'classnames'

import { classModifier } from 'utils'

import './Label.scss'
import Tooltip from '../Tooltip/Tooltip'


const Label = ({ className, icon: Icon, text, onClick }) => {
  return (
    <Tooltip text={text} visibleWhenIsOverflowed>
      <mark
        class={classNames(className, classModifier("label", onClick && "clickable"))}
        onClick={onClick}
      >
        <div class="label__icon-wrapper">
          <Icon />
        </div>

        <p className="label__text">
          {text}
        </p>
      </mark>
    </Tooltip>
  )
}

export default Label;
