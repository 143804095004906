import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import { classModifier } from 'utils';
import { BUTTON_THEMES, SIZES } from 'config/constants';

import "./Button.scss";
import ICONS from 'assets/icons';


const ButtonContext = React.createContext({});

const ContentWrapper = ({ children, className }) => (
  <div className={classNames(className, "icon-button__part-wrapper")}>
    {children}
  </div>
)

const TextWrapper = ({ children, className }) => (
  <div className={classNames(className, "icon-button__text")}>
    {children}
  </div>
)

const Button = React.forwardRef((props, ref) => {
  const {
    content,
    icon = typeof content === 'object' ? content : null,
    text = typeof content === 'string' ? content : null,
    active,
    children,
    className,
    component: Component,
    size,
    iconSize = React.Children.count(children) === 1 || !!icon ? 'full' : SIZES.S,
    theme = BUTTON_THEMES.SECONDARY_DARK,
    leftContent,
    radius = SIZES.S,
    tabPosition,
    squared = React.Children.count(children) === 1 || !!icon,
    isBroad,
    showChecked,
    onClick,
    disabled,
    color,
    ...restProps
  } = props;

  const [showedCheckIcon, setShowedCheckIcon] = useState(false);

  const inlineRef = useRef();

  const Wrapper = useCallback((props) => {
    const modifiedChildren = (
      <div
        className="content"
        style={{ '--main-color': `rgb(var(${color}))` }}
      >
        {props.children}
      </div>
    )

    if (Component) {
      return <Component {...props} ref={ref || inlineRef}>{modifiedChildren}</Component>
    } else {
      return <button {...props} ref={ref || inlineRef}>{modifiedChildren}</button>
    }
  }, [Component]);

  const predifinedIconsByTheme = {
    [BUTTON_THEMES.PLUS]: ICONS.plusCircle,
    [BUTTON_THEMES.PLUS_INVERTED]: ICONS.plusCircle,
  }

  const classes = classNames(
    className,
    classModifier(
      'icon-button',
      [
        (squared || Object.keys(predifinedIconsByTheme).includes(theme)) && 'squared',
        theme && 'theme-' + theme,
        size && 'size-' + size,
        iconSize && 'icon-size-' + iconSize,
        radius && `radius-${radius}`,
        isBroad && 'broad',
        showedCheckIcon && 'showed-check-icon',
      ]
    ),
    active && 'active',
    disabled && 'disabled',
  )

  const modifiedOnClick = () => {
    onClick?.();

    if (showChecked) {
      setShowedCheckIcon(true);
      setTimeout(() => {
        setShowedCheckIcon(false);
      }, 700);
    }
  }

  const wrapperProps = {
    className: classes,
    type: 'button',
    disabled,
    onClick: modifiedOnClick,
    ...restProps,
  }

  const Icon = showedCheckIcon ? ICONS.check : icon;

  if (Icon) {
    return (
      <Wrapper {...wrapperProps}>
        <ContentWrapper>
          <Icon className="icon-button__icon" />
          {children}
        </ContentWrapper>
      </Wrapper>
    )
  }

  if (text) {
    return (
      <Wrapper {...wrapperProps}>
        <TextWrapper>
          {text}
          {children}
        </TextWrapper>
      </Wrapper>
    )
  }

  return (
    <ButtonContext.Provider value={{ Icon }}>
      <Wrapper {...wrapperProps}>
        {children}
      </Wrapper>
    </ButtonContext.Provider>
  );
})


Button.Icon = ({ icon: Icon, children }) => {
  return (
    <ContentWrapper>
      {children
        ? React.cloneElement(children, { className: "icon-button__icon" })
        : Icon ? <Icon className="icon-button__icon" /> : null}
    </ContentWrapper>
  )
};

Object.entries(ICONS).forEach(([iconName, Icon]) => {
  const Component = () => (
    <ContentWrapper>
      <Icon className={classModifier('icon-button__icon', iconName)} />
    </ContentWrapper>
  )

  Button.Icon[iconName] = Component;
})

Button.Icon.displayName = 'Icon';

Button.Arrow = () => {
  const ChevronIcon = ICONS.chevron;

  return (
    <ContentWrapper>
      <ChevronIcon className={classModifier("icon-button__icon", "arrow")} />
    </ContentWrapper>
  )
}

Button.Arrow.displayName = 'Arrow';

Button.Status = ({ color }) => (
  <ContentWrapper>
    <span
      className="icon-button__status"
      style={{ backgroundColor: `rgb(var(${color}))` }}
    />
  </ContentWrapper>
)

Button.Status.displayName = 'Status';

Button.Text = ({ children, className }) => (
  <TextWrapper className={className}>{children}</TextWrapper>
)

Button.ContentWrapper = ContentWrapper;


export default Button;
