import API from 'api/api';
import ICONS from 'assets/icons';
import OverflowedTags from 'components/OverflowedTags/OverflowedTags'
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTelegramGroupMembers, addTelegramGroupMembersPhotos, updateActiveGroup } from 'redux/ducks/girlChats';
import { classModifier } from 'utils';
import ContactTels from './ContactTels';
import ContactEmails from './ContactEmails';
import ContactTags from 'components/ContactTags/ContactTags';
import { CONTACT_TYPES } from 'config/constants';

const ChatAdditionalInfo = (props) => {
  const activeGroup = useSelector((state) => state.girlChats.activeGroup);
  const loadedGroupIds = useSelector((state) => state.girlChats.groupChat.loadedGroupIds);
  const [telegramGroup, setTelegramGroup] = useState(null);
  const [isBookingsMembersShown, setIsBookingsMembersShown] = useState(false);
  const [isCommissionMembersShown, setIsCommissionMembersShows] = useState(false);

  const isGirlChat = props.activeRecipient.type === CONTACT_TYPES.GIRL;
  const isClientChat = props.activeRecipient.type === CONTACT_TYPES.CLIENT;

  const dispatch = useDispatch();

  const msgRef = useRef();

  const selectGroupChat = (groupId, groupMembersIds, groupInternalId, type) => {
    if (groupId === activeGroup) return;

    if (!loadedGroupIds.includes(groupId)) {
      const memberIds = !!groupMembersIds ? groupMembersIds : Object.keys(groupMembers);

      if (!!memberIds.length) {
        API.getChatGroupsPhotos(groupId, memberIds, type)
          .then(({ data }) => {
            dispatch(addTelegramGroupMembersPhotos(groupId, data));
          })
          .catch(console.error);
      }
    }

    dispatch(updateActiveGroup(groupId, groupInternalId, type));
  };

  const getChatGroups = () => {
    API.getChatGroups(props.activeRecipient.id)
      .then(({ data }) => {
        const groupMembers = data.map(group => group.members).flat();
        const bookingGroup = data.find((group) => group.type === 1);
        const commissionGroup = data.find((group) => group.type === 2);
        const groupMembersIds = groupMembers.map(({ user_telegram_id }) => user_telegram_id);

        if (bookingGroup) {
          selectGroupChat(bookingGroup.telegram_group_id, groupMembersIds, bookingGroup.id, bookingGroup.type);
        } else if (commissionGroup) {
          selectGroupChat(commissionGroup.telegram_group_id, groupMembersIds, commissionGroup.id, commissionGroup.type);
        }

        setTelegramGroup(data);
        dispatch(addTelegramGroupMembers(groupMembers));
      })
      .catch(console.error)
  };

  const getMembersShownStatus = (groupType) => {
    if(groupType === 1) {
      return isBookingsMembersShown
    } 

    return isCommissionMembersShown;
  }

  const toggleMembersShown = (groupType) => {
    if(groupType === 1) {
      setIsBookingsMembersShown(!isBookingsMembersShown)
    } else {
      setIsCommissionMembersShows(!isCommissionMembersShown);
    }
  } 

  const isMembersVisible = (activeGroupId) => {
    const activeGroup = telegramGroup?.find((group) => group.telegram_group_id === activeGroupId);

    if(activeGroup) {
      return getMembersShownStatus(activeGroup.type);
    }

    return false;
  }

  useEffect(() => {
    if (isGirlChat) {
      setTelegramGroup(null);
      setIsBookingsMembersShown(false);
      setIsCommissionMembersShows(false);

      setTimeout(() => {
        getChatGroups();
      })
    }

    if (!isClientChat && !isGirlChat) {
      setTelegramGroup(null);
      setIsBookingsMembersShown(false);
      setIsCommissionMembersShows(false);
      updateActiveGroup(null);
    }
  }, [props.activeRecipient.id])

  return (
    <div className='chat-header__additional-info'>
      <div className="chat-header__row" ref={msgRef}>
        <div className="chat-header__contact">
          <div className="chat-header__contact-tels">
            <ContactTels
              callFromChat={props.callFromChat}
              activeRecipient={props.activeRecipient}
            />
          </div>
  
          <div className="chat-header__contact-emails">
            <ContactEmails activeRecipient={props.activeRecipient} />
          </div>
        </div>
      </div>
  
      <ContactTags
        className="chat-header__tags"
        callers={props.activeRecipient}
        withoutAbsentLabel
        wrapper={OverflowedTags}
        wrapperProps={{ responsive: true }}
      />

      {isGirlChat &&
        <>
          <div className="chat-header__contact-groups">
            <ul className="chat-header__contact-groups-title-list">
              <li
                className={classModifier('chat-header__contact-groups-title',
                  [activeGroup === null && 'active'])}
                onClick={() => updateActiveGroup(null)}
              >
                Private
              </li>
              {telegramGroup?.map((group) => (
                <li
                  key={group.id}
                  className={classModifier('chat-header__contact-groups-title',
                    [activeGroup === group.telegram_group_id && 'active']
                  )}
                  onClick={() => selectGroupChat(group.telegram_group_id, null, group.id, group.type)}
                >
                  {CHAT_TELEGRAM_GROUP[group.type]}
                  
                  <button 
                    className={classModifier('chat-header__contact-groups-title-btn',
                      [getMembersShownStatus(group.type) && 'active']
                    )}
                    onClick={() => toggleMembersShown(group.type)}
                  >
                    <ICONS.angleQuote
                      className='chat-header__contact-groups-title-btn-icon'
                      width={11}
                      height={11}
                    />
                  </button>
                </li>
              ))}
            </ul>

            {isMembersVisible(activeGroup) && (
              <ul className="chat-header__contact-groups-name-list">
                {telegramGroup
                  ?.find((group) => group.telegram_group_id === activeGroup)
                  ?.members?.map((member) => (
                    <li
                      key={member.id}
                      className="chat-header__contact-groups-name"
                    >
                      {member.first_name || member.username}
                    </li>
                  ))}
              </ul>
            )}

          </div>
        </>
      }
    </div>
  )
}

export default ChatAdditionalInfo;
