import { useState } from 'react';
import ICONS from 'assets/icons';
import { useSelector } from 'react-redux';

import { selectMapGirlBySecondaryMode, selectMostImportantSecondaryMode } from 'redux/selectors/selectors';
import { classModifier } from 'utils';
import { useToggle } from 'hooks';
import API from 'api/api';
import Tooltip from 'components/UI/Tooltip/Tooltip';


const CopyLinkButton = () => {
  const mostImportantSecondaryMode = useSelector(selectMostImportantSecondaryMode);
  const girlsForSending = useSelector(
    (state) => selectMapGirlBySecondaryMode(state, mostImportantSecondaryMode, { shouldBeFiltered: true })
  )

  const [isSuccessIcon, toggleIsSuccessIcon] = useToggle(false);

  const handleClick = () => {
    const girlsIds = girlsForSending.map(({ girl }) => girl.profile_id);

    API.getDivaLink(girlsIds)
      .then(({ data: divaLink }) => {
        navigator.clipboard.writeText(divaLink)
          .catch(console.error);
        toggleIsSuccessIcon(true);

        setTimeout(() => {
          toggleIsSuccessIcon(false)
        }, 700);
        
      })
      .catch(console.error);
  }

  const isDisabled = Array.isArray(girlsForSending)
    ? girlsForSending.length === 0 || girlsForSending.length > 16
    : !girlsForSending

  return (
    <Tooltip
      text="No fewer than 1 and no more than 16"
      disable={!isDisabled}
    >
      <button
        className={classModifier("girls-map__toggle-btn", "copy")}
        disabled={isDisabled}
        onClick={handleClick}
      >
        Copy Link
        {isSuccessIcon
          ? <ICONS.check className="girls-map__toggle-btn-icon" />
          : <ICONS.link className="girls-map__toggle-btn-icon" />
        }
      </button>
    </Tooltip>
  )
}

export default CopyLinkButton;
