import React from "react";
import { connect } from "react-redux";

import {
  updateComparedId,
  updateBufferedId,
} from "redux/ducks/sessions";
import {
  selectPropertyOfActiveSession,
  selectCanIEditSession,
  selectIsProfileInBuffer,
} from "redux/selectors/selectors";
import { DIVA_GIRLS_IMG_HOST_PREVIEW } from "redux/ducks/divaGirls";
import { classModifier } from 'utils';

import ICONS from "assets/icons";

const MentionedOrRecentlyViewedProfile = ({ id, profile, isBuffer, ...props }) => {
  const handleClick = () => {
    if (isBuffer) {
      props.updateBufferedId(id, props.activeSession, props.canIEditSession, profile.id_new, profile.contact_uid);
    } else {
      props.updateComparedId(id, props.activeSession, props.canIEditSession)
    }
  };

  const renderPrices = () => {
    let prices = '';

    if (profile.price_30_minutes_in) prices += profile.price_30_minutes_in + ' / ';
    if (profile.price_30_minutes_out) prices += profile.price_30_minutes_out + ' / ';
    if (profile.price_1_hour_in) prices += profile.price_1_hour_in + ' / ';
    if (profile.price_1_hour_out) prices += profile.price_1_hour_out + ' / ';

    return prices;
  }

  return (
    <li
      className={classModifier('recently-viewed-profiles__item', [
        isBuffer && props.isProfileInBuffer && 'disabled',
      ])}
      onClick={handleClick}
    >
      <div className="recently-viewed-profiles__img-wrap">
        <img src={DIVA_GIRLS_IMG_HOST_PREVIEW + profile.avatar} className="recently-viewed-profiles__img" />
      </div>

      <div className="recently-viewed-profiles__info">
        <div className="recently-viewed-profiles__name">
          <ICONS.eye className="recently-viewed-profiles__name-icon" />
          {profile.name}
        </div>
        <div>{renderPrices()} {profile.age} y.o. / {profile.height} cm / {profile.dress} / {profile.breast_size}</div>
        <div>{profile.nationality}</div>
      </div>
    </li>
  )
};

const mapStateToProps = (state, ownProps) => ({
  profile: ownProps.profile || state.divaGirls.entities[ownProps.id],
  activeSession: state.sessions.activeSession,
  comparedIds: selectPropertyOfActiveSession(state, "comparedIds"),
  canIEditSession: selectCanIEditSession(state),
  isProfileInBuffer: selectIsProfileInBuffer(state, ownProps.id),
});

const mapDispatchToProps = {
  updateComparedId,
  updateBufferedId,
}

export default connect(mapStateToProps, mapDispatchToProps)(MentionedOrRecentlyViewedProfile);