import { useDispatch, useSelector } from 'react-redux'
import uuid from 'react-tabs/lib/helpers/uuid'

import { CARD_TABS, CONTACT_TYPES } from 'config/constants'
import { selectContactById } from 'redux/selectors/selectors'
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows'
import { SPECIAL_TAG_KEYS } from 'components/ContactTags/config/config'
import API from 'api/api'

import "./SidebarContactItemWrapper.scss";
import Counter from 'components/UI/Counter/Counter'
import ICONS from 'assets/icons'
import Tooltip from 'components/UI/Tooltip/Tooltip'
import ContactItemBlock from 'components/UI/ContactItemBlock/ContactItemBlock'
import FloatingMenu from 'components/UI/Menu/FloatingMenu'
import SidebarContactItem from '../SidebarContactsList/SidebarContactItem'
import ContactTags from 'components/ContactTags/ContactTags'
import fetchSearchClientsContacts from 'utils/fetchSearchClientsContacts'
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem'
import Title from 'components/UI/Title/Title'

const SidebarContactItemWrapper = ({ id, style, ...restProps }) => {
  const contact = useSelector((state) => selectContactById(state, id));
  const bookingDetails = useSelector((state) => state.clientChats.bookingDetails);

  const dispatch = useDispatch();

  const girlBookingsInfo = bookingDetails.find(el => el.girlId === id);
  const isTemporary = contact.callerTags?.some(tag => tag.title === 'Temporary') || !!contact.isTemporary;

  const getGirlContactName = () => {
    return contact.headerName.startsWith('EX ')
          ? <><span className="sidebar-contacts-list__contact-ex">EX&nbsp;</span>{contact.headerName.slice(3)}</>
          : contact.headerName
  }


  const handleEdit = (event) => {
    event.stopPropagation();

    dispatch(openModal(MODAL_TYPES.contactCard, {
      contact,
      defaultTab: CARD_TABS.EDIT,
      autoFocusInput: 'fn',
    }))
  };

  const onAddClient = () => {
    props.openModal(MODAL_TYPES.searchList, {
      fetchData: fetchSearchClientsContacts,
      itemComponent: SearchListContactItem,
      onChoose: async (selectedContact) => {
        try {
          const preparedContact = {
            ...selectedContact,
            tels: [
              ...selectedContact.tels,
              {
                default: 0,
                labelName: 'default',
                uuid: uuid(),
                tel: contact.fn,
              },
            ]
          }

          await API.mergeContactCards(preparedContact, contact.id)

          dispatch(openModal(MODAL_TYPES.contactCard, {
            contact: preparedContact,
            defaultTab: CARD_TABS.EDIT,
            autoFocusInput: 'fn',
          }));

          dispatch(closeModal(MODAL_TYPES.searchList));
        } catch (error) {
          console.error(error)
        }
        setIsLoading(false)
      },
    })
  }

  const addNewContactItems = [
    {
      name: 'Add new contact',
      action: handleEdit,
    },
    {
      name: 'Add to existing contact',
      action: onAddClient,
    },
  ]

  let contactName;

  switch (contact.type) {
    case CONTACT_TYPES.GIRL:
      const GirlName = () => (
        <Tooltip text={contact.fn}>
          <ContactItemBlock.Body.Name>
            {Boolean(contact.todayBookingsCount) && (
              <mark className="sidebar-contact-wrapper__unfinished-bookings-count">
                {contact.todayBookingsCount}
              </mark>
            )}
            
            <ContactTags
              callers={contact}
              tagClassName="sidebar-contact-wrapper__tag"
              selectedSpecificTags={SPECIAL_TAG_KEYS.DEBTPRICE}
            />

            {contact.headerName.startsWith('EX ')
              ? <><span className="sidebar-contacts-list__contact-ex">EX&nbsp;</span>{contact.headerName.slice(3)}</>
              : contact.headerName}
          </ContactItemBlock.Body.Name>
        </Tooltip>
      )

      contactName = GirlName;
      break;
    case CONTACT_TYPES.CLIENT:
      const ClientName = () => (
        <FloatingMenu
          options={addNewContactItems}
          disabled={!isTemporary}
        >
          <ContactItemBlock.Body.Name
            component={Title}
            icon={isTemporary ? ICONS.plusCircle : null}
          >
            {contact.fn}
          </ContactItemBlock.Body.Name>
        </FloatingMenu>
      )

      contactName = ClientName;
      break;
    default:
      contactName = () => contact.fn;
  }

  return (
    <div
      className="sidebar-contacts-list__contact-item-wrapper"
      style={style}
    >
      {contact.type === CONTACT_TYPES.GIRL && !!girlBookingsInfo && (
        <Counter
          count={girlBookingsInfo?.bookingsCount}
          icon={ICONS.eyeNew}
        />
      )}

      <SidebarContactItem
        {...restProps}
        id={id}
        contactName={contactName}
      />
    </div>
  )
}

export default SidebarContactItemWrapper;
