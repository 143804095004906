import React, { useRef, useState } from 'react';
import { Field } from 'react-final-form';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import EmojiPickerBtn from 'containers/Chat/components/ChatMessageInput/components/EmojiPickerBtn/EmojiPickerBtn';

const EmojiField = ({ name, serverError, setValues, values, label = 'Emoji', isMistress }) => {
  const [isOpenEmojiList, setIsOpenEmojiList] = useState(false);

  const labelRef = useRef();
  const paddingLeft = labelRef.current ? labelRef.current?.getBoundingClientRect().width + 10 + 'px' : 0;

  return (
    <div className={classModifier("adr-book-contact-form__fn-wrapper", "emoji")}>
      <span ref={labelRef} className='adr-book-contact-form__input-title'>{label}:</span>
      <Field
        name={`${name}`}
        component={FormTextInput}
        serverErrors={serverError}
        autoComplete="off"
        placeholder="Emoji"
        className={classModifier('adr-book-contact-form__input', ['emoji', isMistress && 'emoji-mistress'])}
        inputProps={{
          onClick: () => setIsOpenEmojiList(!isOpenEmojiList),
          style: { paddingLeft }
        }}
      />

      <EmojiPickerBtn
        onSelect={emoji => {
          const sym = emoji.unified.split('-');
          const codesArray = [];

          sym.forEach(el => codesArray.push('0x' + el));
          const emojiPic = String.fromCodePoint(...codesArray);

          if(name.includes('.')) {
            const [rootKey, childKey] = name.split('.');
            const emojiValue = values[rootKey][childKey];
            setValues(name, (emojiValue || '') + emojiPic);
          } else setValues(name, (values[name] || '') + emojiPic);
        }}
        isHiddenBtn
        isOpenFromParent={isOpenEmojiList}
        isShow={isOpenEmojiList}
        onClick={() => {}}
        onClose={() => setIsOpenEmojiList(false)}
      />
      {values[name] && 
        <button 
          type="button" 
          className="adr-book-contact-form__btn-clear"
          onClick={() => setValues(name, '')}
        >
          <ICONS.close className="adr-book-contact-form__btn-clear-icon"/>
        </button>
      }
    </div>
  )
}

export default EmojiField;