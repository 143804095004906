import { classModifier } from 'utils';
import './Checkbox.scss';
import ICONS from 'assets/icons';

export default function Checkbox({ inputProps, input: finalFormInputProps, theme, text }) {
  return (
  //   <span
  //     className={classModifier('checkbox', theme)}
  //   >
  //     <input
  //       {...finalFormInputProps}
  //       className="checkbox__input" 
  //       type="checkbox"
  //       {...inputProps}
  //     />

  //     <span
  //       style={{
  //         ...(size ? { width: size, height: size } : {})
  //       }}
  //       className="checkbox__indicator"
  //     >
  //       <ICONS.checkRounded />
  //     </span>
  //   </span>
  <label className="mcui-checkbox">
    <input
      {...finalFormInputProps}
      type="checkbox"
      {...inputProps}
    />

    <div>
      <ICONS.checkRounded className="mcui-check" />
    </div>

    {text}
  </label>
  );
}
