import React, { useEffect, useRef } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { Field, useFormState } from 'react-final-form';

import { usePrevious } from 'hooks';
import { classModifier, decodeHTML } from 'utils';
import { BOOKING_LOCATION_TYPES } from 'config/constants';
import BookingTooltipAddFrom from './BookingTooltipAddFrom/BookingTooltipAddFrom';
import SelectFormField from 'components/SelectFormField/SelectFormField';

const BookingLocationFields = (props) => {
  const { 
    isLoaded,
    setMapData,
    locationType,
    isBookingEdit,
    setLocationType,
    onSearchBoxLoad, 
    onPlacesChanged,
    callerId,
    setProfile,
  } = props;

  const inputRef = useRef();
  const { values, errors } = useFormState();
  const prevInputValue = usePrevious(inputRef.current?.value);
  const defaultValue = values.address?.locationName;
  const incallAddress = values.type === 'incall_duo' ? values.escort?.place : values.profile?.place;
  const isIncallBoth = values.type === 'incall_both' || (isBookingEdit && values.type === 'incall' && values.meetingType === 'duo');
  const INCALL_ADDRESSES = [
    { value: values.profile?.place, label: values.profile?.place, profile: values.profile },
    { value: values.escort?.place, label: values.escort?.place, profile: values.escort }
  ];

  const defaultAddressValue = isBookingEdit 
    ? INCALL_ADDRESSES.find(address => address.value === values.address?.locationName) 
    : INCALL_ADDRESSES[0];

  useEffect(() => {
    if(setMapData && prevInputValue && prevInputValue !== inputRef.current?.value) {
      setMapData(inputRef.current.value);
    }
  }, [inputRef.current?.value]);

  const onChange = (e, input) => {
    isBookingEdit ? setLocationType(e.target.value) : input?.onChange(e);
  };

  const changeValueByMessage = (item) => {
    inputRef.current.value = item.message;
    inputRef.current.focus();
    setMapData?.(item.message);
  }

  return (<>
    {values.type === 'outcall' 
      ? <div className='booking-form__location-info'>
          <div className='booking-form__location-info-header'>
            <div className='booking-form__location-controls'>
              <Field name='address.type' type='radio' value={BOOKING_LOCATION_TYPES.PRIVATE}>
                {({ input }) => {
                  const checked = isBookingEdit ? locationType === BOOKING_LOCATION_TYPES.PRIVATE : input.checked;
                  return (
                    <label className={classModifier('booking-form__booking-radio-field', 'location')}>
                      <input
                        {...input}
                        checked={checked}
                        onChange={e => onChange(e, input)}
                      />
                      <div className={classModifier('booking-form__radio', checked && 'selected')}></div>
                      Private Place
                    </label>
                  )
                }}
              </Field>

              <Field name='address.type' type='radio' value={BOOKING_LOCATION_TYPES.HOTEL}>
                {({ input }) => {
                  const checked = isBookingEdit ? locationType === BOOKING_LOCATION_TYPES.HOTEL : input.checked;
                  return (
                    <label className={classModifier('booking-form__booking-radio-field', 'location')}>
                      <input
                        {...input}
                        checked={checked}
                        onChange={e => onChange(e, input)}
                      />
                      <div className={classModifier('booking-form__radio', checked && 'selected')}></div>
                      Hotel
                    </label>
                  )
                }}
              </Field>
            </div>

            <BookingTooltipAddFrom
              callerId={callerId}
              onSelect={changeValueByMessage}
              isBookingEdit={isBookingEdit}
            />
          </div>

          {isLoaded && 
            <Field name="address.locationName">
              {({ meta: { touched }}) => {
                return (
                  <>
                    <StandaloneSearchBox
                      onLoad={onSearchBoxLoad}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <input
                        ref={inputRef}
                        type="text"
                        defaultValue={defaultValue}
                        onKeyPress={e => e.key === 'Enter' &&  e.preventDefault()}
                        onChange={e => inputRef.current.value = e.target.value}
                        className='booking-form__location'
                        placeholder="Search"
                      />
                    </StandaloneSearchBox>
                    {touched && errors.locationName && <span className='booking-form__location-error'>{errors.locationName}</span>}
                  </>
                )
              }}

              {/* {!isSearch && <div 
                onClick={() => {
                  setIsSearch(true);
                  // inputRef.current.focus();
                }}
                className={classModifier('booking-form__location', 'value-block')}
              >
                {searchValue}
              </div>} */}
            </Field>
          }
        </div>

      : <div className='booking-form__select-wrapper'> 
          <h3 className='booking-form__select-title'>Incall Address</h3>

          {isIncallBoth 
            ? <Field
                name='addressIncall'
                fieldType='address'
                component={SelectFormField}
                setProfile={setProfile}
                stateValue={defaultAddressValue}
                options={INCALL_ADDRESSES}
              />
            : <p className='booking-form__select-incall-address'>
                {decodeHTML(incallAddress)}
              </p>
          }

          {/* <Field
            name='incallType'
            component={SelectFormField}
            fieldType='incallType'
            options={TYPE_FIELD_OPTIONS}
          /> */}
        </div>
      }
    </>
  )
}

export default React.memo(BookingLocationFields);
