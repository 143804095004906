const contactStatues = {
  available: 'online',
  off: 'offline',
  ['off today']: 'offline-today',
}

const userStatuses = {
  online: 'online',
  away: 'away',
  busy: 'offline',
}

export default (obj) => {
  if (obj.role) { // if obj is a operator
    return userStatuses[obj.status];
  }

  if (obj.interactionObject) { //if obj is a contact
    return contactStatues[obj.availability];
  }

  console.error('Unknown object type');
}
