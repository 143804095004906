import React from 'react';
import { useDispatch } from 'react-redux';

import { getContactsById } from 'redux/ducks/contacts';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import clientImg from 'assets/images/avatars/avatarDefault.png'
import ICONS from 'assets/icons';

import { updateActiveContact } from 'redux/ducks/clientChats';
import Label from 'components/UI/Label/Label';

const BookingClient = ({// [ ]: delete unused attributes
  className,
  booking,
  isAvatarShowed,
  isNotClickable,
  extraText,
  shouldOpenChat,
}) => {
  const caller_avatar = booking.caller_avatar || booking.caller.photo || clientImg;
  const dispatch = useDispatch();

  const openContactModal = (callerId) => {
    dispatch(getContactsById(callerId))
      .then((contact) => {
        if (contact) {
          dispatch(openModal(MODAL_TYPES.contactCard, { contact: callerId }));
        }
      })
  };

  const openChat = (callerId) => {
    dispatch(getContactsById(callerId))
      .then((contact) => {
        if (contact) {
          dispatch(updateActiveContact(contact))
        }
      })

    const customEvent = new CustomEvent('openChat', { detail: { clientId: callerId } });
    dispatchEvent(customEvent);
  }

  const handleNameClick = (e) => {
    if (isNotClickable) return;

    e.stopPropagation();

    const callerId = booking.callerId || +booking.caller_id;

    if (!callerId) return;

    dispatch(getContactsById(callerId))
      .then((contact) => {
        if (contact) {
          shouldOpenChat ? openChat(callerId) : openContactModal(callerId);
        }
      })
  }

  return (
    <Label
      className={className}
      icon={ICONS.client}
      text={booking.caller.fn}
      onClick={handleNameClick}
    />
  )
}

export default BookingClient;
