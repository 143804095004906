import ReactDOM from 'react-dom';
import React, { useState, useEffect, useRef, memo, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import API from 'api/api';
import ICONS from 'assets/icons';
import { useToggle } from 'hooks';
import { getContactAvatar, classModifier } from 'utils';
import { BUTTON_THEMES, CHAT_TELEGRAM_GROUP, CHAT_TYPES, CONTACT_TYPES, SEARCH_LIST_THEMES, SIZES } from 'config/constants';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { toggleContactPin, toggleContactMark } from 'redux/ducks/contacts';
import {
  selectUserTimezone,
  selectDefaultSession,
  selectContactsActiveGirlsFromChats,
  selectActiveGirlByDiva,
  selectActiveSessionByContactId,
} from 'redux/selectors/selectors';
import { addDivaGirlsToEntities, getActiveDivaGirls } from 'redux/ducks/divaGirls';
import {
  addTelegramGroupMembers,
  addTelegramGroupMembersPhotos,
  updateActiveGroup,
} from 'redux/ducks/girlChats';
import { createSession, updateActiveSession } from 'redux/ducks/sessions';

import './ChatHeader.scss';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import ContactTels from './ContactTels';
import ContactEmails from './ContactEmails';
import TriStateSwitch from 'components/TriStateSwitch/TriStateSwitch';
import ClientBookingButton from '../../../../components/Buttons/ClientBookingButton';
import CopyToClipboardButton from 'components/Buttons/CopyToClipboardButton';
import ChatHeaderActionMenu from './ChatHeaderActionMenu/ChatHeaderActionMenu';
import ProfileRates from 'components/ProfileRates/ProfileRates';
import QuickBookingGirlsListItemByDiva from '../QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import ChatCreatorForm from '../ChatCreatorForm/ChatCreatorForm';
import Icon from 'components/Icon/Icon';
import ContactBlockInfo from './ContactBlockInfo';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';
import OverflowedTags from 'components/OverflowedTags/OverflowedTags';
import ContactTags from 'components/ContactTags/ContactTags';
import CreateSessionButton from 'components/Buttons/CreateSessionButton';
import PinChatButton from 'components/Buttons/PinChatButton';
import MarkChatButton from 'components/Buttons/MarkChatButton';
import Button from 'components/UI/Button/Button';
import SearchList from 'components/SearchList/SearchList';
import ShowContactNotesButton from 'components/Buttons/ShowContactNotesButton';
import TakeChatButton from 'components/Buttons/TakeChatButton';
import ShowExtendedBookingInfo from 'components/Buttons/ShowExtendedBookingInfo';
import { getDivaInfo } from 'utils/getDivaInfo';
import ShowGirlRatesButton from 'components/Buttons/ShowGirlRatesButtons';
import MapButton from 'components/Buttons/MapButton';
import OpenGirlBookingCalendar from 'components/Buttons/OpenGirlBookingCalendar';
import OpenProfileOnDivaSiteButton from 'components/Buttons/OpenProfileOnDivaSiteButton';
import GroupedButtons from 'components/UI/GroupedButtons/GroupedButtons';

const getRealAge = (birthday) => {
  if (birthday) {
    const b = birthday.split('-');
    const birthdayDate = new Date(b[0], b[1] - 1, b[2]);
    return `${Math.floor((Date.now() - birthdayDate.getTime()) / 1000 / 60 / 60 / 24 / 365)}`;
  }
}

const ChatHeader = ({
  activeRecipient,
  profile,
  getActiveDivaGirls,
  activeSession,
  defaultSession,
  createSession,
  openModal,
  activeGirl,
  updateActiveGroup,
  activeGroup,
  groupMembers,
  loadedGroupIds,
  addTelegramGroupMembers,
  addTelegramGroupMembersPhotos,
  contactSessionId,
  isSalesPageOpen,
  ...props
}) => {
  const [isGirlDetailsShowed, setIsGirlDetailsShowed] = useState(false);
  const [blockInfo, toggleContactInfo] = useToggle(false);
  const [isRatesOpen, toggleRatesOpen] = useToggle(false);
  // const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [reviewsCount, setReviewsCount] = useState(0);

  const [isGirlsForBookingsOpen, toggleIsGirlsForBookinsOpen] = useToggle(false);


  const dispatch = useDispatch();

  const contactInfoRef = useRef();

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
    getArrowProps,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'bottom',
  });

  useEffect(() => {
    contactInfoRef.current && contactInfoRef.current.scrollToTop();

    if (isGirlChat && !profile && activeRecipient.diva_id) {
      const prepIds = props.activeContactsGirlsFromChats.map(girl => girl.diva_id);
      getActiveDivaGirls({ params: { 'filter-ids': prepIds } });
    }

    if (isGirlChat) {
      if (!activeRecipient.has_telegram_groups) {
        updateActiveGroup(null);
      } else {
        updateActiveGroup(0);
      }

      API.getActiveDivaGirlsByContactsIds(activeRecipient.id)
        .then(res => props.addDivaGirlsToEntities([res.data]))
        .catch(console.error);
    }
  }, [activeRecipient.id]);

  useEffect(() => {
    if (profile) {
      setReviewsCount(profile.reviewsCount);
    }
  }, [profile]);

  const openProfileReviews = (event) => {
    event.stopPropagation();

    if (!profile.reviewsCount || !activeRecipient.diva_default_id) return;

    const handleDeleteReview = () => setReviewsCount(prevState => prevState - 1);

    openModal(MODAL_TYPES.profileReviews, { id: activeRecipient.diva_default_id, handleDeleteReview });
  };

  const onQuickBookingClick = () => {
    openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      isHandleClickCanBeRedefinedByItem: true,
    });
  }

  const handleBookingsListOpen = () => {
    openModal(MODAL_TYPES.bookingsList, {
      contactId: activeRecipient.id,
      activeRecipient,
      onQuickBookingClick
    })
  }

  const isBlocked = !!activeRecipient.blocked;

  const onNameClick = () => {
    if (!props.fullScreenMode) {
      openModal(MODAL_TYPES.contactCard, { contact: activeRecipient.id });
    }
  };

  const getContactName = () => {
    const isEx = activeRecipient.fn?.startsWith('EX ');
    const splittedName = activeRecipient.fn?.split(' ');
    let name = activeRecipient.fn;

    if (isGirlChat) {
      // name = isEx ? splittedName[1] : splittedName[0];
      // name = `${activeRecipient.short_name} ${activeRecipient.agentName || ''}`;
      name = `${activeRecipient.headerName || ''}`;
    } else if (isEx) {
      name = splittedName.slice(1).join(' ');
    }

    if (isEx) {
      return <><span className="chat-header__ex">EX</span>{name}</>
    }

    return name;
  };


  const isGirlChat = activeRecipient.type === CONTACT_TYPES.GIRL;
  const isClientChat = activeRecipient.type === CONTACT_TYPES.CLIENT;
  const girlBookingsInfo = props.girlBookingDetails.find(el => el.girlId === activeRecipient.id);

  const portraitMedia = useMemo(() => {
    if (!profile) {
      return [];
    }

    return profile.images_info
      .filter((image) => (
        image.is_published && image.ratio === 'portrait'
      ))
      .sort((prevImage, nextImage) => Number(nextImage.is_video) - Number(prevImage.is_video))
  }, [profile])

  const onOpenGallery = useCallback(() => {
    const media = portraitMedia.length ? portraitMedia : [profile.avatar];

    openModal(MODAL_TYPES.photoGallery, {
      profile,
      state: {
        activeSlide: 0,
      },
      media
    })
  }, [portraitMedia]);

  const contactNameAndDetails = (
    <>
      <p
        ref={setTriggerRef}
        className='chat-header__name flexrow'
        onMouseEnter={() => isGirlChat && setIsGirlDetailsShowed(true)}
      >
        {getContactName()}

        {isGirlChat && <ICONS.roundedWarn />}
      </p>

      {isGirlChat && (
        <p className="flexrow">
          <span className='chat-header__id'>
            {activeRecipient.diva_default_id && `ID: ${activeRecipient.diva_default_id}`}
            {/* ID: 5874538750 */}
          </span>
          &nbsp;
          <span className="chat-header__rating">
            {!!profile?.rating && profile.rating.rating}
            {/* 5.0 */}
          </span>
          &nbsp;
          {!!activeRecipient.diva_default_id && profile && (
            <span
              className="chat-header__reviews"
              onClick={openProfileReviews}
            >
              ({reviewsCount})
            </span>
          )}
        </p>
      )}
    </>
  )

  const clientButtons = (
    <>
      <CopyToClipboardButton value={activeRecipient.fn} title='Copy name' />
      <ShowContactNotesButton />
      <CreateSessionButton />
      <ShowExtendedBookingInfo />
      <ClientBookingButton />
      <TakeChatButton />
      <PinChatButton />
      <MarkChatButton />
    </>
  )

  const girlButtons = (
    <>
      <CopyToClipboardButton value={activeRecipient.fn} title='Copy name' />
      <ShowContactNotesButton />
      <ShowGirlRatesButton />
      <PinChatButton />
      <MapButton />
      <OpenGirlBookingCalendar />
      <OpenProfileOnDivaSiteButton />
    </>
  )

  const generalButtonProps = {
    activeRecipient,
    profile,
    contentContainer: props.contentContainer,
    theme: BUTTON_THEMES.NONE_DARK,
    iconSize: SIZES.L
  }

  return (
    <div
      className={classModifier("chat-header", [
        !activeRecipient.id && "hidden",
        activeRecipient.agentId && "with-agent",
        activeRecipient.availability && activeRecipient.availability
      ])}
    >
      {activeRecipient.id === "new_chat" ? (
        <ChatCreatorForm //TODO v2
          updateActiveContact={props.updateActiveContact}
          removeContactTab={props.removeContactTab}
        />
      ) : (
        <>
          <div
            className={`chat-header__ava-wrap ${isBlocked ? "blocked" : ""}`}
            onClick={profile && onOpenGallery}
            onMouseEnter={isBlocked ? () => toggleContactInfo() : null}
            onMouseLeave={isBlocked ? () => toggleContactInfo() : null}
          >
            <LazyLoadImage
              src={getContactAvatar(activeRecipient)}
              className='round-img'
              alt='contact_avatar'
            />

            {blockInfo && (
              <ContactBlockInfo
                activeRecipient={activeRecipient}
                userTimezone={props.userTimezone}
              />
            )}
          </div>

          {/* {visible && (isGirlChat || isClientChat) && // [ ]: implement with tooltip component
            <div
              ref={setTooltipRef}
              {...getTooltipProps()}
              className='chat-header__fn-tooltip-wrapper'
            >
              {activeRecipient.fn}
              <span {...getArrowProps()}></span>
            </div>
          }
 */}
          <div className="chat-header__info">
            <div
              className='chat-header__contact-details'
              onClick={onNameClick}
              onMouseLeave={() => isGirlChat && setIsGirlDetailsShowed(false)}
            >
              {/* {Boolean(activeRecipient.available_card) && ( // [ ]: should be implemented in new design?
                <ICONS.card className='chat-header__card' />
              )} */}
              {(isGirlChat && isGirlDetailsShowed && profile) ? getDivaInfo(profile) : contactNameAndDetails}
            </div>

            {/* {props.type === CHAT_TYPES.GIRL && !!girlBookingsInfo &&
                  <div
                    className="chat-header__bookings-block"
                  >
                    <span className="chat-header__bookings-count">{girlBookingsInfo?.bookingsCount}</span>
                    <ICONS.eye className="chat-header__booking-icon-eye" />
                  </div> // [ ]: should be implemented in new design?
                } */}

            {activeRecipient.type === CONTACT_TYPES.GIRL && (
              <TriStateSwitch
                className='chat-header__available-switcher'
                value={activeRecipient.availability ? activeRecipient.availability : "waiting"}
                onChange={(value) => API.changeGirlsStatus(value, activeRecipient.id)}
              />
            )}

            {/* <div className={classModifier("chat-header__buttons", isClientChat ? 'client' : 'girl')}>
              <CopyToClipboardButton
                value={activeRecipient.fn}
                title='Copy name'
              />
              <ShowContactNotesButton
                activeRecipient={activeRecipient}
                container={props.contentContainer.current}
              />
              <PinChatButton activeRecipient={activeRecipient} />
              <MarkChatButton activeRecipient={activeRecipient} />

              {isClientChat &&
                <>
                  <CreateSessionButton activeRecipient={activeRecipient} />
                  <ShowExtendedBookingInfo activeRecipient={activeRecipient} container={props.contentContainer.current} />

                  <ClientBookingButton
                    activeRecipient={activeRecipient}
                    contentContainer={props.contentContainer}
                  />

                  <TakeChatButton activeRecipient={activeRecipient} userId={props.userId} />
                </>
              }

              {isGirlChat && 
                <>
                  <ShowGirlRatesButton profile={profile} />
                  <MapButton profile={profile} />
                  <OpenGirlBookingCalendar profile={profile} />
                  <OpenProfileOnDivaSiteButton activeRecipient={activeRecipient} />
                </>
              }
            </div> */}

            {isClientChat && (
              <GroupedButtons
                className={classModifier("chat-header__buttons", isClientChat ? 'client' : 'girl')}
                generalButtonProps={generalButtonProps}
              >
                <CopyToClipboardButton value={activeRecipient.fn} title='Copy name' />
                <ShowContactNotesButton />
                <CreateSessionButton />
                <ShowExtendedBookingInfo />
                <ClientBookingButton />
                <TakeChatButton />
                <PinChatButton />
                <MarkChatButton />
              </GroupedButtons>
            )}

            {isGirlChat && (
              <GroupedButtons
                className="chat-header__buttons"
                generalButtonProps={generalButtonProps}
              >
                <CopyToClipboardButton value={activeRecipient.fn} title='Copy name' />
                <ShowContactNotesButton />
                <ShowGirlRatesButton />
                <PinChatButton />
                <MapButton />
                <OpenGirlBookingCalendar />
                <OpenProfileOnDivaSiteButton />
              </GroupedButtons>
            )}

            {/*
              <ContactDetails
                profile={profile}
                activeRecipient={activeRecipient} 
              /> */}

            {/* <div className="chat-header__action-menu">
              <ChatHeaderActionMenu
                type={props.type}
                profile={profile}
                userId={props.userId}
                girlDivaId={profile?.id}
                activeRecipient={activeRecipient}
                toggleContactPin={props.toggleContactPin}
                toggleContactMark={props.toggleContactMark}
                openModal={openModal}
              />
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  profile:
    state.divaGirls.entities[ownProps.activeRecipient.diva_default_id]
    || state.divaGirls.entities[ownProps.activeRecipient.diva_id],
  userTimezone: selectUserTimezone(state),
  girlBookingDetails: state.clientChats.bookingDetails,
  activeSession: state.sessions.activeSession,
  defaultSession: selectDefaultSession(state),
  activeGirl: selectActiveGirlByDiva(state),
  activeGroup: state.girlChats.activeGroup,
  groupMembers: state.girlChats.groupChat.members,
  loadedGroupIds: state.girlChats.groupChat.loadedGroupIds,
  activeContactsGirlsFromChats: selectContactsActiveGirlsFromChats(state),
  contactSessionId: selectActiveSessionByContactId(state, ownProps.activeRecipient.id) || 0,
  isSalesPageOpen: state.user.isSalesPageOpen,
});

const mapDispatchToProps = {
  getActiveDivaGirls,
  openModal,
  toggleContactPin,
  toggleContactMark,
  addDivaGirlsToEntities,
  createSession,
  updateActiveGroup,
  addTelegramGroupMembers,
  addTelegramGroupMembersPhotos,
  updateActiveSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ChatHeader));
