export const BASE_DATE_CONFIG = 'hh:mm A';
export const SHORT_DATE_CONFIG = '[@]hh A';
export const MULTI_MESSAGE_DATE_CONFIG = 'DD/MM/yyyy hh:mm A';
export const MESSAGE_DATE_CONFIG = 'hh:mm:ss A';
export const FEEDBACK_DATE_CONFIG = 'DD/MM/yy';
export const ATTACHMENT_BOOKING_DATE_CONFIG = 'DD.MM.YY, hh:mm A';
export const MAIL_REPLAYED_DATE_CONFIG = 'DD MMMM yyyy hh:mm A';
export const BOOKING_DETAILS_DATE_CONFIG = 'ddd, MMMM D, @ hh:mm A';
export const BOOKING_MESSAGES_DATE_CONFIG = 'D/MM/YY [at] h:mm A';
export const BOOKING_CALENDAR_DATE_CONFIG = 'dddd, DD/MM/yyyy';
export const BOOKING_CALENDAR_LABEL_ATTRIBUTE_DATE_CONFIG = 'DD/MM/yyyy, hh';
export const BOOKING_ITEM_DATE_CONFIG = 'D/MM/YY [at] h:mm A';
export const CALENDAR_FORM_DATE_CONFIG = 'DD/MM/yyyy, hh:mm A';
export const PINNED_BOOKING_DATE_CONFIG = 'DD.MM.YY, hh:mm A';
export const CONTACT_INFO_DATE_CONFIG = 'DD/MM/yyyy';
export const CONTACT_LOG_DATE_CONFIG = 'DD MMM, hh:mm A';
export const TASKS_OR_REMINDERS_DATE_CONFIG = 'DD/MM @ hh:mm A';
export const CALENDAR_WEEK_DAY_CONFIG = 'ddd D/MM';
export const CALENDAR_SPLIT_CONFIG = 'MMMM yyyy';
export const CALENDAR_TABLE_DATE_CONFIG = 'dddd, MMMM DD, YYYY';
export const CALENDAR_TABLE_BOOKING_DATE_CONFIG = 'DD/MM/yyyy hh:mm A';
export const NOTES_DATE_CONFIG = 'dddd , DD MMMM';
export const CALENDAR_ITEM_ATTRIBUTE_DATE_CONFIG = 'DD/MM/YYYY hh'
export const PROFILE_BOOKING_DATE_CONFIG = 'D/MM/YY [at] h:mm A';
export const PROFILE_HOLIDAY_DATE_CONFIG = '[On holiday since] DD MMM, yyyy';

export const TODO_MESSAGE_DATE_CONFIG = ({ passedDays }) => {
  return passedDays > 0 ? 'D/MM/yyyy hh:mm A' : 'hh:mm A';
}

export const MAIL_ITEM_DATE_CONFIG = ({ passedDays }) => {
  return passedDays > 0 ? 'D MMMM yyyy' : 'hh:mm A';
}

export const BOOKING_CALENDAR_LABEL_DATE_CONFIG = ({ hour12 }) => {
  return hour12 ? 'h A' : 'HH:[00]';
}

export const CALENDAR_WEEK_VIEW_DATE_CONFIG = ({ hour12 }) => {
  return hour12 ? '[@]hh:mmA' : '[@]HH:mm';
}

export const CONTACT_ITEM_CONFIG = ({ passedDays, isPreviousYear }) => {
  if (isPreviousYear) return 'DD.MM.YY';
  if (passedDays > 1) return 'MMM D';
  if (passedDays === 1) return '[Ystd]';
  return 'hh:mm A';
}

export const DATE_INDICATOR_CONFIG = ({ passedDays, isPreviousYear }) => {
  if (isPreviousYear) return 'MMMM DD, yyyy';
  if (passedDays > 1) return 'dddd, MMMM DD';
  if (passedDays === 1) return '[Yesterday]';
  return '[Today]';
}

export const RECENT_CALL_DATE_CONFIG = ({ passedDays }) => {
  return passedDays > 0 ? 'MM/DD' : 'hh:mm A';
}

export const MISSED_CALL_DATE_CONFIG = ({ passedDays }) => {
  return passedDays > 0 ? 'DD/MM/YY hh:mm A' : 'hh:mm A';
}

export const NOTIFICATION_DATE_CONFIG = ({ passedDays }) => {
  if (passedDays === 0) return '[Today] hh:mm A';
  if (passedDays === 1) return '[Yesterday] hh:mm A';
  if (passedDays < 7) return 'dddd hh:mm A';
  return 'yyyy.MM.DD hh:mm A';
}

export const REQUEST_DATE_CONFIG = ({ passedDays }) => {
  if (passedDays >= 7) return 'yyyy.MM.DD, hh:mm A';
  if (passedDays < 7) return 'dddd hh:mm, A';
  if (passedDays === 1) return '[Yesterday], hh:mm A';
  return '[Today], hh:mm A';
}

export const CALENDAR_CHOOSE_DATE_CONFIG = ({ isRangeDate }) => {
  return isRangeDate ? 'MMM DD, MM/yyyy' : 'dddd, DD/MM/YYYY' // [ ]: fix range date format
}

export const TWO_DAYS_CALENDAR_DATE_CONFIG = ({ passedDays }) => {
  if (passedDays === -1) return '[Tomorrow] ddd D/MM';
  if (passedDays === 0) return '[Today] ddd D/MM';
	return 'ddd D/MM';
}

export const BOOKING_ITEM_BY_FORM_CONFIG = ({ passedDays }) => {
  if (passedDays === -1) return '[Booking: Tommorow], hh:mm A';
  if (passedDays === 0) return '[Booking: Today], hh:mm A';
  return '[Booking:] DD/MM/YY, hh:mm A';
}
