import React, { createContext, useContext } from 'react';
import classNames from 'classnames';

import { classModifier } from 'utils';
import { BOOKING_FILTERS } from 'components/FilteredBookings/config/constants';

import './BookingFilters.scss';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import Button from 'components/UI/Button/Button';
import { BUTTON_THEMES, SIZES } from 'config/constants';

const BookingFiltersContext = createContext(null);

export const BookingFilters = ({ getterProps, className, children }) => (
  <BookingFiltersContext.Provider value={{ getterProps }}>
    <div className={classNames(className, "booking-filters")}>
      {children}
    </div>
  </BookingFiltersContext.Provider>
);

Object.entries(BOOKING_FILTERS).forEach(([filterName, filter]) => {
  const Component = ({ className, getterProps, tooltip, children = null }) => {
    const contextProps = useContext(BookingFiltersContext);
    const { active, disabled, onClick, iconSize }
      = (contextProps?.getterProps || getterProps)({ name: filter.label });
    const buttonContent = filter.image || children;

    return (
      <Tooltip text={tooltip || filter.label}>
        <Button
          type="button"
          theme={BUTTON_THEMES.FILTER}
          active={active}
          disabled={disabled}
          onClick={onClick}
          icon={buttonContent}
          iconSize={iconSize}
        />
      </Tooltip>
    )
  }

  BookingFilters[filterName] = Component;
})
