import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';

import ICONS from 'assets/icons';
import { GirlsMapModalContext } from '../../GirlsMapModal';
import { MAP_GIRL_FILTERS, updateFilter, updateFindedGirlId, updateGirlCoordinateWithExtraInformation } from 'redux/ducks/map';
import { selectIsMapFilterSelected } from 'redux/selectors/selectors';
import { classModifier } from 'utils';

import QuickBookingGirlsListItemByDiva from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';


const FindEscortButton = () => {
  const {
    girlsData,
    filteredGirlIdsByFilters: { [MAP_GIRL_FILTERS.FINDED_ESCORT]: findedGirlId }
  } = useSelector((state) => state.map);

  const isFindedGirlFilterEnabled = useSelector((state) => selectIsMapFilterSelected(state, MAP_GIRL_FILTERS.FINDED_ESCORT));

  const { findGirlMarkerOnMap } = useContext(GirlsMapModalContext);
  
  const dispatch = useDispatch();

  const { profile: findedProfile } = girlsData[findedGirlId] || {};
  
  const handleFindByName = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      onChoose: (profile) => {
        dispatch(updateGirlCoordinateWithExtraInformation(profile.contact_uid, { profile }))
        dispatch(updateFindedGirlId(profile.contact_uid));
        dispatch(closeModal(MODAL_TYPES.searchList));
      }
    }))
  }
  
  const handleClick = () => {
    if (isFindedGirlFilterEnabled) {
      dispatch(updateFilter(MAP_GIRL_FILTERS.FINDED_ESCORT));
      dispatch(updateFindedGirlId(null));
    } else {
      handleFindByName()
    }
  }

  useEffect(() => {
    if (!findedProfile) return;

    findGirlMarkerOnMap(findedProfile)
      .then(() => {
        dispatch(updateFilter(MAP_GIRL_FILTERS.FINDED_ESCORT));
      })
      .catch((girl) => {
        dispatch(updateFindedGirlId(null));
        console.error(`${girl.fn} not found`);
      })
  }, [findedGirlId])

  return (
    <button
      className={classModifier("girls-map__toggle-btn", [isFindedGirlFilterEnabled && 'active'])}
      onClick={handleClick}
    >
      Find Escort By Name
      <ICONS.venus className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default FindEscortButton;
