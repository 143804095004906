import React from 'react';
import classNames from 'classnames';

import { SELECT_THEMES } from 'config/constants';

import Select from 'components/UI/Select/Select';
import Menu from 'components/UI/Menu/Menu';



const ChatListFilter = (props) => {
  const defaultFilter = props.filters.find((filter) => filter.name === props.defaultFilter)
    || props.filters.find((filter) => filter.name.includes('All'));

  const onFilterChange = (filter) => {
    if (props.isQuery) {
      props.setQuery('');
    }
    filter.action();
    props.toggleOpenned?.();
  }

  return (
    <div
      className={classNames(props.className, "sidebar-filter-select")}
      ref={props.selectorRef}
    >
      <Select
        type={SELECT_THEMES.NONE}
        options={props.filters}
        getOptionValue={option => option.name}
        getOptionLabel={option => option.name}
        value={{ name: defaultFilter.name }}
        onChange={onFilterChange}
        isSearchable={false}
        menuPlacement="auto"
        components={{ Menu }}
      />
    </div>
  )
}

export default React.memo(ChatListFilter);
