import React, { memo, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import ICONS from 'assets/icons';
import { ADR_BOOK_CONTACT_TYPE_FILTERS, CONTACT_TYPES, INTERACTION_TYPES } from 'config/constants';
import { classModifier, getContactAvatar, getHighLightedData } from 'utils';
import { selectContactById } from 'redux/selectors/selectors';
import { updateActiveAdrBookContactId } from 'redux/ducks/addressBook';
import { getContactsById } from 'redux/ducks/contacts';

import "./AdrBookItem.scss";
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const AdrBookItem = ({ style = { height: 0 } , ...props }) => {
  const { 
    contact, 
    isActiveSelect, 
    onAddSelectContact, 
    isSelectMode, 
    selectedContactsList = [], 
    dataPending, 
    isNewContactCreation 
  } = props;

  const [updatedSearch, setUpdatedSearch] = useState(props.search);
  const [highlightRef, setHighlightRef] = useState(null);

  useEffect(() => {
    !props.contactsPending && setUpdatedSearch(props.search);
  }, [props.contactsPending]);

  const {
    visible,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef
  } = usePopperTooltip({
    offset: [0, 0],
    trigger: 'hover',
    placement: 'auto-start',
  });

  const [
    highLightedFnText, 
    highLightedContent
  ] = useMemo(() => getHighLightedData(contact, props.search, props.searchFilters), 
    [
      contact,
      updatedSearch,
      props.searchFilters
    ]
  );

  const contactUrls = contact.minimizedUrls || contact.urls;
  const isNeedSearchingAllContactsButton =
    props.search &&
    props.lastIndex === props.index &&
    props.contactType !== ADR_BOOK_CONTACT_TYPE_FILTERS.ALL &&
    !isActiveSelect;

  const isSelectedContact = selectedContactsList.map(item => item.id).includes(contact.id);
  const isCommunicationViaBot = [
      INTERACTION_TYPES.INCOMING_MSG_TELEGRAM, 
      INTERACTION_TYPES.OUTGOING_MSG_TELEGRAM,
      INTERACTION_TYPES.INCOMING_NIGHT_BOT_MESSAGE, 
      INTERACTION_TYPES.OUTGOING_NIGHT_BOT_MESSAGE
    ].includes(contact.interactionObject?.type);

  const isDinstarMsg = [
      INTERACTION_TYPES.INCOMING_MSG_DINSTAR, 
      INTERACTION_TYPES.OUTGOING_MSG_DINSTAR
    ].includes(contact.interactionObject?.type);

  const isMailMsg = [
      INTERACTION_TYPES.INCOMING_EMAIL
    ].includes(contact.interactionObject?.type);

  const isWhatsAppMsg = [
      INTERACTION_TYPES.INCOMING_MSG_WHATSAPP,
      INTERACTION_TYPES.OUTGOING_MSG_WHATSAPP,
    ].includes(contact.interactionObject?.type);


  const isTelegramMsg = [
      INTERACTION_TYPES.TELEGRAM_GROUP_MESSAGE,
      INTERACTION_TYPES.INCOMING_PRIVATE_MSG_TELEGRAM,
      INTERACTION_TYPES.OUTGOING_PRIVATE_MSG_TELEGRAM,
      INTERACTION_TYPES.MASTER_TASK_TELEGRAM_GROUP,
    ].includes(contact.interactionObject?.type);

  const isAppleMsg = [
      INTERACTION_TYPES.INCOMING_MSG_IPHONE_IMESSAGE,
      INTERACTION_TYPES.OUTGOING_MSG_IPHONE_IMESSAGE,
      INTERACTION_TYPES.INCOMING_MSG_IPHONE_SMS,
      INTERACTION_TYPES.OUTGOING_MSG_IPHONE_SMS,
    ].includes(contact.interactionObject?.type);

  if (contact.isTempStart) {
    return (
      <div 
        style={{
          ...style,
          bottom: style.bottom ? style.bottom + 8 : 8,
          height: style.height - 8
        }}
        className={classModifier('adr-book-item', 'divider-for-temporary')}
      >
        <span className='adr-book-item__divider-temp-text'>
          Temporary contacts
        </span>
      </div>
    )
  }

  const handleClick = () => {
    if(dataPending || isNewContactCreation) return;

    if(isSelectMode && !isSelectedContact) {
      onAddSelectContact && onAddSelectContact(contact);
      return; 
    }

    if (!props.isActive) {
      props.getContactsById(contact.id, contact.type)
        .then((contact) => {
          props.updateActiveAdrBookContactId(contact.id);
        });
    }
  }

  const isVisiblePopup = visible 
    && props.search 
    && Array.isArray(highLightedContent) 
    && highlightRef?.getBoundingClientRect().width > 290;

  return (
    <div
      style={{
        ...style,
        bottom: style.bottom ? style.bottom + 8 : 8,
        height: style.height ? style.height - 8 : 'auto'
      }}
      className={classModifier('adr-book-item', [
        isSelectMode 
          ? (isActiveSelect || (!isActiveSelect & isSelectedContact)) && "active" 
          : props.isActive && "active",
        (dataPending || isNewContactCreation) && 'disabled'
      ])}
      onClick={handleClick}
    >
      {/* {contact.type === 2 && <div className={classModifier("adr-book-item__contact-status", "active")}></div>} */}

      <div className={classModifier("adr-book-item__img-wrap", [
        contact.availability && contact.availability === 'off today'
          ? 'off-today'
          : contact.availability,
        ])}
      >
        <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className="adr-book-item__img" />

        {(contact.default_channel === 'telegram' || isTelegramMsg) &&
          <ICONS.telegram className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }

        {contact?.has_telegram_groups &&
          <ICONS.users className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }

        {isCommunicationViaBot &&
          <ICONS.robot className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }

        {isDinstarMsg &&
          <ICONS.comments className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }

        {isWhatsAppMsg &&
          <ICONS.whatsapp className={classModifier("adr-book-item__ava-icon", "whatsapp")} />
        }

        {isMailMsg &&
          <ICONS.mail className={classModifier("adr-book-item__ava-icon", "mail")} />
        }

        {isAppleMsg &&
          <ICONS.apple className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }
        {/* {contact.type === 2 && contact.availability && <ICONS.arrowUpdate width="10px" height="10px" />} */}

      </div>

      <div className="adr-book-item__info">
        <span className="adr-book-item__name">{highLightedFnText}</span>
        <span className="adr-book-item__content" ref={ref => {
          setTriggerRef(ref);
          setHighlightRef(ref);
        }}>
            {highLightedContent}
        </span>
      </div>

      {isVisiblePopup &&
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'adr-book-item__content-popup' })}
        > 
          {highLightedContent?.filter(highlight => highlight !== '/')}
        </div>
      }

      {+contact.type === +CONTACT_TYPES.GIRL && !!contactUrls?.length &&
        <div className="adr-book-item__link-wrap">
          <a
            href={contactUrls[0].url}
            target="_blank"
            className="adr-book-item__external-link"
          >
            <ICONS.externalLinkSquare width="14px" height="14px" />
          </a>
        </div>
      }
      {isNeedSearchingAllContactsButton &&
        <button
          onClick={(e) => {
            e.stopPropagation();

            props.changeContactType(ADR_BOOK_CONTACT_TYPE_FILTERS.ALL, props.userId);
          }}
          className="adr-book-list__global-search-btn adr-book-list__global-search-btn--modifier"
        >
          Try Searching All Contacts
        </button>
      }
    </div>
  );
}

const mapStateToProps = (state, { id }) => ({
  userId: state.user.id,
  contact: selectContactById(state, id),
  isActive: state.addressBook.active === id,
  search: state.addressBook.search,
  isNewContactCreation: state.addressBook.isNewContactCreation,
  contactsPending: state.addressBook.contactsPending,
  searchFilters: state.addressBook.searchFilters
});

const mapDispatchToProps = {
  updateActiveAdrBookContactId,
  getContactsById
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(AdrBookItem));
