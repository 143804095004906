import React, { Component } from 'react';
import { connect } from 'react-redux';

import API from 'api/api';
import LS from 'utils/localStorageAPI';
import { updateRoom } from 'redux/ducks/rooms';
import { CHAT_SOURCES, CHAT_TYPES } from 'config/constants';
import { playMedia, openModal } from 'redux/ducks/activeWindows';
import { removeContactTab, updateActiveContact } from 'redux/ducks/clientChats';
import { selectUserTimezone } from 'redux/selectors/selectors';
import {
  sendMessageToChat,
  updateChatTimeline,
  getChatTimeline,
  updateActiveChat,
  markChatAsRead,
  updateSharedContact,
  getChatMessageContext,
  getChatTimelineMedia,
  cleanChatTimelineMedia,
  updateChatTimelineMedia,
  pinMsg,
  unpinMsg,
  fixRoomTab,
  updateChatMessageSearch,
  stopChatMessageSearch,
  searchMessageInChat,
  updateChatMessageContext,
  createNewRoom,
  onRemoveChatTab,
  GENERAL_ROOM_ID
} from 'redux/ducks/roomChats';

import './OperatorsChat.scss';
import ICONS from 'assets/icons';
import ChatTimeline from '../../components/ChatTimeline/ChatTimeline1';
import ChatMessageInput from '../../components/ChatMessageInput/ChatMessageInput';
import AttachmentMessage from '../../components/ChatAttachments/AttachmentMessage';
import AttachmentContact from '../../components/ChatAttachments/AttachmentContact';
import AttachmentEditedMessage from '../../components/ChatAttachments/AttachmentEditedMessage';
import AttachmentImages from "components/AttachmentImagesAndVideos/AttachmentImagesAndVideos";
import ChatAttachments from '../../components/ChatAttachments/ChatAttachments';
import DropMediaContainer from 'components/DropMediaContainer/DropMediaContainer';
import ChatSources from '../../components/ChatSources/ChatSources';
import OperatorsChatRooms from './components/OperatorsChatRooms/OperatorsChatRooms';
import RoomCreationForm from './components/RoomCreationForm/RoomCreationForm';

class OperatorsChat extends Component {
  state = {
    sharedMsg: null,
    editedMsg: null,
    voiceMsg: null,
    isShowChatCreator: false,
    isHideNewChat: false,
    images: null,
    isNewRoom: false,
    editingRoom: null,
  };

  componentDidMount() {
    if (this.props.active && !this.props.timeline.length) {
      this.props.getChatTimeline(this.props.active, this.props.userId, this.props.userTimezone);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeChatSource } = this.props;

    if (prevProps.active !== this.props.active) {
      if (this.props.active !== null) {
        this.props.getChatTimeline(this.props.active, this.props.userId, this.props.userTimezone);
      }
      if (this.state.voiceMsg) {
        this.updateVoiceMsg();
      }
      if (this.state.editedMsg) {
        this.cleanEditedMsg();
      }
      if (this.state.images) {
        this.cleanImages();
      }
    }

    if (activeChatSource !== prevProps.activeChatSource) {
      if (activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.getChatTimelineMedia(this.props.activeRecipient, this.props.userId, this.props.userTimezone);
      }

      else if (prevProps.activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.cleanChatTimelineMedia();
      }
    }
  }

  markChatAsRead = () => {
    //REVIEW: without setTimeout active recipient doesnt change
    if (this.props.activeRecipient?.unreadCount) {
      this.props.markChatAsRead(this.props.activeRecipient.id);
    }
  }

  shareMsg = (msg) => {
    this.setState({ sharedMsg: msg });
  }

  editMsg = (msg) => {
    this.setState({ editedMsg: msg });
  }

  updateVoiceMsg = (blob, duration) => {
    if (blob && duration) {                 // add voiceMsg
      this.setState({
        voiceMsg: {
          blob,
          url: URL.createObjectURL(blob),
          duration
        }
      });
    }
    else if (this.state.voiceMsg) {         // clean voiceMsg
      this.setState({ voiceMsg: null });
    }
  }

  updateImages = (images) => {
    const imagesWithUrl = images.map(file => Object.assign(file, {
      url: URL.createObjectURL(file)
    }));

    this.setState({ images: imagesWithUrl });
  }
  cleanImages = () => {
    this.state.images.forEach(file => URL.revokeObjectURL(file.url));
    this.setState({ images: null });
  }


  cleanSharedMsg = () => this.setState({ sharedMsg: null });

  cleanEditedMsg = () => this.setState({ editedMsg: null });

  timeline = () => {
    if (this.props.contextMsgId) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.props.search) {
      return this.props.auxiliaryTimeline;
    }
    else if (this.props.activeChatSource !== CHAT_SOURCES.MSGS) {
      return this.props.auxiliaryTimeline;
    }
    return this.props.timeline;
  }

  updateTimeline = () => {
    if (this.props.contextMsgId) {
      return this.updateMsgContext;
    }
    else if (this.props.search) {
      return this.updateChatMessageSearch;
    }
    else if (this.props.activeChatSource === CHAT_SOURCES.MEDIA) {
      return this.updateChatTimelineMedia;
    }
    return this.updateChatTimeline;
  }

  updateChatTimeline = (chat, page, loadDirection) =>
    this.props.updateChatTimeline(chat, page, loadDirection, this.props.userId, this.props.userTimezone);

  startSearch = (query) =>
    this.props.searchMessageInChat(query, this.props.activeRecipient.id, this.props.userId, this.props.userTimezone);

  updateChatMessageSearch = (activeRecipient, page, loadDirection) =>
    this.props.updateChatMessageSearch(activeRecipient, page, loadDirection, this.props.search, this.props.userId, this.props.userTimezone);

  getChatMsgContext = (msgId, contact) =>
    this.props.getChatMessageContext(msgId, this.props.active, contact, this.props.userId, this.props.search, this.props.userTimezone);

  updateMsgContext = (activeRecipient, page, loadDirection) =>
    this.props.updateChatMessageContext(this.props.contextMsgId, page, loadDirection, this.props.userId, activeRecipient.id, this.props.userTimezone);

  updateChatTimelineMedia = (activeRecipient, page, loadDirection) =>
    this.props.updateChatTimelineMedia(activeRecipient, page, loadDirection, this.props.userId, this.props.userTimezone);

  handleDropMediaSubmit = (images) => {
    // return API.sendMessageToChat({ files: images }, this.props.activeRecipient.id);
    this.updateImages(images);
  }

  handleDeleteRoom = () => {
    if (window.confirm(`Remove room ${this.props.activeRecipient.chatTitle}?`)) {
      API.removeRoomForAll(this.props.activeRecipient.id)
        .then(() => {
          // const recentTabs = LS.getItem('roomChatsRecentTabs', user.id);
          // if (recentTabs) {
          //   const relevantTabs = recentTabs.all.filter(tab => tab !== activeRecipient.id);

          //   dispatch(updateRecentTabs(null, CHAT_TYPES.ROOM, relevantTabs));
          // }
        })
        .catch((err) => {
          alert("Only the room administrator can delete");
        });
    }
  }

  changeRecipientAudioStatus = (action, recipientId) =>
    API.setRoomAudioStatus(action, recipientId)
      .then(({ data }) => {
        this.props.updateRoom(data.chat);
      })
      .catch(console.error);

  render() {
    const {
      activeRecipient,
      search,
      user,
      userId,
      userTimezone,
      userHour12,
      operatorsEntities,
    } = this.props;

    const isMainTimelineOpen = this.props.search ||
      this.props.activeChatSource !== CHAT_SOURCES.MSGS ||
      this.props.contextMsgId
      ? false
      : true;

    const timelineLowerLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryLowerLoadedPage
      : this.props.timelineLowerLoadedPage;

    const timelinePageCount = !isMainTimelineOpen
      ? this.props.auxiliaryPageCount
      : this.props.timelinePageCount;

    const timelineHigherLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryHigherLoadedPage
      : this.props.timelineCurrentPage;

    const timelineCurrentPage = !isMainTimelineOpen
      ? this.props.auxiliaryCurrentPage
      : this.props.timelineCurrentPage;

    return (
      <div className="operators-chat" onClick={() => setTimeout(() => this.markChatAsRead(), 0)}>
        <div className="operators-chat__header">
          <div className="operators-chat__header-top">
            <div className="operators-chat__header-wrap">
              <h3 className="public-chat__title">
                All Operators ({activeRecipient.usersIds.length})
              </h3>
              {activeRecipient.id !== GENERAL_ROOM_ID &&
                <div className="operators-chat__change-btns">
                  <button
                    className="operators-chat__edit-room-btn"
                    onClick={() => this.setState({ editingRoom: activeRecipient })}
                  >
                    <ICONS.pencil className="operators-chat__edit-room-icon" />
                  </button>

                  <button
                    className="operators-chat__delete-btn"
                    onClick={this.handleDeleteRoom}
                  >
                    <ICONS.trash className="operators-chat__delete-icon" />
                  </button>
                </div>
              }
            </div>

            <button
              className="operators-chat__add-room-btn"
              onClick={() => this.setState({ isNewRoom: true })}
            >
              <ICONS.usersPlus className="operators-chat__add-room-icon" />
            </button>
          </div>

          <OperatorsChatRooms />
        </div>

        <ChatSources
          type={CHAT_TYPES.ROOM}
          activeRecipient={activeRecipient}
          stopMessageSearch={this.props.stopChatMessageSearch}
          startMessageSearch={this.startSearch}
          activeChatSource={this.props.activeChatSource}
          search={search}
        />

        <DropMediaContainer
          activeRecipient={activeRecipient}
          openModal={this.props.openModal}
          type={CHAT_TYPES.ROOM}
          // classNamePrefix="chat-dropzone"
          onSubmit={this.handleDropMediaSubmit}
        >
          {(getInputProps) => (
            <>
              <ChatTimeline
                activeRecipient={activeRecipient}
                type={CHAT_TYPES.ROOM}

                timelinePending={this.props.timelinePending}
                updatePending={this.props.updatePending}
                timeline={this.timeline()}
                timelineCurrentPage={timelineCurrentPage}
                timelinePageCount={timelinePageCount}
                timelineHigherLoadedPage={timelineHigherLoadedPage}
                timelineLowerLoadedPage={timelineLowerLoadedPage}
                newInteractionType={this.props.newInteractionType}
                isMainTimelineOpen={isMainTimelineOpen}
                updateContactTimeline={this.updateTimeline()}

                setActiveChatSource={this.setActiveChatSource}

                profileId={userId}
                userTimezone={userTimezone}
                userHour12={userHour12}

                // showModalImage={this.props.showModalImage} // not used
                search={search}
                contextMsgId={this.props.contextMsgId}
                getMessageContext={this.getChatMsgContext}
                updateActiveContact={this.props.updateActiveContact}
                publicChat
                playMedia={this.props.playMedia}

                pinnedMsg={this.props.pinnedMsg}
                pinMsg={this.props.pinMsg}
                unpinMsg={this.props.unpinMsg}

                shareMsg={this.shareMsg}
                editMsg={this.editMsg}
              />


              <ChatAttachments
                isShow={!!this.state.sharedMsg && !this.state.editedMsg}
                onClose={this.cleanSharedMsg} >
                <AttachmentMessage
                  showTitle
                  interaction={this.state.sharedMsg}
                  profileId={userId}
                />
              </ChatAttachments>

              <ChatAttachments
                isShow={!!this.props.sharedContactId && !this.state.editedMsg}
                onClose={() => this.props.updateSharedContact(null)} >
                <AttachmentContact
                  showTitle
                  contactId={this.props.sharedContactId} />
              </ChatAttachments>

              {/* <ChatAttachments
                isShow={this.state.voiceMsg && !this.state.voiceMsg.sendAtImmediately && !this.state.editedMsg}
                onClose={this.updateVoiceMsg} >
                <AttachmentVoiceMessage
                  showTitle
                  voiceMsg={this.state.voiceMsg} />
              </ChatAttachments> */}

              <ChatAttachments
                isShow={this.state.editedMsg}
                onClose={this.cleanEditedMsg} >
                <AttachmentEditedMessage
                  showTitle
                  interaction={this.state.editedMsg} />
              </ChatAttachments>

              <ChatAttachments
                isShow={!!this.state.images?.length}
                onClose={this.cleanImages} >
                <AttachmentImages
                  media={this.state.images} />
              </ChatAttachments>

              {this.props.active && !['girls', 'webmaster', 'new_chat', 'bookings', 'off_today', 'available'].includes(this.props.active) &&
                <ChatMessageInput
                  publicChat
                  typingStatus={this.props.typingStatus}
                  activeRecipient={activeRecipient}
                  shortcuts={this.props.shortcuts}
                  sendMessage={this.props.sendMessage}
                  userId={userId}
                  isAnyAttachments={!!this.state.sharedMsg || !!this.state.voiceMsg || !!this.state.editedMsg || !!this.props.sharedContactId}
                  sharedMsg={this.state.sharedMsg}
                  cleanSharedMsg={this.cleanSharedMsg}
                  editedMsg={this.state.editedMsg}
                  cleanEditedMsg={this.cleanEditedMsg}
                  sharedContactId={this.props.sharedContactId}
                  updateSharedContact={this.props.updateSharedContact}
                  voiceMsg={this.state.voiceMsg}
                  images={this.state.images}
                  cleanImages={this.cleanImages}
                  updateVoiceMsg={this.updateVoiceMsg}
                  fileInputProps={getInputProps}
                  fixTab={this.props.fixRoomTab}
                  unfixedTab={this.props.unfixedTab}
                  changeRecipientAudioStatus={this.changeRecipientAudioStatus}
                  type={CHAT_TYPES.ROOM}
                  openModal={this.props.openModal}
                />
              }
            </>
          )}
        </ DropMediaContainer >

        {(this.state.isNewRoom || this.state.editingRoom) &&
          <div className="operators-chat__new-room">
            <RoomCreationForm
              userId={this.props.userId}
              editingRoom={this.state.editingRoom}
              clearEditingRoom={() => this.setState({ editingRoom: null })}
              closeForm={() => this.setState({ isNewRoom: false })}
            />
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // active: state.roomChats.active,
  // activeRecipient: state.rooms.entities[state.roomChats.active],
  active: state.roomChats.activeRoomId,
  activeRecipient: state.rooms.entities[state.roomChats.activeRoomId],
  unfixedTab: state.roomChats.unfixedTab,

  timeline: state.roomChats.timeline,
  timelinePending: state.roomChats.timelinePending,
  updatePending: state.roomChats.updatePending,
  timelineCurrentPage: state.roomChats.timelineCurrentPage,
  timelinePageCount: state.roomChats.timelinePageCount,
  timelineLowerLoadedPage: state.roomChats.timelineLowerLoadedPage,
  newInteractionType: state.roomChats.newInteractionType,

  auxiliaryTimeline: state.roomChats.auxiliaryTimeline,
  auxiliaryLowerLoadedPage: state.roomChats.auxiliaryLowerLoadedPage,
  auxiliaryPageCount: state.roomChats.auxiliaryPageCount,
  auxiliaryHigherLoadedPage: state.roomChats.auxiliaryHigherLoadedPage,
  auxiliaryCurrentPage: state.roomChats.auxiliaryCurrentPage,

  sharedContactId: state.roomChats.sharedContactId,
  pinnedMsg: state.roomChats.pinnedMsg,

  search: state.roomChats.search,
  // searchData: state.roomChats.searchedMessages,
  // timelineMedia: state.roomChats.timelineMedia,
  // messageContext: state.roomChats.timelineMsgContext,

  contextMsgId: state.roomChats.contextMsgId,
  user: state.user,
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  shortcuts: state.msgTemplates.shortcuts,
  userHour12: state.user.hour12,
  operatorsEntities: state.operators.entities,

  typingStatus: state.typingOperators.chats[state.roomChats.active + '_3'],
  activeChatSource: state.operators.chatSource,
});
const mapDispatchToProps = {
  updateRoom,
  sendMessage: sendMessageToChat,
  updateChatTimeline,
  getChatTimeline,
  updateChatMessageSearch,
  updateActiveChat,
  removeContactTab,
  stopChatMessageSearch,
  searchMessageInChat,
  markChatAsRead,
  updateSharedContact,
  getChatMessageContext,
  updateChatMessageContext,
  getChatTimelineMedia,
  updateChatTimelineMedia,
  cleanChatTimelineMedia,
  updateActiveContact,
  pinMsg,
  unpinMsg,
  fixRoomTab,
  playMedia,
  createNewRoom,
  onRemoveChatTab,
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsChat);
