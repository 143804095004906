import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';

import { BUTTON_THEMES, GROUPED_BUTTONS_THEMES, ROUTES, SIZES } from "config/constants";

import ICONS from 'assets/icons';
import GroupedButtons from 'components/UI/GroupedButtons/GroupedButtons';

import "./Navbar.scss";
import { classModifier } from "utils";


const Navbar = (props) => {
  const {
    isBurgerMenu,
    isButtonsDisabledWhenPageOpenned
  } = props;

  const isSalesPageOpen = useSelector((state) => state.user.isSalesPageOpen);
  const isMailPageOpen = useSelector((state) => state.user.isMailPageOpen);
  const isAdrBookPageOpen = useSelector((state) => state.user.isAdrBookPageOpen);
  const isCalendarPageOpen = useSelector((state) => state.user.isCalendarPageOpen);

  const generateButtonProps = () => {
    const setOfProps = [
      { to: ROUTES.sales, icon: ICONS.puzzle, disabled: isSalesPageOpen },
      { to: ROUTES.adrBook, icon: ICONS.contactBook, disabled: isAdrBookPageOpen },
      { to: ROUTES.mail, icon: ICONS.mail, disabled: isMailPageOpen },
      { to: ROUTES.calendar, icon: ICONS.calendar, disabled: isCalendarPageOpen },
    ]

    return setOfProps.map((props) => ({
      ...props,
      target: "_blank",
      rel: "noreferrer",
      active: props.disabled,
      disabled: isButtonsDisabledWhenPageOpenned ? props.disabled : false,
    }))
  }

  const generalButtonProps = {
    iconSize: SIZES.S,
    theme: BUTTON_THEMES.NONE_DARK,
  }

  return (
    <GroupedButtons
      // className={classModifier("navbar", isBurgerMenu && 'is-dropdowned')}
      className={isBurgerMenu ? "navbar-dropdowned" : 'navbar'}
      theme={GROUPED_BUTTONS_THEMES.DIM}
      generateButtonProps={generateButtonProps}
      generalButtonProps={generalButtonProps}
      buttonComponent={NavLink}
      isDropdowned={isBurgerMenu}
    />
  )
}

export default Navbar;
