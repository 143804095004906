import { useDispatch } from 'react-redux'

import { openModal } from 'redux/ducks/activeWindows'

import Button from 'components/UI/Button/Button';
import ICONS from 'assets/icons';


const OpenGirlBookingCalendar = (props) => {
  const dispatch = useDispatch();

  const handleClick = () =>
    dispatch(openModal(MODAL_TYPES.bufferedGirlsBookingsModal, { girlId: profile.id }));

  return (
    <Button
      className={props.className}
      icon={ICONS.calendar}
      onClick={handleClick}
      title="Actual bookings"
      disabled={!props.profile}
      {...props}
    />
  )
}

export default OpenGirlBookingCalendar;
