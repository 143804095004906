import { CHILD_MODAL_ANIMATION_DURATION } from 'config/constants';
import flattenByProperty from 'utils/flattenByProperty';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';
export const CLOSE_CHILD_MODAL = 'CLOSE_CHILD_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';
export const REMOVE_ALL_MODALS = 'REMOVE_ALL_MODALS';
export const OPEN_CHILD_MODAL_START = 'OPEN_CHILD_MODAL_START';
export const OPEN_CHILD_MODAL_END = 'OPEN_CHILD_MODAL_END';	
export const CLOSE_CHILD_MODAL_START = 'CLOSE_CHILD_MODAL_START';
export const CLOSE_CHILD_MODAL_ACTIVE = 'CLOSE_CHILD_MODAL_ACTIVE';
export const CLOSE_CHILD_MODAL_END = 'CLOSE_CHILD_MODAL_END';

export const PLAY_MEDIA = 'PLAY_MEDIA';
export const STOP_MEDIA = 'STOP_MEDIA';

export const UPDATE_MODAL_PROPS = 'UPDATE_MODAL_PROPS';

export const MODAL_TYPES = {
  editContactForm: 'edit-contact-form',
  addContactForm: 'add-contact-form',
  userSettings: 'user-settings',
  profileRingtones: 'profile-ringtones',
  timePicker: 'time-picker',
  contactCard: 'contact-card',
  blockForm: 'block-form',
  transferedCall: 'transfered-call',
  voicemails: 'voicemails',
  incomingQueueCall: 'incoming-queue-call',
  conferenceFullMode: 'conference-full-mode',
  webrtcConferenceFullMode: 'webrtc-conference-full-mode',
  bookingsList: 'bookings-list',
  bookingsByDates: 'bookings-by-dates',
  chat: 'chat',
  profilesBuffer: 'profiles-buffer',
  profilesBooked: 'profiles-booked',
  profileReviews: 'profile-reviews',
  profileCarousel: 'profile-carousel',
  incomingCall: 'incoming-call',
  outgoingCall: 'outgoing-call',
  error: 'error',
  image: 'image',
  video: 'video',
  fullScreenCall: 'full-screen-call',
  awaySystem: 'away-system',
  mergeContacts: 'merge-contacts',
  mailingComponent: 'mailing-component',
  photoGallery: 'photo-gallery',
  contactsMergeList: 'contacts-merge-list',
  bookingExistWarning: 'booking-exist-warning',
  nightBotReport: 'night-bot-report',
  profilePricesEditing: 'profile-prices-editing',
  profileNotesList: 'profile-notes-list',
  mailContactSearch: 'mail-contact-search',
  notForContact: 'not-for-contact',
  call: 'call',
  feedback: 'feedback',
  searchList: 'search-list',
  salesFiltersEditor: 'edit-filters-modal',
  bookingForm: 'bookingForm',
  bookingEditForm: 'bookingEditForm',
  girlsMap: 'girlsMap',
  transferredOrAssistanceSession: 'transferredOrAssistanceSession',
  sendPhotoForm: 'sendPhotoForm',
  multiMessageModal: 'multiMessageModal',
  addNewTasksOrRemaindersModal: 'addNewTasksOrRemaindersModal',
  conflictingBookingsModal: 'conflictingBookingsModal',
  openAvatarEditor: 'openAvatarEditor',
  computerModal: 'computerModal',
  sendAttachmentMessageModal: 'sendAttachmentMessageModal',
  sendGirlsToClientModal: 'sendGirlsToClientModal',
  sendNavigationPathModal: 'sendNavigationPathModal',
  openCommModule: 'open-comm-module',
  bufferChatsModal: 'buffer-chats-modal',
  bufferedGirlsBookingsModal: 'bufferedGirlsBookingsModal',
  confirmOnLogoutModal: 'confirmOnLogoutModal',
}

export const openModal = (type, props) => dispatch => {
  dispatch({
    type: OPEN_MODAL,
    payload: {
      type,
      props,
    },
  })

  return new Promise((resolve) => 
    document.addEventListener('closeModal', (event) => {
      resolve(event.detail);
    })
  )
}

export const openChildModal = (type, props) => dispatch => {
  dispatch({
    type: OPEN_MODAL,
    payload: {
      type,
      props,
      child: true
    }
  })

  setTimeout(() => dispatch({ type: OPEN_CHILD_MODAL_START }), 0);

  setTimeout(
    () => dispatch({ type: OPEN_CHILD_MODAL_END }),
    CHILD_MODAL_ANIMATION_DURATION,
  );

  return new Promise((resolve) => 
    document.addEventListener('closeModal', (event) => {
      resolve(event.detail);
    })
  )
}

export const closeModal = (modalType = null, withDelay = true) => dispatch => {
  dispatch({
    type: CLOSE_MODAL,
    payload: modalType,
  });

  if (withDelay) {
    setTimeout(() => dispatch({
      type: REMOVE_MODAL,
      payload: modalType,
    }), 500);
  } else {
    dispatch({
      type: REMOVE_MODAL,
      payload: modalType,
    })
  }
}

export const closeAllModals = () => dispatch => {
  dispatch({
    type: CLOSE_ALL_MODALS,
  });

  setTimeout(() => dispatch({
    type: REMOVE_ALL_MODALS,
  }), 500);
};

export const closeChildModal = () => dispatch => {
  dispatch({ type: CLOSE_CHILD_MODAL_START });

  setTimeout(() => dispatch({ type: CLOSE_CHILD_MODAL_ACTIVE }), 0);
  
  setTimeout(
    () => {
      dispatch({ type: CLOSE_CHILD_MODAL_END });
      dispatch({ type: CLOSE_CHILD_MODAL })
    },
    CHILD_MODAL_ANIMATION_DURATION,
  );

}

export const updateModalProps = (props, modalType) => dispatch => {
  dispatch({
    type: UPDATE_MODAL_PROPS,
    payload: {
      props,
      modalType,
    }
  })
}

export const openTransferedCallModal = (call, audio) => (dispatch, getState) => {
  if (!call.id) {
    const media = getState().activeWindows.media;

    if (media && !media.paused) {
      media.pause();
      media.currentTime = 0;
    }
  }

  dispatch({
    type: OPEN_MODAL,
    payload: {
      type: MODAL_TYPES.transferedCall,
      props: { call },
      audio,
    }
  });
};

export const playMedia = (media) => dispatch => {
  dispatch({
    type: PLAY_MEDIA,
    payload: media,
  });
};

export const stopMedia = (media) => dispatch => {
  dispatch({
    type: STOP_MEDIA,
    payload: media,
  });
};

const initialState = {
  activeModals: [],
  modalsTransitionModes: {},
  commonModalProps: {},
  media: null,
  smsAudio: null,
  callAudio: null,
};

const getModalTypesOfChildModals = (state) => { // function for getting the types of modals that are closing and opening
  const lastActiveModalIndex = state.activeModals.length - 1;
  const lastActiveModal = state.activeModals[lastActiveModalIndex];
  const connectedModals = flattenByProperty(lastActiveModal, (modal) => modal.props?.child);

  const connectedModalsLength = connectedModals.length;
  const lastIndex = connectedModalsLength - 1;
  const penaltimateIndex = lastIndex - 1;

  const getPathForChildModalsType = (index) => {
    if (index === 0) {
      return `${lastActiveModalIndex}.type`;
    }

    return `${lastActiveModalIndex}.${Array(index).fill('props.child').join('.')}.type`;
  }

  const previousModalType = _.get(state.activeModals, getPathForChildModalsType(penaltimateIndex));
  const lastModalType = _.get(state.activeModals, getPathForChildModalsType(lastIndex));

  return [previousModalType, lastModalType];
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL: {
      const { type, props, audio, child } = payload;
      const activeModalWithCurrentType = state.activeModals.find(modal => modal.type === type);

      if (activeModalWithCurrentType && JSON.stringify(activeModalWithCurrentType.props) === JSON.stringify(props)) {
        return state;
      }
      const newModal = {
        type,
        props,
        isShow: true,
        isCloseOutside: true,
        isCloseOnEscape: true,
        backgroundClassModifier: null,
      };

      if (type === MODAL_TYPES.incomingCall) {
        newModal.isCloseOutside = false;
      }

      if ([MODAL_TYPES.photoGallery, MODAL_TYPES.profileCarousel].includes(type)) {
        newModal.backgroundClassModifier = 'gallery';
      }

      if (type === MODAL_TYPES.profilesBuffer) {
        newModal.backgroundClassModifier = 'profile-buffer';
      }

      if (type === MODAL_TYPES.girlsMap) {
        newModal.backgroundClassModifier = 'girls-map';
      }

      if (type === MODAL_TYPES.transferredOrAssistanceSession) {
        newModal.backgroundClassModifier = 'transferred-or-assistance-session';
      }

      if (type === MODAL_TYPES.userSettings) {
        newModal.isCloseOutside = false;
        newModal.isCloseOnEscape = false;
      }

      if (type === MODAL_TYPES.computerModal) {
        newModal.isCloseOutside = false;
        newModal.isCloseOnEscape = false;
      }

      if (type === MODAL_TYPES.openCommModule) {
        newModal.isCloseOutside = false;
        newModal.isCloseOnEscape = false;
      }

      let updatedActiveModals = [ ...state.activeModals ];
      const updatedTransitionModes = { ...state.modalsTransitionModes };

      if (child) {
        updatedTransitionModes[newModal.type] = 'enter';
        updatedActiveModals.at(-1).props.child = newModal;
        updatedTransitionModes[updatedActiveModals.at(-1).type] = 'exit';
      } else {
        updatedActiveModals = [...state.activeModals, newModal];
      }

      const commonModalProps = payload.props?.commonProps || state.commonModalProps;

      return {
        ...state,
        activeModals: updatedActiveModals,
        modalsTransitionModes: updatedTransitionModes,
        media: audio || state.media,
        commonModalProps,
      }
    }

    case OPEN_CHILD_MODAL_START: {
      const updatedTransitionModes = { ...state.modalsTransitionModes };
      const [previousModalType, lastModalType] = getModalTypesOfChildModals(state);
      updatedTransitionModes[previousModalType] = 'exit-active';
      updatedTransitionModes[lastModalType] = 'enter-active';

      return {
        ...state,
        modalsTransitionModes: updatedTransitionModes
      }
    }

    case OPEN_CHILD_MODAL_END: {
      const updatedTransitionModes = { ...state.modalsTransitionModes };
      const [previousModalType, lastModalType] = getModalTypesOfChildModals(state);
      updatedTransitionModes[previousModalType] = 'exit-done';
      updatedTransitionModes[lastModalType] = 'enter-done';

      return {
        ...state,
        modalsTransitionModes: updatedTransitionModes,
      }
    }

    case CLOSE_CHILD_MODAL_START: {
      const updatedTransitionModes = { ...state.modalsTransitionModes };
      const [previousModalType, lastModalType] = getModalTypesOfChildModals(state);
      updatedTransitionModes[previousModalType] = 'enter';
      updatedTransitionModes[lastModalType] = 'exit';

      return {
        ...state,
        modalsTransitionModes: updatedTransitionModes
      }
    }

    case CLOSE_CHILD_MODAL_ACTIVE: {
      const updatedTransitionModes = { ...state.modalsTransitionModes };
      const [previousModalType, lastModalType] = getModalTypesOfChildModals(state);
      updatedTransitionModes[previousModalType] = 'enter-active';
      updatedTransitionModes[lastModalType] = 'exit-active';

      return {
        ...state,
        modalsTransitionModes: updatedTransitionModes
      }
    }

    case CLOSE_CHILD_MODAL_END: {
      const updatedTransitionModes = { ...state.modalsTransitionModes };
      const [previousModalType, lastModalType] = getModalTypesOfChildModals(state);
      updatedTransitionModes[previousModalType] = 'enter-done';
      updatedTransitionModes[lastModalType] = 'exit-done';

      return {
        ...state,
        modalsTransitionModes: updatedTransitionModes,
      }
      
    }

    case CLOSE_MODAL: {
      const updatedActiveModals = [...state.activeModals];

      const lastIndex = updatedActiveModals.length - 1;
      const removedIndex = payload
        ? state.activeModals.findIndex(modal => modal.type === payload)
        : lastIndex;

      if (removedIndex === -1) {
        return state;
      }
      const removedModal = updatedActiveModals[removedIndex];

      updatedActiveModals[removedIndex] = {
        ...removedModal,
        isShow: false,
      }

      return {
        ...state,
        activeModals: updatedActiveModals,
        modalsTransitionModes: {}
      }
    }

    case CLOSE_ALL_MODALS: {
      const updatedActiveModals = [...state.activeModals].map((modal) => ({
        ...modal,
        isShow: false,
      }));

      return {
        ...state,
        activeModals: updatedActiveModals,
      };
    }

    case CLOSE_CHILD_MODAL: {
      const updatedActiveModals = [...state.activeModals];
      const lastIndex = updatedActiveModals.length - 1;
      delete updatedActiveModals[lastIndex].props.child;

      return {
        ...state,
        activeModals: updatedActiveModals,
      }
    }

    case REMOVE_MODAL: {
      const updatedActiveModals = [...state.activeModals];

      const lastIndex = updatedActiveModals.length - 1;
      const removedIndex = payload
        ? state.activeModals.findIndex(modal => modal.type === payload)
        : lastIndex;

      if (removedIndex === -1) {
        return state;
      }
      updatedActiveModals.splice(removedIndex, 1);

      return {
        ...state,
        activeModals: updatedActiveModals,
      }
    }

    case REMOVE_ALL_MODALS: {
      return {
        ...state,
        activeModals: [],
      };
    }

    case PLAY_MEDIA: {
      if (state.media && state.media === payload) {
        return state;
      }
      else if (state.media && state.media !== payload) {
        state.media.pause();
        state.media.currentTime = 0;
      }

      return {
        ...state,
        media: payload,
      };
    }
    case STOP_MEDIA: {
      if (state.media) {
        state.media.pause();
        state.media.currentTime = 0;
      }

      return state;
    }
    case UPDATE_MODAL_PROPS: {
      const updatedActiveModals = [...state.activeModals];

      const updatedIndex = updatedActiveModals.findIndex(modal => modal.type === payload.modalType);


      if (updatedIndex === -1) {
        return state;
      }

      updatedActiveModals[updatedIndex] = {
        ...updatedActiveModals[updatedIndex],
        props: payload.props,
      }

      return {
        ...state,
        activeModals: [...updatedActiveModals]
      };
    }

    default:
      return state;
  }
};
