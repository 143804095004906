import React, { memo, useCallback, useState } from 'react'
import { FieldArray } from 'react-final-form-arrays';

import { classModifier } from '../../../utils';
import ICONS from 'assets/icons';

import NicknameTelegramFieldItem from './NicknameTelegramFieldItem';

const MAX_FIELDS_LIMIT = 10;

const NicknameTelegramField = ({
  classPrefix, 
  serverError,
  setServerError, 
  isNewContactCreation = true,
  saveContact,
  values,
  isInAdrBook
}) => {

  const addNewField = (fields) => {
    if (fields.length >= MAX_FIELDS_LIMIT) return;

    fields.push({});
  }

  return (
    <FieldArray name="telegram_nicknames">
      {({ fields, meta: fieldArrayMeta }) => (
        <div className={`${classPrefix}__telegram-nicknames`}>
          {fields.length <= MAX_FIELDS_LIMIT && (
            <h3 className={classModifier("adr-book-contact-form__field-title", "telegram")}>
              <button
                type="button"
                onClick={() => addNewField(fields)}
                className={classModifier(`${classPrefix}__add-field-btn`, "telegram-add")}
              >
                <div className={`${classPrefix}__btn-add-icon-wrap`}>
                  <ICONS.plusCircle className={`${classPrefix}__btn-add-icon`}/>
                </div>
                Telegram
              </button>
            </h3>
          )}

          {fields.length > 0 &&
            <div className={classModifier(`${classPrefix}__group`, [
              'telegram-nickname',
              !isNewContactCreation && 
                fieldArrayMeta.initial?.some((value, idx) => fields.value[0]?.id && !fields.value[idx]?.id) && 
                'edited'
            ])}>
              {fields.map((nickname, index) => (
                <NicknameTelegramFieldItem 
                  nickname={nickname}
                  key={index}
                  index={index}
                  values={values}
                  fields={fields}
                  serverError={serverError}
                  saveContact={saveContact}
                  isInAdrBook={isInAdrBook}
                  classPrefix={classPrefix}
                  fieldArrayMeta={fieldArrayMeta} 
                  setServerError={setServerError}
                  isNewContactCreation={isNewContactCreation}
                />
              ))}
            </div>
          }
        </div>
      )
      }
    </FieldArray>
  )
}

export default memo(NicknameTelegramField);