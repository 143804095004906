import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import {
  LIST_TYPES,
  COMPOSE_PENDING_TYPES,
  setComposeDraftId,
  setComposePending,
  deleteMessageDraftRequest,
  changeDefaultMailForUserRequest,
} from 'redux/ducks/mail';
import ICONS from 'assets/icons';

import './MailHeader.scss';
import MailHeaderComposeActions from './components/MailHeaderComposeActions';
import MailHeaderListActions from './components/MailHeaderListActions';
import Spinner from 'components/UI/Spinner/Spinner';
import MailboxUsers from './components/MailboxUsers';
import Navbar from 'containers/Header/components/Navbar/Navbar';

const mailListHeaderRoutes = Object.values(LIST_TYPES).map((type) => `/${type}`);

const MailHeader = (props) => {
  const {
    draftId,
    draftStatus,
    composePending,
    setComposePending,
    setComposeDraftId,
    deleteMessageDraftRequest,
    availableMails,
    changeDefaultMailForUserRequest,
    defaultModeOfOperation,
  } = props;

  const [mailsOption, setMailsOption] = useState([])

  useEffect(() => {
    const modeOfOperationList = [
      defaultModeOfOperation,
    ];

    if (availableMails?.length) {
      const foundMode = availableMails.find(a => !a.is_default_type);
      
      if(foundMode) {
        modeOfOperationList.push(foundMode);
      }
    };

    if(defaultModeOfOperation) {
      setMailsOption(modeOfOperationList?.map((mail) => ({
        value: mail.id,
        label: mail.type,
        is_default: mail.is_default_type,
      })));
    };
  }, [availableMails, defaultModeOfOperation]);

  const navigate = useNavigate();

  const handleDeleteDraft = async () => {
    setComposePending({ [COMPOSE_PENDING_TYPES.delDraft]: true });

    try {
      if (draftId) {
        await deleteMessageDraftRequest(draftId);
      } else {
        // At first: disable draft saving in MailFormAutosave by setPending({ delDraft: true })
        // And on next render: unmount MailFormAutosave by navigate.push('inbox')
        await Promise.resolve();
      }

      navigate('/mail/inbox');
      setComposePending({ [COMPOSE_PENDING_TYPES.delDraft]: false });
      setComposeDraftId(null);
    } catch {
      setComposePending({ [COMPOSE_PENDING_TYPES.delDraft]: false });
    }
  };

  const handleChangeMail = (option) => {
    changeDefaultMailForUserRequest(option, true)
      .then(() => {
        navigate('/mail/inbox');
      });
  };

  return (
    <div className="mail-header">
      <div className="mail-header__email">
        <div className="mail-header__navbar-btn-wrap">
          <Navbar isBurgerMenu />
        </div>

        {mailsOption?.length && defaultModeOfOperation ? (
          <div className="mail-header__mail-select">
            <Select
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Mails"
              options={mailsOption}
              defaultValue={{
                value: defaultModeOfOperation.id,
                label: defaultModeOfOperation.type,
                is_default: defaultModeOfOperation.is_default,
              }}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
              onChange={handleChangeMail}
              isSearchable={false}
              components={{
                IndicatorSeparator: null,
              }}
            />
          </div>
        ) : (
          <Spinner spinnerSize={30} className="mail-header__select-spinner"/>
        )}
      </div>
      <div className="mail-header__actions">
        <Routes>
          <Route
            path="/compose"
            element={
              <MailHeaderComposeActions
                handleDeleteDraft={handleDeleteDraft}
                pending={composePending}
                draftStatus={draftStatus}
              />
            }
          />

          {mailListHeaderRoutes.map((path) => (
            <Route
              path={path}
              key={path}
              element={ <MailHeaderListActions /> }
            />
          ))}
        </Routes>
      </div>
      <MailboxUsers/>
    </div>
  )
}

const mapStateToProps = (state) => ({
  draftId: state.mail.compose.draftId,
  composePending: state.mail.compose.pending,
  draftStatus: state.mail.compose.draftStatus,
  availableMails: state.mail.availableMails,
  defaultModeOfOperation: state.mail.defaultModeOfOperation,
});

const mapDispatchToProps = {
  setComposePending,
  setComposeDraftId,
  deleteMessageDraftRequest,
  changeDefaultMailForUserRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailHeader);
