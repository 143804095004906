import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';
import { selectModalCount } from 'redux/selectors/selectors';

import { toggleIsOpenTasksAndRemindersModal } from 'redux/ducks/notifications';
import { BUTTON_THEMES, SIZES } from 'config/constants';

import ICONS from 'assets/icons';
import TasksAndRemindersModal from 'components/TasksAndRemindersModal/TasksAndRemindersModal';
import Button from 'components/UI/Button/Button';
import Portal from 'components/Portal';


const TasksButton = (props) => {
  const dispatch = useDispatch();
  const isTasksAndRemindersModalOpen = useSelector(state => state.notifications.isOpenTasksAndRemindersModal)

  const {
    triggerRef,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
    tooltipRef,
  } = usePopperTooltip(
    {
      offset: [0, 18],
      trigger: 'click',
      placement: 'bottom',
      visible: isTasksAndRemindersModalOpen,
      onVisibleChange: () => dispatch(toggleIsOpenTasksAndRemindersModal({ status: !isTasksAndRemindersModalOpen })),
      closeOnOutsideClick: false,
    }
  );

  const modalCount = useSelector((state) => selectModalCount(state));
  const isModalOpened = !!modalCount;

  const isModalOpen = document.getElementById('dialogs-root').children.length;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef?.contains(event.target)) {
        return null;
      }
      
      if (tooltipRef && !tooltipRef.contains(event.target) && !isModalOpened && !isModalOpen) {
        dispatch(toggleIsOpenTasksAndRemindersModal({ status: false }))
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isTasksAndRemindersModalOpen, isModalOpened, tooltipRef, isModalOpen])

  return (
    <>
      <Button
        className={props.className}
        icon={ICONS.checkboxNew}
        ref={setTriggerRef}
        active={isTasksAndRemindersModalOpen}
        title="Open tasks modal"
        {...props}
      />

      {isTasksAndRemindersModalOpen &&
        <Portal>
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ style: { zIndex: 100 } })}
          >
            <TasksAndRemindersModal />
          </div>
        </Portal>
      }
    </>
  );
};

export default TasksButton;
