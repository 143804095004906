import React from 'react';
import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import { classModifier } from 'utils';

import "./GroupedButtons.scss";
import Button from '../Button/Button';
import ICONS from 'assets/icons';


const GroupedButtons = (props) => { // [ ]: implement overflowing buttons with Overflow component with array of dom elements data
  const {
    className,
    children,
    theme,
    isDropdowned,
    buttonComponent,
    generateButtonProps,
    generalButtonProps,
  } = props;

  const {
    visible: isRevealed,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    trigger: 'click',
    mutationObserverOptions: null,
  });

  const groupedButtonsClasses = classModifier('grouped-buttons', theme && 'theme-' + theme);

  const createButtons = () => {
    const generateButtons = (buttonProps) => (
      buttonProps.map((props, index) => (
        <Button
          className="grouped-buttons__button"
          key={index}
          component={buttonComponent}
          radius={null}
          {...props}
          {...generalButtonProps}
        />
    )))

    if (!isDropdowned) {
      if (children) {
        return React.Children.map(children, (child) => (
          React.cloneElement(child, { className: 'grouped-buttons__button', radius: null, ...generalButtonProps })
        ));
      }

      return generateButtons(generateButtonProps());
    }

    if (children) {
      return (
        <>
          <div className={classNames(groupedButtonsClasses)}>
            <Button
              className={classNames(`${className}__burger-button`, 'dropdown-button', isRevealed && 'revealed')}
              ref={setTriggerRef}
              icon={ICONS.burgerMenu}
              radius={null}
              {...generalButtonProps}
            />
          </div>

          <div
            className={classNames(groupedButtonsClasses, [isRevealed && 'visible', 'grouped-menu'])}
            ref={setTooltipRef}
          >
            <button className={`${className}__null-box`} />
            {React.Children.map(children, (child) => (
              React.cloneElement(child, { className: 'grouped-buttons__button', radius: null, ...generalButtonProps })
            ))};
          </div>
        </>
      )
    }

    const buttonProps = generateButtonProps();

    const activeIndexIcon = buttonProps.findIndex((button) => button.active);
    const [activeProps] = activeIndexIcon !== -1 ? buttonProps.splice(activeIndexIcon, 1) : [{ icon: ICONS.burgerMenu }];

    return (
      <>
        <div className={classNames(groupedButtonsClasses)}>
          <Button
            className={classNames(`${className}__burger-button`, 'dropdown-button', isRevealed && 'revealed')}
            ref={setTriggerRef}
            {...activeProps}
            {...generalButtonProps}
          />
        </div>

        <div
          className={classNames(groupedButtonsClasses, [isRevealed && 'visible', 'grouped-menu'])}
          ref={setTooltipRef}
        >
          <button className="grouped-menu__null-box" />
          {generateButtons(buttonProps)}
        </div>
      </>
    )
  }

  return (
    <div className={classNames(className, groupedButtonsClasses)}>
      {createButtons()}
    </div>
  )
}

export default GroupedButtons;
