import classNames from 'classnames';
import { classModifier } from 'utils';

import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import DateTime from 'components/DateTime';
import { BASE_DATE_CONFIG } from 'config/dates-сonfig';

import './BookingDetails.scss';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import Label from 'components/UI/Label/Label';
import ICONS from 'assets/icons';

const DEFAULT_DETAILS_ITEMS = [
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
]


const getFormatedDuration = (booking) => {
  let [counts, dimensions] = booking.duration.split(' ');

	if (!dimensions) {
    return booking.duration;
  }

  if (dimensions === 'minutes') {
    dimensions = 'min';
  }

  return `${counts} ${dimensions}`;
}

const getFormatedType = (booking) => booking.type[0].toUpperCase() + booking.type.slice(1)

const getBookingDetailsItem = {
  [BOOKING_DETAILS_ITEMS_NAMES.DURATION]: (booking) => getFormatedDuration(booking),
  [BOOKING_DETAILS_ITEMS_NAMES.TYPE]: (booking) => getFormatedType(booking),
  [BOOKING_DETAILS_ITEMS_NAMES.RATE]: (booking) => `£${booking.rate}`,
  [BOOKING_DETAILS_ITEMS_NAMES.DATE]:
  	(booking, dateConfig = BASE_DATE_CONFIG) => <DateTime date={booking.date} config={dateConfig} />,
  [BOOKING_DETAILS_ITEMS_NAMES.AGENTS]: (booking) => booking.agents,
  [BOOKING_DETAILS_ITEMS_NAMES.REQUESTS]: (booking) => booking.requests
  // [BOOKING_DETAILS_ITEMS_NAMES.AGE]: ({ girl }) => `${girl.age} years`,
  // [BOOKING_DETAILS_ITEMS_NAMES.HEIGHT]: ({ girl }) => `${girl.height} cm`,
  // [BOOKING_DETAILS_ITEMS_NAMES.BREAST_SIZE]: ({ girl }) => girl.breast_size,
  // [BOOKING_DETAILS_ITEMS_NAMES.DRESS]: ({ girl }) => `${girl.dress} size`,
  // [BOOKING_DETAILS_ITEMS_NAMES.NATIONALITY]: ({ girl }) => girl.nationality,
}

const BookingDetails = ({
  className,
  booking,
  items = DEFAULT_DETAILS_ITEMS,
  namesAlignment = 'row',
  dateConfig,
  style
}) => {
  const details = items.reduce((acc, item, i) => {
		const detailText = getBookingDetailsItem[item](booking, dateConfig);

    if (!detailText) {
      return acc;
    }

    const detail = (
      <Tooltip key={i} text={detailText} visibleWhenIsOverflowed>
        <span className={classModifier("booking-details__item", item)}>
          {detailText}
        </span>
      </Tooltip>
    )

    return [...acc, detail]
  }, []);

  const classes = classNames(
    className,
    classModifier('booking-details', namesAlignment)
  )

  return (
    // <Tooltip
    //   text={details}
    //   visibleWhenIsOverflowed
    // >
    //   <div className={classes} style={style}>
    //     {details}
    //   </div>
    // </Tooltip>
    <Label className={classes} icon={ICONS.out} text={details} />
  )
}

export default BookingDetails;
