import classNames from 'classnames';

import Tooltip from 'components/UI/Tooltip/Tooltip';

import "./BookingRequirements.scss";

const BookingRequirements = ({ className, booking }) => {
  const tooltipContent = (
    <div className="booking-requirements-new__titles">
      {(booking.requirementsTitles || []).map((title) => (
        <li className="booking-requirements-new__titles-item" key={title}>{title}</li>
      ))}
    </div>
  )

  if (!booking.requirementsColors?.length) {
    return null;
  }

  return (
    <Tooltip text={tooltipContent}>
      <div className={classNames(className, 'booking-requirements-new')}>
        {(booking.requirementsColors || []).map((color) => (
          <div
            key={color}
            className="booking-requirements-new__item"
            style={{ backgroundColor: color }}
          ></div>
        ))}
      </div>
    </Tooltip>
  )
}

export default BookingRequirements;
