import React from 'react';
import { connect } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import { classModifier } from 'utils';
import { 
  selectOperatorIncludingCurrent, 
} from 'redux/selectors/selectors';
import { CONTACT_LOG_DATE_CONFIG } from 'config/dates-сonfig';
  
  import ICONS from 'assets/icons';
import Portal from 'components/Portal';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import DateTime from 'components/DateTime';

const ContactLogItem = props => {
  const {
    item,
    username,
  } = props;
  
  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'top',
    onVisibleChange,
    offset: [0, 2],
  });

  function onVisibleChange(isVisible) {
    if (!item.description && !item.avatar) {
      return false;
    }

    return isVisible;
  }

  const renderDescription = () => {
    if (item.action === 'changed_avatar') {
      return <>
        Avatar was changed from
        <LazyLoadImage
          src={item.avatar?.oldValue}
          containerClassName="contact-log__description-avatar"
        />
        to
        <LazyLoadImage
          src={item.avatar?.newValue}
          containerClassName="contact-log__description-avatar"
        />
      </>
    } else if (item.action === 'added_avatar') {
      return <>
        Added new avatar
        <LazyLoadImage
          src={item.avatar?.newValue}
          containerClassName="contact-log__description-avatar"
        />
      </>
    } else if (item.action === 'merge_contacts') {
      const data = JSON.parse(item.description);

      return (
        <table border="1" cellPadding="8">
          <thead>
          <tr>
            <th></th>
            <th>Donor</th>
            <th>Acceptor</th>
            <th>Result</th>
          </tr>
          </thead>
          <tbody>
          {Object.keys(data).map((field) => {
            if (field === 'photo') {
              return (
                <tr key={field}>
                  <td>{field}</td>
                  <td>
                    {data[field].donor &&
                      <LazyLoadImage
                        src={data[field].donor}
                        containerClassName="contact-log__description-avatar"
                      />
                    }
                  </td>
                  <td>
                    {data[field].acceptor &&
                      <LazyLoadImage
                        src={data[field].acceptor}
                        containerClassName="contact-log__description-avatar"
                      />
                    }
                  </td>
                  <td>
                    {data[field].result &&
                      <LazyLoadImage
                        src={data[field].result}
                        containerClassName="contact-log__description-avatar"
                      />
                    }
                  </td>
                </tr>
              );
            }

            return (
              <tr key={field}>
                <td>{field}</td>
                <td>{Array.isArray(data[field].donor) ? data[field].donor.join(', ') : data[field].donor || ''}</td>
                <td>{Array.isArray(data[field].acceptor) ? data[field].acceptor.join(', ') : data[field].acceptor || ''}</td>
                <td>{Array.isArray(data[field].result) ? data[field].result.join(', ') : data[field].result || ''}</td>
              </tr>
            );
          })}
          </tbody>
        </table>
      );
    }

    return item.description;
  };

  return (
    <li className="contact-log" ref={setTriggerRef}>
      <div className={classModifier("contact-log__row", "date")}>
        <div className="contact-log__date">
          <DateTime
            date={item.created_at}
            config={CONTACT_LOG_DATE_CONFIG}
          />
        </div>
      </div>

      <div className="contact-log__row">
        <div className="contact-log__operator">
          <ICONS.operator />
          {username || 'System'}
        </div>

        <div className="contact-log__action">
          <ICONS.pencil />
          {item.title}
        </div>
      </div>

      {!!item.data &&
        <button type="button" className="contact-log__undo-btn">
          <ICONS.undo className="contact-log__undo-btn-icon"/>
        </button>
      }

      {visible &&
        <Portal>
          <div ref={setTooltipRef} {...getTooltipProps()} className="contact-log__description">
            {renderDescription()}
          </div>
        </Portal>
      }
    </li>
  )
};

const mapStateToProps = (state, ownProps) => ({
  username: selectOperatorIncludingCurrent(state, ownProps.item.user_id)?.username,
});

export default connect(mapStateToProps)(ContactLogItem);
