import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Cropper from 'react-easy-crop';
import { useDispatch } from 'react-redux';

import API from 'api/api';
import ICONS from 'assets/icons';
import { useToggle } from 'hooks';
import { getContactAvatar } from 'utils';
import getCroppedImg from 'utils/cropImage';
import { MODAL_TYPES, closeModal } from 'redux/ducks/activeWindows';

import './ImgCropper.scss';
// import Toggle from '../Toggle/Toggle';
import Slider from '../AudioPlayer/Slider';
import ImgInput from '../ImgInput/ImgInput';
import Spinner from '../UI/Spinner/Spinner';

const SHAPE = {
  round: 'round',
  rect: 'rect'
};


const ImgCropper = (props) => {
  const {
    photo: currPhoto,
    aspect,
    // setPhoto,
    currAvatar,
    cropSubmit,
    isDisabled,
    isWereAvatar,
    classNamePrefix,
    currContactType,
    isVerticalSlider,
    // toggleAvaCropper,
  } = props;

  const [thumbWasMoved, setThumbWasMoved] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [avatarsList, setAvatarsList] = useState(null);

  const [photo, setCurrPhoto] = useState(currPhoto);

  const initPhoto = useMemo(() => currAvatar, []);

  const [cropShape, toggleCropShape] = useToggle(true);

  const dispatch = useDispatch();

  useEffect(() => {
    API.getDefaultAvatars()
      .then(res => {
        setAvatarsList(res.data.data);
      })
      .catch(console.error)

    setCurrPhoto(currPhoto);
  }, []);

  const onCropComplete = useCallback((croppedArea, pixels) => {
    setCroppedAreaPixels(pixels);
  }, []);

  const onUpdate = (z) => {    
    if(z <= 0) {
      setZoom(1);
    } else if(z >= 2) {
      setZoom(3);
    } else setZoom(z + 1);
    
    if (z + 1 !== zoom) {
      setThumbWasMoved(!thumbWasMoved);
    }
  };

  const onCropSubmitHandler = useCallback(async () => {
    try {
      if(photo) {
        const croppedImage = await getCroppedImg(photo, croppedAreaPixels); 
        cropSubmit(croppedImage);
      } else {
        cropSubmit(null);
      }

      onCloseModal();
    }
    catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, photo]);

  const changeContactAvatar = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (file.type.startsWith('image')) {
        setCurrPhoto(URL.createObjectURL(file));
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onZoomChange = z => {
    return !photo ? false : onUpdate(z - 1);
  }

  const onCloseModal = () => {
    dispatch(closeModal(MODAL_TYPES.openAvatarEditor));
  }

  const onCancel = () => {
    setCurrPhoto(isWereAvatar ? initPhoto: getContactAvatar({ type: +currContactType }, 'contact', true));
    onUpdate(0);
  }

  const onSelectDefaultAvatar = (avatar) => {
    setCurrPhoto(avatar);
    onUpdate(0);
  }

  return (
    <div className={`${classNamePrefix}__ava-cropper-wrap`} onClick={e => e.stopPropagation()}>
      <div className={`${classNamePrefix}__header`}>
        Edit avatar
        <button type='button' className={`${classNamePrefix}__header-btn`} onClick={() => onCloseModal()}>
          <ICONS.close />
        </button>
      </div>

      <div className={`${classNamePrefix}__ava-editor-wrap`}>
        <section className={`${classNamePrefix}__ava-cropper`}>
          <Cropper
            crop={crop}
            zoom={zoom}
            name="image"
            image={photo || getContactAvatar({ type: +currContactType }, 'contact', true)}
            aspect={aspect}
            cropShape={cropShape ? SHAPE.round : SHAPE.rect}
            onCropChange={(crop) => !photo ? false : setCrop(crop)}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </section>

        {photo &&
          <div className={`${classNamePrefix}__ava-cropper-options-bar`}>
            <Slider
              zoom={zoom}
              isShowZoomBtns
              countOfSteps={2}
              onUpdate={onUpdate}
              currentStep={zoom - 1}
              thumbWasMoved={thumbWasMoved}
              isVertical={isVerticalSlider}
            />

            {/* <div className={`${classNamePrefix}__toggle-wrap`}>
              <span className={`${classNamePrefix}__toggle-title`}>{cropShape ? SHAPE.round : SHAPE.rect}</span>

              <Toggle
                className={`${classNamePrefix}__ava-cropper-toggler`}
                isActive={cropShape}
                onToggle={() => toggleCropShape()}
              />
            </div> */}
          </div>
        }

        <nav className={`${classNamePrefix}__ava-cropper-navigation-bar`}>
          <button
            className={`${classNamePrefix}__ava-cropper-btn-file-cancel ava-cropper-btn`}
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>

          <label
            htmlFor="btn-file-add"
            className={`${classNamePrefix}__ava-cropper-btn-file-upload ava-cropper-btn`}
          >
            Upload
          </label>

          {/* <Field
            id="btn-file-add"
            name="image"
            component={ImgInput}
            className="edit-contact-form__file"
            onChangeHandler={changeContactAvatar}
          /> */}
          <ImgInput
            id="btn-file-add"
            name="image"
            className="edit-contact-form__file"
            onChangeHandler={changeContactAvatar}
          />

          <button
            onClick={onCropSubmitHandler}
            type="button"
            // disabled={isDisabled}
            className={`${classNamePrefix}__ava-cropper-btn-file-save ava-cropper-btn`}
          >
            Save
          </button>
        </nav>
      </div>

      <div className={`${classNamePrefix}__ava-cropper-avatars`}>
        <h3 className={`${classNamePrefix}__ava-cropper-avatars-title`}>Or choose from library</h3>

        <ul className={`${classNamePrefix}__ava-cropper-avatars-list`}>
          {avatarsList
            ? avatarsList?.length > 0 
              ? avatarsList.map(ava => (
                  <li 
                    key={ava.id} 
                    onClick={() => onSelectDefaultAvatar(ava.avatar)}
                    style={{ backgroundImage: `url(${ava.avatar})` }} 
                    className={`${classNamePrefix}__ava-cropper-avatars-list-item`}
                  />
                ))
              : '(no items)'
            : <Spinner spinnerSize={50}/>
          }
        </ul>
      </div>
    </div>
  );
};

export default ImgCropper;
