import { useDispatch } from 'react-redux';

import { BUTTON_THEMES, SIZES } from 'config/constants';
import { toggleContactPin } from 'redux/ducks/contacts';

import Button from 'components/UI/Button/Button';
import ICONS from 'assets/icons';


const PinChatButton = (props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleContactPin(props.activeRecipient));
  }

  return (
    <Button
      className={props.className}
      title={props.activeRecipient.pinned ? 'Unpin' : 'Pin'}
      icon={props.activeRecipient.pinned ? ICONS.pin : ICONS.pinTransparent}
      active={props.activeRecipient.pinned}
      iconSize={props.size || SIZES.L}
      theme={props.theme || BUTTON_THEMES.NONE_DARK}
      disabled={props.disabled}
      onClick={handleClick}
    />
  )
}

export default PinChatButton;
