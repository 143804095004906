import ICONS from 'assets/icons';
import Button from 'components/UI/Button/Button';

const OpenProfileOnDivaSiteButton = (props) => {
  const handleClick = () =>
    window.open(props.activeRecipient.urls[0].url, '_blank');

  return (
    <Button
      className={props.className}
      icon={ICONS.externalLinkSquare}
      onClick={handleClick}
      title="Open profile"
      disabled={!props.activeRecipient.urls?.length}
      {...props}
    />
  )
}

export default OpenProfileOnDivaSiteButton;
