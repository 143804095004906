import { useState, useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation, useNavigate, Navigate, Outlet } from 'react-router-dom';

import API from 'api/api';
import { initApp } from './redux/ducks/user';
import { detectMobileDevices, deleteCookie, capitalizeFirstLetter, getCookie, classModifier } from './utils';
import { ROUTES, TITLE } from 'config/constants';
import { useDidMount } from 'hooks';

import './App.scss';
import Header from 'containers/Header/Header';
import ChatSocket from './components/ChatSocket';
import MainPage from 'pages/MainPage/MainPage';
import SalesPage from 'pages/SalesPage/SalesPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import TwoFactorPage from 'pages/TwoFactorPage/TwoFactorPage';
import AdrBookPage from 'pages/AdrBookPage/AdrBookPage';
import GlobalPreloader from 'components/GlobalPreloader/GlobalPreloader';
import MobileDevicesPlug from 'containers/MobileDevicesPlug/MobileDevicesPlug';
import MailPage from 'pages/MailPage/MailPage';
import CalendarPage from 'pages/CalendarPage/CalendarPage';
import Dialogs from 'containers/Dialogs/Dialogs';
import NotificationsButton from 'components/NotificationsButton/NotificationsButton';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

const PrivateRoutes = (props) => {
  const [pagePending, setPagePending] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isComputerModalActive = useSelector(state => state.activeWindows?.activeModals?.some(modal => modal.type === MODAL_TYPES.userSettings))

  const computer = getCookie('computer');

  const isSalesPage = location.pathname === '/sales';
  const isMailPage = location.pathname.split('/').includes('mail');
  const isCalendarPage = location.pathname.split('/').includes('calendar');
  const isAdrBookPage = location.pathname === '/address-book';

  useEffect(() => {
    if (Boolean(user.is_developer)) return;
    if (!computer && user.last_computer && !isComputerModalActive) {
      dispatch(openModal(MODAL_TYPES.computerModal))
    } else if (user.id && !computer && !user.last_computer) {
      dispatch(openModal(MODAL_TYPES.userSettings, { defaultTabIndex: 1 }));
    }
  }, [user, computer])

  useEffect(() => {
    const pathName = location.pathname;
    const tabTitle = pathName.substring(pathName.lastIndexOf('/') + 1);

    if (tabTitle) {
      document.title = `${capitalizeFirstLetter(tabTitle)} | ${TITLE}`;
    }
    else {
      document.title = TITLE;
    }
  }, [location]);

  useDidMount(() => {
    const initApp = async () => {
      await API.getIsIAmLoggedIn()
        .then((res) => {
          if (!localStorage.isLoggedIn) {
            localStorage.isLoggedIn = 1;
          }

          localStorage.currentUserId = res.data.id;

          props.initApp(res.data);
        })
        .catch(() => {
          deleteCookie('isAuthenticated');

          console.log('hello, cookie remove');

          delete localStorage.isLoggedIn;
          delete localStorage.currentUserId;

          sessionStorage.setItem('lastInteractionPage', window.location.pathname);

          navigate(ROUTES.login);
        })
        .finally(() => setPagePending(false))
    }
    initApp();
  });

  if (pagePending) {
    return <GlobalPreloader />;
  }

  return (
    <div className={classModifier('app', 'light')}>
      <ChatSocket />

      <Dialogs />

      {(!isSalesPage && !isMailPage && !isAdrBookPage && !isCalendarPage) && <Header setPagePending={setPagePending} />}

      <Routes>
        <Route path={ROUTES.main} element={<MainPage />} />
        <Route path={ROUTES.sales} element={<SalesPage />} />
        <Route path={ROUTES.adrBook} element={<AdrBookPage />} />
        <Route path={`${ROUTES.mail}/*`} element={<MailPage />} />
        <Route path={`${ROUTES.calendar}/*`} element={<CalendarPage />} />

        <Route path="*" element={<Navigate replace to={ROUTES.main} />} />
      </Routes>

      <NotificationsButton />
    </div>
  );
};

const mapDispatchToProps = {
  initApp,
};

const ConnectedPrivateRoute = connect(null, mapDispatchToProps)(PrivateRoutes);

const RequireAuth = () => {
  const isAuth = process.env.NODE_ENV === 'development'
    ? !!localStorage.isLoggedIn
    : !!getCookie('isAuthenticated');

  if (!isAuth) {
    return <Navigate to={ROUTES.login} />;
  }

  return <Outlet />;
}

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isMobileDevice = useMemo(detectMobileDevices, []);

  // const handleBeforeUnload = () => {
  //   CS.updateUserStorage();
  // };

  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  if (isMobileDevice) {
    return <MobileDevicesPlug />;
  }

  return (
    <Routes>
      <Route
        path={ROUTES.login}
        element={<LoginPage setIsLoggedIn={setIsLoggedIn} />}
      />

      <Route
        path={ROUTES.twoFactor}
        element={isLoggedIn ? <TwoFactorPage /> : <Navigate to={ROUTES.login} />}
      />

      <Route element={<RequireAuth />}>
        <Route path="/*" element={<ConnectedPrivateRoute />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
