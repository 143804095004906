import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

import ICONS from "assets/icons";
import { getContactAvatar } from "utils";
import { MODAL_TYPES, openModal } from "redux/ducks/activeWindows";

import LazyLoadImage from "components/LazyLoadImage/LazyLoadImage";


const ContactAvatar = (props) => {
  const {
    photo,
    values,
    cropSubmit,
    classPrefix,
    saveContact,
    isLoadingImage,
    editedContact = {},
    cropperClassPrefix,
    isNewContactCreation,
    onChangeContactAvatar,
    onDeleteContactAvatar,
  } = props;

  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    noDrag: true,
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      setFiles(files);

      if (onChangeContactAvatar) {
        onChangeContactAvatar(acceptedFiles[0], true);
        onOpenEditor(URL.createObjectURL(acceptedFiles[0]));
      }
    }
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]
  );

  const handleAvatarClick = (e) => {
    if (e.target.closest("button[data-action='delete']")) {
      e.preventDefault();

      onDeleteContactAvatar();
      return;
    } 

    onOpenEditor();

    // else if (e.target.closest("button[data-action='upload']")) {
    //   // getRootProps().onClick(e);
    //   onOpenEditor();
    // }
    // else if (e.target.closest("button[data-action='edit']")) {
    //   // toggleAvaCropper();

    //   // dispatch(openModal(MODAL_TYPES.openAvatarEditor, {
    //   //   isVerticalSlider: true,
    //   //   cropSubmit: (croppedImage) => {

    //   //     cropSubmit(croppedImage, false);
    //   //     toggleAvaCropper();
    //   //   },
    //   //   aspect: 4 / 4,
    //   //   photo: photo.src || photo.url || getContactAvatar(editedContact, 'contact', true),
    //   //   setPhoto: cropSubmit,
    //   //   isDisabled: !editedContact.photo && !photo.url,
    //   //   classNamePrefix: cropperClassPrefix,
    //   //   // toggleAvaCropper={toggleAvaCropper}
    //   // }));
    // }
  }

  const onOpenEditor = (file) => {
    dispatch(openModal(MODAL_TYPES.openAvatarEditor, {
      isVerticalSlider: true,
      cropSubmit: (croppedImage) => {
        cropSubmit(croppedImage, false);
        saveContact(croppedImage);
      },
      currContactType: editedContact?.type,
      currAvatar,
      aspect: 4 / 4,
      isWereAvatar: !!editedContact.photo,
      photo: file || photo.src || photo.url || getContactAvatar(editedContact, 'contact', true),
      isDisabled: !editedContact.photo && !photo.url,
      classNamePrefix: cropperClassPrefix,
    }));
  }

  const currAvatar = isNewContactCreation 
    ? photo.url || getContactAvatar({ type: +values.type })
    : editedContact.photo?.src || getContactAvatar({ type: +values.type });

  return (
    <div className={classPrefix}>
      <div className={`${classPrefix}__img-container`}>
        <div className={`${classPrefix}__img-wrap`}>
          <LazyLoadImage
            src={currAvatar}
            className={`${classPrefix}__img`}
            alt="contact_avatar"
            spinnerSize={50}
            isLoadingImage={isLoadingImage}
          />
        </div>

        <div className={`${classPrefix}__control-icons`}>
          <div
            {...getRootProps({ className: `${classPrefix}__image-input` })}
            onClick={handleAvatarClick}
          >
            <input {...getInputProps()} />

            {(values.photo?.src) && (
              <button
                type="button"
                className={`${classPrefix}__icon-wrapper ${classPrefix}__icon-wrapper--delete`}
                data-action="delete"
              >
                <ICONS.close className={`${classPrefix}__icon ${classPrefix}__icon--delete`} />
              </button>
            )}

            {!values.photo?.src && 
              <button
                type="button"
                className={`${classPrefix}__icon-wrapper ${classPrefix}__icon-wrapper--upload`}
                data-action="upload"
              >
                <ICONS.upload className={`${classPrefix}__icon ${classPrefix}__icon--upload`}/>
              </button>
            }

            {((editedContact.photo && !isNewContactCreation) || props.isSelectedDivaProfile) && (
              <button
                type="button"
                className={`${classPrefix}__icon-wrapper ${classPrefix}__icon-wrapper--edit`}
                data-action="edit"
              >
                <ICONS.pencil className={`${classPrefix}__icon ${classPrefix}__icon--edit`}/>
              </button>
            )}
          </div>
        </div>
      </div>

      {/* <DelayedComponent isMount={avaCropper}>
        <div className={avaCropper ? cropperClassPrefix : `${cropperClassPrefix} close`} onClick={toggleAvaCropper}>
          <ImgCropper
            isVerticalSlider
            cropSubmit={(croppedImage) => {
              cropSubmit(croppedImage, false);
              toggleAvaCropper();
            }}
            aspect={4 / 4}
            photo={photo.src || photo.url || getContactAvatar(editedContact, 'contact', true)}
            setPhoto={cropSubmit}
            isDisabled={!editedContact.photo && !photo.url}
            toggleAvaCropper={toggleAvaCropper}
            classNamePrefix={cropperClassPrefix}
          />
        </div>
      </DelayedComponent> */}
    </div>
  );
}

export default ContactAvatar;
