import React, { useState, useCallback, useEffect, useLayoutEffect, useMemo, useReducer } from 'react';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import ICONS from 'assets/icons';
import { openModal, playMedia, MODAL_TYPES, closeModal, openChildModal } from 'redux/ducks/activeWindows';
import { PhotoGalleryContext } from './PhotoGallery.context';
import { selectModalsTransitionModes } from 'redux/selectors/selectors';
import { BUTTON_THEMES } from 'config/constants';
import { classModifier } from 'utils';

import './PhotoGallery.scss';
import GalleryPhoto from './components/GalleryPhoto';
import GalleryVideo from './components/GalleryVideo';
import GalleryCarousel, { GalleryCarouselItem } from './components/GalleryCarousel/GalleryCarousel';
import Button from 'components/Button/Button';
import AttachmentImagesAndVideos from 'components/AttachmentImagesAndVideos/AttachmentImagesAndVideos';

const selectImagesReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_PHOTO':
      const { number, src } = action.payload;

      if (state.images[number]) {
        const { images } = state;
        const { [number]: picture, ...rest } = images;

        return {
          images: rest,
          videos: state.videos
        };
      } else {
        return {
          ...state,
          images: {
            ...state.images,
            [number]: { url: src }
          }
        }
      }
    case 'CLEAR': {
      return { images: {}, videos: {} };
    }
  }
}

const PhotoGallery = ({
  media,
  closeModal,
  openModal,
  openChildModal,
  isPrivate,
  profile,
}) => {
  const modalsTransitionModes = useSelector(selectModalsTransitionModes);
  
  const [isPrivateGallery, setIsPrivateGallery] = useState(isPrivate);
  const [gallery, setGallery] = useState({});
  const [activeSlide, setActiveSlide] = useState(0);
  const [selectedMode, setSelectedMode] = useState(false);
  const [selectedPhotos, dispatchPhotos] = useReducer(selectImagesReducer, { images: {}, videos: {} });

  useEffect(() => {
    if (!selectedMode) {
      dispatchPhotos({ type: 'CLEAR' })
    }
  }, [selectedMode])

  useLayoutEffect(() => {
    if (isPrivateGallery) {
      setGallery(media.filter((item) => item.is_video || item.is_private))
    } else {
      setGallery(media.filter((item) => !item.is_video && !item.is_private))
    }

    setActiveSlide(0);
  }, [isPrivateGallery]);

  const openPhotoCarousel = useCallback((index) => {
    const modalProps = {
      media: gallery,
      defaultActiveSlide: index,
      updateSliderCount: setActiveSlide,
    }

    openChildModal(MODAL_TYPES.profileCarousel, modalProps);
  }, [gallery]);

  const openSendPhotoForm = () => {
    const createAttachmentView = (attachments) => {
      const attachmentsUrls = attachments.map(({ url }) => ({ url }));

      return (
        <AttachmentImagesAndVideos
          media={attachmentsUrls}
          maxImagesPerRow={4}
          maxImages={8}
          disableExpansion
        />
      )
    }
    
    const sendMiddleware = (send, { selectedAttachments }) => {
      const divaPhotoUrls = Object.values(selectedAttachments).map(({ url }) => url);

      return send({
        divaPhotoUrls,
        subject: profile.name,
      })
    }

    const attachments = { ...selectedPhotos.images, ...selectedPhotos.videos };

    const modalProps = {
      attachments,
      attachmentsAlignment: 'left',
      title: 'Send photos to',
      createAttachmentView: createAttachmentView,
      channels: ['email', 'telegram'],
      sendMiddleware,
    }

    _.size(attachments) && openModal(MODAL_TYPES.sendAttachmentMessageModal, modalProps);
  }

  const carouselItems = useMemo(() => {
    return (
      _.chunk(gallery, 8).map((chunk, i) => (
        <GalleryCarouselItem
          classPrefix='photo-gallery'
          key={i}
        >
          {
            chunk.map((item, j) => {
              const GalleryItem = item.is_video ? GalleryVideo : GalleryPhoto;

              return (
                <GalleryItem
                  classPrefix="photo-gallery"
                  item={item.image_name}
                  key={item.number}
                  number={i * 8 + j}
                  onClick={() => openPhotoCarousel(i * 8 + j)}
                  preview
                />
              )
            }
            )}
        </GalleryCarouselItem>
      ))
    )
  }, [gallery])

  const classes = classNames(
    classModifier('modal', [
      modalsTransitionModes[MODAL_TYPES.photoGallery],
      MODAL_TYPES.photoGallery
    ]),
    'photo-gallery'
  )

  return (
    <PhotoGalleryContext.Provider value={{ selectedMode, selectedPhotos, dispatchPhotos }}>
      <div className={classes}>
        <div className="photo-gallery__header">
          <div className="photo-gallery__photos-count">Photos ( {gallery.length} )</div>

          <div className="photo-gallery__btn-sections-wrapper">
            <div className={classModifier("photo-gallery__btn-section", "left")}>
              <button
                className={classModifier("photo-gallery__toggle-btn", !isPrivateGallery && 'selected')}
                onClick={() => !selectedMode && setIsPrivateGallery(false)}
              >
                Public <ICONS.lockOpen />
              </button>

              <button
                className={classModifier("photo-gallery__toggle-btn", isPrivateGallery && 'selected')}
                onClick={() => !selectedMode && setIsPrivateGallery(true)}
              >
                Private <ICONS.lock />
              </button>

              <div className="photo-gallery__btn-section-substrate"></div>
            </div>

            {
              !isPrivateGallery && (
                <div className={classModifier("photo-gallery__btn-section", "right")}>
                  {selectedMode
                    ? (
                      <>
                        <Button
                          className={classModifier("photo-gallery__btn", "cancel")}
                          theme={BUTTON_THEMES.light}
                          onClick={() => setSelectedMode(false)}
                          icon={<ICONS.close />}
                        >
                          Cancel
                        </Button>

                        <Button
                          className={classModifier("photo-gallery__btn", "send")}
                          theme={BUTTON_THEMES.light}
                          onClick={openSendPhotoForm}
                          icon={<ICONS.arrow />}
                        >
                          Send Selected Photos to Client
                        </Button>
                      </>
                    )
                    : (
                      <Button
                        className={classModifier("photo-gallery__btn", "select")}
                        theme={BUTTON_THEMES.light}
                        onClick={() => setSelectedMode(true)}
                        icon={<ICONS.arrow />}
                      >
                        Select Photos to Send
                      </Button>
                    )
                  }
                </div>
              )
            }
          </div>

          <button
            className="photo-gallery__esc-btn"
            onClick={() => closeModal()}
          >
            <ICONS.close className="photo-gallery__esc-btn-icon" /> (Esc)
          </button>
        </div>

        <div className="photo-gallery__content-wrapper">
          {!gallery.length &&
            <div className='photo-gallery__no-items'>{isPrivateGallery ? 'Private' : 'Public'} gallery is empty</div>
          }

          {!!gallery.length && (
            <GalleryCarousel
              classPrefix='photo-gallery'
              activeSlide={activeSlide}
            >
              {carouselItems}
            </GalleryCarousel>
          )}
        </div>
      </div>
    </PhotoGalleryContext.Provider>
  )
}

const mapDispatchToProps = {
  playMedia,
  openModal,
  openChildModal,
  closeModal,
};

export default connect(null, mapDispatchToProps)(PhotoGallery);
