import { Allotment } from 'allotment';
import { BUTTON_THEMES, POSITIONS, SIZES } from 'config/constants';
import React, { Fragment, useRef } from 'react';
import ReactDOM from 'react-dom';
import Button from 'components/UI/Button/Button';

import './SlidingPanel.scss';
import { useToggle } from 'hooks';


const SlidingPanel = (props) => {
  const {
    children,
    position,
    content,
    text,
    panelMinSize = 250,
    panelMaxSize = 400,
  } = props;

  const [isRevealed, toggleIsRevealed] = useToggle(false);

  const containerRef = useRef();
  const allotmentRef = useRef();

  const [topPane, bottomPane] = containerRef.current?.querySelectorAll('.split-view-view') || [];
  const contentContainerDOM = position === POSITIONS.TOP ? bottomPane : topPane;

  const handlePaneSizesChange = ([topSize, bottomSize]) => {
    const isSlidingPanelSizeChanged
      = (position === POSITIONS.TOP && topSize > 0)
      || (position === POSITIONS.BOTTOM && bottomSize > 0);

    toggleIsRevealed(isSlidingPanelSizeChanged);
  }

  const handleClick = () => {
    const containerHeight = containerRef.current.offsetHeight;

    if (isRevealed) {
      allotmentRef.current.resize([containerHeight, 0]);
      toggleIsRevealed(true)
    } else {
      allotmentRef.current.resize([containerHeight - panelMinSize, panelMinSize]);
      toggleIsRevealed(false)
    }

    toggleIsRevealed();
  }
  
  const SlidingButton = () => (
    <Button
      className="sash-button"
      theme={BUTTON_THEMES.DIMMED}
      iconSize={SIZES.S}
      rounded={SIZES.S}
      active={isRevealed}
      onClickCapture={handleClick}
    >
      <Button.Text>{text}</Button.Text>
      <Button.Arrow />
    </Button>
  )

  const slidingPanelPaneProps = {
    minSize: 0,
    maxSize: panelMaxSize,
    snap: true,
  }

  return (
    <Fragment>
      {contentContainerDOM && ReactDOM.createPortal(<SlidingButton />, contentContainerDOM)}

      <div ref={containerRef} style={{ height: '100%' }}>
        <Allotment
          ref={allotmentRef}
          defaultSizes={position === POSITIONS.TOP ? [0, 'fill'] : ['fill', 0]}
          onChange={handlePaneSizesChange}
          vertical
        >
          <Allotment.Pane {...(position === POSITIONS.TOP && slidingPanelPaneProps)}>
            {position === POSITIONS.TOP ? content : children}
          </Allotment.Pane>

          <Allotment.Pane {...(position === POSITIONS.BOTTOM && slidingPanelPaneProps)}>
            {position === POSITIONS.TOP ? children : content}
          </Allotment.Pane>
        </Allotment>
      </div>
    </Fragment>
  );
}

export default SlidingPanel;
