import React, { useState } from 'react';

import API from 'api/api';
import { useDidMount } from 'hooks';
import { ADR_BOOK_LIMIT } from 'redux/ducks/addressBook';

import './NotForContactModal.scss';
import List from 'components/List/List';
import Spinner from 'components/UI/Spinner/Spinner';
import SearchInput from 'components/SearchInput/SearchInput';
import NotForContactModalItem from './NotForContactModalItem';

const NotForContactModal = ({ callerId, saveContact, ignoreIds }) => {

  const [list, setList] = useState([]);
  const [query, setQuery] = useState('');
  const [pending, setPending] = useState(false);

  const updateSearch = (query = '') => {
    setPending(true);

    const config = { 
      search: query,
      ids: ignoreIds
    };

    API.getNotForContacts(config)
      .then(res => setList(res.data.contacts))
      .catch(console.error)
      .finally(() => setPending(false));
  }

  useDidMount(() => updateSearch());

  const loadMore = (offset) => {
    const config = { 
      offset,
      search: query, 
      ids: ignoreIds
    };

    API.getNotForContacts(config)
      .then(res => setList([...list, ...res.data.contacts]))
      .catch(console.error)
  }

  const renderTrackVertical = props => {
    const trackVerticalStyles = {
      width: '5px',
      right: '5px',
    }
  
    return <div {...props} style={trackVerticalStyles} className="track-vertical" />
  };

  const renderThumbVertical = props => {
    const thumbVerticalStyles = {
      cursor: 'pointer', 
      backgroundColor: '#808080'
    }

    return <div {...props} style={thumbVerticalStyles} className="scrollbars-thumb" />
  };

  return (
    <div className="not-for-contact-modal">
      <div className="not-for-contact-modal__wrap">
        <h3 className="not-for-contact-modal__title">Search</h3>

        <SearchInput 
          query={query}
          inputWrapClassName="not-for-contact-modal__search-wrap"
          placeholder="Girl name"
          inputClassName="input not-for-contact-modal__search-input"
          setQuery={setQuery}
          startSearch={updateSearch}
          stopSearch={updateSearch}
          showClearBtn
        />
      </div>

      {pending 
        ? (
          <div className="not-for-contact-modal__spinner-wrap">
            <Spinner spinnerSize={30} />
          </div>
        ):(
          <List
            list={list}
            limit={ADR_BOOK_LIMIT}
            scrollInitialPosition='top'
            classPrefix='not-for-contact-modal'
            loadMore={loadMore}
            scrollbarProps={{
              renderTrackVertical,
              renderThumbVertical
            }}
          >
            <NotForContactModalItem
              callerId={callerId}
              saveContact={saveContact}
            />
          </List>
        )
      }
    </div>
  )
}


export default NotForContactModal;
