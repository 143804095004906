import React from 'react';
import classNames from 'classnames';

import './Menu.scss';
import Button from '../Button/Button';
import { BUTTON_THEMES, SIZES } from 'config/constants';
import Tooltip from '../Tooltip/Tooltip';

const Menu = React.forwardRef((props, ref) => {
  const {
    options = [],
    className,
    style
  } = props;

  const handleItemClick = (e, item) => {
    e.stopPropagation();

    item.action && item.action(e, item);

    if (item.notCloseOnClick) {
      return;
    }

    props.closeDropdownMenu && props.closeDropdownMenu();
  }

  return (
    <div
      ref={ref}
      className={classNames(className, 'menu-new')}
      style={style}
    >
      <ul className="menu-new__list">
        {options.map((option, idx) => {
          let isDisabled;
          let disabledReason;

          if (option.disabled) {
            isDisabled = true;
          } else if (option.checkDisabled) {
            isDisabled = option.checkDisabled.some((check) => {
              disabledReason = check();

              if (disabledReason) {
                return true;
              };
            });
          }

          return (
            <li
              key={idx}
              className='menu-new__item'
            >
              <Tooltip
                text={disabledReason}
                disabled={!disabledReason}
              >
                <Button
                  className='menu-new__button'
                  theme={BUTTON_THEMES.NONE_DARK}
                  onMouseOver={option.onMouseOver || null}
                  onMouseDown={(e) => handleItemClick(e, option)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={isDisabled}
                >
                  <Button.Icon>{option.icon}</Button.Icon>
                  <Button.Text>{option.name}</Button.Text>
                </Button>
              </Tooltip>
            </li>
          )
        })}
      </ul>
    </div>
  );
})

export default Menu;
