import { BUTTON_THEMES, SIZES } from 'config/constants';

import Button from 'components/UI/Button/Button';
import ICONS from 'assets/icons';


const BotButton = (props) => (
  <Button
    className={props.className}
    theme={BUTTON_THEMES.NONE_DARK}
    icon={ICONS.robot}
    disabled
    {...props}
  />
);

export default BotButton;
