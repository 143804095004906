import React, { useState, useRef } from 'react';
import { DIVA_GIRLS_LIMIT } from 'redux/ducks/divaGirls';

import API from 'api/api';
import { normalize } from 'utils/normalizeContacts';
import { classModifier } from 'utils';

import FiltersList from './FiltersList';
import SearchInput from 'components/SearchInput/SearchInput';

export const NOT_FOUND_TEXT = 'Filters not Found';
export const FILTERS_ERROR = 'Error, please reload the page';

const SearchByFiltersWrap = (props) => {
  const {
    droppableId,
    filterType,
    activeFilters,
    filters,
    setFilters,
    addNotification,
    addFilter
  } = props;

  const [query, setQuery] = useState('');
  const [search, setSearch] = useState({
    query: '',
    filterIds: [],
    count: 0
  });
  const [filtersCount, setFiltersCount] = useState(0);
  const [addedFromSearchCount, setAddedFromSearchCount] = useState(0);

  const expectedSearchListLength = useRef(DIVA_GIRLS_LIMIT);
  const isMaxSearchPage = useRef(false);
  
  const getFilters = (params = {}) => API.getDivaFilters(params)
    .then(res => {
      return {
        ...normalize(res.data.result, 'key'),
        count: res.data.pagination.items,
        isMaxPage: res.data.pagination.part === res.data.pagination.parts,
      }
    })
    .catch(err => {
      console.dir(err);

      addNotification({
        text: 'Error! Not Found.',
        color: 'yellow',
        duration: 5,
        isCustom: true,
        id: 'get_diva_filters_error', // notification don`t save, so we set id manually
      });

      return {};
    });
  
  const startSearch = (query) => {
    const params = {
      part: 1,
      search: query,
    }

    getFilters({...params, filterType})
      .then(data => {
        const filtersIds = [];
        let filtersCount = data.count;

        data.result.forEach(id => {
          if (!activeFilters.includes(id)) {
            filtersIds.push(id)
          } else {
            filtersCount--;
          }
        })

        expectedSearchListLength.current = DIVA_GIRLS_LIMIT;

        setFilters(prevFilters => ({
          ...prevFilters,
          entities: {
            ...prevFilters.entities,
            ...data.entities,
          },
        }));

        setSearch({
          query,
          filterIds: filtersIds.length
            ? filtersIds
            : [NOT_FOUND_TEXT],
          count: filtersCount,
        })
      })
  }

  const stopSearch = () => {
    setSearch({
      filterIds: [],
      query: '',
      count: 0,
    })

    isMaxSearchPage.current = false;
  }

  return (
    <div className="filters-editor__zone">
      <div className={classModifier('filters-editor__zone-title', [
        filterType === 'services' && 'services'
      ])}>
        {`All ${filterType === 'services' ? 'Services' : 'Filters'} - ${
          search.query
            ? search.count - addedFromSearchCount
            : filtersCount - activeFilters.length > 0
            ? filtersCount - activeFilters.length : 0
        }`}
      </div>

      <div className="filters-editor__zone-content">
        <SearchInput
          inputWrapClassName='filters-editor__search-input'
          inputClassName='filters-editor__input'
          query={query}
          setQuery={setQuery}
          startSearch={startSearch}
          stopSearch={stopSearch}
          placeholder={filterType === 'services' ? 'Find service' : 'Find filter'}
        />

        <FiltersList
          droppableId={droppableId}
          filterType={filterType}
          filters={filters}
          setFilters={setFilters}
          getFilters={getFilters}
          activeFilters={activeFilters}
          search={search}
          setSearch={setSearch}
          addNotification={addNotification}
          expectedSearchListLength={expectedSearchListLength}
          isMaxSearchPage={isMaxSearchPage}
          addFilter={addFilter}
          setFiltersCount={setFiltersCount}
          setAddedFromSearchCount={setAddedFromSearchCount}
        />
      </div>
    </div>
  );
}

export default SearchByFiltersWrap;
