import React from 'react';
import { useDispatch } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { useWillUnmount } from 'hooks';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';

import './CalendarTableBookings.scss';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import { BookingItemNew } from 'components/BookingItemNew';
import ListContainer from 'components/List/ListContainer';
import QuickBookingGirlsListItemByDiva from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';

const SORT_OPTIONS = [
  {
    label: 'Date and Time',
    value: 'date',
  },
  {
    label: 'Escort',
    value: 'escort',
  },
  {
    label: 'Client',
    value: 'client',
  },
  {
    label: 'S',
    value: 'status',
  },
  {
    label: 'Details',
    value: 'details',
  },
  {
    label: 'Markers',
    value: 'markers',
  },
  {
    label: 'R',
    value: 'requirements',
    isDisabled: true,
  },
  {
    label: 'Operator',
    value: 'operator',
  },
  {
    label: 'Notes',
    value: 'notes',
    isDisabled: true,
  },
];

const scrollbarProps = {
  renderThumbVertical: CustomScrollbarThumb,
  renderTrackVertical: (props) => (
    <div className="scrollbars-track" style={{ width: '5px' }} {...props} />),
}

const Checkbox = ({ item, selectedBookings, setSelectedBookings }) => {
  const isSelected = !item ? selectedBookings.all : (
    (selectedBookings.all && !selectedBookings.exceptIds.includes(item.id))
    || selectedBookings.includeIds.includes(item.id)
  );
  const handleSelect = (event, item) => {
    event.stopPropagation();

    if (!item) {
      setSelectedBookings((prev) => ({
        all: !prev.all,
        exceptIds: [],
        includeIds: [],
      }));
    } else if (selectedBookings.all) {
      setSelectedBookings((prev) => ({
        ...prev,
        exceptIds: prev.exceptIds.includes(item.id)
          ? prev.exceptIds.filter((id) => id !== item.id)
          : [...prev.exceptIds, item.id],
        }))
    } else {
      setSelectedBookings((prev) => ({
        ...prev,
        includeIds: prev.includeIds.includes(item.id)
          ? prev.includeIds.filter((id) => id !== item.id)
          : [...prev.includeIds, item.id],
        }))
    }
  }


  return (
    <button
      className="calendar-table-bookings__checkbox-btn"
      onClick={(event) => handleSelect(event, item)}
    // disabled={isDisabled}
    >
      <div
        className={classModifier('calendar-table-bookings__checkbox',
          isSelected && 'active'
        )}
      >
        {isSelected &&
          <ICONS.check className="mail-list-item__checkbox-check-icon" />
        }
      </div>
    </button>
  );
}

const TableHeader = (props) => {
  const {
    actualDate,
    selectedBookings,
    setSelectedBookings,
    activeSortOption,
    isAsk,
    onChangeSort,
  } = props;

  const dispatch = useDispatch();

  const createNewBooking = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      isHandleClickCanBeRedefinedByItem: true,
      commonProps: { date: actualDate, ignoreSessionContact: true },
    }))
  }

  return (
    <div className="calendar-table-bookings__table-header">
      <div className={classModifier('calendar-table-bookings__table-col', 'checkbox')}>
        <Checkbox
          setSelectedBookings={setSelectedBookings}
          selectedBookings={selectedBookings}
        />

        <button
          className="calendar-table-bookings__new-booking"
          onClick={createNewBooking}
        >
          <ICONS.plusThick />
        </button>
      </div>

      {SORT_OPTIONS.map((option) => (
        <div
          key={option.value}
          className={classModifier('calendar-table-bookings__table-col', option.value)}
          onClick={() => onChangeSort(option.value)}
        >
          {option.label}

          {!option.isDisabled && (
            <ICONS.angleQuote
              className={classModifier('calendar-table-bookings__sort-icon', [
                option.value === activeSortOption && 'active',
                option.value === activeSortOption && isAsk && 'asc',
              ])}
            />
          )}
        </div>
      ))}
    </div>
  )
}

const CalendarTableBookings = (props) => {
  const {
    date,
    actualDate,
    onChangeSort,
    activeSortOption,
    isAsk,
    getters,
    loadFilteredBookings,
    selectedBookings,
    setSelectedBookings,
  } = props;

  useWillUnmount(() => {
    setSelectedBookings({
      all: false,
      exceptIds: [],
      includeIds: [],
    });
  })

  const {
    getBookingListProps,
    getBookingProps,
  } = getters;

  return (
    <div className="calendar-table-bookings">
      <div className="calendar-table-bookings__table">
        <TableHeader
        	date={date[0]}
          actualDate={actualDate}
          selectedBookings={selectedBookings}
          setSelectedBookings={setSelectedBookings}
          activeSortOption={activeSortOption}
          isAsk={isAsk}
          onChangeSort={onChangeSort}
          loadMore={loadFilteredBookings}
        />

        <ListContainer
          classPrefix='calendar-table-bookings'
          scrollInitialPosition='top'
          scrollbarProps={scrollbarProps}
          itemSize={51}
          autoHide
          // virtualized
          {...getBookingListProps()}
        >
          {({ item, style }) => (
            <BookingItemNew
              className="calendar-table-bookings"
              key={item.id}
              item={item}
              type={BOOKING_ITEM_VIEWS.TABLE}
              style={style}
              checkbox={
                <Checkbox
                  item={item}
                  selectedBookings={selectedBookings}
                  setSelectedBookings={setSelectedBookings}
                />
              }
              {...getBookingProps()}
            />
          )}
        </ListContainer>
      </div>
    </div>
  );
};

export default CalendarTableBookings;
