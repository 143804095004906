import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BUTTON_THEMES, SEARCH_LIST_THEMES, SIZES } from 'config/constants';
import { getDateByTimezoneOffset } from '../../utils';
import { useToggle } from 'hooks';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import ICONS from 'assets/icons';
import Button from '../UI/Button/Button';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';
import QuickBookingGirlsListItemByDiva from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import SearchList from '../SearchList/SearchList';

const bookingDurations = {
  '30 minutes': 30 * 60 * 1000,
  '45 minutes': 45 * 60 * 1000,
  '1 hour': 60 * 60 * 1000,
  '90 minutes': 90 * 60 * 1000,
  '2 hours': 2 * 60 * 60 * 1000,
  '3 hours': 3 * 60 * 60 * 1000,
  'Overnight': 12 * 60 * 60 * 1000,
};

const bookingStages = {
  sixHours: 6 * 60 * 60 * 1000,
  twoHours: 2 * 60 * 60 * 1000,
  oneHour: 60 * 60 * 1000,
}

const getButtonIcon = (booking) => {
  if (!booking.date) return ICONS.f

  if (booking.is_prebooking) return ICONS.p;

  return ICONS.booking;
}

const getIconColor = (booking, dateDiff) => {
  const { sixHours, twoHours, oneHour } = bookingStages;

  if (!booking.date) return '--blue-status-color';

  if (dateDiff >= sixHours && booking.is_prebooking) {
    return '--violet-status-color';
  }

  if (dateDiff >= sixHours) {
    return '--green-status-color';
  }

  if (dateDiff <= sixHours && dateDiff >= twoHours) {
    return '--yellow-green-status-color';
  }

  if (dateDiff <= twoHours && dateDiff >= oneHour) {
    return '--orange-status-color';
  }

  if (dateDiff <= oneHour && dateDiff >= 0) {
    return '--red-status-color';
  }

  return '--pink-status-color';
}

const BookingIcon = props => {
  const {
    activeRecipient,
    contentContainer,
    ...restProps
  } = props;

  const userTimezone = useSelector(state => state.user.timezone);
  const [isGirlsForBookingsOpen, toggleIsGirlsForBookinsOpen] = useToggle(false);

  const dispatch = useDispatch();

  const isBookingInFutureExist = activeRecipient?.closestBooking && !activeRecipient?.closestBooking.is_deleted;

  const onQuickBookingClick = () => {
    openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      isHandleClickCanBeRedefinedByItem: true,
    });
  }

  const handleBookingsListOpen = () => {
    openModal(MODAL_TYPES.bookingsList, {
      contactId: activeRecipient.id,
      activeRecipient,
      onQuickBookingClick
    })
  }

  if (!isBookingInFutureExist) {
    return (
      <Button
        acitve={isGirlsForBookingsOpen}
        icon={ICONS.booking}
        onClick={toggleIsGirlsForBookinsOpen}
        {...restProps}
      >
        {isGirlsForBookingsOpen && ReactDOM.createPortal(
          <div className="chat-header__floating-container">
            <SearchList
              theme={SEARCH_LIST_THEMES.empty}
              inputLabel={null}
              fetchData={(props) => fetchGirlContactsForBookingCreation({ dispatch, ...props })}
              itemComponent={QuickBookingGirlsListItemByDiva}
              isHandleClickCanBeRedefinedByItem
              inputPlaceholder="Search escort"
            />
          </div>
          , props.contentContainer.current
        )}
      </Button>
    )
  }

  const dateByTimezone = getDateByTimezoneOffset(userTimezone);
  const bookingDateByTimezone = getDateByTimezoneOffset(userTimezone, activeRecipient.closestBooking?.date);
  const dateDiff = bookingDateByTimezone - dateByTimezone;

  // if unknown or empty duration
  if (!bookingDurations[activeRecipient.closestBooking?.duration]) return null;
  // if booking finished
  if (dateDiff + bookingDurations[activeRecipient.closestBooking?.duration] < 0) return null;

  return (
    <Button
      icon={getButtonIcon(activeRecipient.closestBooking)}
      color={getIconColor(activeRecipient.closestBooking, dateDiff)}
      onClick={handleBookingsListOpen}
      {...restProps}
    >
    </Button>
  )
};

export default BookingIcon;
