import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { findFlagUrlByNationality } from "country-flags-svg";
import pkLogo from 'assets/images/pk.png';
import Portal from 'components/Portal';

const ProfileCardFlagImg = ({ profile }) => {
  const {
    getTooltipProps: getFlagTooltipProps,
    setTooltipRef: setFlagTooltipRef,
    setTriggerRef: setFlagTriggerRef,
    visible: flagTooltipVisible,
  } = usePopperTooltip({
    trigger: "hover",
    placement: 'top',
  })

  const flagUrl = findFlagUrlByNationality(profile.nationality) || pkLogo;
  const additionalFlagUrl = profile.nationality_alt?.includes('European') ? pkLogo : findFlagUrlByNationality(profile.nationality_alt);
  const flagTooltipText = `Nationality: ${profile.nationality}` + (profile.nationality_alt ? `, ${profile.nationality_alt}` : '');

  return (
    <>
      <div ref={setFlagTriggerRef} className='gallery-profile-card__flag-img-wrap'>
        {<img 
          alt='flag' 
          src={flagUrl} 
          className='gallery-profile-card__flag-img'
        />}

        {additionalFlagUrl && 
          <img 
            alt='flag' 
            src={additionalFlagUrl} 
            className='gallery-profile-card__flag-img'
          />
        }
      </div>

      {flagTooltipVisible && 
        <Portal>
          <div
            className="popup"
            ref={setFlagTooltipRef}
            {...getFlagTooltipProps()}
          >
            {flagTooltipText}
          </div>
        </Portal>
      }
    </>
  )
}

export default ProfileCardFlagImg;