import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';
import { selectModalCount } from 'redux/selectors/selectors';

import { BUTTON_THEMES, SIZES } from 'config/constants';

import ICONS from 'assets/icons';
import BookingsByDateModal from 'components/BookingsByDateModal/BookingsByDateModal';
import Button from 'components/UI/Button/Button';
import Portal from 'components/Portal';


const BookingButton = (props) => {
  const [isBookingPopupOpen, setIsBookingPopupOpen] = useState(false);

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
    tooltipRef,
    triggerRef,
  } = usePopperTooltip(
    {
      offset: [0, 18],
      trigger: 'click',
      placement: 'top',
      visible: isBookingPopupOpen,
      onVisibleChange: setIsBookingPopupOpen,
      closeOnOutsideClick: false,
    }
  );

  const modalCount = useSelector((state) => selectModalCount(state));
  const isModalOpened = !!modalCount;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef?.contains(event.target)) {
        return null;
      }

      if (tooltipRef && !tooltipRef.contains(event.target) && !isModalOpened) {
        setIsBookingPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isBookingPopupOpen, isModalOpened, tooltipRef]);

  return (
    <>
      <Button
        className={props.className}
        ref={setTriggerRef}
        icon={ICONS.bookingNew}
        active={visible}
        title="Open callendar bookings modal"
        {...props}
      />

      {visible &&
        <Portal>
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ style: { zIndex: 90 } })}
          >
            <BookingsByDateModal />
          </div>
        </Portal>
      }
    </>
  );
};

export default BookingButton;
