export default (interactionObject, userId) => {
  let lastInteraction = '';

  // const interactionObject = props.searchSource === 'msgs' && !!contact.interactionObject_searched && !!props.search
  //   ? contact.interactionObject_searched // [ ]: is interactionObject_searched exist?
  //   : contact.interactionObject;

  if (!interactionObject?.type) return null

  switch (interactionObject.type) {
    case 1: {
      lastInteraction = 'Incoming call';
      break;
    }
    case 2: {
      lastInteraction = 'Outgoing call';
      break;
    }
    case 3: {
      lastInteraction = interactionObject.message;
    }
    case 4: {
      if (interactionObject.user_id === userId) {
        lastInteraction = 'You: ' + interactionObject.message;
        break;
      }
      else {
        lastInteraction = interactionObject.message;
        break;
      }
    }
    // case 8: {
    //   // // we don't need parse msg on attachment if it's system msg // [ ]: can interactionObject.type === 8 be?
    //   return <SystemMsg
    //     interaction={interactionObject}
    //     returnOnlyMsgString={true}
    //     userHour12={props.userHour12}
    //   />
    // }
    case 9: {
      if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
        lastInteraction = 'Incoming voice message'
        break;
      }
      if (
        interactionObject.attachments?.hasOwnProperty('images') &&
        interactionObject.attachments?.hasOwnProperty('videos')
      ) {
        lastInteraction = 'Incoming media'
        break;
      }
      if (interactionObject.attachments?.hasOwnProperty('videos')) {
        lastInteraction = 'Incoming video'
        break;
      }
      if (interactionObject.attachments?.hasOwnProperty('images')) {
        lastInteraction = 'Incoming image'
        break;
      }
      else {
        lastInteraction = interactionObject.message;
        break;
      }
    }
    case 10: {
      if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
        lastInteraction = 'Outgoing voice message'
        break;
      }
      if (
        interactionObject.attachments?.hasOwnProperty('images') &&
        interactionObject.attachments?.hasOwnProperty('videos')
      ) {
        lastInteraction = 'Outgoing media'
        break;
      }
      if (interactionObject.attachments?.hasOwnProperty('videos')) {
        lastInteraction = 'Outgoing video'
        break;
      }
      if (interactionObject.attachments?.hasOwnProperty('images')) {
        lastInteraction = 'Outgoing image'
        break;
      }
      else {
        lastInteraction = interactionObject.message;
        break;
      }
    }
    case 12: {
      lastInteraction = 'Incoming email'
      break;
    }
    default:
      lastInteraction = interactionObject.message;
  }

  return lastInteraction;
};
