import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { selectContactsByIds, selectIsMapStateSaved } from 'redux/selectors/selectors';
import { BUTTON_THEMES, SIZES } from 'config/constants';

import ICONS from 'assets/icons';
import Button from 'components/UI/Button/Button';


const MapButton = (props) => {
  const isMapStateSaved = useSelector(selectIsMapStateSaved);
  const activeCallers = useSelector((state) => selectContactsByIds(state, state.girlChats.tabs));

  const dispatch = useDispatch();

  const handleClick = () => {
    let mapProps; 

    if (props.profile) {
      mapProps = { profile: props.profile };
    } else {
      mapProps = { activeCallers };
    }

    dispatch(openModal(MODAL_TYPES.girlsMap, { mapProps }));
  }

  return (
    <Button
      className={props.className}
      icon={props.hasOwnProperty('profile') ? ICONS.mapDash : ICONS.map}
      onClick={handleClick}
      active={isMapStateSaved}
      title="Open map"
      disabled={props.hasOwnProperty('profile') && !props.profile}
      {...props}
    />
  )
}

export default MapButton;
