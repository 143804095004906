import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ICONS from 'assets/icons';
import { usePrevious } from 'hooks';
import { ALL_FILTER } from 'redux/ducks/contacts';
import { BUTTON_THEMES, INPUT_THEMES, MAIN_CLIENT_FILTERS, MAIN_GIRLS_FILTERS, SIZES } from 'config/constants';
import useActiveOperators from 'hooks/useActiveOperators';
import { createContactChat } from 'redux/ducks/clientChats';
import { classModifier } from 'utils';

import './SidebarContactsFilters.scss';
import SearchInput from 'components/SearchInput/SearchInput';
import SidebarContactsSelectFilter from './SidebarContactsSelectFilter';
import Button from 'components/UI/Button/Button';
import Counter from 'components/UI/Counter/Counter';

const classPrefix = 'sidebar-contacts-filters';

const convertOperatorToFilterObject = (operator) => ({
  name: String(operator.id),
  value: (operator.alias || operator.username).slice(0, 2).toUpperCase(),
})


const SidebarContactsFilters = ({
  initialQuery,
  activeTab,
  activeSelectFilter,
  searchSource,
  filtersCounters = {},
  ...props
}) => {
  const [query, setQuery] = useState(initialQuery || '');
  const activeOperatorsNames = useActiveOperators(filtersCounters, convertOperatorToFilterObject)

  const dispatch = useDispatch();

  const prevActiveTab = usePrevious(activeTab);
  const isContactsSearch = searchSource === 'contacts';

  const createChat = () => dispatch(createContactChat())

  const getFilters = () => {
    let mainFilters;
    let secondaryFilters;
    let activeFilter;

    if (activeTab === 'clients') {
      mainFilters = MAIN_CLIENT_FILTERS;
      secondaryFilters = activeOperatorsNames;
      activeFilter = props.clientsMainActiveFilter;
    } else {
      mainFilters = MAIN_GIRLS_FILTERS;
      secondaryFilters = [];
      activeFilter = props.girlsMainActiveFilter;
    }

    return [mainFilters, secondaryFilters].map((filters, i) => {
      if (!filters.length) return null;

      return (
        <div
          key={i}
          className={`${classPrefix}__filters-wrap`}
        >
          {filters.map((filter) => {
            const topCounter = filtersCounters[filter.name]?.topCounter;
            const bottomCounter = filtersCounters[filter.name]?.bottomCounter;

            const totalConversationCounter = topCounter?.length ?? (topCounter || 0);
            const totalActiveChatsCounter = bottomCounter?.length ?? (bottomCounter || 0);

            const handleClick = () => props.toggleMainFilter(activeTab, filter.name);

            return (
              <div className={classModifier(`${classPrefix}__item`, filter.name)}>
                <Button
                  theme={BUTTON_THEMES.TAG_LIGHT}
                  active={activeFilter === filter.name}
                  onClick={handleClick}
                  iconSize={SIZES.M}
                  content={filter.value}
                />

                <Counter
                  className={`${classPrefix}__left-counter`}
                  count={totalConversationCounter}
                  title={`${totalConversationCounter} chats per day`}
                  underlied
                />

                <Counter
                  className={`${classPrefix}__right-counter`}
                  count={totalActiveChatsCounter}
                  color="--red-status-color"
                  title={`${totalActiveChatsCounter} active chats`}
                  underlied
                />
              </div>
            )
          })}
        </div>
      )
    })
  }

  useEffect(() => {
    if ((query && activeSelectFilter !== ALL_FILTER) || (activeTab !== prevActiveTab)) {
      setQuery(initialQuery);
    }
  }, [activeTab, activeSelectFilter]);

  return (
    <>
      <div className={`${classPrefix}__main-filters-wrap`}>
        {getFilters()}
      </div>

      <SearchInput
        theme={INPUT_THEMES.inversion}
        inputWrapClassName={`${classPrefix}__input-wrap`}
        inputClassName={`${classPrefix}__input`}
        startSearch={props.startSearch}
        stopSearch={props.stopSearch}
        showSearchBtn
        showClearBtn
        query={query}
        setQuery={setQuery}
        icon={ICONS.contactBook}
      >
        <Button
          className={`${classPrefix}__search-source-btn`}
          onClick={() => props.toggleSearchSource(activeTab)}
          theme={BUTTON_THEMES.PRIMARY_DARK}
          iconSize={SIZES.M}
          rounded={SIZES.XS}
        >
          {isContactsSearch ? <Button.Icon.usersNew /> : <Button.Icon.commentsNew />}
        </Button>
      </SearchInput>

      <div className="flexrow sidebar-contacts-filters__select-wrapper">
        <SidebarContactsSelectFilter
          isQuery={!!query.length}
          setQuery={setQuery}
          filters={props.filters}
          contactType={props.contactType}
          defaultFilter={activeSelectFilter}
        />

        {props.contactType === 'clients' && (
          <Button
            theme={BUTTON_THEMES.PRIMARY_INVERSION}
            onClick={createChat}
            icon={ICONS.plusCircle}
            iconSize={SIZES.L}
          />
        )}
      </div>
    </>
  );
}

export default React.memo(SidebarContactsFilters);
