import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { BUTTON_THEMES, SIZES } from 'config/constants';

import ICONS from 'assets/icons';
import Button from 'components/UI/Button/Button';


const MultiMessageButton = (props) => {
  const activeModal = useSelector(state => state.activeWindows.activeModals)
  const { type } = activeModal[activeModal.length - 1] || {};
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openModal(MODAL_TYPES.multiMessageModal));
  }

  return (
    <Button
      className={props.className}
      theme={BUTTON_THEMES.NONE_DARK}
      icon={ICONS.letters}
      onClick={handleClick}
      active={type === MODAL_TYPES.multiMessageModal}
      title="Open multi message sending modal"
      {...props}
    />
  )
}

export default MultiMessageButton;
