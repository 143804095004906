import API from 'api/api';
import LS from 'utils/localStorageAPI';
import { CONTACT_TYPES } from 'config/constants';
import { isEmptyObj } from 'utils';
import { normalize } from 'utils/normalizeContacts';
import {
  UPDATE_CLIENT,
  UPDATE_GIRL,
  REMOVE_CLIENT,
  REMOVE_GIRL,
} from './contacts';

const duckName = 'addressBook/';

export const GET_CONTACTS_PENDING = duckName + 'GET_CONTACTS_PENDING';
export const GET_CONTACTS_LIST = duckName + 'GET_CONTACTS_LIST';
export const UPDATE_CONTACTS_LIST = duckName + 'UPDATE_CONTACTS_LIST';
export const SET_TOTAL_CONTACTS_COUNT = duckName + 'SET_TOTAL_CONTACTS_COUNT';
export const SET_CONTACT_SEARCH_FILTERS = duckName + 'SET_CONTACT_SEARCH_FILTERS';

export const UPDATE_ACTIVE_CONTACT_ID = duckName + 'UPDATE_ACTIVE_CONTACT_ID';

export const GET_TAGS_PENDING = duckName + 'GET_TAGS_PENDING';
export const GET_TAG_LIST = duckName + 'GET_TAG_LIST';
export const UPDATE_TAG_LIST = duckName + 'UPDATE_TAG_LIST';

export const FILTER_CONTACTS_LIST = duckName + 'FILTER_CONTACTS_LIST';
export const UPDATE_FILTERED_CONTACTS_LIST = duckName + 'UPDATE_FILTERED_CONTACTS_LIST';

export const UPDATE_TAGS_FILTER = duckName + 'UPDATE_TAGS_FILTER';
export const CHANGE_CONTACT_TYPE = duckName + 'CHANGE_CONTACT_TYPE';
export const CHANGE_CONTACT_SORT = duckName + 'CHANGE_CONTACT_SORT';
export const TOGGLE_INCLUDE_UNSAVED = duckName + 'TOGGLE_INCLUDE_UNSAVED';
export const UPDATE_SEARCH = duckName + 'UPDATE_SEARCH';

export const TOGGLE_CREATE_CONTACT_FORM = duckName + 'TOGGLE_CREATE_CONTACT_FORM';

export const RESET_SEARCH_INPUT = duckName + 'RESET_SEARCH_INPUT';

export const ADR_BOOK_LIMIT = 30;

export const setContactsPending = (pending) => dispatch => {
  dispatch({
    type: GET_CONTACTS_PENDING,
    payload: pending
  });
}

export const setContactsSearchFilters = (filters) => dispatch => {
  dispatch({
    type: SET_CONTACT_SEARCH_FILTERS,
    payload: filters
  });
}

export const getContactsForAdrBook = (config) => dispatch => {
  if (!config.offset) {
    dispatch({
      type: GET_CONTACTS_PENDING
    });
  }

  return API.getOptimizedContactsForAdrBook(config)
    .then(res => {
      dispatch({
        type: config.offset ? UPDATE_CONTACTS_LIST : GET_CONTACTS_LIST,
        payload: {
          ...normalize(res.data),
          // totalCount: res.data.totalCount,
        }
      });
    })
    .catch(console.error);
}

export const filterContactsForAdrBook = (config) => dispatch => {
  if (!config.offset) {
    dispatch({
      type: GET_CONTACTS_PENDING
    });
  }

  return API.getOptimizedContactsForAdrBook(config)
    .then(res => {
      dispatch({
        type: config.offset ? UPDATE_FILTERED_CONTACTS_LIST : FILTER_CONTACTS_LIST,
        payload: {
          ...normalize(res.data),
          // totalCount: res.data.totalCount,
        }
      });
    })
    .catch(console.error);
}

export const getTagsForAdrBook = (config) => dispatch => {
  if (!config.offset) {
    dispatch({
      type: GET_TAGS_PENDING
    });
  }

  return API.getTagsForAdrBook(config)
    .then(res => {
      dispatch({
        type: GET_TAG_LIST,
        payload: {
          ...normalize(res.data),
        }
      });
    })
    .catch(console.error);
}

export const updateAdrBookTags = (tags, isUndo = false) => dispatch => {
  dispatch({
    type: UPDATE_TAG_LIST,
    payload: {
      tags: normalize(tags),
      isUndo: isUndo,
    }
  })
}

export const updateActiveAdrBookContactId = (contactId) => (dispatch, getState) => {
  const userId = getState().user.id;

  LS.setItem("adrBookContactId", contactId, userId);

  dispatch({
    type: UPDATE_ACTIVE_CONTACT_ID,
    payload: contactId
  });
}

export const toggleCreateContactForm = (show) => dispatch => {
  dispatch({
    type: TOGGLE_CREATE_CONTACT_FORM,
    payload: show
  });
}

export const changeContactType = (type, userId) => (dispatch) => {
  API.getContactsCountForAdrBook(type)
    .then(res => {
      dispatch({
        type: SET_TOTAL_CONTACTS_COUNT,
        payload: {
          totalCount: res.data,
        }
      });
    })
    .catch(console.error);

  LS.setItem("adrBookContactType", type, userId);

  dispatch({
    type: CHANGE_CONTACT_TYPE,
    payload: type
  });
}

export const changeContactSorting = (sortBy) => dispatch => {
  dispatch({
    type: CHANGE_CONTACT_SORT,
    payload: sortBy
  });
}

export const updateTagsFilter = (newTag) => dispatch => {
  dispatch({
    type: UPDATE_TAGS_FILTER,
    payload: newTag
  });
}

export const updateSearch = (query) => dispatch => {
  dispatch({
    type: UPDATE_SEARCH,
    payload: query
  });
}

export const toggleIncludeUnsaved = () => dispatch => {
  dispatch({
    type: TOGGLE_INCLUDE_UNSAVED
  });
}

export const resetAddrSearchInput = () => ({ type: RESET_SEARCH_INPUT });

const initialState = {
  contactsPending: false,
  entities: {},
  ids: [],
  prevActive: null,
  active: null,
  totalCount: null,

  tags: {
    ids: [],
    entities: {},
    active: {},
    pending: true,
    allContactsCount: null,
  },

  contactType: LS.getItem('adrBookContactType'),
  search: LS.getItem('adrBookSearch') || '',
  sortBy: 'last_interaction=descending',
  msgsListIsAscSort: LS.getItem('msgsListIsAscSort'),
  includeUnsaved: 0,
  searchFilters: LS.getItem('adrBookSearchFilters') || [],

  auxiliaryIds: [],

  isNewContactCreation: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS_PENDING: {
      
      return {
        ...state,
        contactsPending: true,
      }
    }

    case GET_CONTACTS_LIST: {
      return {
        ...state,
        contactsPending: false,
        // entities: addToEntitiesIfMissing(
        //   state.entities,
        //   action.payload.entities,
        //   "id"
        // ),
        ids: action.payload.result,
        // totalCount: action.payload.totalCount,
        // isNewContactCreation: false,
        auxiliaryIds: state.auxiliaryIds.length ? [] : state.auxiliaryIds
      }
    }

    case SET_CONTACT_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload
      }
    }

    case UPDATE_CONTACTS_LIST: {
      return {
        ...state,
        // entities: addToEntitiesIfMissing(
        //   state.entities,
        //   action.payload.entities,
        //   "id"
        // ),
        ids: [...state.ids, ...action.payload.result],
        // totalCount: action.payload.totalCount
      }
    }

    case FILTER_CONTACTS_LIST: {
      return {
        ...state,
        contactsPending: false,
        // entities: addToEntitiesIfMissing(
        //   state.entities,
        //   action.payload.entities,
        //   "id"
        // ),
        auxiliaryIds: action.payload.result,
        // totalCount: action.payload.totalCount,
        ids: state.ids.length ? [] : state.ids,
      }
    }

    case UPDATE_FILTERED_CONTACTS_LIST: {
      return {
        ...state,
        // entities: addToEntitiesIfMissing(
        //   state.entities,
        //   action.payload.entities,
        //   "id"
        // ),
        auxiliaryIds: [...state.auxiliaryIds, ...action.payload.result],
        contactsPending: false,
        // totalCount: action.payload.totalCount
      }
    }

    case SET_TOTAL_CONTACTS_COUNT: {
      return {
        ...state,
        totalCount: action.payload.totalCount,
        tags: {
          ...state.tags,
          allContactsCount: action.payload.totalCount,
        }
      }
    }

    case CHANGE_CONTACT_TYPE: {
      if (action.payload === state.contactType) return state;

      let updatedActiveTags = state.tags.active;

      if (action.payload !== 0 && !isEmptyObj(updatedActiveTags)) {
        updatedActiveTags = {}
      }

      return {
        ...state,
        contactType: action.payload,
        tags: {
          ...state.tags,
          active: updatedActiveTags
        }
      }
    }

    case CHANGE_CONTACT_SORT: {
      if (action.payload === state.sortBy) return state;

      return {
        ...state,
        sortBy: action.payload
      }
    }

    case UPDATE_ACTIVE_CONTACT_ID: {
      if (action.payload === state.active) return state;

      return {
        ...state,
        active: action.payload,
        isNewContactCreation: false,
      }
    }

    case UPDATE_TAGS_FILTER: {
      let updatedActiveTags = { ...state.tags.active };

      // clear tags filter
      if (action.payload === undefined) {
        updatedActiveTags = {};
      }
      // add new tag or remove if exist
      else {
        if (updatedActiveTags[action.payload]) {
          delete updatedActiveTags[action.payload];
        }
        else {
          updatedActiveTags[action.payload] = true;
        }
      }
      
      return {
        ...state,
        tags: {
          ...state.tags,
          active: updatedActiveTags
        }
      }
    }

    case GET_TAGS_PENDING: {
      return {
        ...state,
        tags: {
          ...state.tags,
          pending: true
        }
      }
    }

    case GET_TAG_LIST: {
      return {
        ...state,
        tags: {
          ...state.tags,
          pending: false,
          ids: action.payload.result,
          entities: { ...state.tags.entities, ...action.payload.entities },
          allContactsCount: action.payload.allContacts || state.tags.allContactsCount,
        }
      }
    }

    case UPDATE_TAG_LIST: {
      const tagIds = action.payload.tags.result;
      const newIds = tagIds.filter(id => state.tags.ids.indexOf(id) < 0);

      const tagsEntities = state.tags.entities;
      const newEntities = action.payload.tags.entities;

      let updatedActiveTags = null;
      let updatedIds = state.tags.ids;
      let updatedEntities = { ...tagsEntities, ...newEntities };

      if (newIds.length) {
        newIds.forEach((newId) => {
          const newTag = newEntities[newId];

          const index = updatedIds.findIndex((tagId) => {
            const currentTag = tagsEntities[tagId];

            if (
              newTag.caller_type === currentTag.caller_type && (
                !!currentTag.is_temporary || newTag.position < currentTag.position
              )
            ) {
              return true;
            }
          });

          if (index === -1) {
            updatedIds = [...updatedIds, newId];
          } else {
            updatedIds = [...updatedIds.slice(0, index), newId, ...updatedIds.slice(index)];
          }
        });
      }

      // is written to trigger a new request in AdrBookListWrap to get contacts and tags
      if (action.payload.isUndo) {
        return {
          ...state,
          tags: {
            ...state.tags,
            active: { ...state.tags.active },
          }
        };
      }

      if (!isEmptyObj(state.tags.active)) {
        tagIds.forEach((tagId) => {
          const tag = newEntities[tagId];

          if (tag.callersCount === 0) {
            updatedIds = updatedIds.filter((id) => id !== tag.id);

            if (state.tags.active[tag.id]) {
              updatedActiveTags = { ...state.tags.active };

              delete updatedActiveTags[tag.id];
            }
          } else {
            // is written to trigger a new request to retrieve contacts and tags
            // if the active tag filter has been changed
            if (state.tags.active[tag.id]) {
              updatedActiveTags = { ...state.tags.active };
            }
          }
        });
      }

      return {
        ...state,
        tags: {
          ...state.tags,
          ids: updatedIds,
          entities: updatedEntities,
          active: updatedActiveTags
            ? updatedActiveTags
            : state.tags.active,
        }
      };
    }

    case UPDATE_SEARCH: {
      return {
        ...state,
        search: action.payload
      }
    }

    case TOGGLE_INCLUDE_UNSAVED: {
      return {
        ...state,
        includeUnsaved: +!state.includeUnsaved
      }
    }

    case TOGGLE_CREATE_CONTACT_FORM: {
      if (action.payload === state.isNewContactCreation) return state;

      if (action.payload) {
        return {
          ...state,
          isNewContactCreation: action.payload,
          prevActive: state.active,
          active: null
        }
      } else {
        return {
          ...state,
          isNewContactCreation: action.payload,
          active: state.prevActive,
        }
      }
    }

    case REMOVE_CLIENT:
    case REMOVE_GIRL: {
      const idsKey = state.auxiliaryIds.length ? 'auxiliaryIds' : 'ids';

      let updatedIds = state.auxiliaryIds.length ? state.auxiliaryIds : state.ids;
      let updatedActive = state.active;

      if (updatedIds.length) {
        let idx = updatedIds.indexOf(action.payload);

        if (idx !== -1) {
          updatedIds = [
            ...updatedIds.slice(0, idx),
            ...updatedIds.slice(idx + 1)
          ]
        }
      }
      if (updatedActive = action.payload) {
        updatedActive = null;
      }

      return {
        ...state,
        [idsKey]: updatedIds,
        active: updatedActive
      }
    }
    case UPDATE_CLIENT:
    case UPDATE_GIRL: {
      if (!action.payload.isTypeChanged) return state;

      const updatedContact = action.payload.ids
        ? action.payload.entities[action.payload.ids[0]]
        : action.payload.entities[action.payload.pinIds[0]];

      const isNeedToDeleteIdFromState = state.contactType !== 0 && (
        (state.contactType === CONTACT_TYPES.CLIENT && updatedContact.type !== CONTACT_TYPES.CLIENT)
        || (state.contactType === CONTACT_TYPES.GIRL && updatedContact.type === CONTACT_TYPES.CLIENT)
      )

      if (!isNeedToDeleteIdFromState) return state;

      const idsKey = state.auxiliaryIds.length ? 'auxiliaryIds' : 'ids';

      let updatedIds = state.auxiliaryIds.length ? state.auxiliaryIds : state.ids;

      if (updatedIds.length) {
        let idx = updatedIds.indexOf(updatedContact.id);

        if (idx !== -1) {
          updatedIds = [
            ...updatedIds.slice(0, idx),
            ...updatedIds.slice(idx + 1)
          ]
        }
      }

      return {
        ...state,
        [idsKey]: updatedIds,
      }
    }

    case RESET_SEARCH_INPUT: {
      return {
        ...state,
        search: '',
      }
    }

    default:
      return state;
  }
}
