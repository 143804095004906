
import classNames from 'classnames';
import { components as globalComponents } from 'react-select';
import { useEffect, useMemo, useRef, useState } from 'react';
import uuid from 'react-tabs/lib/helpers/uuid';

import { classModifier } from 'utils';

import './Select.scss';
import Creatable from 'react-select/creatable';
import { AsyncPaginate } from 'react-select-async-paginate';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import Button from '../Button/Button';
import { BUTTON_THEMES, SELECT_THEMES, SIZES } from 'config/constants';


const Select = (props) => {
  const [uniqueId] = useState(uuid());

  const { components, className, type, disableTooltips, ...restProps } = props;
  const {
    Menu: DefaultMenu,
    MenuList: DefaultMenuList,
    Option: DefaultOption
  } = components || {};
  const menuRef = useRef();

  const checkIsMenuScrolled = () => {
    const scrollPosition = menuRef.current?.scrollTop;

    if (scrollPosition > 0 && scrollPosition !== undefined) {
      menuRef.current.classList.add('select__menu--scrolled');
    } else {
      menuRef.current.classList.remove('select__menu--scrolled');
    }
  }

  const SingleValue = ({ innerProps, ...props }) => {
    const { children, selectProps: { menuIsOpen } } = props;
  
    return (
      <Tooltip
        text={children}
        visibleWhenIsOverflowed
        extraCondition={!menuIsOpen}
        disable={disableTooltips}
      >
        {(setTriggerRef) => (
          <globalComponents.SingleValue
            innerProps={{ ref: setTriggerRef, ...innerProps }}
            {...props}
          >
            {children}
          </globalComponents.SingleValue>
        )}
      </Tooltip>
    )
  }

  const ValueContainer = (props) => {
    const { children } = props;

    return (
      <globalComponents.ValueContainer {...props}>
        <Button
          iconSize={SIZES.XS}
          theme={BUTTON_THEMES.NONE_LIGHT}
          active={props.selectProps.menuIsOpen}
        >
          <Button.Arrow />
          {children}
        </Button>
      </globalComponents.ValueContainer>
    )
  } 

  const InnerMenuList = (props) => {
    const { children } = props;
    
    return (
      <globalComponents.MenuList {...props}>
        {children}
      </globalComponents.MenuList>
    )
  }

  const MenuList = (props) => {
    const MenuList = DefaultMenuList || InnerMenuList;

    useEffect(() => {
      menuRef.current?.addEventListener('scroll', checkIsMenuScrolled);

      return () => {
        menuRef.current?.removeEventListener('scroll', checkIsMenuScrolled);
      };
    }, []);

    return (
      <menu>
        <MenuList
          innerRef={menuRef}
          {...props}
        >
          {props.children}
        </MenuList>
      </menu>
    )
  }

  const InnerMenu = (props) => {
    const { children } = props;

    return (
      <globalComponents.Menu {...props}>
        {children}
      </globalComponents.Menu>
    )
  }

  const Menu = (props) => {
    const Menu = DefaultMenu || InnerMenu;

    return (
      <Menu className="select__menu" {...props}>
        {props.children}
      </Menu>
    )
  }

  const Option = (props) => {
    const newProps = props.data.value === 'special'
      ? { className: 'select__option--special', ...props }
      : props

    return (
      <Tooltip
        text={props.data.label}
        visibleWhenIsOverflowed
        disable={disableTooltips}
      >
        {(setTriggerRef) => {
          const optionLabel = useMemo(() => (
            <>
              {props.data.leftIcon || null}

              <p
                className='select__option-text'
                ref={setTriggerRef}
              >
                {props.label}
              </p>

              {props.data.rightIcon || null}
            </>
          ), [props])

          if (DefaultOption) {
            return <DefaultOption {...newProps}>{optionLabel}</DefaultOption>
          } else {
            return <globalComponents.Option {...newProps}>{optionLabel}</globalComponents.Option>
          }
        }}
      </Tooltip>
    )
  }

  const DropdownIndicator = (props) => {
    return (
      <globalComponents.DropdownIndicator {...props}>
        <Button
          mode="details"
          iconSize={SIZES.S}
          theme={BUTTON_THEMES.none}
        />
      </globalComponents.DropdownIndicator>
    )
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.value === 'special' ? '#0092F2' : '#808080',
    })
  };

  const SelectComponent = props.creatable ? Creatable : AsyncPaginate;

  const handleClose = () => {
    const menuEl = document.querySelector(`#${uniqueId} .select__menu`);
    const containerEl = menuEl?.parentElement;
    const clonedMenuEl = menuEl?.cloneNode(true);

    if (!clonedMenuEl) return; // safeguard

    clonedMenuEl.classList.add("select__menu--close");
    clonedMenuEl.addEventListener("animationend", () => {
      containerEl?.removeChild(clonedMenuEl);
    });

    containerEl?.appendChild(clonedMenuEl);
  }

  return (
    <SelectComponent
      id={uniqueId}
      className={classNames(className, classModifier('select', type))}
      classNamePrefix='select'
      styles={{ ...customStyles, ...props.customStyles }}
      menuPortalTarget={document.querySelector("#dialogs-root")}
      components={{
        ...components,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        DropdownIndicator: () => null,
        SingleValue,
        ValueContainer,
        MenuList,
        Menu,
        Option,
        Input: () => null,
      }}
      onMenuClose={handleClose}
      {...restProps}
    />
  )
}

export default Select;
