import ICONS from 'assets/icons';

import { BUTTON_THEMES, SIZES } from 'config/constants';

import Button from 'components/UI/Button/Button';


const HistoryButton = (props) => (
  <Button
    className={props.className}
    icon={ICONS.historyNew}
    disabled
    {...props}
  />
)

export default HistoryButton;
