import { BUTTON_THEMES, SIZES } from 'config/constants';

import ICONS from 'assets/icons';
import Button from 'components/UI/Button/Button';


const VoicemailButton = (props) => (
  <Button
    className={props.className}
    icon={ICONS.phoneVoicemailNew}
    disabled
    {...props}
  />
);

export default VoicemailButton;
