import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

import { CONTACT_TYPES, ROUTES } from 'config/constants';
import parse from 'html-react-parser';

import {
  LIST_TYPES,
  deleteMailsToTrash,
  deleteMailsPermanently,
  DRAFT_STATUSES,
} from 'redux/ducks/mail';
import {
  classModifier,
  getContactAvatar,
  parseMsgText,
} from 'utils';
import {
  selectDefaultSession,
  selectActiveSessionByContactId,
} from 'redux/selectors/selectors';
import ICONS from 'assets/icons';
import { MAIL_REPLAYED_DATE_CONFIG } from 'config/dates-сonfig';
import { updateActiveAdrBookContactId } from 'redux/ducks/addressBook';
import { createSession, updateActiveSession } from 'redux/ducks/sessions';
import { getContactsById } from 'redux/ducks/contacts';
import { addContactsToEntities } from 'redux/ducks/contacts';

import './MailChatItem.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import API from 'api/api';
import MailAttachmentFiles from '../../MailAttachmentsFiles/MailAttachmentsFiles';
import DateTime from 'components/DateTime';
import InfoMenu from 'components/InfoMenu/InfoMenu';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { MailReplayForm, MailForwardForm } from './components';
import MsgText from 'containers/Chat/components/ChatTimeline/interactions/MsgText';
import QuickBookingGirlsListItemByDiva from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';

const MailChatItem = ({ currentMail, isLastItem, isNewestMsg }) => {
  const activeSession = useSelector(state => state.sessions.activeSession);
  const defaultSession = useSelector(selectDefaultSession);
  const isAdrBookPageOpen = useSelector(state => state.user.isAdrBookPageOpen);
  const contactSessionId = useSelector(state => selectActiveSessionByContactId(state, currentMail.caller?.id)) || 0;
  const isSalesPageOpen = useSelector(state => state.user.isSalesPageOpen);
  const item = useSelector(state => state.mail.activeMailChatMessages?.find(mail => mail.id === currentMail.id))

  const dispatch = useDispatch();

  const initPending = {
    delMsg: false,
    sendReply: false,
    delDraft: false,
  };
  const [pending, setPending] = useState(initPending);
  const [sanitizedHtml, setSanitizedHtml] = useState('');
  const [isReply, setIsReply] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [mailBookingData, setMailBookingData] = useState({ booking: null, infoForBooking: null });
  const [status, setStatus] = useState(null);
  const [isCollapsedMsg, setIsCollapsedMsg] = useState(false);

  const [sanitizedHtmlOption, setSanitizedHtmlOption] = useState({
    replace(domNode) {
      if (domNode.type === "text" && domNode.data.includes("[girl]")) {
        return (
          <MsgText
            key={domNode.data}
            interaction={{ body: domNode.data, hasUrls: true }}
            onGirlNameClick={handleGirlNameClick}
          />
        );
      }
    },
  });

  useEffect(() => {
    if (item.info) {
      setMailBookingData({
        booking: item.info.booking,
        infoForBooking: item.info,
      });
    }
  }, [item, item.info]);

  useEffect(() => {
    DOMPurify.setConfig({ ADD_ATTR: ['target'] });

    const sanitizedHtml = DOMPurify.sanitize(item.html);

    const container = document.createElement('div');
    container.innerHTML = sanitizedHtml;

    const tablesWithWidth = container.querySelectorAll('table[style*="width"]');
    tablesWithWidth.forEach((table) => {
      const currentStyle = table.style.width;
      if (currentStyle) {
        table.style.removeProperty('width');
      }
    });

    setSanitizedHtml(container.innerHTML);
    setSanitizedHtmlOption((prev) => ({
      ...prev,
      transform(reactNode, domNode, index) {
        if (mailBookingData.infoForBooking &&
          domNode.type === "text" &&
          domNode.data.includes("Divaescort. Booking")
        ) {
          return (
            <div className="mail-chat-item__book-button-wrap">
              {domNode.data}
              <button
                onClick={handleBookingFormOpen}
                className="mail-chat-item__book-button"
              >
                {mailBookingData.booking ? "Update" : "Book"}
              </button>
            </div>
          );
        }
        if (domNode.name === "img" && domNode.attribs?.src?.includes("[girl]")) {
          return {...reactNode, props: {...reactNode.props, src: parseMsgText(domNode.attribs?.src, true)}};
        }
        return reactNode;
      },
    }));

    if (item.draft) {
      setIsReply(true);
    }

    if(isNewestMsg) {
      setIsCollapsedMsg(true);
    }

  }, [item, mailBookingData.booking, mailBookingData.infoForBooking]);

  const somePending = Object.values(pending).some((value) => value);
  const globalPending = pending.delMsg || pending.sendReply || pending.delDraft;

  const itemFolder = item.folder_type?.toLowerCase();
  const isSent = itemFolder === LIST_TYPES.sent;
  const isTrash = itemFolder === LIST_TYPES.trash;
  const isSpam = itemFolder === LIST_TYPES.spam;
  const isOutgoing = item.user?.id || isSent;

  const senderName = isOutgoing
    ? item.user?.username || "Fastmail"
    : item.caller?.fn || item.from;


  const handleGirlNameClick = useCallback((e) => {
    e.preventDefault();
    const initialQuery = e.currentTarget.getAttribute('data-value');

    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      initialQuery,
      itemComponent: QuickBookingGirlsListItemByDiva,
      itemProps: {
        activeRecipientFromMail: item.caller,
      },
      isHandleClickCanBeRedefinedByItem: true,
    }));
  }, [item.caller?.id]);

  const handleBookingFormOpen = useCallback((e) => {
    e.preventDefault();
    if (mailBookingData.booking) {
      dispatch(openModal(MODAL_TYPES.bookingEditForm, {
        booking: mailBookingData.booking,
        profileId: mailBookingData.booking.profile_id,
        callerId: mailBookingData.booking.callerId,
      }));
    } else {
      dispatch(openModal(MODAL_TYPES.bookingForm, {
        initialProfile: mailBookingData.infoForBooking?.profiles[0],
        initialActiveSessionContact: mailBookingData.infoForBooking?.caller,
        activeSessionId: contactSessionId,
        date: mailBookingData.infoForBooking?.date,
        duration: mailBookingData.infoForBooking?.duration,
        type: mailBookingData.infoForBooking?.type,
        mailConversationId: item.conversation_id,
        ignoreTimezone: true,
      }));
    }
  }, [mailBookingData, item, contactSessionId])

  const handleDelete = async (e) => {
    e.stopPropagation();

    const isPermanently = isTrash || isSpam;

    if (!confirmDelete(isPermanently)) {
      return null;
    }

    setPending(prev => ({ ...prev, delMsg: true }));

    if (isPermanently) {
      dispatch(deleteMailsPermanently({ msgId: item.id }));
    } else {
      dispatch(deleteMailsToTrash({ msgId: item.id }));
    }

    setPending(prev => ({ ...prev, delMsg: false }));
  };

  const confirmDelete = (isPermanently) => {
    const text = isPermanently
      ? 'Just to confirm: selected message will be deleted permanently.'
      : 'Just to confirm: selected message will be moved to Trash.';

    return window.confirm(text);
  };

  const handleReplyBtnClick = (e) => {
    e.stopPropagation();

    setIsReply(true);
    setIsForward(false);
    setStatus(DRAFT_STATUSES.saved);
  };

  const handleForwardBtnClick = (e) => {
    e.stopPropagation();

    setIsForward(true);
    setIsReply(false);
  };

  const menuActions = [
    {icon: <ICONS.adrBook />, title: "Open in addressbook ", action: () => {
        if (!item.caller) {
          return null
        }

        API.switchAdrBookCaller(item.caller.id);

        dispatch(addContactsToEntities([item.caller]));
        dispatch(updateActiveAdrBookContactId(item.caller.id));

        if (!isAdrBookPageOpen) {
          window.open(ROUTES.adrBook, '_blank');
        }
      }
    },
    {icon: <ICONS.chatMsg />, title: "Open chat", action: () => {
      if (!item.caller) {
        return null;
      }
      
      dispatch(getContactsById(item.caller.id))
        .then((contact) => {
          const channel = new BroadcastChannel('adr_book_contact');
          channel.postMessage({ name: 'editedContact', editedContact: contact });
        })
    }
    },
    {
      icon: <ICONS.puzzle/>,
      isHidden: !item.caller || item.caller.type !== CONTACT_TYPES.CLIENT,
      title: contactSessionId ? "Switch session" : "Start session",
      action: () => {
        if (contactSessionId) {
          API.switchSalesSession(contactSessionId);

          dispatch(updateActiveSession(contactSessionId));
        } else {
          if (activeSession === 0) {
            dispatch(createSession(
              item.caller.id,
              [],
              defaultSession.comparedIds,
              defaultSession.activeFilters,
              defaultSession.additionalFilters,
              defaultSession.recentlyViewedIds,
            )).then((sessionId) => {
              dispatch(updateActiveSession(sessionId));
            })
          } else {
            dispatch(createSession(item.caller.id)).then((sessionId) => {
              dispatch(updateActiveSession(sessionId));
            });
          }

          if (!isSalesPageOpen) {
            window.open('/sales', '_blank');
          }
        }
      }
    },
    // for future template 2/5 actions menu
    // {icon: <ICONS.phoneSquare />, action: () => {}}, 
    // {icon: <ICONS.handPaper />, action: () => {}},
  ]

  const handleOpen = (index) => {
    const modalProps = {
      media: item.attachments,
      defaultActiveSlide: index,
      isGallery: true
    }

    dispatch(openModal(MODAL_TYPES.profileCarousel, modalProps));
  }

  const renderMailHTML = useMemo(() => (
    <div className="mail-chat-item__message-body">{parse(sanitizedHtml, sanitizedHtmlOption)}</div>
  ), [sanitizedHtml, sanitizedHtmlOption])

  return (
    <div
      className={classModifier("mail-chat-item", [
        isLastItem && "last",
        somePending && "some-pending",
        globalPending && "global-pending",
        !isOutgoing && "inbox",
      ])}
    >
      <div className="mail-chat-item__main">
        <div className="mail-chat-item__header" onClick={() => setIsCollapsedMsg(!isCollapsedMsg)}>
          <div className="mail-chat-item__avatar-wrap">
            <div className="mail-chat-item__avatar">
              <LazyLoadImage
                src={getContactAvatar(isOutgoing ? item.user : item.caller)}
              />
            </div>
          </div>
          <div className="mail-chat-item__header-content">
            <div className="mail-chat-item__header-top">
              <div className='mail-chat-item__info'>
                <div className="mail-chat-item__name-box">
                  <div className="mail-chat-item__name" title={senderName}>
                    {senderName}
                  </div>
                  <div className="mail-chat-item__email">from: {item.from}</div>
                  <div className="mail-chat-item__email">to: {item.to}</div>
                  <div className="mail-chat-item__email">subject: {item.subject}</div>
                </div>
                
                {!isOutgoing && <div className="mail-chat-item__info-icon">
                  <InfoMenu
                    title={senderName}
                    iconSize={14}
                    menuItems={menuActions}
                    icon={ICONS.infoNew}
                    dropWrapperProps={{
                      placement: 'top',
                    }}
                    />
                </div>}
                {item.folder_type &&
                  <div className="mail-chat-item__folder-type-wrapper">
                    <p className="mail-chat-item__folder-type">
                      {item.folder_type.toLowerCase()}
                    </p>
                  </div>
                }
              </div>

              <div className="mail-chat-item__actions">
                <button
                  className="mail-chat-item__action-btn"
                  title={
                    isTrash || isSpam
                      ? "Delete Permanently"
                      : "Delete (to Trash)"
                  }
                  disabled={somePending}
                  onClick={handleDelete}
                >
                  {pending.delMsg ? <Spinner /> : <ICONS.trash />}
                </button>

                <button
                  className="mail-chat-item__action-btn"
                  title="Forward"
                  disabled={isForward || somePending}
                  onClick={handleForwardBtnClick}
                >
                  <ICONS.forward />
                </button>

                <button
                  className="mail-chat-item__action-btn"
                  title="Reply"
                  disabled={isReply || somePending}
                  onClick={handleReplyBtnClick}
                >
                  <ICONS.reply />
                </button>
              </div>
            </div>

            <DateTime
              className="mail-chat-item__date"
              date={item.created_at}
              config={MAIL_REPLAYED_DATE_CONFIG}
            />
          </div>
        </div>

        {isCollapsedMsg && (
          <div className="mail-chat-item__message">
            {renderMailHTML}
            <ul className="mail-chat-item__attachments-list">
              {!!item.attachments.length &&
                item.attachments.map(
                  ({ id, fileName, loading, url, extension }, idx) => (
                    <MailAttachmentFiles
                      key={idx}
                      counter={idx+1}
                      fileName={fileName}
                      loading={loading}
                      id={id}
                      url={url}
                      fileExtension={extension}
                      onClick={() => handleOpen(idx)}
                    />
                  )
                )}
            </ul>
          </div>
        )}
        {isReply && (
          <MailReplayForm
            item={item}
            senderName={senderName}
            isOutgoing={isOutgoing}
            somePending={somePending}
            status={status}
            setStatus={setStatus}
            setPending={setPending}
            pending={pending}
            setIsReply={setIsReply}
            extraContent={renderMailHTML}
          />
        )}
        {isForward && (
          <MailForwardForm
            item={item}
            somePending={somePending}
            setPending={setPending}
            setIsForward={setIsForward}
            extraContent={renderMailHTML}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(MailChatItem);
