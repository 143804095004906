import React from 'react';
import { Field, useForm } from 'react-final-form';

import ICONS from 'assets/icons';

import './ContactNotesTab.scss';
import FormTextTextarea from 'components/FormTextTextarea/FormTextarea';
import CopyToClipboardButton from 'components/Buttons/CopyToClipboardButton';

const ContactNotesTab = ({ contactNote, setEmptyNoteValue }) => {
  const form = useForm(); 

  const handleDeleteNote = () => {
    form.change('note', null);
    setEmptyNoteValue();
  }

  return (
    <div className='contact-notes-tab'>
      <div className='contact-notes-tab__btn-wrapper'>
        <CopyToClipboardButton
          className='item__copy'
          value={contactNote}
          title='Copy note'
        />
        <button 
          type='button' 
          onClick={handleDeleteNote}
          className='contact-notes-tab__btn-close'
        >
          <ICONS.trashTransparent className='contact-notes-tab__close-icon'/>
        </button>
      </div>

      <div className='contact-notes-tab__text-wrapper'>
        <Field
          name="note"
          autosize
          component={FormTextTextarea}
          inputProps={{ placeholder: "Add note" }}
          className='contact-notes-tab__textarea'
          inputClassName="contact-notes-tab__input"
        />
      </div>
    </div>
  )
}

export default ContactNotesTab;
