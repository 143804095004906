import axios from 'axios';
import axiosInstance, { ADMIN_ROOT } from 'services/axiosInstance';
import axiosInstanceCronjobs from 'services/axiosInstanceCronjobs';

import { LIMIT } from 'redux/ducks/clientChats';
import { CONTACTS_LIMIT, GIRLS_LIMIT, CHAT_CONTACTS_LIMIT } from 'redux/ducks/contacts';
import { ADR_BOOK_LIMIT } from 'redux/ducks/addressBook';
import { ROOMS_LIMIT } from 'redux/ducks/rooms';
import { DIVA_GIRLS_LIMIT } from 'redux/ducks/divaGirls';
import { getDateByTimezoneOffset, LS, getTelegramServerData } from 'utils';
import {
  CONTACT_TYPES,
  GOOGLE_MAPS_API_KEY,
  MAIL_SEARCH_CONTACTS_LIMIT,
  MAIN_CLIENT_FILTERS,
  MAIN_GIRLS_FILTERS,
  USER_SETTINGS_COLORS_LIMIT,
  USER_SETTINGS_TAGS_LIMIT,
} from 'config/constants';
import { isNumber } from 'lodash';
import { MAIL_LIST_SORT_OPTIONS } from 'redux/ducks/mail';

const CancelToken = axios.CancelToken;

const baseUrlV2 = axiosInstance.defaults.baseURL.replace('/api/', '/apiV2/');
const baseUrlWithoutApi = axiosInstance.defaults.baseURL.replace('/api/', '');

const getSenderGsm = () => axiosInstance.get('phones/get-gsm');

const loginUser = (credentials) => {
  const Credentials = new FormData();

  Credentials.set('username', credentials.username);
  Credentials.set('password', credentials.password);
  Credentials.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
  if (!!credentials.ipPhoneId) Credentials.set('ip_phone_id', credentials.ipPhoneId);
  Credentials.set('computer', credentials.computer || '');

  return axiosInstance.post('auth-v2/login', Credentials, {
    baseURL: baseUrlV2,
  });
};

const sendVerificationCode = ({ username, code, ipPhoneId }) => {
  const Code = new FormData();

  Code.set('username', username);
  Code.set('code', code);
  if (!!ipPhoneId) Code.set('ip_phone_id', ipPhoneId);

  return axiosInstance.post('auth-v2/confirm-sms', Code, {
    baseURL: baseUrlV2,
  });
};

const resendVerificationCode = (username) => {
  const Username = new FormData();

  Username.set('username', username);

  return axiosInstance.post('auth-v2/resend-code', Username, {
    baseURL: baseUrlV2,
  });
};

const getIsIAmLoggedIn = () => {
  return axiosInstance.get('user/get-current-user');
};

const logoutUser = () => axiosInstance.post('auth/logout');

const updateUserTimezone = (timezone, automaticTimezoneMode) => {
  const Timezone = new FormData();

  timezone && Timezone.set('timezone', timezone);
  Timezone.set('isTimezoneAuto', Number(automaticTimezoneMode || 0));

  return axiosInstance.post('user/set-timezone', Timezone);
};

const getTimezones = () => axiosInstance.get('timezones');

const getConnectedContacts = () => axiosInstance.get('callers/active');

const markVoicemailAsListened = (id) => {
  const formData = new FormData();

  axiosInstance.post('voicemail/listen/' + id, formData);
};

const lockContact = (action, id, userId) => {
  let api = 'caller/unlock';

  if (action === 'lock') {
    api = 'caller/lock';
  }
  if (action === 'hard-lock') {
    api = 'caller/hard-lock';
  }

  const LockData = new FormData();

  LockData.set('callerId', id);
  userId && LockData.set('userId', userId);

  return axiosInstance.post(api, LockData);
};

export const getUnreadChats = (offset = 0) => {
  const config = {
    params: {
      limit: ROOMS_LIMIT,
      offset,
    },
  };

  return axiosInstance.get('chats/unread', config);
};

const removeMissedCallForAll = (callId, callerType) => {
  const MissedCall = new FormData();

  MissedCall.set('interactionId', callId);
  MissedCall.set('isUserCall', callerType === 9 ? 1 : 0);

  return axiosInstance.post('calls/remove-missed-call', MissedCall);
};

const putCallOnHold = (callId) => {
  const Call = new FormData();

  Call.set('interactionId', callId);

  return axiosInstance.post('calls/prepare-hold', Call);
};

const hangUpCallOnHold = (callId) => {
  const Call = new FormData();

  Call.set('interactionId', callId);

  return axiosInstance.post('calls/hang-up', Call);
};

const createTransferredCall = (callId, info) => {
  const Call = new FormData();

  Call.set('interactionId', callId);

  if (info) {
    Call.set('description', info.reason || '');
    Call.set('recipientId', info.operatorId || '');
  }

  return axiosInstance.post('calls/prepare-transfer', Call);
};

const createWarmTransferCall = (callId, info) => {
  const Call = new FormData();

  Call.set('interactionId', callId);

  if (info) {
    Call.set('description', info.reason || '');
    Call.set('participantIds', [info.operatorId]);
  }

  return axiosInstance.post('calls/prepare-warm-transfer', Call);
};

const getAllVoicemails = () => axiosInstance.get('voicemail/get-all');

let cancelGetInteractions;

const getInteractions = (contactId, page = null, withCancel = true, isArchive) => {
  if (cancelGetInteractions) {
    cancelGetInteractions('Request "getInteractions" canceled');
  }

  const config = {
    baseURL: baseUrlV2,
    params: {
      limit: LIMIT,
    },
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      if (withCancel) {
        cancelGetInteractions = c;
      }
    }),
  };

  if (page) {
    config.params.page = page;
  }

  if (isArchive) {
    return axiosInstance.get('archive-interactions/caller/' + contactId, config);
  }

  return axiosInstance.get('interactions/caller/' + contactId, config);
};

const changeContactDefaultChannel = (id, channel) => {
  const DefaultChannel = new FormData();

  DefaultChannel.set('id', id);
  DefaultChannel.set('channel', channel);

  return axiosInstance.post(
    'callers/change-default-channel-manually',
    DefaultChannel,
  );
};

const sendMessage = (message, recipient) => {
  const Message = new FormData();

  Message.set('message', message.text || '');
  if (message.shortcuts) {
    Message.set('shortcuts', message.shortcuts);
  }
  Message.set('To', recipient.id);

  if (message.repliedMsgId) {
    Message.set('repliedTo', message.repliedMsgId);
  }
  if (message.voiceMsg) {
    Message.set('voiceMsg', message.voiceMsg.blob);
    Message.set('voiceMsgDuration', message.voiceMsg.duration);
  }
  if (message.files) {
    message.files.forEach((file) => Message.append('files[]', file));
  }
  if (message.divaPhotoUrls) {
    Message.set('divaPhotoUrls', JSON.stringify(message.divaPhotoUrls));
  }
  if (message.list) {
    message.list.forEach((id) =>
      Message.append('list[]', id),
    );
  }
  if (message.isRoute) {
    Message.set('isRoute', Number(message.isRoute));
  }

  !!message.channel && Message.set('channel', message.channel);

  return axiosInstance.post('messages/send', Message);
};

const markMessageAsUnread = (msgId) => {
  return axiosInstance.get(`messages/mark-unread/${msgId}`);
};

const markContactChatAsRead = (contactId) => {
  return axiosInstance.get('callers/mark-read/' + contactId);
};

const setMessageReminder = (callerId, msgId, inTime) => {
  const Message = new FormData();

  Message.set('callerId', callerId);
  Message.set('messageId', msgId);
  Message.set('remind_at', inTime);

  return axiosInstance.post('messages/remind', Message);
};

const removeMessageReminder = (remindId) => {
  const Remind = new FormData();

  Remind.set('remindId', remindId);

  return axiosInstance.post('messages/delete-remind', Remind);
};

const deleteReminderFromWidget = (remindId) => {
  return axiosInstance.delete(`/reminders/delete?id=${remindId}`, {
    baseURL: baseUrlV2,
  });
};

// START Tasks And Reminders //

const getTasksAndReminders = (props) => {
  const config = {
    params: {
      ...props,
      offset: props.offset || 0,
      limit: LIMIT,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('/tasks', config);
}

const createTasks = ({
  description,
  title,
  caller_id,
  remind_at,
  user_id
}) => {
  const Task = new FormData();

  Task.set('description', description);
  Task.set('title', title);
  caller_id && Task.set('caller_id', caller_id);
  remind_at && Task.set('remind_at', remind_at);
  Task.set('assigned_to_user_id', user_id);

  return axiosInstance.post(
    'tasks/create',
    Task,
    { baseURL: baseUrlV2 }
  );
}

const createWebmasterTask = ({
  description,
  title,
  caller_id,
  interaction_id,
  user_id,
  remind_at,
  telegram_message_id,
  message_text,
  telegram_group_id,
  telegram_member_id,
  telegram_username,
}) => {
  const Task = new FormData();

  Task.set('description', description);
  Task.set('title', title);
  caller_id && Task.set('caller_id', caller_id);
  remind_at && Task.set('remind_at', remind_at);
  interaction_id && Task.set('interaction_id', interaction_id);
  telegram_message_id && Task.set('telegram_message_id', telegram_message_id);
  message_text && Task.set('message_text', message_text);
  telegram_group_id && Task.set('telegram_group_id', telegram_group_id);
  telegram_member_id && Task.set('telegram_member_id', telegram_member_id);
  telegram_username && Task.set('telegram_username', telegram_username);
  Task.set('assigned_to_user_id', user_id);

  return axiosInstance.post(
    'web-master/create',
    Task,
    { baseURL: baseUrlV2 }
  );
}

const updateWebmasterTask = ({
  description,
  title,
  caller_id,
  webmaster_id,
  user_id,
  remind_at,
  interaction_id,
}) => {
  const Task = new FormData();

  Task.set('description', description);
  Task.set('title', title);
  caller_id && Task.set('caller_id', caller_id);
  remind_at && Task.set('remind_at', remind_at);
  interaction_id && Task.set('interaction_id', interaction_id);
  Task.set('assigned_to_user_id', user_id);

  return axiosInstance.post(
    `web-master/update/${webmaster_id}`,
    Task,
    { baseURL: baseUrlV2 }
  );
}

const updateTasks = ({
  description,
  title,
  caller_id,
  remind_at,
  user_id,
  id
}) => {
  const Task = new FormData();

  Task.set('description', description);
  Task.set('title', title);
  caller_id && Task.set('caller_id', caller_id);
  Task.set('remind_at', remind_at);
  Task.set('assigned_to_user_id', user_id);

  return axiosInstance.post(
    'tasks/update/' + id,
    Task,
    { baseURL: baseUrlV2 }
  );
}

const updateReminders = ({
  remind_at,
  id
}) => {
  const Task = new FormData();
  remind_at && Task.set('remind_at', remind_at);

  return axiosInstance.post(
    'reminders/update/' + id,
    Task,
    { baseURL: baseUrlV2 }
  );
}

const markAsDoneTasksOrReminders = (id) => {
  const config = {
    params: {
      id,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get(`tasks/mark-task-as-done/`, config);
}

const markAsInProgressTasksOrReminders = (id) => {
  const config = {
    params: {
      id,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get(`tasks/mark-task-as-in-progress/`, config);
}

const deleteTasks = (id) => {
  const config = {
    params: {
      id,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get(`tasks/delete/`, config);
}

const deleteReminders = (id) => {
  const config = {
    params: {
      id,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get(`reminders/delete/`, config);
}

const createMailRemind = ({
  conversationId,
  caller_id,
  remind_at,
}) => {
  const Task = new FormData();

  Task.set('conversationId', conversationId);
  Task.set('callerId', caller_id);
  Task.set('remind_at', remind_at);

  return axiosInstance.post('messages/mail-remind', Task);
}

// END Tasks And Reminders //

const getConversationMedia = (id, page = null, isArchive) => {
  const config = {
    params: {
      callerId: id,
      limit: 10,
    },
    ...(isArchive ? { baseURL: baseUrlV2 } : {}),
  };

  if (page) {
    config.params.page = page;
  }

  if (isArchive) {
    return axiosInstance.get('archive-interactions/get-conversation-media/', config);
  }

  return axiosInstance.get('interactions/get-conversation-media/', config);
};

const getChatConversationMedia = (id, page = null) => {
  const Chat = new FormData();

  Chat.set('chatId', id);
  Chat.set('limit', LIMIT);

  page && Chat.set('page', page);

  return axiosInstance.post('chats/get-rooms-conversation-media', Chat);
};

const getContactLog = (id, offset = 0, limit = CONTACTS_LIMIT) => {
  const config = {
    baseURL: baseUrlV2,
    params: {
      id,
      limit,
      offset,
    },
  };

  return axiosInstance.get('log/caller', config);
};

const getAgentGirls = (id, offset = 0) => {
  const config = {
    params: {
      limit: CONTACTS_LIMIT,
      offset,
    },
  };

  return axiosInstance.get('callers/get-agent-girls/' + id, config);
};

const deleteGirlFromAgent = (girlId) => {
  const Girl = new FormData();

  Girl.set('girlId', girlId);

  return axiosInstance.post('callers/delete-girl-from-agent', Girl);
};

const addGirlToAgent = (agentId, girlId) => {
  const Girl = new FormData();

  Girl.set('girlId', girlId);
  Girl.set('agentId', agentId);

  return axiosInstance.post('callers/add-girl-to-agent', Girl);
};

const searchGirls = (query, agentId, offset = 0, limit) => {
  const config = {
    params: {
      q: query,
      agentId,
      offset,
      limit: limit || GIRLS_LIMIT,
    },
  };

  return axiosInstance.get('callers/search-girls', config);
};

const getActiveDivaProfiles = (offset = 0, limit) => {
  const config = {
    params: {
      limit: limit || CHAT_CONTACTS_LIMIT,
      offset,
    },
  };

  return axiosInstanceCronjobs.get('callers-chats/optimize-not-texted-for-long', config);
};

const getTemporaryGirlContacts = (offset = 0, limit) => {
  const config = {
    params: {
      limit: limit || CHAT_CONTACTS_LIMIT,
      offset,
      type: 2
    },
  };

  return axiosInstanceCronjobs.get('callers-chats/optimize-temporary-contacts', config);
};

const getDebtGirlContacts = (offset = 0, limit) => {
  const config = {
    params: {
      limit: limit || CHAT_CONTACTS_LIMIT,
      offset,
      type: 2
    },
  };

  return axiosInstanceCronjobs.get('callers-chats/optimize-debt-contacts', config);
};

const getAllContactsList = () => {
  return axios
    .all([
      getContactList({ type: 1, filter: MAIN_CLIENT_FILTERS[0].name }),
      getContactList({ type: 2, filter: MAIN_GIRLS_FILTERS[0].name }),
      getContactList({ type: 3, offset: 0, limit: -1 }),
    ])
    .then(
      axios.spread((clients, girls, agents) => ({ clients, girls, agents })),
    );
};

const getAllMessageCount = () => {
  return axiosInstance
    .all([getCountMessageByType(1), getCountMessageByType(2)])
    .then(axiosInstance.spread((clients, girls) => ({ clients, girls })));
};

const getContactsById = (ids) => {
  const Ids = new FormData();

  Ids.set('ids', Array.isArray(ids) ? ids : [ids]); // if its not array it means only one id:integer

  return axiosInstance.post('callers/list', Ids);
};

const getContactsByProfileDivaId = (ids) => {
  const Ids = new FormData();

  const config = {
    baseURL: baseUrlV2,
    ids
  }

  Ids.set("ids", ids);

  return axiosInstance.get(`callers/get-by-profile-ids?ids=[${ids}]`, config);
};

const getContactByProfileId = (id) => {
  const config = {
    baseURL: baseUrlV2,
  };

  return axiosInstance.get(`callers/get-full-by-profile-id?profileId=${id}`, config);
};

const getCallerIdByProfileDivaId = (id) => {
  const Id = new FormData();

  const config = {
    baseURL: baseUrlV2,
    id
  }

  Id.set("profileId", id);

  return axiosInstance.get(`callers/get-by-profile-id?profileId=${id}`, config);
};

// FIXME: remove this function with serverSide fix
// FIXME: photo does not work
const formatDefaultContactFields = (fieldsArray, fieldName) => {
  if (!fieldsArray || !fieldsArray.length) {
    return;
  }
  if (fieldsArray.length === 1 && !fieldsArray[0]) {
    return;
  }

  const fieldsData = fieldsArray.map((item, index) => {
    if (item.id) {
      return item;
    }

    const name = Object.keys(item)[0];
    const field = Object.values(item)[0];

    const result = {
      default: item.default,
      label: fieldName,
      [fieldName]: item[fieldName],
    };

    if (item.uuid) {
      result.uuid = item.uuid;
    }

    return result;
  });

  return JSON.stringify(fieldsData);
};

const saveContact = async (contact, action, initialType, cancelToken, isDeleteCustomId) => {
  let Contact = new FormData();
  const config = {};

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  const emails = formatDefaultContactFields(contact.emails, 'email');
  const isContactWithAgentId = +contact.type === 2 || +contact.type === 5;
  const tags = contact?.callerTags?.map((tag) => tag.id) || [];

  if(![
    +CONTACT_TYPES.CLIENT, 
    // +CONTACT_TYPES.GIRL
  ].includes(+contact.type)) {
    Contact.set('fn', contact.fn || '');
  }
  
  contact.tels && Contact.set('tels', JSON.stringify(contact.tels));
  emails && Contact.set('emails', emails);
  contact.telegram_nicknames && Contact.set("telegram_nicknames", JSON.stringify(contact.telegram_nicknames));
  contact.type && Contact.set('type', +contact.type);
  Contact.set('note', contact.note || '');
  Contact.set('urls', JSON.stringify(contact.urls ? contact.urls : []));
  Contact.set('is_banned_for_media', contact.is_banned_for_media ? 1 : 0);
  Contact.set('addresses', JSON.stringify(contact.addresses));
  Contact.set('ex', contact.is_ex !== undefined ? +contact.is_ex : 0);
  contact?.callerTags?.length && Contact.set('callerTags', JSON.stringify(tags));
  contact.notes?.length && Contact.set('notes', JSON.stringify(contact.notes));
  Contact.set('via', JSON.stringify(contact.via));
  Contact.set('available_card', Number(contact.available_card));
  typeof contact.subtype === 'number' && Contact.set('subtype', contact.subtype);
  Contact.set('category', contact.category ? contact.category : '');

  if(+contact.type === +CONTACT_TYPES.GIRL) {
    Contact.set('is_mistress', +!!contact.is_mistress);
    Contact.set('is_mistress_only', +!!contact.is_mistress_only);
    Contact.set('is_top', +!!contact.is_top);
    
    contact.fnParts?.custom_id && Contact.set('custom_id', contact.fnParts?.custom_id);
    contact.fnParts?.name && Contact.set('name', contact.fnParts?.name);
    contact.fnParts?.feedback_letters && Contact.set('feedback_letters', contact.fnParts?.feedback_letters);
    contact.fnParts?.location && Contact.set('location', contact.fnParts?.location);
    contact.fnParts?.nationality && Contact.set('nationality', contact.fnParts?.nationality);
    contact.fnParts?.emoji && Contact.set('emoji', contact.fnParts?.emoji);
    contact.fnParts?.prices && Contact.set('prices', contact.fnParts?.prices);

    contact.fnParts?.emoji_mistress && Contact.set('emoji_mistress', contact.fnParts?.emoji_mistress);
    contact.fnParts?.feedback_letters_mistress && Contact.set('feedback_letters_mistress', contact.fnParts?.feedback_letters_mistress);
    contact.fnParts?.custom_id_mistress && Contact.set('custom_id_mistress', contact.fnParts?.custom_id_mistress);
  } 
    
  action === 'update' && Contact.set('deleteCustomId', !isDeleteCustomId ? '' : true);

  if(+contact.type !== +CONTACT_TYPES.GIRL) {
    contact.custom_id && Contact.set('custom_id', contact.custom_id);
    contact.name && Contact.set('name', contact.name || "");
  }

  contact.description && Contact.set('description', contact.description);

  if (action === 'update') {
    initialType && Contact.set('initialType', initialType);
    contact.isActive && Contact.set('isActive', contact.isActive || '');
    contact.unreadCount &&
      Contact.set('unreadCount', contact.unreadCount || '');
  }
  if (isContactWithAgentId) {
    contact.agentId && Contact.set('agentId', contact.agentId);
  }

  const image = contact.image || contact.photo?.src || null;

  image && Contact.set('file', image);

  return action === 'update'
    ? axiosInstance.post('callers/update/' + contact.id, Contact, config)
    : axiosInstance.post('callers/create/', Contact);
};

const getNotForContacts = (config = {}) => {
  const apiConfig = {
    params: {
      limit: ADR_BOOK_LIMIT,
    },
  };

  if (config.ids) {
    apiConfig.params.ids = config.ids;
  }
  if (config.offset) {
    apiConfig.params.offset = config.offset;
  }
  if (config.search) {
    apiConfig.params.search = config.search;
  }

  return axiosInstance.get('callers/get-list-for-not-for', apiConfig);
};

const removeContact = (contactId) => {
  return axiosInstance.post(`callers/delete/${contactId}`);
};

const toggleContactPin = (contact) => {
  return axiosInstance.get(
    `callers/${contact.pinned ? 'un' : ''}pin/${contact.id}`,
  );
};

const toggleContactMark = (contact) => {
  return axiosInstance.get(
    `callers/${contact.marked ? 'un' : ''}mark/${contact.id}`,
  );
};

const toggleContactBlock = (contact, reason = '') => {
  const BlockData = new FormData();

  BlockData.set('reason', reason);
  BlockData.set('callerId', contact.id);

  return axiosInstance.post(
    `callers/${contact.blocked ? 'un' : ''}block`,
    BlockData,
  );
};

const getFilteredContacts = (action, type, offset = 0, mainFilter) => {
  const config = {
    params: {
      limit: CHAT_CONTACTS_LIMIT,
      type,
      offset,
      mainFilter,
    },
  };

  let apiAction;

  if (action === 'marked') {
    apiAction = 'optimize-marked';
  } else if (action === 'unread') {
    apiAction = 'optimize-unread-chats';
  } else if (action === 'related') {
    apiAction = 'optimize-related';
  } else if (action === 'available') {
    apiAction = 'optimize-available-girls';
  }

  return axiosInstanceCronjobs.get('callers-chats/' + apiAction, config);
};

const toggleContactActiveChat = (contact) => {
  const Data = new FormData();

  Data.set('id', contact.id);

  return axiosInstance.post(
    `callers/${contact.is_active_chat ? 'delete-from-active-chat' : 'add-to-active-chat'
    }`,
    Data,
  );
};

const getTopTags = (contactType) => {
  const config = {
    params: {
      contactType,
    },
  };

  return axiosInstance.get('tags/get-top-tags', config);
};

const searchTags = (query, contactType) => {
  const config = {
    params: {
      query,
      contactType,
    },
  };

  return axiosInstance.get('tags/search', config);
};

const getTagColors = ({ type, color, title, offset = 0 }) => {
  const config = {
    params: {
      type,
      color,
      title,
      limit: USER_SETTINGS_COLORS_LIMIT,
      offset,
    },
  };

  return axiosInstance.get('colors', config);
};

const getTags = ({ id, caller_type, title, color_id, color, offset = 0 }) => {
  const config = {
    params: {
      id,
      caller_type,
      title,
      color_id,
      color,
      limit: USER_SETTINGS_TAGS_LIMIT,
      offset,
    },
  };

  return axiosInstance.get('tags/get-all-tags', config);
};

const createTag = (title, color_id, caller_type) => {
  const Tag = new FormData();
  Tag.set('title', title);
  Tag.set('color_id', color_id);
  Tag.set('caller_type', caller_type);

  return axiosInstance.post('tags/create', Tag);
};

const addContactTag = (contactId, tag) => {
  const Tag = new FormData();

  Tag.set('title', tag.title);
  Tag.set('color', tag.color);

  return axiosInstance.post('callers/add-tag/' + contactId, Tag);
};

const removeContactTag = (contactId, tag) => {
  const Tag = new FormData();

  Tag.set('callerId', contactId);
  Tag.set('tagId', tag.id);

  return axiosInstance.post('callers/delete-tag', Tag);
};

const getAllTemplates = () => {
  return axios
    .all([
      axiosInstance.get('pattern-msg/get-patterns-by-caller-type?type=1'),
      axiosInstance.get('pattern-msg/get-patterns-by-caller-type?type=2'),
    ])
    .then(
      axios.spread((clientsTemplates, girlsTemplates) => ({
        clientsTemplates,
        girlsTemplates,
      })),
    );
};

const getMsgShortcuts = () => axiosInstance.get('shortcuts');

const changeBusyStatus = (status) => {
  const BusyStatus = new FormData();

  BusyStatus.set('status', status === 'online' ? 0 : 2);

  return axiosInstance.post('user/change-status', BusyStatus);
};

const changeUserAvatar = (ava, userId) => {
  const Avatar = new FormData();

  Avatar.set('file', ava);

  return axiosInstance.post('users/update-avatar/' + userId, Avatar);
};

const getUserRingtones = (type) => {
  return axiosInstance.get(`ringtones/get-all-by-type/${type}`);
};

const getAvailableRingtones = (
  triggerType,
  query,
  callerType,
  offset,
  limit = LIMIT,
) =>
  axiosInstance.get('ringtone/available', {
    baseURL: baseUrlV2,
    params: { type: triggerType, title: query, callerType, offset, limit },
  });

const getUserRingtonesByType = (type) =>
  axiosInstance.get('ringtone', { baseURL: baseUrlV2, params: { type } });

const changeRingtone = (id, triggerType, callerType) => {
  const Ringtone = new FormData();
  Ringtone.set('id', String(id));
  Ringtone.set('type', String(triggerType));
  Ringtone.set('callerType', String(callerType));

  return axiosInstance.post('ringtone/set-for-group', Ringtone, {
    baseURL: baseUrlV2,
  });
};

const updateUserRingtone = (type, ringtoneId) => {
  const Ringtone = new FormData();

  Ringtone.set('ringtoneId', ringtoneId);
  Ringtone.set('type', type);

  return axiosInstance.post('ringtones/update/', Ringtone);
};

const getCustomRingtones = (offset = 0) =>
  axiosInstance.get('user-ringtone', {
    baseURL: baseUrlV2,
    params: { limit: LIMIT, offset },
  });

const getDefaultCallersRingtones = () =>
  axiosInstance.get('ringtone/default-for-callers', {
    baseURL: baseUrlV2,
  });

const getAllAvailableCustomRingtones = (title, offset = 0) =>
  axiosInstance.get('user-ringtone/available', {
    baseURL: baseUrlV2,
    params: { title, limit: LIMIT, offset },
  });

const deleteCustomRingtones = (id) =>
  axiosInstance.get('user-ringtone/delete', {
    baseURL: baseUrlV2,
    params: { id },
  });

const addCustomRingtones = (callerId, ringtoneId, type) => {
  const Ringtone = new FormData();

  Ringtone.set('callerId', callerId);
  Ringtone.set('ringtoneId', ringtoneId);
  Ringtone.set('type', type);

  return axiosInstance.post('user-ringtone/add', Ringtone, {
    baseURL: baseUrlV2,
  });
};

let cancelGetChatInteractions;

const getChatInteractions = (chatId, page) => {
  if (cancelGetChatInteractions) {
    cancelGetChatInteractions('Request "getChatInteraction" canceled');
  }

  const config = {
    params: {
      limit: LIMIT,
    },
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancelGetChatInteractions = c;
    }),
  };

  if (page) {
    config.params.page = page;
  }

  return axiosInstance.get('chat?chatId=' + chatId, config);
};

const getGirlsChatInteraction = (page = null, restParams) => {
  const config = {
    params: {
      limit: LIMIT,
      ...restParams,
      ...(page ? { page } : {}),
    },
  };

  return axiosInstance.get('interactions/girls', config);
};

const getGirlsGlossaryChatInteraction = (page = null, restParams) => {
  const config = {
    params: {
      limit: LIMIT,
      ...restParams,
      ...(page ? { page } : {}),
    },
  };

  return axiosInstance.get('interactions/girls-glossary', config);
};

const sendMessageToChat = (message, chatId) => {
  const ChatMsg = new FormData();

  ChatMsg.set('message', message.text || '');
  ChatMsg.set('shortcuts', message.shortcuts);
  ChatMsg.set('To', chatId);

  if (message.sharedMsgId) {
    ChatMsg.set('sharedMsgId', message.sharedMsgId);
  }
  if (message.sharedContactId) {
    ChatMsg.set('sharedCallerId', message.sharedContactId);
  }
  if (message.sharedVoiceMsg) {
    ChatMsg.set('sharedVoiceMsg', message.sharedVoiceMsg);
  }
  if (message.voiceMsg) {
    ChatMsg.set('voiceMsg', message.voiceMsg.blob);
    ChatMsg.set('voiceMsgDuration', message.voiceMsg.duration);
  }
  if (message.files) {
    message.files.forEach((file) => ChatMsg.append('files[]', file));
    // ChatMsg.set('files', message.files);
  }

  return axiosInstance.post('chat/send', ChatMsg);
};

const updateChatMsg = (msg) => {
  const msgForm = new FormData();

  msgForm.set('message', msg.body);

  return axiosInstance.post('message/edit/' + msg.id, msgForm);
};

const removeChatMsg = (msgId) => {
  const msgForm = new FormData();

  return axiosInstance.post('message/delete/' + msgId, msgForm, {
    baseURL: baseUrlV2,
  });
};

const getAddressesByPostcode = (query = '') => {
  const config = {
    baseURL: baseUrlV2,
    params: {
      query
    },
  };

  return axiosInstance.get('address/api-query/', config);
}

const markChatAsRead = (chatId) => {
  const Chat = new FormData();

  Chat.set('chatId', chatId);

  return axiosInstance.post('chat/mark-read/', Chat);
};

const sendDeliveredStatus = (msgId) => {
  const MsgStatus = new FormData();

  return axiosInstance.post('message/viewed/' + msgId, MsgStatus);
};

const getRoomsById = (ids) => axiosInstance.get(`chats/list?ids=${ids}`);

const getRoomsList = (offset = 0) => {
  const config = {
    baseURL: baseUrlV2,
    params: {
      limit: CONTACTS_LIMIT,
      offset,
    },
  };

  return axiosInstance.get('chats/', config);
};

const searchRooms = (query, offset = 0, cancelToken) => {
  const config = {
    params: {
      limit: CONTACTS_LIMIT,
      q: query,
      offset,
    },
  };

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  return axiosInstance.get('chats/search', config);
};

const saveRoom = async (roomName, operatorIds, photo) => {
  const Room = new FormData();

  Room.set('roomName', roomName);
  Room.set('usersIds', JSON.stringify(operatorIds));
  Room.set('photo', photo || '');

  return axiosInstance.post('chats/create-room', Room);
};

const getChatRooms = (offset, limit) => {
  const config = {
    params: {},
  };

  if (limit) {
    config.params.limit = limit;
  }

  if (offset) {
    config.params.offset = offset;
  }

  return axiosInstance.get('chats/rooms', config);
};

const searchContacts = (
  type,
  query,
  offset = 0,
  limit = CONTACTS_LIMIT,
  cancelToken,
  girlsStatus = null,
  withNumber = null,
) => {
  const config = {
    baseURL: baseUrlV2,
    params: {
      query,
      type,
      limit,
      offset,
      girlsStatus: girlsStatus || null,
      withNumber: withNumber,
    },
  };

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  return axiosInstance.get('callers/search-by-callers', config);
};

const searchMessageInChat = (query, page = null) => {
  const SearchData = new FormData();

  SearchData.set('search', query);
  SearchData.set('limit', LIMIT);

  page && SearchData.set('page', page);

  return axiosInstance.post('interactions/girls', SearchData);
};

const searchContactMessage = (contactId, query, page = null, isArchive) => {
  const SearchData = new FormData();

  SearchData.set('query', query);
  SearchData.set('contact_id', contactId);
  SearchData.set('limit', LIMIT);

  page && SearchData.set('page', page);

  if (isArchive) {
    return axiosInstance.post('archive-interactions/search-message', SearchData, { baseURL: baseUrlV2 });
  }

  return axiosInstance.post('callers/search-message', SearchData);
};

const getMessageContext = (messageId, page = null) => {
  const Msg = new FormData();

  Msg.set('interactionId', messageId);
  Msg.set('limit', LIMIT);

  page && Msg.set('page', page);

  return axiosInstance.post('messages/get-context', Msg);
};

const getChatMessageContext = (messageId, page = null, chatId) => {
  const Msg = new FormData();

  Msg.set('interactionId', messageId);
  Msg.set('chatId', chatId);

  page && Msg.set('page', page);

  return axiosInstance.post('chat/get-message-context', Msg);
};

const getAllSessions = () => {
  return axiosInstance.get('sales-sessions/', { baseURL: baseUrlV2 });
};

const getAllOptimizedSessions = () => {
  return axiosInstance.get('sales-sessions/optimize-today', { baseURL: baseUrlV2 });
};

const getAllSessionsIds = () => {
  return axiosInstance.get('sales-sessions/get-active', { baseURL: baseUrlV2 });
};


const getMentionedProfiles = (callerIds) => {
  const MentionedProfiles = new FormData();

  MentionedProfiles.set('callerIds', JSON.stringify(callerIds));

  const config = {
    baseURL: baseUrlV2,
  };

  return axiosInstance.post(
    'sales-sessions/mentioned-names',
    MentionedProfiles,
    config,
  );
};

const createBooking = (data) => {
  const {
    date,
    rate,
    type,
    notes,
    isDuo,
    address,
    taxiFee,
    taxiNote,
    services,
    discount,
    duration,
    sessionId,
    profileId,
    duoConfig,
    priceType,
    caller_id,
    operatorId,
    profileUid,
    requirements,
    discount_rate,
    confirmation_status,
    notesAttachments,
    forceBooking,
    mailConversationId,
  } = data;

  const BookingV2 = new FormData();

  const bookings = [
    {
      type,
      duration,
      discount,
      taxiNote,
      discount_rate,
      rate: String(rate), // current rate by profile
      profile_id: profileId,
      contact_uid: profileUid,
      services: JSON.stringify(services),
      taxi_fee: taxiFee,
      price_type: priceType,
      requirements,
    },
  ];

  isDuo && bookings.push(duoConfig);

  BookingV2.set('date', date);
  BookingV2.set('notes', notes);
  BookingV2.set('caller_id', caller_id);
  BookingV2.set('session_id', sessionId);
  BookingV2.set('bookings', JSON.stringify(bookings));
  BookingV2.set('confirmation_status', confirmation_status);

  if (notesAttachments) {
    notesAttachments.forEach((image) =>
      BookingV2.append('notes_attachments[]', image),
    );
  }

  address && BookingV2.set('address', JSON.stringify(address));
  operatorId && BookingV2.set('operator_id', operatorId);
  requirements && BookingV2.set('requirements', JSON.stringify(requirements));
  forceBooking && BookingV2.set('forceBooking', forceBooking);
  mailConversationId && BookingV2.set('mail_conv_id', mailConversationId);

  return axiosInstance.post('booking/create', BookingV2, {
    baseURL: baseUrlV2,
  });
};

const getBooking = (bookingId) => {
  const config = {
    params: {
      bookingId,
    },
  };

  return axiosInstance.get('booking/get', config);
};

const getOptimizedChatBookings = (bookingIds) => {
  const config = {
    params: { bookingIds },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('bookings/optimize-all', config);
};

// const getBookingsByCallerId = (callerId, offset, filter) => {
//   const config = {
//     params: {
//       callerId,
//       offset,
//       limit: LIMIT,
//     },
//   };

//   if (filter) {
//     config.params.status = filter;
//   }

//   return axiosInstance.get('booking/get-bookings-by-caller', config);
// };

const getStatisticsBookingsByCallerId = ({ callerId, profileId }) => {
  return axiosInstance.get('bookings/statistics', { params: { callerId, profileId } });
}

const getBookingsDividedByDate = (search, offset = 0, limit = 20) => {
  const config = {
    params: {
      search,
      limit,
      offset,
    },
  };
  return axiosInstance.get('bookings/search', config);
};

const getContactsForBookings = (params) => {
  const { type, query, offset = 0, contactId, limit = CONTACTS_LIMIT } = params;

  const config = {
    params: {
      limit,
      type,
      offset,
    },
  };

  if (query) {
    config.params.query = query;
  }
  if (contactId) {
    config.params.contact_id = contactId;
  }

  return axiosInstance.get('bookings/get-contacts-for-bookings', config);
};

const getContactUnfinishedBookings = (callerId, offset) => {
  const config = {
    params: {
      callerId,
      offset,
      limit: LIMIT,
    },
  };

  return axiosInstance.get('booking/get-unfinished-bookings', config);
};

const updateBooking = (data, cancelToken) => {
  const {
    bookingId,
    sessionId,
    profileId,
    date,
    type,
    duration,
    rate,
    discount_rate,
    isSuccess,
    reason,
    description,
    operatorId,
    requirements,
    caller_id,
    services,
    confirmation_status,
    taxiFee,
    priceType,
    discount,
    notes,
    address,
    contact_uid,
    duoConfig,
    isDuo,
    taxiNote,
    uidGroup,
    notesAttachments,
    forceBooking,
  } = data;

  const config = { baseURL: baseUrlV2 };

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  const BookingV2 = new FormData();

  const bookings = [
    {
      type,
      duration,
      bookingId,
      discount,
      taxiNote,
      discount_rate,
      rate: String(rate), // current rate by profile
      profile_id: profileId,
      contact_uid,
      services: JSON.stringify(services),
      taxi_fee: taxiFee,
      price_type: priceType,
      requirements,
    },
  ];

  isDuo && bookings.push(duoConfig);

  BookingV2.set('date', date);
  BookingV2.set('notes', notes);
  BookingV2.set('caller_id', caller_id);
  BookingV2.set('session_id', sessionId);
  BookingV2.set('is_success', +isSuccess);
  BookingV2.set('bookings', JSON.stringify(bookings));
  BookingV2.set('confirmation_status', confirmation_status);

  reason && BookingV2.set('reason', reason);
  operatorId && BookingV2.set('operator_id', operatorId);
  description && BookingV2.set('description', description);
  address && BookingV2.set('address', JSON.stringify(address));
  requirements && BookingV2.set('requirements', JSON.stringify(requirements));
  contact_uid && BookingV2.set('contact_uid', contact_uid);
  uidGroup && BookingV2.set('uuid_group', uidGroup);
  notesAttachments && BookingV2.set('notes_attachments', notesAttachments);
  forceBooking && BookingV2.set('forceBooking', forceBooking);

  return axiosInstance.post('booking/update', BookingV2, config);
};

const deleteAttachment = (booking_id, attachment_number) => {
  const Attachment = new FormData();

  Attachment.set('booking_id', booking_id);
  Attachment.set('attachment_number', attachment_number);

  return axiosInstance.post('bookings/delete-note-attachment', Attachment, {
    baseURL: baseUrlV2,
  });
};

const deleteBookingV2 = (bookingId, canPermanentlyDeleteBooking) => {
  const config = {
    baseURL: baseUrlV2,
    params: { bookingId, canPermanentlyDeleteBooking },
  };

  return canPermanentlyDeleteBooking
    ? axiosInstance.delete("booking/permanent-delete", config)
    : axiosInstance.delete("booking/soft-delete", config);
};

const updateBookingsRequirementChecked = (id, isChecked) => {
  const Checked = new FormData();

  Checked.set('id', id);
  Checked.set('isChecked', +isChecked);

  return axiosInstance.post(
    'booking/toggle-booking-requirement-checked',
    Checked,
  );
};

const getClientPrevBookedProfiles = (clientId) => {
  const Booking = new FormData();

  Booking.set('callerId', clientId);

  return axiosInstance.post(
    'callers/get-count-of-booked-profiles-by-caller-id',
    Booking,
  );
};

const getOverdueBookings = () => {
  return axiosInstance.post('bookings/get-overdue');
};

const addBookingRequest = (config) => {
  const { description, text, booking_id, is_success } = config;
  const Request = new FormData();

  Request.set('text', text);
  Request.set('bookingId', String(booking_id));
  Request.set('isSuccess', String(+is_success));
  description && Request.set('description', description);

  return axiosInstance.post('callers-requests/add', Request);
};

const updateBookingRequest = (config) => {
  const { description, text, id, is_success } = config;
  const Request = new FormData();

  text && Request.set('text', text);
  Request.set('id', String(id));
  is_success !== undefined && Request.set('isSuccess', String(+is_success));
  typeof description === 'string' && Request.set('description', description);

  return axiosInstance.post('callers-requests/update', Request);
};

const deleteBookingRequest = (id) => {
  const Request = new FormData();

  Request.set('id', String(id));

  return axiosInstance.post('callers-requests/delete', Request);
};

const getContactRequests = (callerId, offset = 0, isSortByNewest) => {
  const config = {
    params: {
      limit: LIMIT,
      offset,
      callerId,
    },
  };

  if (isSortByNewest) {
    config.params.sortByNewest = 1;
  }

  return axiosInstance.get('callers-requests/index', config);
};

const getContactRequestsCount = (callerId) => {
  const config = {
    params: {
      callerId,
    },
  };

  return axiosInstance.get('callers-requests/count', config);
};

const getSuccessfulSessions = (offset = 0) => {
  const Session = new FormData();

  Session.set('limit', 20);
  Session.set('offset', offset);

  return axiosInstance.post('sales-sessions/get-booked-list', Session);
};

const getSessionById = (sessionId, viewingOfSession) => {
  const Session = new FormData();

  Session.set('sessionId', sessionId);
  viewingOfSession && Session.set('viewingOfSession', viewingOfSession);

  return axiosInstance.post('sales-session/', Session);
};

const createSession = (
  contactId,
  bufferedIds = [],
  comparedIds = [],
  activeFilters,
  additionalFilters,
  recentlyViewedIds,
) => {
  const Session = new FormData();

  Session.set('callerId', contactId);
  Session.set('bufferedIds', JSON.stringify(bufferedIds));
  Session.set('comparedIds', JSON.stringify(comparedIds));
  activeFilters && Session.set('activeFilters', JSON.stringify(activeFilters));
  additionalFilters &&
    Session.set('additionalFilters', JSON.stringify(additionalFilters));
  recentlyViewedIds &&
    Session.set('recentlyViewedIds', JSON.stringify(recentlyViewedIds));

  return axiosInstance.post('sales-sessions/start', Session);
};

const getCompletedSessions = () => {
  return axiosInstance.get('sales-sessions', { params: { isActive: 0 } });
};

const closeSession = (sessionId) => {
  const Session = new FormData();

  Session.set('sessionId', sessionId);

  return axiosInstance.post('sales-session/close', Session);
};

const deleteSession = (sessionId) => {
  const Session = new FormData();

  Session.set('sessionId', sessionId);

  return axiosInstance.post('sales-session/delete', Session);
};

const updateComparedIds = (sessionId, newComparedIds) => {
  const ComparedIds = new FormData();

  ComparedIds.set('sessionId', sessionId);
  ComparedIds.set('profilesIds', `[${newComparedIds}]`);

  return axiosInstance.post('sales-session/to-compare', ComparedIds);
};

const removeComparedId = (sessionId, newComparedIds) => {
  const ComparedId = new FormData();

  ComparedId.set('sessionId', sessionId);
  ComparedId.set('profilesIds', `[${newComparedIds}]`);

  return axiosInstance.post('sales-session/to-compare', ComparedId);
};

const addBufferId = (profileId, sessionId, profileIdNew, contact_uid = '') => {
  const BufferIds = new FormData();

  BufferIds.set('sessionId', sessionId);
  BufferIds.set('profilesIds', `[${profileId}]`);

  BufferIds.set('profileId', profileId);
  BufferIds.set('profileIdNew', profileIdNew);

  BufferIds.set('contact_uid', contact_uid);

  return axiosInstance.post('sales-session/add-to-buffer', BufferIds);
};

// profileIds may be a one (Number) or several ids (Array)
const addProfilesToSessionBuffer = ({ sessionId, profileIds }) => {
  const Data = new FormData();

  Data.set('sessionId', sessionId);
  Data.set('profilesIds', JSON.stringify(profileIds));

  return axiosInstance.post('sales-session/add-to-buffer', Data);
};

const removeBufferedId = (profileId, sessionId) => {
  const BufferId = new FormData();

  BufferId.set('sessionId', sessionId);
  BufferId.set('profilesIds', `[${profileId}]`);

  return axiosInstance.post('sales-session/remove-from-buffer', BufferId);
};

const updateActiveFilters = (sessionId, newActiveFilters) => {
  const ActiveFilters = new FormData();

  ActiveFilters.set('sessionId', sessionId);
  ActiveFilters.set('activeFilters', JSON.stringify(newActiveFilters));

  return axiosInstance.post('sales-session/active-filters', ActiveFilters);
};

const updateAdditionalFilters = (sessionId, additionalFilters) => {
  const AdditionalFilters = new FormData();

  AdditionalFilters.set('sessionId', sessionId);
  AdditionalFilters.set('additionalFilters', JSON.stringify(additionalFilters));

  return axiosInstance.post(
    'sales-session/additional-filters',
    AdditionalFilters,
  );
};

const addBookedId = (profileId, sessionId) => {
  const BookedIds = new FormData();

  BookedIds.set('sessionId', sessionId);
  BookedIds.set('profilesIds', `[${profileId}]`);

  return axiosInstance.post('sales-session/add-to-booked', BookedIds);
};

const removeBookedId = (profileId, sessionId) => {
  const BookedIds = new FormData();

  BookedIds.set('sessionId', sessionId);
  BookedIds.set('profilesIds', `[${profileId}]`);

  return axiosInstance.post('sales-session/remove-from-booked', BookedIds);
};

const addProposedId = (operatorId, sessionId, profileId) => {
  const ProposedIds = new FormData();

  ProposedIds.set('sessionId', sessionId);
  ProposedIds.set('operatorId', operatorId);
  ProposedIds.set('profileId', profileId);

  return axiosInstance.post('sales-session/add-to-proposed', ProposedIds);
};

const removeProposedId = (operatorId, sessionId, profileId) => {
  const ProposedIds = new FormData();

  ProposedIds.set('sessionId', sessionId);
  ProposedIds.set('operatorId', operatorId);
  ProposedIds.set('profileId', profileId);

  return axiosInstance.post('sales-session/remove-from-proposed', ProposedIds);
};

const getSessionsHistory = (type, id, config = {}) => {
  return axiosInstance.get(`${type}/sales-sessions/${id}`, config);
};

const toggleHistorySessionSuccess = (id) => {
  const HistorySession = new FormData();

  HistorySession.set('sessionId', id);

  return axiosInstance.post('sales-session/toggle', HistorySession);
};

const continueClosedSession = (id) => {
  const HistorySession = new FormData();

  HistorySession.set('sessionId', id);

  return axiosInstance.post('sales-session/continue', HistorySession);
};

const duplicateClosedSession = (id, clientId) => {
  const HistorySession = new FormData();

  HistorySession.set('sessionId', id);

  if (clientId) {
    HistorySession.set('callerId', clientId);
  }

  return axiosInstance.post('sales-session/duplicate', HistorySession);
};

const transferTheSession = (sessionId, operatorId) => {
  const TransferSession = new FormData();

  TransferSession.set('sessionId', sessionId);
  TransferSession.set('userId', operatorId);

  // return axiosInstance.post('sales-session/transfer', TransferSession);
  return axiosInstance.post(
    'sales-session/request-for-transfer-session',
    TransferSession,
  );
};

const responseForTransferSession = (sessionId, status) => {
  const AssistantSession = new FormData();

  AssistantSession.set('sessionId', sessionId);
  AssistantSession.set('status', status);

  return axiosInstance.post(
    'sales-session/response-for-transfer-session',
    AssistantSession,
  );
};

const addAssistantToSession = (sessionId, operatorId) => {
  const AssistantSession = new FormData();

  AssistantSession.set('sessionId', sessionId);
  AssistantSession.set('userId', operatorId);

  // return axiosInstance.post('sales-session/add-assistant', AssistantSession);
  return axiosInstance.post(
    'sales-session/request-for-add-to-session',
    AssistantSession,
  );
};

const responseForAddToAssistant = (sessionId, status) => {
  const AssistantSession = new FormData();

  AssistantSession.set('sessionId', sessionId);
  AssistantSession.set('status', status);

  return axiosInstance.post(
    'sales-session/response-for-add-to-session',
    AssistantSession,
  );
};

const removeAssistantFromSession = (sessionId, operatorId) => {
  const AssistantSession = new FormData();

  AssistantSession.set('sessionId', sessionId);
  AssistantSession.set('userId', operatorId);

  return axiosInstance.post(
    'sales-session/remove-partner-from-session',
    AssistantSession,
  );
};

const removeViewedInSession = (sessionId) => {
  const SessionViewed = new FormData();

  SessionViewed.set('sessionId', sessionId);

  return axiosInstance.post('sales-session/remove-viewed', SessionViewed);
};

const updateUserSalesFilters = (filtersKeys = []) => {
  const UpdateFilters = new FormData();

  UpdateFilters.set('filters', JSON.stringify(filtersKeys));

  return axiosInstance.post('user/update-sales-filters', UpdateFilters);
};

const changeDefaultContactTel = (telId) => {
  const DefaultTel = new FormData();

  return axiosInstance.post('callers/set-default-number/' + telId, DefaultTel);
};

const changeDefaultContactEmail = (emailId) => {
  const DefaultEmail = new FormData();

  return axiosInstance.post(
    'callers/set-default-email/' + emailId,
    DefaultEmail,
  );
};

const changeClientInSession = (sessionId, newClientId) => {
  const ChangeClientSession = new FormData();

  ChangeClientSession.set('sessionId', sessionId);
  ChangeClientSession.set('callerId', newClientId);

  return axiosInstance.post('sales-session/change-caller', ChangeClientSession);
};

const updateGalleryUrlInSession = (newUrl, sessionId) => {
  const GalleryUrl = new FormData();

  GalleryUrl.set('url', newUrl);
  GalleryUrl.set('sessionId', sessionId);

  return axiosInstance.post('sales-session/add-url', GalleryUrl);
};

const getClientSessionCounts = (id) => {
  const SessionCounts = new FormData();

  SessionCounts.set('id', id);

  return axiosInstance.post('callers/sales-session-counts', SessionCounts);
};

const getGirlBookings = (girlDivaId, offset = 0, dateRange) => {
  const GirlBookings = new FormData();

  GirlBookings.set('girlId', girlDivaId);
  GirlBookings.set('limit', LIMIT);
  GirlBookings.set('offset', offset);

  if (dateRange) {
    GirlBookings.set('dateRange', JSON.stringify(dateRange));

    // return new Promise((res, rej) => { setTimeout(() => { res({ data: [
    //   { callerId: 4, close_confirmed: 0, date: '2020-08-06 10:20:00', date_created: '2020-08-06 10:17:10', duration: '30 minutes', id: 263, is_success: 1, profile_id: girlDivaId, rate: '100', requirements: [], session_id: 790, status: 1, type: 'incall', usersIds: [1], },
    //   { callerId: 4, close_confirmed: 0, date: '2020-08-06 10:20:00', date_created: '2020-08-06 10:17:10', duration: '1 hour', id: 264, is_success: 1, profile_id: girlDivaId, rate: '100', requirements: [], session_id: 790, status: 1, type: 'outcall', usersIds: [1], },
    // ], }); }, 500); });
  }

  return axiosInstance.post('callers/get-girl-bookings', GirlBookings);
};

const getIsGirlHasBookingsForToday = (girlIds, userTimezone) => {
  const GirlBookings = new FormData();

  const from =
    getDateByTimezoneOffset(userTimezone).setHours(3, 0, 0, 0) -
    (userTimezone - new Date().getTimezoneOffset() * -1) * 60000;
  const to = from + 86400000 - 1;

  GirlBookings.set('girlIds', JSON.stringify(girlIds));
  GirlBookings.set('dateRange', JSON.stringify([from, to]));

  // return new Promise((res, rej) => { // FIXME: mock
  //   setTimeout(() => {
  //     const result = {};

  //     girlIds.forEach(id => {
  //       result[id] = true;
  //     });

  //     res({
  //       data: result,
  //     });
  //   }, 500);
  // });

  return axiosInstance.post(
    'callers/get-is-girl-have-bookings-for-today',
    GirlBookings,
  );
};

const removeUserFromRoom = (chatId, userId) => {
  const RemoveUserData = new FormData();

  RemoveUserData.set('chatId', chatId);
  RemoveUserData.set('userId', userId);

  return axiosInstance.post('chat/remove-user', RemoveUserData);
};

const addUsersFromRoom = (chatId, usersIds) => {
  const AddUserData = new FormData();

  AddUserData.set('chatId', chatId);
  AddUserData.set('usersIds', JSON.stringify(usersIds));

  return axiosInstance.post('chat/add-users', AddUserData);
};

const leaveRoom = (chatId) => {
  const LeaveFromRoomData = new FormData();

  LeaveFromRoomData.set('chatId', chatId);

  return axiosInstance.post('chat/leave', LeaveFromRoomData);
};

const removeRoomForAll = (chatId) => {
  const RemoveRoomData = new FormData();

  RemoveRoomData.set('chatId', chatId);

  return axiosInstance.post('chat/delete', RemoveRoomData);
};

const updateRoom = async ({ chatId, roomName, usersIds, photo }) => {
  const RoomInfoData = new FormData();

  RoomInfoData.set('chatId', chatId);
  RoomInfoData.set('roomName', roomName);
  RoomInfoData.set('usersIds', JSON.stringify(usersIds));
  photo && RoomInfoData.set('photo', photo || '');

  return axiosInstance.post('chat/edit', RoomInfoData);
};

const fetchCallsLog = (offset = 0) => {
  const Calls = new FormData();

  Calls.set('offset', offset);

  return axiosInstance.post('interactions/get-calls-by-limit', Calls);
};

const getUserNotifications = (offset = 0) => {
  const Notifications = new FormData();

  Notifications.set('offset', offset);

  return axiosInstance.post(
    'notifications/get-notifications-for-user',
    Notifications,
  );
};

const readUserNotification = (notificationId) => {
  const ReadNotification = new FormData();

  ReadNotification.set('notificationId', notificationId);

  return axiosInstance.post('notifications/set-read', ReadNotification);
};

const readAllUserNotifications = () => {
  return axiosInstance.post('notifications/set-all-read');
};

const getUserNotificationsCount = () => {
  return axiosInstance.post('notifications/get-count-of-unread-notifications');
};

const cancelConferenceInvitation = (conferenceId) => {
  return axiosInstance.get('conference/cancel-invitation', {
    params: { conferenceId },
  });
};

const leaveConference = (callSid) => {
  return axiosInstance.get('conference/leave-conference', {
    params: { callSid },
  });
};

const inviteParticipantsToConference = (confId, participantIds) => {
  const Participants = new FormData();

  Participants.set('userIds', JSON.stringify(participantIds));
  Participants.set('interactionId', confId);

  return axiosInstance.post('conference/conference-invite', Participants);
};

const getAllConferences = () => axiosInstance.get('conference/my-active-confs');

//----------------settings-------------------------------------------------------->
const changeSettingsUserMode = ({
  sms,
  call,
  communication_channel,
  is_call_active,
  is_sms_active,
  is_communication_channel_active,

}) => {
  const Mode = new FormData();

  Mode.set('sms', JSON.stringify(sms));
  Mode.set('call', JSON.stringify(call));
  Mode.set('communication_channel', JSON.stringify(communication_channel));
  Mode.set('is_call_active', JSON.stringify(is_call_active));
  Mode.set('is_sms_active', JSON.stringify(is_sms_active));
  Mode.set('is_communication_channel_active', JSON.stringify(is_communication_channel_active));

  return axiosInstance.post('user/set-user-mode', Mode);
};

const getSettingsAllNumbers = () => axiosInstance.get('user/get-all-numbers');

const setSettingsPhoneNumberAsDefault = (id, callerType) => {
  const PhoneNumber = new FormData();

  PhoneNumber.set('numberId', id);
  PhoneNumber.set('callerType', callerType);

  return axiosInstance.post('user/change-user-number', PhoneNumber);
};

//----------------settings-------------------------------------------------------->

const pinMsg = (msgId, chatId) => {
  const PinMsg = new FormData();

  PinMsg.set('interectionId', msgId);
  PinMsg.set('conversationId', chatId);

  return axiosInstance.post('message/pinn', PinMsg);
};

const unpinMsg = (chatId) => {
  const PinMsg = new FormData();

  PinMsg.set('conversationId', chatId);

  return axiosInstance.post('message/unpinn', PinMsg);
};

const pinClientMsg = (messageId, callerId) => {
  const PinMsg = new FormData();

  PinMsg.set('messageId', messageId);
  PinMsg.set('callerId', callerId);

  return axiosInstance.post('message/pinn-caller', PinMsg);
};

const deletePinnedMessage = (id) => {
  const PinMsg = new FormData();

  PinMsg.set('id', id);

  const config = {
    baseURL: baseUrlV2,
  };

  return axiosInstance.post(
    'interactions/delete-pinned-message',
    PinMsg,
    config,
  );
};

const sendTyping = (chatId, isTyping, text) => {
  const [id, type] = chatId.split('_').map((elem) => +elem);

  const Typing = new FormData();

  Typing.set('id', id);
  Typing.set('type', type);
  Typing.set('typing', isTyping);
  typeof text === 'string' && Typing.set('text', text);

  axiosInstance
    .post('chats/start-typing', Typing)
    .then(console.log('typing ping sent.'))
    .catch(console.error);
};

const sendTypeInMail = (conversationId, emailId) => {
  const Typing = new FormData();

  Typing.set('email_id', emailId);
  Typing.set('conversation_id', conversationId);

  axiosInstance.post('mail/start-typing', Typing, {
    baseURL: baseUrlV2,
  })
}

const clearAllMissedForMe = () => axiosInstance.post('calls/hide-missed-calls');

const createNewChat = (tel) => {
  const Tel = new FormData();

  Tel.set('tel', tel);

  return axiosInstance.post('callers/get-number-for-chats', Tel);
};

const removeMissedContactCallForMe = (callerId) => {
  const missedCall = new FormData();

  missedCall.set('callerId', callerId);

  return axiosInstance.post('caller/hide-missed', missedCall);
};

const removeMissedOperatorCallForMe = (userId) => {
  const missedCall = new FormData();

  missedCall.set('userId', userId);

  return axiosInstance.post('user/hide-missed', missedCall);
};

const getCountMessageByType = (type) =>
  axiosInstance.get(`callers/get-count-message?type=${type}`);

const updateUserInfo = async (info) => {
  const User = new FormData();
  let image = null;

  User.set('username', info.username);
  User.set('email', info.email || '');
  User.set('computer', info.computer || '');
  User.set('file', image);
  User.set('file', info.image || '');

  return axiosInstance.post('user/change-user-data', User);
};

const getContactReminders = (callerId, page = null) => {
  const ChatInfo = new FormData();

  ChatInfo.set('limit', LIMIT);
  ChatInfo.set('callerId', callerId);

  page && ChatInfo.set('page', page);

  return axiosInstance.post('messages/get-all-reminders', ChatInfo);
};

const getAllContactReminders = (offset = 0, limit = 20) => {
  const config = {
    params: {
      limit,
      offset
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('reminders/all', config);
};

const addGirlToAvailableByMsgId = (msgId) => {
  const Msg = new FormData();

  Msg.set('interactionId', msgId);

  return axiosInstance.post('callers/add-girl-to-available-by-message-id', Msg);
};

const addGirlToAvailable = (girlId) => {
  const Msg = new FormData();

  Msg.set('callerId', girlId);

  return axiosInstance.post('callers/add-girl-to-available', Msg);
};

const removeGirlFromAvailableByMsgId = (msgId) => {
  const Msg = new FormData();

  Msg.set('interactionId', msgId);

  return axiosInstance.post(
    'callers/remove-girl-from-available-by-message-id',
    Msg,
  );
};

const removeGirlFromAvailable = (girlId) => {
  const Msg = new FormData();

  Msg.set('callerId', girlId);

  return axiosInstance.post('callers/remove-girl-from-available', Msg);
};

const changeGirlsStatus = (status, girlId) => {
  const Msg = new FormData();

  Msg.set('status', status);
  Msg.set('callerId', girlId);

  return axiosInstance.post('callers/change-girls-status', Msg);
};

const addMessageToAvailableGlossary = (msgId, type) => {
  const Msg = new FormData();

  Msg.set('interactionId', msgId);
  Msg.set('type', type);

  return axiosInstance.post('callers/add-message-to-glossary', Msg);
};

const removeMessageFromAvailableGlossary = (glossaryId, msgId) => {
  const Msg = new FormData();

  Msg.set('glossaryId', glossaryId);
  Msg.set('interactionId', msgId);

  return axiosInstance.post('callers/delete-message-from-glossary', Msg);
};

const getGirlsByDivaId = (id, id_new) => {
  const Ids = new FormData();

  Ids.set('id', id);
  Ids.set('id_new', id_new);

  return axiosInstance.post('contacts/get-by-diva-ids', Ids);
};

const getGirlsByContactUid = (uids) => {
  const Ids = new FormData();

  Ids.set('uids', JSON.stringify(uids));

  return axiosInstance.post('callers/get-caller-by-uid', Ids);
};

const getAllWebmasterTasks = (page, search, isCompelted) => {
  const Chat = new FormData();

  page && Chat.set('page', page);
  search && Chat.set('search', search);
  isCompelted && Chat.set('completed', Number(isCompelted));

  return axiosInstance.post('web-master/get-all-tasks', Chat, {
    baseURL: baseUrlV2,
  });
};

const addWebmasterTask = (interactionId) => {
  const Msg = new FormData();

  Msg.set('interactionId', interactionId);

  return axiosInstance.post('web-master/add-task', Msg);
};

const completeWebmasterTask = (taskId, isCompleted) => {
  const Task = new FormData();

  Task.set('taskId', taskId);
  Task.set('isCompleted', +isCompleted);

  return axiosInstance.post('web-master/finish-task', Task);
};

const removeWebmasterTask = (taskId) => {
  const config = {
    baseURL: baseUrlV2,
    params: { id: taskId }
  };

  return axiosInstance.get('web-master/delete', config);
}

const setContactAudioStatus = (status, contactId) => {
  const Status = new FormData();

  Status.set('status', status);
  Status.set('callerId', contactId);

  return axiosInstance.post('callers/add-important-interaction-caller', Status);
};

const setRoomAudioStatus = (status, roomId) => {
  const Status = new FormData();

  Status.set('status', status);
  Status.set('chatId', roomId);

  return axiosInstance.post('chat/add-important-interaction', Status);
};

const getContactDateMsgContext = (contextDate, contactId, page = null) => {
  const Chat = new FormData();

  Chat.set('date', contextDate);
  Chat.set('callerId', contactId);
  Chat.set('limit', LIMIT);

  page && Chat.set('page', page);

  return axiosInstance.post('interactions/get-messages-by-date', Chat);
};

const getContactChatOfHistorySession = (sessionId, contactId, page = null) => {
  const Chat = new FormData();

  Chat.set('sessionId', sessionId);
  Chat.set('limit', LIMIT);
  Chat.set('callerId', contactId);

  page && Chat.set('page', page);

  return axiosInstance.post(
    'interactions/get-interactions-by-session-id',
    Chat,
  );
};

const cleanContactsUnreadCount = (activeTab) => {
  const Contact = new FormData();

  Contact.set('type', activeTab);

  return axiosInstance.post('callers/set-all-read', Contact);
};

const mergeContactCards = (newCard, deletingCardId) => {
  const Card = new FormData();

  Card.set('callerIdForDelete', deletingCardId);
  Card.set('callerNew', JSON.stringify(newCard));
  Card.set('callerIdNew', newCard.id);

  return axiosInstance.post('callers/merge-contact', Card);
};

// DIVA API

const getDivaGirls = (girlIds) => {
  return axiosInstance.get('diva/not-for', {
    params: {
      girlIds: JSON.stringify(girlIds instanceof Array ? girlIds : [girlIds]),
    },
  });
};

const getActiveDivaGirls = (params) => {
  const newParams = {};
  const config = {};

  const setParam = (key, value) => {
    newParams[key] = value;
  };

  if (!params.part) {
    params.part = 1;
  }
  if (!params.limit) {
    params.limit = DIVA_GIRLS_LIMIT;
  }
  setParam('format', 'json');
  params['same-params'] && setParam('same-params', params['same-params']);
  params['filterby'] && setParam('filterby', params['filterby']);
  params['host-profile'] && setParam('host-profile', params['host-profile']);
  params['sortby'] && setParam('sortby', params['sortby']);
  params['includeoff'] === false && setParam('includeoff', 1);
  params['include-banned'] === false && setParam('include-banned', 1);
  params['on-holidays'] === false && setParam('filter-is_on_holiday', 1);
  params['filter-is_available_now'] &&
    setParam('filter-is_available_now', params['filter-is_available_now']);
  params['search'] && setParam('search', params['search']);
  params['bestmatch'] && setParam('bestmatch', params['bestmatch']);
  params['limit'] && setParam('limit', params['limit']);
  params['part'] && setParam('part', params['part']);
  params['format'] && setParam('format', params['format']);
  params['filter-age_filter'] &&
    setParam('filter-age_filter', params['filter-age_filter']);
  params['filter-height_filter'] &&
    setParam('filter-height_filter', params['filter-height_filter']);
  params['filter-breast_filter'] &&
    setParam('filter-breast_filter', params['filter-breast_filter']);
  params['filter-ethnicity'] &&
    setParam('filter-ethnicity', params['filter-ethnicity']);
  params['filter-hair'] && setParam('filter-hair', params['filter-hair']);
  params['filter-location'] &&
    setParam('filter-location', params['filter-location']);
  params['filter-language'] &&
    setParam('filter-language', params['filter-language']);
  params['filter-price_filter'] &&
    setParam('filter-price_filter', params['filter-price_filter']);
  params['filter-dress_filter'] &&
    setParam('filter-dress_filter', params['filter-dress_filter']);
  params['filter-nationality'] &&
    setParam('filter-nationality', params['filter-nationality']);
  params['filter-city'] && setParam('filter-city', params['filter-city']);
  params['filter-services'] &&
    setParam('filter-services', params['filter-services']);
  params['filter-profileid'] &&
    setParam('filter-profileid', params['filter-profileid']);
  params['filter-ids'] && setParam('filter-ids', params['filter-ids']);
  params['opt-with-review'] &&
    setParam('opt-with-review', params['opt-with-review']);
  params['isPreviouslyBooked'] &&
    setParam('isPreviouslyBooked', params['isPreviouslyBooked']);
  config.params = newParams;

  if (params.cancelToken) {
    config.cancelToken = params.cancelToken;
  }

  return axiosInstance.get('diva/girls', config);
};

const getFilterFieldOptions = (name, params) => {
  const newParams = {};
  const config = {};
  const setParam = (key, value) => {
    newParams[key] = value;
  };

  if (!params.part) {
    params.part = 1;
  }
  if (!params.limit) {
    params.limit = DIVA_GIRLS_LIMIT;
  }
  setParam('format', 'json');
  setParam('name', name);
  params['limit'] && setParam('limit', params['limit']);
  params['part'] && setParam('part', params['part']);
  params['format'] && setParam('format', params['format']);
  params.selectedValues.age &&
    setParam('filter-age_filter', params.selectedValues.age);
  params.selectedValues.height &&
    setParam('filter-height_filter', params.selectedValues.height);
  params.selectedValues.breast &&
    setParam('filter-breast_filter', params.selectedValues.breast);
  params.selectedValues.ethnicity &&
    setParam('filter-ethnicity', params.selectedValues.ethnicity);
  // params.selectedValues.hair && setParam('filter-hair_length', params.selectedValues.hair);
  params.selectedValues.hair &&
    setParam('filter-hair', params.selectedValues.hair);
  params.selectedValues.location &&
    setParam('filter-location', params.selectedValues.location);
  params.selectedValues.language &&
    setParam('filter-language', params.selectedValues.language);
  params.selectedValues.price &&
    setParam('filter-price_filter', params.selectedValues.price);
  // params.selectedValues.dress && setParam('filter-dress', params.selectedValues.dress);
  params.selectedValues.dress &&
    setParam('filter-dress_filter', params.selectedValues.dress);
  params.selectedValues.nationality &&
    setParam('filter-nationality', params.selectedValues.nationality);
  params.selectedValues.city &&
    setParam('filter-city', params.selectedValues.city);
  params.selectedValues.services &&
    setParam('filter-services', params.selectedValues.services);
  config.params = newParams;

  if (params.cancelToken) {
    config.cancelToken = params.cancelToken;
  }

  return axiosInstance.get('diva/filter-field-update', config);
};

const getPrivatePhotos = (profileId) => {
  const params = {};

  params.format = 'json';
  params.girlId = profileId;

  return axiosInstance.get('diva/private-photo-v2', { params });
};

const getPrivateVideos = (profileId, part = 0) => {
  const params = {};

  params.limit = DIVA_GIRLS_LIMIT;
  params.part = part;
  params.format = 'json';
  params.girlId = profileId;

  return axiosInstance.get('diva/private-video', { params });
};

const getDivaFilters = ({ part = 1, limit = DIVA_GIRLS_LIMIT, ...params }) => {
  const config = {
    params: {
      limit,
      part,
      format: 'json',
      'sort-name': 'asc',
      ...params,
    },
  };

  return axiosInstance.get('diva/filters', config);
};

const getAdditionalFiltersFromDiva = () => {
  return axiosInstance.get('diva/get-sales-filters-with-value');
};

// Instead of sessionId may be also galleryId (for default session)
// profileIds may be a one (Number) or several ids (Array)
const addProfilesToGallery = (sessionId, profileIds, isBufferEmpty = true) => {
  return axiosInstance.get('diva/add-to-gallery', {
    params: {
      sessid: sessionId,
      list: profileIds,
      isFirst: isBufferEmpty ? 1 : 0,
    },
  });
};

const getProfilesGalleryLink = (sessionId) => {
  return axiosInstance.get('diva/get-link', {
    params: {
      sessid: sessionId,
    }
  });
};

const removeProfileFromGallery = (sessionId, profile) => {
  return axiosInstance.get('diva/remove-from-gallery', {
    params: {
      sessid: sessionId,
      list: profile,
    },
  });
};

const getGalleryUrlBySessionId = (sessionId) => {
  return axiosInstance.get('diva/gallery', {
    params: {
      sessid: sessionId,
    },
  });
};

const getActiveDivaGirlsByContactsIds = (girlIds) => {
  return axiosInstance.get('diva/get-diva-girls-by-callers-id', {
    params: {
      callerIds: Array.isArray(girlIds) ? JSON.stringify(girlIds) : girlIds,
    },
  });
};

const sendMailing = (message, ids, toGroup) => {
  const Mailing = new FormData();

  Mailing.set('To', JSON.stringify(ids));
  Mailing.set('message', message || '');
  Mailing.set('ToGroup', JSON.stringify(toGroup));

  return axiosInstance.post('messages/send-mailing', Mailing);
};

const getMailingHistory = (offset = 0, limit = 20, query = '') =>
  axiosInstance.get(
    `messages/get-mailing?limit=${limit}&offset=${offset}&query=${query}`,
  );

const getContactsForMerge = (offset = 0) => {
  const config = {
    params: {
      limit: CONTACTS_LIMIT,
      offset,
    },
  };

  return axiosInstance.get('callers/get-merge-contacts', config);
};

// Not For
const getClientNotFor = (caller_id) =>
  axiosInstance.get('caller-not-for/view', { params: { caller_id } });

const addClientNotFor = (contact_id, contact_ignore_id) => {
  const NotForData = new FormData();

  NotForData.set('caller_id', String(contact_id));
  NotForData.set('caller_ignore_id', String(contact_ignore_id));

  return axiosInstance.post('caller-not-for/create', NotForData);
};

const removeClientNotFor = (contact_id, contact_ignore_id) => {
  const RemoveData = new FormData();

  RemoveData.set('caller_id', String(contact_id));
  RemoveData.set('caller_ignore_id', String(contact_ignore_id));

  return axiosInstance.post('caller-not-for/delete', RemoveData);
};

const getPhoneLabels = () => axiosInstance.get('callers/get-contacts-labels');

const createPhoneLabels = (labels) => {
  const LabelsData = new FormData();

  labels && LabelsData.set('labels', JSON.stringify(labels));

  return axiosInstance.post('contacts/create-labels', LabelsData, {
    baseURL: baseUrlV2,
  });
};

const updatePhoneLabels = (labels) => {
  const LabelsData = new FormData();

  _.forEach(labels, (label, id) => LabelsData.set(id, label));

  return axiosInstance.post('contacts/update-labels', LabelsData, {
    baseURL: baseUrlV2,
  });
}

const getLinksLabels = () => axiosInstance.get('callers/get-links-labels');

const getNightBotReport = () => axiosInstance.get('night-bot/index');

const deleteNightBotReportSession = (session_id) => {
  const DeleteData = new FormData();

  DeleteData.set('session_id', String(session_id));

  return axiosInstance.post('night-bot/view', DeleteData);
};

const redirectIncomingCall = (call_id, user_redirect_id = null) => {
  const RedirectData = new FormData();

  RedirectData.set('call_id', String(call_id));
  user_redirect_id &&
    RedirectData.set('user_redirect_id', String(user_redirect_id));

  return axiosInstance.post('calls/receive-redirect', RedirectData);
};

const getGirlsMsgCountToday = (userTimezone) => {
  const MsgCountData = new FormData();

  const currentTimeByTimeZone = getDateByTimezoneOffset(userTimezone);
  const dateFromByTimeZone =
    currentTimeByTimeZone.getHours() < 4
      ? new Date(
        currentTimeByTimeZone.setDate(currentTimeByTimeZone.getDate() - 1),
      )
      : currentTimeByTimeZone;

  const timeFrom =
    dateFromByTimeZone.setHours(4, 0, 0, 0) -
    (userTimezone - new Date().getTimezoneOffset() * -1) * 60000;

  MsgCountData.set('timeFrom', timeFrom);

  return axiosInstance.post('interactions/girls-count', MsgCountData);
};

const sortProfileReviews = (profileId, sortBy) => {
  const config = {
    params: {
      profileId,
      sortBy,
    },
  };

  return axiosInstance.get('diva/sort-review', config);
};

const searchProfileReviews = (profileId, text) => {
  const config = {
    params: {
      profileId,
      text,
    },
  };

  return axiosInstance.get('diva/search-by-text-review', config);
};

const editProfileReview = (reviewId, text, is_published) => {
  const EditReviewData = new FormData();

  EditReviewData.set('reviewId', reviewId);
  text && EditReviewData.set('text', text);
  is_published !== null && EditReviewData.set('is_published', is_published);

  return axiosInstance.post('diva/edit-review', EditReviewData);
};

const deleteProfileReview = (reviewId) => {
  const DeletedReviewId = new FormData();

  DeletedReviewId.set('reviewId', reviewId);

  return axiosInstance.post('diva/delete-review', DeletedReviewId);
};

const changeUserTimeFormat = (hour12) => {
  const Hour12 = new FormData();

  hour12 && Hour12.set('isHour12', hour12);

  return axiosInstance.post('user/change-time-format', Hour12);
};

const takeFutureBooking = (sessionId, userId) => {
  const FutureBookingData = new FormData();

  FutureBookingData.set('sessionId', sessionId);
  FutureBookingData.set('userId', userId);

  return axiosInstance.post(
    'sales-session/take-future-booking',
    FutureBookingData,
  );
};

const getContactScheduledMsgs = (contactId, page = null) => {
  const config = {
    params: {
      callerId: contactId,
      limit: LIMIT,
    },
  };

  if (page) {
    config.params.page = page;
  }

  return axiosInstance.get('schedule-messages', config);
};

const getScheduledMsgsCount = (contactId) =>
  axiosInstance.get('/schedule-messages/get-total?callerId=' + contactId);

const createScheduledMsg = (msg) => {
  const Msg = new FormData();

  Msg.set('callerId', msg.contactId);
  Msg.set('body', msg.body);
  Msg.set('date', msg.date);
  msg.uid && Msg.set('uid', msg.uid);
  msg.bookingId && Msg.set('bookingId', msg.bookingId);

  return axiosInstance.post('schedule-messages/add', Msg);
};

const updateScheduledMsg = (msg) => {
  const Msg = new FormData();

  Msg.set('id', msg.id);
  msg.body && Msg.set('body', msg.body);
  msg.date && Msg.set('date', msg.date);
  msg.contactId && Msg.set('callerId', msg.contactId);
  msg.bookingId && Msg.set('bookingId', msg.bookingId);

  return axiosInstance.post('schedule-messages/edit', Msg);
};

const deleteScheduledMsg = (msgId) => {
  const Msg = new FormData();

  Msg.set('id', msgId);

  return axiosInstance.post('schedule-messages/delete', Msg);
};
let cancelGetSystemMsgs;

const getContactServiceMsgs = (contactId, page = null, withCancel = true) => {
  if (cancelGetSystemMsgs) {
    cancelGetSystemMsgs('Request "getSystemMsgs" canceled');
  }

  const config = {
    params: {
      limit: LIMIT,
    },
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      if (withCancel) {
        cancelGetSystemMsgs = c;
      }
    }),
  };

  if (page) {
    config.params.page = page;
  }

  return axiosInstance.get('interactions/caller-system/' + contactId, config);
};

const getServiceMsgsCount = (contactId) => {
  const config = {
    params: {
      callerId: contactId,
    },
  };

  return axiosInstance.get('interactions/caller-system', config);
};

//WebRTC
const offerStartCall = (operatorId) => {
  const Offer = new FormData();

  Offer.set('operatorId', operatorId);

  return axiosInstance.post('web-rtc/initiate-incoming-call', Offer);
};

const answerStartCall = (callId) => {
  const Answer = new FormData();

  Answer.set('interactionId', callId);

  return axiosInstance.post('web-rtc/pick-up', Answer);
};

const sendLocalDescription = (operatorId, description, type) => {
  const Answer = new FormData();

  Answer.set('operatorId', operatorId);
  Answer.set('description', JSON.stringify(description));
  Answer.set('type', type);

  return axiosInstance.post('web-rtc/send-local-description', Answer);
};

const sendIceCandidate = (operatorId, candidate) => {
  const IceCandidate = new FormData();

  IceCandidate.set('operatorId', operatorId);
  IceCandidate.set('candidate', JSON.stringify(candidate));

  return axiosInstance.post('web-rtc/init-connections', IceCandidate);
};

const dismissWebrtcCall = (callId) => {
  const Call = new FormData();

  Call.set('interactionId', callId);

  return axiosInstance.post('web-rtc/cancel', Call);
};

const hangUpWebrtcCall = (activeCallId) => {
  const Call = new FormData();

  Call.set('interactionId', activeCallId);

  return axiosInstance.post('web-rtc/hang-up', Call);
};

const sendConnectionStatus = (callId, status) => {
  const ConnectionStatus = new FormData();

  ConnectionStatus.set('interactionId', callId);
  ConnectionStatus.set('status', status);

  return axiosInstance.post('web-rtc/check-connection', ConnectionStatus);
};

//WebRTC conferences
const getWebrtcConferences = () =>
  axiosInstance.get('web-rtc-conference/get-all');

const joinToWebrtcConference = (conferenceId) => {
  const Conference = new FormData();

  Conference.set('conferenceId', conferenceId);

  return axiosInstance.post('web-rtc-conference/join', Conference);
};

const leaveWebrtcConference = () =>
  axiosInstance.post('web-rtc-conference/leave');

const inviteParticipantsToWebrtcConference = (conferenceId, operatorIds) => {
  const ConferenceInvite = new FormData();

  ConferenceInvite.set('conferenceId', conferenceId);
  ConferenceInvite.set('operatorIds', JSON.stringify(operatorIds));

  return axiosInstance.post('web-rtc-conference/invite', ConferenceInvite);
};

const changeWebrtcConferenceStatus = (conferenceId, isClosed) => {
  const ConferenceStatus = new FormData();

  ConferenceStatus.set('conferenceId', conferenceId);
  ConferenceStatus.set('is_closed', isClosed ? 1 : 0);

  return axiosInstance.post(
    'web-rtc-conference/change-conference-status',
    ConferenceStatus,
  );
};

const changeParticipantStatus = (participantId, status) => {
  const ConferenceParticipantStatus = new FormData();

  ConferenceParticipantStatus.set('activeParticipantId', participantId);
  ConferenceParticipantStatus.set('status', status);

  return axiosInstance.post(
    'web-rtc-conference/change-participant-connection-status',
    ConferenceParticipantStatus,
  );
};

const acceptWebrtcConference = (conferenceId) => {
  const Conference = new FormData();

  Conference.set('conferenceId', conferenceId);

  return axiosInstance.post('web-rtc-conference/accept-invitation', Conference);
};

const rejectWebrtcConference = (conferenceId) => {
  const Conference = new FormData();

  Conference.set('conferenceId', conferenceId);

  return axiosInstance.post('web-rtc-conference/reject-invitation', Conference);
};

const webrtcParticipantMute = (isMute) => {
  const Participant = new FormData();

  Participant.set('is_muted', isMute ? 1 : 0);

  return axiosInstance.post('web-rtc-conference/mute', Participant);
};

const webrtcConferenceMute = (isMute) => {
  const Participant = new FormData();

  Participant.set('is_muted_conference', isMute ? 1 : 0);

  return axiosInstance.post('web-rtc-conference/mute-conference', Participant);
};

const getMinifiedContactsWithOneEmail = (config = {}) => {
  // Returns minified contacts where each email has its own contact.
  // If the contact has two emails, two objects will be returned

  const apiConfig = {
    params: {
      limit: MAIL_SEARCH_CONTACTS_LIMIT,
    },
  };

  if (config.part) {
    apiConfig.params.part = config.part;
  }

  if (config.search) {
    apiConfig.params.query = config.search;
  }

  return axiosInstance.get('callers/search-for-email', apiConfig);
};

const getContactsForAdrBook = (config = {}) => {
  const apiConfig = {
    params: {
      limit: ADR_BOOK_LIMIT,
    },
  };

  if (config.sync) {
    apiConfig.params.sync = config.sync;
  }
  if (config.offset) {
    apiConfig.params.offset = config.offset;
  }
  if (config.contactType || config.contactType === "") {
    apiConfig.params.contactType = config.contactType;
  }
  if (config.search) {
    apiConfig.params.search = config.search;
  }
  if (config.includeUnsaved) {
    apiConfig.params.includeUnsaved = config.includeUnsaved;
  }
  if (config.activeTags?.[0]) {
    apiConfig.params.showEX = 1;
  } else if (!config.showEX) {
    apiConfig.params.showEX = config.showEX;
  }
  if (config.activeTags && Object.values(config.activeTags)?.length) {
    const tempTags = { ...config.activeTags };

    if (tempTags[0]) {
      delete tempTags[0];
    }

    if (Object.values(tempTags).length) {
      apiConfig.params.tagIds = JSON.stringify(Object.keys(tempTags));
    }
  }
  if (config.sortBy) {
    apiConfig.params.sortBy = config.sortBy;
  }

  return axiosInstance.get('callers/get-for-address-book', apiConfig);
};

const getContactsCountForAdrBook = (contactType) => {
  const config = {
    params: {},
  };


  if (contactType || contactType === "") {
    config.params.contactType = contactType;
  }

  return axiosInstanceCronjobs.get('address-book/optimize-total-callers', config);
};

const getOptimizedContactsForAdrBook = (config = {}) => {
  const apiConfig = {
    params: {
      limit: ADR_BOOK_LIMIT,
    },
  };

  if (config.cancelToken) {
    apiConfig.cancelToken = config.cancelToken;
  }

  if (config.searchFilters) {
    config.searchFilters.forEach(filter => {
      apiConfig.params[filter] = 1;
    })
  }

  if (config.sync) {
    apiConfig.params.sync = config.sync;
  }
  if (config.offset) {
    apiConfig.params.offset = config.offset;
  }
  if (config.contactType || config.contactType === "") {
    apiConfig.params.contactType = config.contactType;
  }
  if (config.search) {
    apiConfig.params.search = config.search;
  }
  if (config.includeUnsaved) {
    apiConfig.params.includeUnsaved = config.includeUnsaved;
  }
  if (config.activeTags?.[0]) {
    apiConfig.params.showEX = 1;
  } else if (!config.showEX) {
    apiConfig.params.showEX = config.showEX;
  }
  if (config.activeTags && Object.values(config.activeTags)?.length) {
    const tempTags = { ...config.activeTags };

    if (tempTags[0]) {
      delete tempTags[0];
    }

    if (Object.values(tempTags).length) {
      apiConfig.params.tagIds = JSON.stringify(Object.keys(tempTags));
    }
  }
  if (config.sortBy) {
    apiConfig.params.sortBy = config.sortBy;
  }

  return axiosInstanceCronjobs.get('address-book/optimize', apiConfig);
};

const getTagsForAdrBook = (config = {}) => {
  const apiConfig = {
    params: {
      limit: ADR_BOOK_LIMIT,
    },
  };

  if (config.searchFilters) {
    config.searchFilters.forEach(filter => {
      apiConfig.params[filter] = 1;
    })
  };

  if (config.cancelToken) {
    apiConfig.cancelToken = config.cancelToken;
  }
  if (config.sync) {
    apiConfig.params.sync = config.sync;
  }
  if (config.offset) {
    apiConfig.params.offset = config.offset;
  }
  if (config.contactType || config.contactType === "") {
    apiConfig.params.contactType = config.contactType;
  }
  if (config.search) {
    apiConfig.params.search = config.search;
  }
  if (config.includeUnsaved) {
    apiConfig.params.includeUnsaved = config.includeUnsaved;
  }
  if (config.activeTags?.[0]) {
    apiConfig.params.showEX = 1;
  } else if (!config.showEX) {
    apiConfig.params.showEX = config.showEX;
  }
  if (config.activeTags && Object.values(config.activeTags)?.length) {
    const tempTags = { ...config.activeTags };

    if (tempTags[0]) {
      delete tempTags[0];
    }

    if (Object.values(tempTags).length) {
      apiConfig.params.tagIds = JSON.stringify(Object.keys(tempTags));
    }
  }
  if (config.sortBy) {
    apiConfig.params.sortBy = config.sortBy;
  }

  return axiosInstanceCronjobs.get('address-book/optimize-dynamic-tags', apiConfig);
};

const getContactNotes = (callerId, offset = 0, limit = 20) => {
  const apiConfig = {
    params: {
      limit,
      offset,
      callerId,
    },
  };

  return axiosInstance.get('callers-notes', apiConfig);
};

const addFeedback = ({
  clientId,
  divaId,
  type,
  date,
  time,
  duration,
  sourceId,
  sourceType,
  text,
}) => {
  const feedback = new FormData();

  feedback.set('caller_id', clientId);
  feedback.set('diva_id', divaId);
  feedback.set('type', type);
  feedback.set('booking_date', date);
  feedback.set('booking_time', time);
  feedback.set('booking_duration', duration);
  feedback.set('source_id', sourceId);
  feedback.set('source_type', sourceType);
  feedback.set('body', text);

  return axiosInstance.post('callers-notes/add', feedback);
};

const editContactNote = (props) => {
  const Note = new FormData();

  Note.set('id', props.noteId);
  Note.set('body', props.text);
  Note.set('type', props.type);
  Note.set('diva_id', props.divaId);
  Note.set('source', props.sourceId);
  Note.set('booking_date', props.date);
  Note.set('booking_time', props.time);
  Note.set('caller_id', props.clientId);
  Note.set('source_type', props.sourceType);
  Note.set('booking_duration', props.duration);
  Note.set('date_created', props.date_created);

  return axiosInstance.post('callers-notes/edit', Note);
};

const deleteContactNote = (noteId) => {
  const Note = new FormData();

  Note.set('id', noteId);

  return axiosInstance.post('callers-notes/delete', Note);
};

const getEscortsForFeedback = ({
  query = '',
  offset = 0,
  limit = 20,
  cancelToken, // optional
}) => {
  const config = {
    params: { query, offset, limit },
    cancelToken,
  };

  return axiosInstance.get('callers/get-for-reviews', config);
};

const getOperatorsForFeedback = ({
  query = '',
  offset = 0,
  limit = 20,
  exclude,
  status,
  cancelToken,
}) => {
  const config = {
    params: { query, offset, limit },
    cancelToken,
  };

  if (exclude) {
    config.params.exclude = JSON.stringify(exclude);
  }
  if (status !== undefined) {
    config.params.status = status;
  }

  return axiosInstance.get('/users/search', config);
};

const getCancelBookingReasons = () => axiosInstance.get('booking/get-reasons');

const searchContactsByMsg = (type, msg, cancelToken) => {
  const Msg = new FormData();

  Msg.set('query', msg);
  Msg.set('type', type);

  const config = {};

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  return axiosInstance.post('callers/search-by-message', Msg, config);
};

const getGirlsWithStatus = (status, search) => {
  const config = {
    params: {
      status,
      ...(search ? { search } : {}),
    },
  };

  return axiosInstance.get('callers/get-girls-with-status', config);
};

const syncContacts = () => {
  return axiosInstance.get('callers/sync-contacts');
};

const getEmailsNew = ({
  offset,
  limit,
  type,
  mode,
  sortBy,
  emails_ids,
  emailId,
  search
} = {}) => {
  const params = {
    offset,
    limit,
    folder_type: type,
    sortBy,
    emails_ids,
  };

  if (mode && mode !== 'webmaster' && mode !== 'reception') {
    if (mode === 'starred') {
      params.is_starred = 1;
    }
    if (mode === 'unread') {
      params.viewed = 0;
    }
  }
  if (emailId) {
    params.emails_ids = [emailId];
  }
  if (sortBy === MAIL_LIST_SORT_OPTIONS[2].value) {
    params.attachments = true;
    params.sortBy = null;
  }
  if (sortBy === MAIL_LIST_SORT_OPTIONS[3].value) {
    params.member = true;
    params.sortBy = null;
  }
  if (search) {
    params.search = search;
    params.folder_type = '';
  }

  return axiosInstance.get('mail/search', { params, baseURL: baseUrlV2 });
};

const getMailNotes = ({ conversation_id } = {}) => {
  return axiosInstance.get("mail/get-notes", {
    baseURL: baseUrlV2,
    params: { conversation_id },
  });
};

const getMailChatMessages = (conversationId, folder) => {
  const apiConfig = {
    params: {
      conversationId,
      folder,
    },
  };

  return axiosInstance.get('mail/get-mail-conversation', apiConfig);
};

const getCountOfUnreadEmails = () => {
  return axiosInstance.get('mail/get-count-unread-messages');
};

const sendEmailMessage = ({ config, isBeacon }) => {
  const Email = new FormData();

  Email.set('body', config.body);
  Email.set('subject', config.subject);
  Email.set('recipient', JSON.stringify(config.recipient));
  Email.set('isDraft', Number(!!config.isDraft));
  Email.set('from', config.from);
  config.draftId && Email.set('draftId', config.draftId);
  config.delay && Email.set('timeDelay', config.delay);
  config.userId && Email.set('userId', config.userId);
  config.cc?.length && Email.set('cc', JSON.stringify(config.cc));
  config.bcc?.length && Email.set('bcc', JSON.stringify(config.bcc));

  // Beacon is for send unsaved draft on window unload
  if (isBeacon) {
    const { baseURL } = axiosInstance.defaults;

    return navigator.sendBeacon(`${baseURL}mail/send-message`, Email);
  }

  // Normal request by default
  return axiosInstance.post('mail/send-message', Email);
};

const sendEmailMessageWithFiles = ({ config, recipient }) => {
  const Email = new FormData();

  Email.set('text', config.text);
  Email.set('subject', config.subject);
  Email.set('recipient', recipient);
  Email.set('divaPhotoUrls', JSON.stringify(config.divaPhotoUrls));

  return axiosInstance.post('diva/send-email-with-photo-to-client', Email, {
    baseURL: baseUrlV2,
  });
};

const sendEmailForward = ({ config }) => {
  const Forward = new FormData();

  Forward.set('userId', config.userId);
  Forward.set('body', config.body || '');
  Forward.set('to', JSON.stringify(config.to));
  Forward.set('from', config.from);
  config.html && Forward.set('html', config.html);

  return axiosInstance.post('mail/forward', Forward, {
    baseURL: baseUrlV2,
  });
};


const sendEmailReply = ({ config, isBeacon }) => {
  const Reply = new FormData();

  Reply.set('body', config.body || '');
  Reply.set('replyOnId', config.replyOnId);
  Reply.set('isDraft', Number(!!config.isDraft));
  Reply.set('isForward', Number(!!config.isForward));
  Reply.set('recipient', JSON.stringify(config.recipient));
  Reply.set('userId', config.userId);
  Reply.set('from', config.from);
  config.draftId && Reply.set('draftId', config.draftId);
  config.delay && Reply.set('timeDelay', config.delay);

  // Beacon is for send unsaved draft on window unload
  if (isBeacon) {
    const { baseURL } = axiosInstance.defaults;

    return navigator.sendBeacon(`${baseURL}mail/reply`, Reply);
  }

  // Normal request by default
  return axiosInstance.post('mail/reply', Reply);
};

const deleteMailsToTrash = ({
  msgId,
  conversationsIds,
  limit,
  mode,
  offset,
  sortBy,
  folder,
}) => {
  const params = {};

  if (msgId) {
    params.id = msgId;
  } else if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.sortBy = sortBy;
    params.folder = folder;
    params.messageType = mode;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail/delete-message', {
    params,
    baseURL: baseUrlV2,
  });
};

const deleteMailsPermanently = ({
  msgId,
  conversationsIds,
  limit,
  offset,
  sortBy,
  mode,
  folder,
}) => {
  const params = {};

  if (msgId) {
    params.id = msgId;
  } else if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.sortBy = sortBy;
    params.folder = folder;
    params.messageType = mode;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail/permanently-delete', {
    params,
    baseURL: baseUrlV2,
  });
};

const deleteMailsFromSearchList = ({
  conversationsIds,
  limit,
  offset,
  search,
}) => {
  const params = {};

  if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.search = search;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail-search/delete', { params });
};

const moveMailsToFolder = ({
  conversationsIds,
  to,
  limit,
  offset,
  sortBy,
  mode,
  folder,
}) => {
  const params = {
    to,
    limit,
    offset,
    sortBy,
    folder,
    from: folder,
    messageType: mode,
    conversationIds: JSON.stringify(conversationsIds),
  };

  return axiosInstance.get('mail/move-to-folder', { params });
};

const archiveMails = ({
  conversationsIds,
  limit,
  offset,
  sortBy,
  mode,
  folder,
}) => {
  const params = {
    limit,
    offset,
    sortBy,
    folder,
    messageType: mode,
    conversationIds: JSON.stringify(conversationsIds),
  };

  return axiosInstance.get('mail/archive', { params });
};

const markMailsNotSpam = ({ conversationId }) => {
  const params = {
    conversationId,
  };

  return axiosInstance.get('mail/mark-un-spam', { params });
};

const setIsMailStarred = ({ conversationId, isStarred }) => {
  const Data = new FormData();

  Data.set('conversationId', conversationId);
  Data.set('isStarred', isStarred ? 1 : 0);

  return axiosInstance.post('/mail/set-starred', Data);
};

const undeleteMails = ({ msgId, conversationId }) => {
  const params = {};

  if (msgId) {
    params.id = msgId;
  } else if (conversationId) {
    params.conversationId = conversationId;
  }

  return axiosInstance.get('mail/undelete', { params });
};

const deleteMailDraft = (draftId) => {
  const params = {
    id: draftId,
  };

  return axiosInstance.get('mail/discard-draft', { params });
};

const uploadMailAttachments = ({ uploadFiles, draftId, replyOnId }) => {
  const Data = new FormData();

  uploadFiles.forEach((file) => Data.append('files[]', file));
  Data.set('draftId', draftId);
  replyOnId && Data.set('replyOnId', replyOnId);

  return axiosInstance.post('mail-attachment/attach-file-to-draft', Data);
};

const deleteMailAttachment = ({ delId }) => {
  const params = {
    id: delId,
  };

  return axiosInstance.delete('mail-attachment/delete', { params });
};

const cancelDelayedMail = ({ id }) => {
  const Data = new FormData();

  Data.set('uuid', id);

  return axiosInstance.post('mail/cancel-sending', Data);
};

const cancelDeleteMails = ({
  id,
  conversationsIds,
  limit,
  offset,
  sortBy,
  mode,
  folder,
}) => {
  const params = {};

  if (id) {
    params.id = id;
  } else if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.sortBy = sortBy;
    params.folder = folder;
    params.messageType = mode;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail/cancel-delete', {
    params,
    baseURL: baseUrlV2,
  });
};

const cancelDeleteMailsFromSearchList = ({
  conversationsIds,
  limit,
  offset,
  search,
}) => {
  const params = {};

  if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.search = search;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail-search/cancel-delete', { params });
};

const cancelMoveTo = ({
  conversationsIds,
  to,
  limit,
  offset,
  sortBy,
  mode,
  folder,
}) => {
  const params = {
    to,
    limit,
    offset,
    sortBy,
    folder,
    messageType: mode,
    conversationIds: JSON.stringify(conversationsIds),
  };

  return axiosInstance.get('mail/cancel-move-to', { params });
};

const cancelArchiveMails = ({
  mailMovesIds,
  limit,
  offset,
  sortBy,
  mode,
  folder,
}) => {
  const params = {
    limit,
    offset,
    sortBy,
    folder,
    messageType: mode,
    mailMovesIds: JSON.stringify(mailMovesIds),
  };

  return axiosInstance.get('mail/cancel-archive', { params });
};

const deleteMailAttsBeacon = ({ draftId }) => {
  const { baseURL } = axiosInstance.defaults;

  navigator.sendBeacon(`${baseURL}mail-attachment/delete?id=${draftId}`);
};

const banMedia = (caller_id, ban) => {
  const BanData = new FormData();

  BanData.set('caller_id', caller_id);
  BanData.set('ban', ban);

  return axiosInstance.post('callers/ban-media', BanData);
};

const changeOperator = (user_id, session_id) => {
  const Operator = new FormData();

  Operator.set('user_id', user_id);
  Operator.set('session_id', session_id);

  return axiosInstance.post('booking/change-user', Operator);
};

const deleteTelegramMsg = (id) => {
  const Data = new FormData();

  Data.set('message_id', id);

  return axiosInstance.post('message/delete-telegram-message', Data);
};

const getSessionLog = (bookingId) => {
  return axiosInstance.get('sales-sessions/get-booking-log', {
    params: { bookingId },
    baseURL: baseUrlV2,
  });
};

const changeProfileRates = ({
  profileId,
  pricesDefault,
  pricesMember,
  pricesSecret,
}) => {
  const Prices = new FormData();

  Prices.set('id', profileId);
  Prices.set('prices', JSON.stringify(pricesDefault));
  Prices.set('prices_member', JSON.stringify(pricesMember));
  Prices.set('prices_secret', JSON.stringify(pricesSecret));

  return axiosInstance.post('diva/change-rates', Prices);
};

const getBookingsByFilters = (
  date,
  type,
  caller_type,
  is_prebooking,
  is_duo,
  search,
) => {
  const config = {
    params: {
      date,
      type,
      caller_type,
      is_prebooking,
      is_duo,
      search,
    },
  };
  return axiosInstance.get('sales-sessions/bookings-by-date', config);
};

//----------------dialpad start-------------------------------------------------------->
const getDefaultCallersList = (offset = 0, limit = CONTACTS_LIMIT) => {
  const params = {
    limit,
    offset,
  };

  return axiosInstance.get('dialpad/get-default-callers-list', {
    params,
    baseURL: baseUrlV2,
  });
};
const getRecentCallsList = (offset = 0, limit = CONTACTS_LIMIT) => {
  const params = {
    limit,
    offset,
  };

  return axiosInstance.get('dialpad/get-recent-contacts', {
    params,
    baseURL: baseUrlV2,
  });
};

const searchContactsDialpad = (
  query,
  offset = 0,
  limit = CONTACTS_LIMIT,
  cancelToken,
) => {
  const SearchData = new FormData();

  SearchData.set('query', query);
  SearchData.set('limit', limit);
  SearchData.set('offset', offset);

  const config = {
    baseURL: baseUrlV2,
  };

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  return axiosInstance.post('dialpad/search-by-callers', SearchData, config);
};

const searchContactsMailing = (
  query,
  offset = 0,
  limit = CONTACTS_LIMIT,
  cancelToken,
  except,
) => {
  const SearchData = new FormData();

  SearchData.set('query', query);
  SearchData.set('limit', limit);
  SearchData.set('offset', offset);
  // if (except?.length > 0) {
  //   except.forEach((id) => SearchData.append('except[]', id));
  // }
  except?.length > 0 && SearchData.set('except', JSON.stringify(except));

  const config = {
    baseURL: baseUrlV2,
  };

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  return axiosInstance.post('mailing/search-by-callers', SearchData, config);
};

//----------------dialpad end-------------------------------------------------------->
/* Cronjobs API */

const getAllRequirements = () => axiosInstanceCronjobs.get('requirements');

const getCounters = () => axiosInstanceCronjobs.get('statistics/counters');

const getFiltersCounters = () =>
  axiosInstanceCronjobs.get('statistics/filters');

const getOperatorsFiltersCounters = () =>
  axiosInstanceCronjobs.get('statistics/operators-filters');

const getPrebookFilterCounters = () =>
  axiosInstanceCronjobs.get('statistics/prebook-filter');

const getAllFilterCounters = () =>
  axiosInstanceCronjobs.get('statistics/all-filter');

const getActiveChatContacts = (type, offset = 0, mainFilter) => {
  const config = {
    params: {
      limit: CHAT_CONTACTS_LIMIT,
      type,
      offset,
      mainFilter,
    },
  };

  return axiosInstanceCronjobs.get('callers-chats/optimize-active-chats', config);
};

const getContactList = ({ type, offset = 0, limit, filter }) => {
  const config = {
    params: {
      limit: limit || CHAT_CONTACTS_LIMIT,
      type,
      offset,
      mainFilter: filter || null,
    },
  };

  return axiosInstanceCronjobs.get('callers-chats/optimize-callers-by-type', config);
};

const getAllCalls = () => axiosInstanceCronjobs.get('calls/queues');

const getOperatorsList = () => axiosInstanceCronjobs.get('users');

const addGirlsIdsThatLinksIsSendInChat = (sessionId, girlId) => {
  const Session = new FormData();

  Session.set('sessionId', sessionId);

  Session.set('girlId', girlId);

  return axios.post('sales-session/add-message-girl-id', Session);
};

const deleteGirlsIdsThatLinksIsSendInChat = (sessionId) => {
  const Session = new FormData();

  Session.set('sessionId', sessionId);

  return axios.post('sales-session/delete-from-message-girl-id', Session);
};

const getExtendedBookingInfoByCallerId = (callerId) => {
  return axiosInstanceCronjobs.get(
    `callers/extended-bookings-info?caller_id=${callerId}`,
  );
};

const getBookingsByMonth = (date) => {
  const config = {
    params: {
      date: date,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('bookings/monthly-calendar', config);
};

const getFilteredBookingsByDate = (filtersData) => {
  const {
    date,
    dateFrom,
    dateTo,
    agentId,
    confirmationStatus,
    type,
    meeting_type,
    prebooking,
    agent,
    finished,
    searchQuery,
    girlId,
    clientId,
    operatorId,
    offset = 0,
    limit,
    sortOption,
    sortOptionType,
  } = filtersData;

  const config = {
    params: {
      offset: offset,
      limit: limit || 30,
      date: date ? date : null,
      dateFrom: dateFrom ? dateFrom : null,
      dateTo: dateTo ? dateTo : null,
      agentId: !!agentId ? agentId : null,
      girlId: !!girlId ? girlId : null,
      clientId: !!clientId ? clientId : null,
      operatorId: !!operatorId ? operatorId : null,
      confirmationStatus: confirmationStatus,
      type: !!type ? type : null,
      meeting_type: !!meeting_type ? meeting_type : null,
      is_prebooking: prebooking ? 1 : null,
      isAgent: agent ? 1 : null,
      status: finished ? 'finished' : null,
      query: searchQuery || null,
      orderBy: sortOption ? sortOption : null,
      sort: sortOptionType ? sortOptionType : null,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('bookings/get-by-date', config);
};

const getBookingById = (id) => {
  const config = {
    params: {
      id
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('bookings/get-by-id', config);
}

const getBookingsByIds = (ids) => {
  const config = {
    params: {
      ids
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('bookings/get-by-ids', config);
}

const getFutureBookings = (clientIds = [], girlId = [], exceptUuid = []) => {
  const config = {
    params: {
      girlId,
      clientIds,
      exceptUuid
    },
    baseURL: baseUrlV2,
  }

  return axiosInstance.get('bookings/future', config);
}

const getOptimizedBookingsByDate = ({ cancelToken, ...filtersData }) => {
  const config = {
    params: filtersData,
    baseURL: baseUrlV2,
    cancelToken
  };

  return axiosInstance.get('bookings', config);
}

const getAgentAndRequests = (params) => {
  const config = {
    baseURL: baseUrlV2,
    params
  }

  return axiosInstance.get('bookings/get-agent-and-requests', config);
}

const getExportedBookingsDownloadLink = (params) => {
  const {
    date,
    dateFrom,
    dateTo,
    agentId,
    confirmationStatus,
    type,
    meeting_type,
    prebooking,
    agent,
    finished,
    searchQuery,
    girlId,
    clientId,
    operatorId,
    sortOption,
    sortOptionType,
    all,
    exceptIds,
    includeIds,
  } = params;

  const config = {
    params: {
      date: date ? date : null,
      dateFrom: dateFrom ? dateFrom : null,
      dateTo: dateTo ? dateTo : null,
      agentId: !!agentId ? agentId : null,
      girlId: !!girlId ? girlId : null,
      clientId: !!clientId ? clientId : null,
      operatorId: !!operatorId ? operatorId : null,
      confirmationStatus: confirmationStatus,
      type: !!type ? type : null,
      meeting_type: !!meeting_type ? meeting_type : null,
      is_prebooking: prebooking ? 1 : null,
      isAgent: agent ? 1 : null,
      status: finished ? 'finished' : null,
      query: searchQuery?.length ? searchQuery : null,
      orderBy: sortOption ? sortOption : null,
      sort: sortOptionType ? sortOptionType : null,
      all: all ? 1 : 0,
      except: !!exceptIds.length ? JSON.stringify(exceptIds) : null,
      include: !!includeIds.length ? JSON.stringify(includeIds) : null,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('calendar/get-download-link', config);
};

const getGirlsCoordinatesByIds = (ids) => {
  const config = {
    params: { callerIds: JSON.stringify(ids) },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('map/girls-coordinates', config);
};

const getGirlsCoordinatesByBounds = (params) => {
  const {
    latitudeMin,
    latitudeMax,
    longitudeMin,
    longitudeMax,
    radiusLongitudeMin,
    radiusLongitudeMax,
    radiusLatitudeMin,
    radiusLatitudeMax,
    centerLatitude,
    centerLongitude,
    radius,
    name = '',
    cacheClear,
    availableFilter,
  } = params;

  const config = {
    params: {
      latitudeMin,
      latitudeMax,
      longitudeMin,
      longitudeMax,
      radiusLongitudeMin,
      radiusLongitudeMax,
      radiusLatitudeMin,
      radiusLatitudeMax,
      centerLatitude,
      centerLongitude,
      radius,
      ['host-profile']: params['host-profile'],
    },
    baseURL: baseUrlV2,
  };

  if (cacheClear) config.params.cacheClear = 1;
  if (name.length) config.params.name = name;
  if (availableFilter) config.params.is_available = 1;

  return axiosInstance.get('map/global-girls', config);
};

const getCountriesOptionsForGirlsMap = ({
  name = '',
  limit = 20,
  offset = 0,
}) => {
  const config = {
    params: {
      name,
      limit,
      offset,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('address-filter/country', config);
};

const getCitiesOptionsForGirlsMap = ({
  country,
  name = '',
  limit = 20,
  offset = 0,
}) => {
  const config = {
    params: {
      name,
      limit,
      offset,
      country,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('address-filter/city', config);
};

const getLocationsOptionsForGirlsMap = ({
  city,
  name = '',
  limit = 50,
  offset = 0,
}) => {
  const config = {
    params: {
      name,
      limit,
      offset,
      city,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('address-filter/location', config);
};

const getAvailableMailsForUserRequest = (userId) => {
  const config = {
    params: {
      userId,
    },
  };

  return axiosInstance.get('mail/get-mails-available-for-user', config);
};

const changeDefaultMailForUser = (data) => {
  const config = {
    params: isNumber(data) ? {
      emailId: data,
    } : {
      type: data,
    },
  };

  return axiosInstance.get('mail/set-default', config);
};

const getAgentsFilters = () => {
  return axiosInstance.get('agents/get-for-bookings', { baseURL: baseUrlV2 });
};

const getUsersAvatarsForMail = (emailId) =>
  axiosInstance.get('mail/users-avatars-for-mail', {
    params: { email_id: emailId },
    baseURL: baseUrlV2,
  });

const enterOnTheMailPage = (emailId) => {
  return axiosInstance.get('/mail/user-into-mailbox', {
    params: { email_id: emailId },
    baseURL: baseUrlV2,
  })
}

const exitOnTheMailPage = (emailId) => {
  return axiosInstance.get('/mail/user-out-of-mailbox', {
    params: { email_id: emailId },
    baseURL: baseUrlV2,
  })
}

const getEscortOptionsForBookingsFilter = ({ limit = 20, offset = 0, ...rest }) => {
  const config = {
    params: {
      limit,
      offset,
      ...rest
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('calendar/get-escorts', config);
};

const getCallersOptionsForBookingsFilter = ({ limit = 20, offset = 0, ...rest }) => {
  const config = {
    params: {
      limit,
      offset,
      ...rest
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('calendar/get-callers', config);
};

const getOperatorsOptionsForBookingsFilter = ({ query = '', limit = 20, offset = 0 }) => {
  const config = {
    params: {
      query,
      limit,
      offset,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('calendar/get-operators', config);
};

const getChatGroups = (callerId) => {
  const config = {
    params: {
      callerId,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('telegram-group/all', config);
};

const getChatGroupsPhotos = (groupId, memberIds, type) => {
  const isLocalhost = window?.location?.hostname === 'localhost';
  const telegramServer = getTelegramServerData('type', type);

  const config = {
    params: {
      groupId: groupId,
      telegram_user_ids: memberIds,
    },
    baseURL: isLocalhost ? baseUrlV2 : telegramServer?.url,
    headers: {
      ...(!isLocalhost ? { 'Consumer-Api-Token': telegramServer?.token } : {}),
    },
  };

  if (isLocalhost) {
    return axiosInstance.get(`telegram-client/test-photos`, config);
  } else {
    return axiosInstance.get(`group/${groupId}/members/photos`, config);
  }
};

const getTelegramWebmasterMsgs = (groupId, messageIds) => {
  const config = {
    baseURL: baseUrlV2,
    params: {
      telegram_group_id: groupId,
      telegram_message_ids: JSON.stringify(messageIds),
    },
  };

  return axiosInstance.get('web-master/get-map-telegram-group-messages', config);
};

const getTelegramServerUrl = () => {
  const config = {
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('telegram-client/telegram-server-url', config);
};

let cancelGetGroupInteractions;

const getGroupInteractions = (groupId, offset = null, withCancel = true, type) => {
  if (cancelGetGroupInteractions) {
    cancelGetGroupInteractions('Request "getTgGroupInteractions" canceled');
  }

  const isLocalhost = window?.location?.hostname === 'localhost';
  const telegramServer = getTelegramServerData('type', type);

  const config = {
    params: {
      groupId: groupId,
      limit: LIMIT,
      offset: 0,
      ...(isLocalhost ? {
          token: 'j9qKRER60EtN0RroPlWsFHuhwDIel9bV',
          url: 'http://tg-backend/api/',
        } : {}
      ),
    },
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      if (withCancel) {
        cancelGetGroupInteractions = c;
      }
    }),
    baseURL: isLocalhost ? baseUrlV2 : telegramServer?.url,
    headers: {
      ...(!isLocalhost ? { 'Consumer-Api-Token': telegramServer?.token } : {}),
    },
  };

  if (offset) {
    config.params.offset = offset;
  }

  if (isLocalhost) {
    return axiosInstance.get(`telegram-client/test`, config);
  } else {
    return axiosInstance.get(`group/${groupId}/messages`, config);
  }
};

const sendGroupMessage = (message, groupId, type) => {
  const isLocalhost = window?.location?.hostname === 'localhost';
  const telegramServer = getTelegramServerData('type', type);

  const config = {
    baseURL: isLocalhost ? baseUrlV2 : telegramServer?.url,
    headers: {
      ...(!isLocalhost ? { 'Consumer-Api-Token': telegramServer?.token } : {}),
    },
  };

  const Message = new FormData();

  Message.set('message', message.text || '');
  Message.set('group_id', groupId);

  if (message.files) {
    message.files.forEach((file) => Message.append('media[]', file));
  }

  if (isLocalhost) {
    return axiosInstance.post('telegram-client/send-message-group', Message, config);
  } else {
    return axiosInstance.post('message/send-to-group', Message, config);
  }
};

const getSubwayLines = () => {
  const config = {
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('map/subway-lines', config);
};

const dismissForMerge = (id) => {
  return axiosInstance.get('callers/dismiss-from-merge', { params: { id } });
}

const sendMailNote = (conversationId, body) => {
  const Message = new FormData();

  Message.set('conversationId', conversationId);
  Message.set('body', body);

  return axiosInstance.post('mail-notes/add', Message);
}

const callTo = (from, to) => {
  const Call = new FormData();

  const config = {
    baseURL: baseUrlV2,
  };

  Call.set('from', from);
  Call.set('to', to);

  return axiosInstance.post('calls/make-call', Call, config);
}

const getIpPhones = () => {
  return axiosInstance.get('ip-phones');
};

const setIpPhone = (userId, phoneId) => {
  const Phone = new FormData();

  Phone.set('userId', userId);
  Phone.set('ipPhoneId', phoneId);

  return axiosInstance.post('active-users/set-ip-phone', Phone);
};

const getDefaultAvatars = () => {
  const config = {
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('default-avatars/', config);
};

const setDefaultAvatars = (file) => {
  const config = {
    baseURL: baseUrlV2,
  };

  const Img = new FormData();
  Img.set('file', file);

  return axiosInstance.post('default-avatars/create', Img, config);
};

const updateDefaultAvatar = (id, file) => {
  const config = {
    baseURL: baseUrlV2,
  };

  const Img = new FormData();
  Img.set('file', file);

  return axiosInstance.post(`default-avatars/update/${id}`, Img, config);
};

const deleteDefaultAvatar = (id) => {
  const config = {
    baseURL: baseUrlV2,
  };

  const Img = new FormData();
  Img.set('id', id);

  return axiosInstance.post(`default-avatars/delete/${id}`, Img, config);
};

const getMessagesByCaller = (callerId, limit = 10, offset = 0) => {
  const config = {
    params: {
      limit,
      offset
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get(`interactions/caller-for-special-request/${callerId}`, config)
}

const addSpecialRequestsFromMessages = (bookingId, messages) => {
  const Messages = new FormData();

  Messages.set('bookingId', bookingId);
  Messages.set('messages', JSON.stringify(messages));

  return axiosInstance.post(`callers-requests/add-from-messages`, Messages)
}

const deleteSpecialRequestsFromMessages = (bookingId, requestIds) => {
  const Requests = new FormData();

  Requests.set('bookingId', bookingId);
  Requests.set('requestIds', JSON.stringify(requestIds));

  return axiosInstance.post(`callers-requests/delete-from-messages`, Requests);
}

const getPermissions = (role) => {
  const Permissions = new FormData();
  Permissions.set('role', role);

  return axiosInstance.post('rbac/permission/get-permissions', Permissions, {
    baseURL: ADMIN_ROOT,
  });
};

const getAllTags = () => {
  return axiosInstance.get('tags', { params: { limit: 1000 }});
}

const switchAdrBookCaller = (calledId) => {
  const Caller = new FormData();

  Caller.set('caller_id', calledId);

  return axiosInstance.post('address/sync-pages', Caller, {
    baseURL: baseUrlV2,
  });
};

const switchSalesSessionByCaller = (calledId) => {
  const Session = new FormData();

  Session.set('caller_id', calledId);

  return axiosInstance.post('sales-sessions/switch-session-by-caller-id', Session, {
    baseURL: baseUrlV2,
  });
};

const switchSalesSession = (sessionId, isFromHistory = false) => {
  const Session = new FormData();

  Session.set('sales_session_id', sessionId);
  Session.set('is_from_history', String(isFromHistory));

  return axiosInstance.post('sales-sessions/switch-sales-session', Session, {
    baseURL: baseUrlV2,
  });
};

const getAllComputers = () => axiosInstance.get('computer', { baseURL: baseUrlV2 })

const getWebmasterTask = (id) => axiosInstance.get(`web-master/view/${id}`, { baseURL: baseUrlV2 })

const getGirlOnMap = (profile) => {
  const config = {
    params: {
      search: profile.id,
      is_mistress: profile.is_mistress === "true" ? 1 : 0,
      name: profile.name,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('map/search', config)
}

const getDistanceToGirl = (id, latitude, longitude) => {
  const config = {
    params: {
      id,
      latitude,
      longitude,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('map/get-pop-up-info', config)
}

const getAddressByCoordinates = (latitude, longitude) => {
  const config = {
    params: {
      latlng: `${latitude},${longitude}`,
      key: GOOGLE_MAPS_API_KEY,
    },
  }

  return axios.get('https://maps.googleapis.com/maps/api/geocode/json', config);
}

const getDivaLink = (girlIds) => {
  const config = { params: { girlIds } };

  return axiosInstance.get('diva/girls-link', config);
}

const getDefaultRingtones = () => {
  return axiosInstance.get('ringtones/get-default');
}

const getGlossaryList = () => {
  const config = {
    params: {
      limit: -1,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('glossary', config);
};

const checkAvailabilityComputer = (name) => {
  const config = {
    params: {
      name,
    },
    baseURL: baseUrlV2,
  };

  return axiosInstance.get('computer/is-ok', config);
};

const API = {
  getDivaLink,
  getAddressByCoordinates,
  setDefaultAvatars,
  getDefaultAvatars,
  updateDefaultAvatar,
  deleteDefaultAvatar,
  getPermissions,
  sendMailNote,
  dismissForMerge,
  changeOperator,
  deleteBookingV2,
  getAllContactReminders,
  deleteReminderFromWidget,
  getSessionLog,
  banMedia,
  getOptimizedBookingsByDate,
  getSenderGsm,
  getBookingById,
  loginUser,
  getContactsByProfileDivaId,
  getContactByProfileId,
  logoutUser,
  updateUserTimezone,
  getAllCalls,
  getConnectedContacts,
  removeMissedCallForAll,
  putCallOnHold,
  hangUpCallOnHold,
  createTransferredCall,
  getAllVoicemails,
  getInteractions,
  sendMessage,
  markMessageAsUnread,
  markContactChatAsRead,
  setMessageReminder,
  getConversationMedia,
  getContactLog,
  getContactList,
  getActiveDivaProfiles,
  getContactsById,
  getAllContactsList,
  saveContact,
  removeContact,
  toggleContactPin,
  toggleContactMark,
  toggleContactBlock,
  getFilteredContacts,
  getActiveChatContacts,
  toggleContactActiveChat,
  searchTags,
  getTopTags,
  addContactTag,
  removeContactTag,
  getAllTemplates,
  getMsgShortcuts,
  getOperatorsList,
  changeBusyStatus,
  changeUserAvatar,
  getUserRingtones,
  updateUserRingtone,
  getChatInteractions,
  sendMessageToChat,
  updateChatMsg,
  removeChatMsg,
  markChatAsRead,
  sendDeliveredStatus,
  sendTyping,
  getFilterFieldOptions,
  // getPrivateRooms,
  getFutureBookings,
  getRoomsById,
  getRoomsList,
  searchRooms,
  saveRoom,
  getChatRooms,
  searchContacts,
  searchMessageInChat,
  searchContactMessage,
  getMessageContext,
  getAllSessions,
  getAllOptimizedSessions,
  getAllSessionsIds,
  getSessionById,
  createBooking,
  getContactUnfinishedBookings,
  getBooking,
  getBookingsByFilters,
  getBookingsDividedByDate,
  getContactsForBookings,
  updateBooking,
  updateBookingsRequirementChecked,
  getOverdueBookings,
  addBookingRequest,
  updateBookingRequest,
  deleteBookingRequest,
  getContactRequests,
  getContactRequestsCount,
  getSuccessfulSessions,
  createSession,
  closeSession,
  deleteSession,
  updateComparedIds,
  removeComparedId,
  addBufferId,
  getAddressesByPostcode,
  removeBufferedId,
  updateActiveFilters,
  updateAdditionalFilters,
  addBookedId,
  removeBookedId,
  getSessionsHistory,
  toggleHistorySessionSuccess,
  continueClosedSession,
  duplicateClosedSession,
  transferTheSession,
  responseForTransferSession,
  addAssistantToSession,
  responseForAddToAssistant,
  removeAssistantFromSession,
  removeViewedInSession,
  updateUserSalesFilters,
  lockContact,
  getUnreadChats,
  markVoicemailAsListened,
  changeDefaultContactTel,
  changeDefaultContactEmail,
  changeClientInSession,
  updateGalleryUrlInSession,
  getClientSessionCounts,
  getGirlBookings,
  getIsGirlHasBookingsForToday,
  getChatMessageContext,
  removeUserFromRoom,
  leaveRoom,
  removeRoomForAll,
  addUsersFromRoom,
  updateRoom,
  addProposedId,
  removeProposedId,
  createWarmTransferCall,
  getChatConversationMedia,
  getGirlsChatInteraction,
  fetchCallsLog,
  cancelConferenceInvitation,
  getAgentGirls,
  deleteGirlFromAgent,
  addGirlToAgent,
  searchGirls,
  leaveConference,
  getUserNotifications,
  readUserNotification,
  readAllUserNotifications,
  getUserNotificationsCount,
  changeSettingsUserMode,
  getSettingsAllNumbers,
  setSettingsPhoneNumberAsDefault,
  pinMsg,
  unpinMsg,
  clearAllMissedForMe,
  createNewChat,
  inviteParticipantsToConference,
  removeMissedContactCallForMe,
  removeMissedOperatorCallForMe,
  pinClientMsg,
  getAllMessageCount,
  updateUserInfo,
  getAllConferences,
  getContactReminders,
  addGirlToAvailableByMsgId,
  addGirlToAvailable,
  removeGirlFromAvailableByMsgId,
  removeGirlFromAvailable,
  changeGirlsStatus,
  addMessageToAvailableGlossary,
  removeMessageFromAvailableGlossary,
  getGirlsByDivaId,
  getAllWebmasterTasks,
  addWebmasterTask,
  completeWebmasterTask,
  removeMessageReminder,
  setContactAudioStatus,
  setRoomAudioStatus,
  getContactDateMsgContext,
  getContactChatOfHistorySession,
  cleanContactsUnreadCount,
  mergeContactCards,
  getIsIAmLoggedIn,
  getActiveDivaGirls,
  getPrivatePhotos,
  getPrivateVideos,
  getDivaFilters,
  getAdditionalFiltersFromDiva,
  getNightBotReport,
  deleteNightBotReportSession,
  addProfilesToGallery,
  getProfilesGalleryLink,
  removeProfileFromGallery,
  getGalleryUrlBySessionId,
  sendMailing,
  getMailingHistory,
  getTimezones,
  getClientNotFor,
  addClientNotFor,
  removeClientNotFor,
  getPhoneLabels,
  getLinksLabels,
  getContactsForMerge,
  getGirlsByContactUid,
  getClientPrevBookedProfiles,
  changeContactDefaultChannel,
  redirectIncomingCall,
  getGirlsMsgCountToday,
  sortProfileReviews,
  searchProfileReviews,
  editProfileReview,
  deleteProfileReview,
  changeUserTimeFormat,
  takeFutureBooking,
  getContactScheduledMsgs,
  getScheduledMsgsCount,
  createScheduledMsg,
  updateScheduledMsg,
  deleteScheduledMsg,
  getContactServiceMsgs,
  getServiceMsgsCount,
  getTemporaryGirlContacts,
  getDebtGirlContacts,
  offerStartCall,
  answerStartCall,
  sendLocalDescription,
  sendIceCandidate,
  dismissWebrtcCall,
  hangUpWebrtcCall,
  getWebrtcConferences,
  joinToWebrtcConference,
  leaveWebrtcConference,
  inviteParticipantsToWebrtcConference,
  changeWebrtcConferenceStatus,
  changeParticipantStatus,
  acceptWebrtcConference,
  rejectWebrtcConference,
  webrtcParticipantMute,
  webrtcConferenceMute,
  sendConnectionStatus,
  getActiveDivaGirlsByContactsIds,
  getContactsForAdrBook,
  getOptimizedContactsForAdrBook,
  getContactsCountForAdrBook,
  getTagsForAdrBook,
  getNotForContacts,
  getContactNotes,
  addFeedback,
  editContactNote,
  deleteContactNote,
  getEscortsForFeedback,
  getOperatorsForFeedback,
  getCancelBookingReasons,
  searchContactsByMsg,
  getGirlsWithStatus,
  syncContacts,
  getEmailsNew,
  getMailChatMessages,
  getCountOfUnreadEmails,
  sendEmailMessage,
  deleteTelegramMsg,
  sendEmailReply,
  deleteMailsToTrash,
  deleteMailsPermanently,
  deleteMailsFromSearchList,
  getAgentAndRequests,
  moveMailsToFolder,
  archiveMails,
  markMailsNotSpam,
  undeleteMails,
  deleteMailDraft,
  uploadMailAttachments,
  deleteMailAttachment,
  cancelDelayedMail,
  cancelDeleteMails,
  cancelDeleteMailsFromSearchList,
  cancelMoveTo,
  cancelArchiveMails,
  deleteMailAttsBeacon,
  changeProfileRates,
  getAllRequirements,
  getCounters,
  getFiltersCounters,
  getOperatorsFiltersCounters,
  getPrebookFilterCounters,
  getAllFilterCounters,
  sendVerificationCode,
  resendVerificationCode,
  getDefaultCallersList,
  getRecentCallsList,
  setIsMailStarred,
  searchContactsDialpad,
  getMinifiedContactsWithOneEmail,
  deletePinnedMessage,
  addProfilesToSessionBuffer,
  getTags,
  createTag,
  getTagColors,
  addGirlsIdsThatLinksIsSendInChat,
  deleteGirlsIdsThatLinksIsSendInChat,
  getCompletedSessions,
  getUserRingtonesByType,
  getAvailableRingtones,
  changeRingtone,
  getExtendedBookingInfoByCallerId,
  getGirlsGlossaryChatInteraction,
  getFilteredBookingsByDate,
  getGirlsCoordinatesByIds,
  getGirlsCoordinatesByBounds,
  getCountriesOptionsForGirlsMap,
  getCitiesOptionsForGirlsMap,
  getLocationsOptionsForGirlsMap,
  getMentionedProfiles,
  getStatisticsBookingsByCallerId,
  getCustomRingtones,
  getDefaultCallersRingtones,
  deleteCustomRingtones,
  addCustomRingtones,
  getAllAvailableCustomRingtones,
  getBookingsByMonth,
  createPhoneLabels,
  getAvailableMailsForUserRequest,
  changeDefaultMailForUser,
  getAgentsFilters,
  deleteAttachment,
  getUsersAvatarsForMail,
  getDivaGirls,
  sendEmailMessageWithFiles,
  sendTypeInMail,
  getTasksAndReminders,
  enterOnTheMailPage,
  exitOnTheMailPage,
  getEscortOptionsForBookingsFilter,
  getCallersOptionsForBookingsFilter,
  getOperatorsOptionsForBookingsFilter,
  getExportedBookingsDownloadLink,
  getChatGroups,
  createTasks,
  updateTasks,
  updateReminders,
  markAsDoneTasksOrReminders,
  markAsInProgressTasksOrReminders,
  deleteTasks,
  deleteReminders,
  searchContactsMailing,
  getTelegramServerUrl,
  getTelegramWebmasterMsgs,
  getGroupInteractions,
  sendGroupMessage,
  getSubwayLines,
  getChatGroupsPhotos,
  createMailRemind,
  getIpPhones,
  setIpPhone,
  callTo,
  getCallerIdByProfileDivaId,
  getOptimizedChatBookings,
  getMessagesByCaller,
  addSpecialRequestsFromMessages,
  deleteSpecialRequestsFromMessages,
  switchAdrBookCaller,
  switchSalesSessionByCaller,
  switchSalesSession,
  getBookingsByIds,
  createWebmasterTask,
  updateWebmasterTask,
  removeWebmasterTask,
  getAllComputers,
  getWebmasterTask,
  updatePhoneLabels,
  getMailNotes,
  getGirlOnMap,
  getDistanceToGirl,
  getAllTags,
  getDefaultRingtones,
  sendEmailForward,
  getGlossaryList,
  checkAvailabilityComputer,
};

export default API;
