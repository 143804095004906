import classNames from 'classnames';

import "./BookingHorizontalItem.scss";

const BookingHorizontalItem = (props) => {
  const {
    className,
    ConfirmationStatus,
    Requirements,
    Operator,
    Date,
    Details,
    Client,
    Status,
    Escort,
    handleOpenBookingModal,
  } = props;
  
  return (
    <div
      className={classNames(className, "booking-horizontal-item")}
      onClick={handleOpenBookingModal}
    >
      <div className="booking-horizontal-item__color-line" />
      <div className="booking-horizontal-item__body">
        <div className="booking-horizontal-item__column">
          <div className="booking-horizontal-item__row">
            <Date isRanged />
            <Status className="booking-horizontal-item__status" />
          </div>

          <div className="booking-horizontal-item__row">
            <Escort />
            <Details />
            <Client />
          </div>
        </div>

        <div className="booking-horizontal-item__column">
          <ConfirmationStatus />
          <Requirements  />
          <Operator />
        </div>
      </div>
    </div>
  )
}

export default BookingHorizontalItem;
