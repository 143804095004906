import API from 'api/api';
import ICONS from 'assets/icons';
import Button from 'components/UI/Button/Button';
import { BUTTON_THEMES, SIZES } from 'config/constants';

const TakeChatButton = (props) => {
  const isTaken = props.activeRecipient.relatedUserId && props.activeRecipient.relatedUserId === props.userId;

  const changeRelatedStatus = () => {
    const apiAction = !isTaken ? "lock" : "unlock";

    return API.lockContact(apiAction, props.activeRecipient.id);
  };

  return (
    <Button
      className={props.className}
      title={isTaken ? "Unlock" : "Lock"}
      theme={props.theme || BUTTON_THEMES.NONE_DARK}
      icon={isTaken ? ICONS.handRock : ICONS.handPaper}
      iconSize={props.iconSize || SIZES.L}
      disabled={!props.activeRecipient.id}
      onClick={changeRelatedStatus}
    />
  )
}

export default TakeChatButton;
